<div class="content">
    <div class="card-block full-height bg-white" fxFlex fxLayout="row" fxLayoutAlign="space-around center" fxLayout.sm="column">
        <div fxFlex="30%" class="card-item" (click)="onNavigateToPhysical()">
            <img src="../../../assets/images/home/cards/physical/physical-card@3x.png"
                srcset="../../../assets/images/home/cards/physical/physical-card@2x.png,
                ../../../assets/images/home/cards/physical/physical-card@3x.png" 
                class="card-preview"/>
            <span class="text card-text">{{'CARDS.PHYSICAL_CARDS' | translate}}</span>
        </div>
    
        <div fxFlex="30%" class="card-item" (click)="onNavigateToVirtual()">
            <img src="../../../assets/images/home/cards/virtual/virtual-card@3x.png"
                srcset="../../../assets/images/home/cards/virtual/virtual-card@2x.png,
                ../../../assets/images/home/cards/virtual/virtual-card@3x.png" 
                class="card-preview"/>
            <span class="text card-text">{{'CARDS.VIRTUAL_CARDS' | translate}}</span>
        </div>
    
        <div fxFlex="30%" class="card-item"(click)="onNavigateToChild()">
            <img src="../../../assets/images/home/cards/virtual/virtual-card@3x.png"
                srcset="../../../assets/images/home/cards/virtual/virtual-card@2x.png,
                ../../../assets/images/home/cards/virtual/virtual-card@3x.png"
                class="card-preview" />
            <span class="text card-text">{{'CARDS.CHILD_CARDS' | translate}}</span>
        </div>
    </div>
</div>

