import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective {

  previousScrollHeightMinusTop: number;
  readyFor: string;
  toReset = false;

  constructor(public elementRef: ElementRef) {
    this.previousScrollHeightMinusTop = 0;
    this.readyFor = 'up';
    this.restore();
  }

  reset(): void {
    console.log('reset is called ...');
    this.previousScrollHeightMinusTop = 0;
    this.readyFor = 'up';
    this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight;
    console.log('scroll height is: ', this.elementRef.nativeElement.scrollHeight);
    // resetting the scroll position to bottom because that is where chats start
  }

  restore(): void {
    console.log('restore is called ...');
    if (this.toReset) {
      console.log('to reset is ', this.toReset);
      if (this.readyFor === 'up') {
        this.elementRef.nativeElement.scrollTop = this.elementRef.nativeElement.scrollHeight - this.previousScrollHeightMinusTop;
        // restoring the scroll position to the one stored earlier
      }
      this.toReset = false;
    }
  }

  prepareFor(direction): void {
    console.log('preparefor is called ...');
    this.toReset = true;
    this.readyFor = direction || 'up';
    this.elementRef.nativeElement.scrollTop = !this.elementRef.nativeElement.scrollTop
      ? this.elementRef.nativeElement.scrollTop + 1
      : this.elementRef.nativeElement.scrollTop;
    this.previousScrollHeightMinusTop = this.elementRef.nativeElement.scrollHeight - this.elementRef.nativeElement.scrollTop;
      // current position is stored before new messages are loaded
  }

}
