import { OffersService } from './../../services/offers/offers.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromMcc from './mcc.action';
import { MccService } from '../../services/mcc/mcc.service';

@Injectable()
export class MccEffects {

    constructor(private actions$: Actions, private mccService: MccService) {}

    @Effect()
    loadMccAttempted$ = this.actions$.pipe(
        ofType<fromMcc.LoadMccAttemptedAction>(fromMcc.LOAD_MCC_ATTEMPTED),
        switchMap((data: fromMcc.LoadMccAttemptedAction) => this.mccService.getMcc(data.payload)
            .pipe(
                map(resp => new fromMcc.LoadMccSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromMcc.LoadMccFailedAction(err)); })
            )
        )
    );

    @Effect()
    addMccAttempted$ = this.actions$.pipe(
        ofType<fromMcc.AddMccAttemptedAction>(fromMcc.ADD_MCC_ATTEMPTED),
        switchMap((data: fromMcc.AddMccAttemptedAction) => this.mccService.add(data.payload)
            .pipe(
                map(resp => new fromMcc.AddMccSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromMcc.AddMccFailedAction(err)); })
            )
        )
    );

    @Effect()
    AddMultipleAttempted$ = this.actions$.pipe(
        ofType<fromMcc.AddMultipleMccAttemptedAction>(fromMcc.ADD_MULTIPLE_MCC_ATTEMPTED),
        switchMap((data: fromMcc.AddMultipleMccAttemptedAction) => this.mccService.addMultiple(data.formData)
            .pipe(
                map(resp => new fromMcc.AddMultipleMccSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromMcc.AddMultipleMccFailedAction(err)); })
            )
        )
    );

    @Effect()
    updateMccAttempted$ = this.actions$.pipe(
        ofType<fromMcc.UpdateMccAttemptedAction>(fromMcc.UPDATE_MCC_ATTEMTPED),
        switchMap((data: fromMcc.UpdateMccAttemptedAction) => this.mccService.update(data.idMcc, data.payload)
            .pipe(
                map(resp => new fromMcc.UpdateMccSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromMcc.UpdateMccFailedAction(err)); })
            )
        )
    );

    @Effect()
    deleteMccAttempted$ = this.actions$.pipe(
        ofType<fromMcc.DeleteMccAttemptedAction>(fromMcc.DELETE_MCC_ATTEMPTED),
        switchMap((data: fromMcc.DeleteMccAttemptedAction) => this.mccService.delete(data.idMcc)
            .pipe(
                map(resp => new fromMcc.DeleteMccSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromMcc.DeleteMccFailedAction(err)); })
            )
        )
    );
}