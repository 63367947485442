import { OffersService } from './../../services/offers/offers.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromOffers from './offers.actions';

@Injectable()
export class OffersEffects {

    constructor(private actions$: Actions, private offersService: OffersService) {}

    @Effect()
    loadLogsAttempted$ = this.actions$.pipe(
        ofType<fromOffers.LoadOffersAttemptedAction>(fromOffers.LOAD_OFFERS_ATTEMPTED),
        switchMap((data: fromOffers.LoadOffersAttemptedAction) => this.offersService.getOffers()
            .pipe(
                map(resp => new fromOffers.LoadOffersSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromOffers.LoadOffersFailedAction(err)); })
            )
        )
    );

    @Effect()
    addOfferAttempted$ = this.actions$.pipe(
        ofType<fromOffers.AddOfferAttemptedAction>(fromOffers.ADD_OFFER_ATTEMPTED),
        switchMap((data: fromOffers.AddOfferAttemptedAction) => this.offersService.add(data.payload)
            .pipe(
                map(resp => new fromOffers.AddofferSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromOffers.AddOfferFailedAction(err)); })
            )
        )
    );

    @Effect()
    deleteOfferAttempted$ = this.actions$.pipe(
        ofType<fromOffers.DeleteOfferAttemptedAction>(fromOffers.DELETE_OFFER_ATTEMPTED),
        switchMap((data: fromOffers.DeleteOfferAttemptedAction) => this.offersService.delete(data.payload)
            .pipe(
                map(resp => new fromOffers.DeleteOfferSuccesffulAction(resp)),
                catchError(err => { console.log(err); return of (new fromOffers.DeleteOfferFailedAction(err)); } )
            )
        )
    );

    @Effect()
    modifyOfferAttempted$ = this.actions$.pipe(
        ofType<fromOffers.UpdateOfferAttemtpedAction>(fromOffers.UPDATE_OFFER_ATTEMPTED),
        switchMap((data: fromOffers.UpdateOfferAttemtpedAction) => this.offersService.modify(data.id, data.payload)
            .pipe(
                map(resp => new fromOffers.UpdateOfferSuccessfulAction(resp)),
                catchError(err => {console.log(err); return of (new fromOffers.UpdateOfferFailedAction(err)); })
            )
        )
    );
}
