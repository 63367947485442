<div class="Details-container">
    <div class="Details-wrapper">
        <h1 class="Details-header">{{'SETTINGS.PROFILE.HEADER' | translate}}</h1>
        <hr />
        <div>
            <div class="spinner" *ngIf="loading" >
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
            
            <div class="flex-row" *ngIf="!loading" >
                <div class="w-50">
                    <form [formGroup]="profileForm" id="user-form" style="width: 100%;">
                        <dl class="form-group mt-1">
                            <dt class="input-label">
                                <label aria-autocomplete="off" for="user-fullname">{{'SETTINGS.PROFILE.USERNAME' | translate}}<span class="error"> * </span></label>
                            </dt>
                            <dd>
                                <input [ngClass]="{'border-error' : this.profileForm.controls['fullname'].hasError('required')}" class="form-control long" formControlName="fullname" autocomplete="off" type="text" id="user-fullname">
                            </dd>
                        </dl>
        
                        <dl class="form-group mt-1">
                            <dt class="input-label">
                                <label aria-autocomplete="off" for="user-email">{{'SETTINGS.PROFILE.EMAIL' | translate}}<span class="error"> * </span></label>
                            </dt>
                            <dd>
                                <input [ngClass]="{'border-error' : this.profileForm.controls['email'].hasError('required')}" class="form-control long" formControlName="email" autocomplete="off" type="text" id="user-email">
                            </dd>
                        </dl>        
                        <hr />
                        <dl class="form-group mt-1">
                            <dt class="input-label">
                                <label aria-autocomplete="off" for="user-role">{{'SETTINGS.PROFILE.ROLE' | translate}}<span class="error"> * </span></label>
                            </dt>
                            <dd>
                                <select disabled="true" [ngClass]="{'border-error' : this.profileForm.controls['role'].hasError('required')}" class="form-control long" formControlName="role" autocomplete="off" id="user-role">
                                    <option value="SUPER_ADMIN">SUPER_ADMIN</option>
                                    <option value="ADMIN">ADMIN</option>
                                    <option value="USER">USER</option>
                                </select>
                            </dd>
                        </dl>
                        <div class="d-flex flex-rows m-1">
                            <button type="button" class="btn btn-sm btn-success" [disabled]="this.profileForm.invalid" (click)="updateProfil()">{{'BUTTONS.UPDATE' | translate}}</button>
                            <span *ngIf="userUpdating" class="ml-1 pt-1"><mat-spinner [diameter]="15"></mat-spinner></span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
