import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoadCommissionTypesAttemptedAction } from '../../../../core/store/commission/commission.action';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { getCommissionTypes } from '../../../../core/store/commission/commission.reducer';
import { filter } from 'rxjs/operators';
import { AddOperationAttemptedAction } from '../../../../core/store/operation/operation.action';
import { getOperationAddResponse } from '../../../../core/store/operation/operation.reducer';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-operation',
  templateUrl: './create-operation.component.html',
  styleUrls: ['./create-operation.component.scss']
})
export class CreateOperationComponent implements OnInit, OnDestroy {

  loading: boolean;

  showError: boolean;

  operationForm: FormGroup;

  private subscription;

  private addingOperationSubscription$;

  commissionTypes: any[];

  commissiontypesLength: number;

  commissionTypesDisplayedColumns: string[] = ['type', 'value', 'actions'];

  constructor(private fb: FormBuilder, private store: Store<AppState>, private router: Router) {
    this.operationForm = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      operation: [null, Validators.compose([Validators.required])],
      paidType: [null, Validators.compose([Validators.required])],
      comissionType: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.addingOperationSubscription$) {
      this.addingOperationSubscription$.unsubscribe();
      console.log('unsubscribing ...');
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.store.dispatch(new LoadCommissionTypesAttemptedAction());
    this.subscription = this.store.select(getCommissionTypes).pipe(filter(data => data !== undefined)).subscribe(data => {
      console.log(data);
      this.commissionTypes = data;
      this.commissiontypesLength = data.length;
      this.loading = false;
    });
    this.addingOperationSubscription$ = this.store.select(getOperationAddResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(res => {
        if (res.code === 200) {
          console.log('res : ', res);
          this.router.navigate(['/application/packages/operations']);
        } else {
          this.showError = true;
          this.operationForm.reset();
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          setTimeout(() => {
            this.showError = false;
          }, 3000);
        }
      });
  }

  onSubmit(): void {
    this.loading = true;
    const payload = {
      name: this.operationForm.get('name').value,
      description: this.operationForm.get('description').value,
      operation: this.operationForm.get('operation').value,
      paidType: this.operationForm.get('paidType').value,
      commissionType: {
        id: this.commissionTypes[this.operationForm.get('comissionType').value].id,
        type: this.commissionTypes[this.operationForm.get('comissionType').value].type,
        value: this.commissionTypes[this.operationForm.get('comissionType').value].value
      }
    };
    this.store.dispatch(new AddOperationAttemptedAction(payload));
  }

}
