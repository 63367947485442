import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor(public datePipe: DatePipe) {}

  transform(value: unknown, ...args: unknown[]): unknown {
    return this.datePipe.transform(value, 'M/d/yy, h:mm a');
  }

}
