import { Action } from '@ngrx/store';

export const LOAD_PHYSICAL_CARDS_ATTEMPTED = '[Cards] Load Physical Cards - Attempted';
export const LOAD_PHYSICAL_CARDS_SUCCESSFUL = '[Cards] Load Physical Cards - Successful';
export const LOAD_PHYSICAL_CARDS_FAILED = '[Cards] Load Physical Cards - Failed';

export const LOAD_VIRTUAL_CARDS_ATTEMPTED = '[Cards] Load Virtual Cards - Attempted';
export const LOAD_VIRTUAL_CARDS_SUCCESSFUL = '[Cards] Load Virtual Cards - Successful';
export const LOAD_VIRTUAL_CARDS_FAILED = '[Cards] Load Virtual Cards - Failed';

export const LOAD_CHILDS_ATTEMPTED = '[Cards] Load Childs - Attempted';
export const LOAD_CHILDS_SUCCESSFUL = '[Cards] Load Childs - Successful';
export const LOAD_CHILDS_FAILED = '[Cards] Load Childs - Failed';

export const LOAD_CHILD_CARDS_ATTEMPTED = '[Cards] Load Child Cards - Attempted';
export const LOAD_CHILD_CARDS_SUCCESSFUL = '[Cards] Load Child Cards - Successful';
export const LOAD_CHILD_CARDS_FAILED = '[Cards] Load Child Cards - Failed';

export const CARDS_SCREEN = '[Cards] Sets the current Cards Screen';

export const SELECT_CHILD = '[Cards] Child selected';
export const SELECT_CARD = '[Cards] Card (physical/virtual) selected';

export const GET_WALLET_ATTEMPTED = '[Cards] Get Wallet Attempted';
export const GET_WALLET_SUCCESSFUL = '[Cards] Get Wallet Successful';
export const GET_WALLET_FAILED = '[Cards] Get Wallet Failed';


export class LoadPhysicalCardsAttemptedAction implements Action {
    readonly type = LOAD_PHYSICAL_CARDS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadPhysicalCardsSuccessfulAction implements Action {
    readonly type = LOAD_PHYSICAL_CARDS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadPhysicalCardsFailedAction implements Action {
    readonly type = LOAD_PHYSICAL_CARDS_FAILED;
    constructor(public payload: any) {}
}

export class LoadVirtualCardsAttemptedAction implements Action {
    readonly type = LOAD_VIRTUAL_CARDS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadVirtualCardsSuccessfulAction implements Action {
    readonly type = LOAD_VIRTUAL_CARDS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadVirtualCardsFailedAction implements Action {
    readonly type = LOAD_VIRTUAL_CARDS_FAILED;
    constructor(public payload: any) {}
}

export class LoadChildsAttemptedAction implements Action {
    readonly type = LOAD_CHILDS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadChildsSuccessfulAction implements Action {
    readonly type = LOAD_CHILDS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadChildsFailedAction implements Action {
    readonly type = LOAD_CHILDS_FAILED;
    constructor(public payload: any) {}
}

export class LoadChildCardsAttemptedAction implements Action {
    readonly type = LOAD_CHILD_CARDS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadChildCardsSuccessfulAction implements Action {
    readonly type = LOAD_CHILD_CARDS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadChildCardsFailedAction implements Action {
    readonly type = LOAD_CHILD_CARDS_FAILED;
    constructor(public payload: any) {}
}

export class CardsScreenAction implements Action {
    readonly type = CARDS_SCREEN;
    constructor(public payload: any) {}
}

export class SelectChildAction implements Action {
    readonly type = SELECT_CHILD;
    constructor(public payload: any) {}
}

export class SelectCardAction implements Action {
    readonly type = SELECT_CARD;
    constructor(public payload: any) {}
}

export class GetWalletAttemptedAction implements Action {
    readonly type = GET_WALLET_ATTEMPTED;
    constructor(public payload: any) {}
}

export class GetWalletSuccessfulAction implements Action {
    readonly type = GET_WALLET_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetWalletFailedAction implements Action {
    readonly type = GET_WALLET_FAILED;
    constructor(public payload: any) {}
}

export type PotsActions =
    |LoadPhysicalCardsAttemptedAction
    |LoadPhysicalCardsSuccessfulAction
    |LoadPhysicalCardsFailedAction
    |LoadVirtualCardsAttemptedAction
    |LoadVirtualCardsSuccessfulAction
    |LoadVirtualCardsFailedAction
    |LoadChildsAttemptedAction
    |LoadChildsSuccessfulAction
    |LoadChildsFailedAction
    |LoadChildCardsAttemptedAction
    |LoadChildCardsSuccessfulAction
    |LoadChildCardsFailedAction
    |CardsScreenAction
    |SelectChildAction
    |SelectCardAction
    |GetWalletAttemptedAction
    |GetWalletSuccessfulAction
    |GetWalletFailedAction
    ;

