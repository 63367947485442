import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadMccAttemptedAction, AddMultipleMccAttemptedAction, DeleteMccAttemptedAction, SetMccAction } from '../../core/store/mcc/mcc.action';
import { getMccs, getMccsAddResponse } from '../../core/store/mcc/mcc.reducer';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { filter } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { Router } from '@angular/router';
import { UpdateMccDialogComponent } from './update-mcc-dialog/update-mcc-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mcc',
  templateUrl: './mcc.component.html',
  styleUrls: ['./mcc.component.scss']
})
export class MccComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  private subscription;

  loading: boolean;

  pageLoading = false;

  client: any;

  mccs: any;

  pageEvent: PageEvent;

  pageIndex = 0;

  pageSize = 25;

  currentPage = 0;

  mccsLength: number;

  array = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  mccsDisplayedColumns: string[] = ['code', 'category', 'sousCategory', 'description', 'actions'];

  @ViewChild('importMenuTrigger') importMenuTrigger: MatMenuTrigger;

  private dialogSubscription;

  private updateDialogSubscription;

  label = 'elements par pagess';

  constructor(private store: Store<AppState>, private dialog: MatDialog, private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_MCC_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_MCC_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    const client = JSON.parse(localStorage.getItem('client'));
    this.client = client;

    this.store.dispatch(new LoadMccAttemptedAction({pageIndex: this.pageIndex, pageSize: this.pageSize}));
    this.subscription = this.store.select(getMccs).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      this.mccs = data.content;
      this.pageIndex = data.number;
      this.pageSize = data.size;
      this.mccsLength = data.totalElements;
      this.loading = false;
      if (this.pageLoading) { this.pageLoading = !this.pageLoading;}
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  uploadExcel(evt: any): void {
    this.importMenuTrigger.closeMenu();
    const selectedFile = evt.target.files[0];
    console.log(selectedFile);
    const formData = new FormData();
    formData.append('file', selectedFile);
    console.log(formData);

    this.store.dispatch(new AddMultipleMccAttemptedAction(formData));
    this.store.select(getMccsAddResponse).pipe(filter(resp => resp !== undefined)).subscribe(resp => {
      if (resp.code !== 200) {
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            title: this.translate.instant('ERROR'),
            message: this.translate.instant('MCC_UPLOAD_MESSAGE'),
            type: 'MCC_UPLOAD'
          }
        });
      }
      console.log(resp);
      this.loading = true;
      this.store.dispatch(new LoadMccAttemptedAction({pageIndex: this.pageIndex, pageSize: this.pageSize}));
    });
  }

  handlePage(event: any): any {
    this.pageLoading = true;
    this.store.dispatch(new LoadMccAttemptedAction(event));
  }

  onSeePermissions(): void {}

  onUpdateMcc(mcc): void {
    console.log(mcc);
    this.store.dispatch(new SetMccAction(mcc));
    const dialogRef = this.dialog.open(UpdateMccDialogComponent, {
      width: '60%',
      maxHeight: '90vh',
      data: {
        value: mcc
      }
    });
    this.updateDialogSubscription = dialogRef.afterClosed().subscribe(result => {
      this.store.dispatch(new LoadMccAttemptedAction({pageIndex: this.pageIndex, pageSize: this.pageSize}));
    });
  }

  onDeleteMcc(mcc): void {
    console.log(mcc);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30%',
      data: {
        title: this.translate.instant('CONFIRMATION_CHOICE'),
        message: this.translate.instant('DELETE_MCC_CONFIRMATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'DELETE_MCC_CONFIRMATION',
        value: mcc
      }
    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.store.dispatch(new DeleteMccAttemptedAction(mcc.id));
        this.store.dispatch(new LoadMccAttemptedAction({pageIndex: this.pageIndex, pageSize: this.pageSize}));
      }
    });
  }

}
