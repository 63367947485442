import { Action } from '@ngrx/store';

export const LOAD_ACCOUNT_ATTEMPTED = '[Account] Load Client Account Attempted';
export const LOAD_ACCOUNT_SUCCESSFUL = '[Account] Load Client Account Successful';
export const LOAD_ACCOUNT_FAILED = '[Account] Load Client Account Failed';


export class LoadAccountAttemptedAction implements Action {
    readonly type = LOAD_ACCOUNT_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadAccountSuccessfulAction implements Action {
    readonly type = LOAD_ACCOUNT_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadAccountFailedAction implements Action {
    readonly type = LOAD_ACCOUNT_FAILED;
    constructor(public payload: any) {}
}


export type AccountActions =
    |LoadAccountAttemptedAction
    |LoadAccountSuccessfulAction
    |LoadAccountFailedAction
    ;
