<div class="tickets-wrapper">
    <h3 class="tickets-title">{{'TICKETS.TITLE' | translate}}<span class="link small float-right" (click)="onNewTicket()">{{'BUTTONS.CREATE' | translate}}</span></h3>

    <div class="spinner" *ngIf="loading" >
        <mat-spinner [diameter]="20"></mat-spinner>
    </div>

    <div style="height: 80%;" *ngIf="!loading">
        <div class="none-div" *ngIf="ticketsLength <= 0">
            <img src="../../../../assets/images/vide/vide.png"
                srcset="../../../../assets/images/vide/vide@2x.png 2x, ../../../../assets/images/vide/vide@3x.png 3x"
                alt="Pas de tickets" class="none" />
                <p class="none-text">{{'TICKETS.NONE' | translate}}</p>
        </div>

        <div class="tickets-content" *ngIf="ticketsLength > 0" >
            <ngx-simplebar class="scroll-content">
                <div #scroll>
                    <article class="Box border bg-white" *ngFor="let ticket of tickets">
                        <div class="float-right f5 text-gray">
                            <span [ngClass]="{'bg-blue': ticket.status === 'OPEN', 'bg-green': ticket.status === 'CLOSED', 'bg-crimson': ticket.status === 'BLOCKED'}">{{ticket.status}}</span>
                        </div>
                        <h4 class="h4 lh-condensed muted-link" (click)="openTicketDialog(ticket)">{{ticket.title}}</h4>
                        <p class="text-kelp text-small overflow my-1">
                            {{ticket.content}}
                        </p>
                        <div class="f6 text-gray mt-2">
                            <span class="d-inline-block">{{'TICKETS.CREATION' | translate}}: {{ticket.openDate | date: 'short'}}</span>
                        </div>
                    </article>
                </div>
            </ngx-simplebar>
        </div>
    </div>

</div>
