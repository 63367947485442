import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TransactionsService {

  private baseUrl = environment.baseUrl;

  private base = 'http://ferdaws-dev.digit-dev.com';

  constructor(private http: HttpClient) { }

  getTransactions(payload: {idClient: string, filterKey: string}): any {
    console.log('transactions web service was called with payload', payload);
    const params = new HttpParams().set('idClient', payload.idClient)
      .set('filterKey', payload.filterKey);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/transactions`, {params});
  }

  getTransactionsFiltered(idClient: string, payload: any): any {
    console.log('filter ws called with payload: ', payload);
    return this.http.post(this.baseUrl + `/clients/${idClient}/transactions/advancedSearch`, payload);
  }





  getTransfers(payload: {category: string}): any {
    return this.http.get<Response>(this.base + `/account/allTransaction?filterKey=` + payload.category);
  }

  searchedTransfers(payload: {
                      amount: number,
                      credit: boolean,
                      currency: string,
                      debit: boolean,
                      startDate: string,
                      endDate: string}): any {
      return this.http.post<Response>(this.base + `/account/advancedSearch`, payload);
  }

  serachTransfers(payload: {key: string}): any {
      return this.http.get<Response>(this.base + `/account/search?key=` + payload.key);
  }

  comingTransfers(): any {
      return this.http.get<Response>(this.base + `/account/forthcoming`);
  }


}
