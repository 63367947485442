import { TicketDialogData } from './../../../home/contact/client-tickets/ticket-dialog/ticket-dialog.component';
import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LogoutAction } from '../../../core/store/auth/auth.action';
import { AppState } from '../../../core/store/index';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup } from '@angular/forms';
import { UpdateMccAttemptedAction } from '../../../core/store/mcc/mcc.action';

@Component({
  selector: 'app-update-mcc-dialog',
  templateUrl: './update-mcc-dialog.component.html',
  styleUrls: ['./update-mcc-dialog.component.scss']
})
export class UpdateMccDialogComponent implements OnInit {

  selectedMcc: any;

  status = ['OPEN', 'BLOCKED', 'CLOSED'];

  mccForm = new FormGroup({
    code: new FormControl(),
    category: new FormControl(''),
    sousCategory: new FormControl(''),
    description: new FormControl(''),
  });

  constructor(@Inject (MAT_DIALOG_DATA) public data: TicketDialogData, private dialogRef: MatDialogRef<UpdateMccDialogComponent>,
              private store: Store<AppState>) { }

  ngOnInit(): void {
    console.log(this.data.value);
    this.selectedMcc = this.data.value;
    this.mccForm.setValue({
      code: this.selectedMcc.code,
      category: this.selectedMcc.category,
      sousCategory: this.selectedMcc.sousCategory,
      description: this.selectedMcc.description
    });
  }

  updateMcc(): void {
    console.log(this.mccForm.value);
    this.store.dispatch(new UpdateMccAttemptedAction(this.selectedMcc.id, this.mccForm.value));
    this.close(true);
  }

  onCancel(): void {
    this.close(false);
  }

  close(value: any): void {
    this.dialogRef.close(value);
  }

  onError(): void {
    this.close(false);
    this.store.dispatch(new LogoutAction());
  }

  @HostListener('keydown.esc')
  onEsc(): void {
    this.close(false);
  }
}
