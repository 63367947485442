import { ImageState } from './../../../core/enums/imageState.enum';
import { filter, take } from 'rxjs/operators';
import { AppState } from './../../../core/store/index';
import { Router } from '@angular/router';
import { User } from './../../../core/models/user.model';
import { UsersService } from './../../../core/services/users/users.service';
import { DialogComponent } from './../../../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GetVerifiedRectificationsAttemptedAction } from './../../../core/store/rectification/rectification.action';
import { getVerifiedRectifications, getResetRectificationFields, RectificationState } from './../../../core/store/rectification/rectification.reducer';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { getUserStatusName } from 'src/app/core/store/user-details/user-details.reducer';
import { AddVerifiedRectificationAttemptedAction,
  RemoveRectificationAttemptedAction } from '../../../core/store/rectification/rectification.action';
import * as _ from 'lodash';
import { getRemoveRectificationsResponse } from '../../../core/store/rectification/rectification.reducer';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-visual-documents',
  templateUrl: './user-visual-documents.component.html',
  styleUrls: ['./user-visual-documents.component.scss']
})
export class UserVisualDocumentsComponent implements OnInit {

  documents: any;

  toggleArrows: boolean;

  user: any;

  isSuperAdmin = false;

  haveReadPermission = false;

  haveWritePermission = false;

  storedRectifications: string[] = [];

  removedRectifications: string[] = [];

  userStatusName: string;

  latestClientRectifications: [];

  selectedDocument: any;

  currentDocumentStatus: ImageState;

  userInfo: User;

  imageToShow: any;

  isPhotoLoading = false;

  documentIdentity: string;

  documentName: string;

  isPhotoRectificationChecked = false;

  isPhotoRectificationCheckedLastState = null;

  rectifButtonDisabled = false;

  rectifModificationEnabled = false;


  RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS_LAST_STATE = null;

  CIN_SCREEN_TEXT_FIELDS_LAST_STATE = null;

  PASSPORT_SCREEN_TEXT_FIELDS_LAST_STATE = null;

  SEJOUR_SCREEN_TEXT_FIELDS_LAST_STATE = null;

  RESIDENCE_SCREEN_TEXT_FIELDS_LAST_STATE = null;

  RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS_LAST_STATE = null;

  PORTRAIT_SCREEN_PHOTO_FIELDS_LAST_STATE = null;

  CIN_SCREEN_PHOTO_FIELDS_LAST_STATE = null;

  PASSPORT_SCREEN_PHOTO_FIELDS_LAST_STATE = null;

  RESIDENCE_SCREEN_PHOTO_FIELDS_LAST_STATE = null;

  PASSPORT_SCREEN_TEXT_FIELDS = [
    { key: 'firstname', value: 'FIRSTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'lastname', value: 'LASTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'birthday', value: 'BIRTHDAY', isValidChecked: true, isInvalidChecked: false},
    { key: 'numPassport', value: 'NUM_PASSPORT', isValidChecked: true, isInvalidChecked: false},
    { key: 'datePassport', value: 'PASSPORT_DATE', isValidChecked: true, isInvalidChecked: false},
  ];

  SEJOUR_SCREEN_TEXT_FIELDS = [
    { key: 'firstname', value: 'FIRSTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'lastname', value: 'LASTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'birthday', value: 'BIRTHDAY', isValidChecked: true, isInvalidChecked: false},
    { key: 'numCarteSejour', value: 'NUM_CARTE_SEJOUR', isValidChecked: true, isInvalidChecked: false},
    { key: 'dateSejour', value: 'CARTE_SEJOUR_DATE', isValidChecked: true, isInvalidChecked: false},
  ];

  CIN_SCREEN_TEXT_FIELDS = [
    { key: 'firstname', value: 'FIRSTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'lastname', value: 'LASTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'birthday', value: 'BIRTHDAY', isValidChecked: true, isInvalidChecked: false},
    { key: 'cin', value: 'CIN', isValidChecked: true, isInvalidChecked: false},
    { key: 'dateCin', value: 'CIN_DATE', isValidChecked: true, isInvalidChecked: false},
    { key: 'gender', value: 'GENDER', isValidChecked: true, isInvalidChecked: false},
    { key: 'citoyen', value: 'CITOYEN', isValidChecked: true, isInvalidChecked: false},
  ];

  RESIDENCE_SCREEN_TEXT_FIELDS = [
    { key: 'rue', value: 'RUE', isValidChecked: true, isInvalidChecked: false},
    { key: 'ville', value: 'VILLE', isValidChecked: true, isInvalidChecked: false},
    { key: 'pays', value: 'PAYS', isValidChecked: true, isInvalidChecked: false},
    { key: 'numRue', value: 'NUM_RUE', isValidChecked: true, isInvalidChecked: false},
    { key: 'codePostal', value: 'CODE_POSTAl', isValidChecked: true, isInvalidChecked: false},
    { key: 'numApp', value: 'NUM_APP', isValidChecked: true, isInvalidChecked: false},
  ];

  RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS = [
    { key: 'rue', value: 'RUE', isValidChecked: true, isInvalidChecked: false},
    { key: 'ville', value: 'VILLE', isValidChecked: true, isInvalidChecked: false},
    { key: 'pays', value: 'PAYS', isValidChecked: true, isInvalidChecked: false},
    { key: 'numRue', value: 'NUM_RUE', isValidChecked: true, isInvalidChecked: false},
    { key: 'codePostal', value: 'CODE_POSTAl', isValidChecked: true, isInvalidChecked: false},
    { key: 'numApp', value: 'NUM_APP', isValidChecked: true, isInvalidChecked: false},
  ];


  RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS = [
    { value: 'ACCOMODATION_CERTIFICATE', isValidChecked: true, isInvalidChecked: false},
    { value: 'RECTO_CIN_OWNER', isValidChecked: true, isInvalidChecked: false},
    { value: 'VERSO_CIN_OWNER', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO1', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO2', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO3', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO4', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO5', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PERMIT_PHOTO', isValidChecked: true, isInvalidChecked: false}
  ];

  PORTRAIT_SCREEN_PHOTO_FIELDS = [
    { value: 'SELFIE', isValidChecked: true, isInvalidChecked: false},
    { value: 'FACE', isValidChecked: true, isInvalidChecked: false}
  ];

  CIN_SCREEN_PHOTO_FIELDS = [
    { value: 'RECTO_CIN', isValidChecked: true, isInvalidChecked: false},
    { value: 'VERSO_CIN', isValidChecked: true, isInvalidChecked: false},
    { value: 'RECTO_CIN_OWNER', isValidChecked: true, isInvalidChecked: false},
    { value: 'VERSO_CIN_OWNER', isValidChecked: true, isInvalidChecked: false}
  ];

  PASSPORT_SCREEN_PHOTO_FIELDS = [
    { value: 'FIRST_PAGE_PASSPORT', isValidChecked: true, isInvalidChecked: false},
    { value: 'FIRST_PAGE_SEJOUR', isValidChecked: true, isInvalidChecked: false}
  ];

  RESIDENCE_SCREEN_PHOTO_FIELDS = [
    { value: 'RESIDENCE_PHOTO1', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO2', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO3', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO4', isValidChecked: true, isInvalidChecked: false},
    { value: 'RESIDENCE_PHOTO5', isValidChecked: true, isInvalidChecked: false},
    { value: 'ACCOMODATION_CERTIFICATE', isValidChecked: true, isInvalidChecked: false},
    { value: 'BILL', isValidChecked: true, isInvalidChecked: false},
  ];

  ALL_RECTIF_FIELDS = [
    { key: 'firstname', value: 'FIRSTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'lastname', value: 'LASTNAME', isValidChecked: true, isInvalidChecked: false},
    { key: 'birthday', value: 'BIRTHDAY', isValidChecked: true, isInvalidChecked: false},
    { key: 'cin', value: 'CIN', isValidChecked: true, isInvalidChecked: false},
    { key: 'numPassport', value: 'NUM_PASSPORT', isValidChecked: true, isInvalidChecked: false},
    { key: 'numCarteSejour', value: 'NUM_CARTE_SEJOUR', isValidChecked: true, isInvalidChecked: false},
    { key: 'datePassport', value: 'PASSPORT_DATE', isValidChecked: true, isInvalidChecked: false},
    { key: 'dateSejour', value: 'CARTE_SEJOUR_DATE', isValidChecked: true, isInvalidChecked: false},
    { key: 'dateCin', value: 'CIN_DATE', isValidChecked: true, isInvalidChecked: false},
    { key: 'rue', value: 'RUE', isValidChecked: true, isInvalidChecked: false},
    { key: 'ville', value: 'VILLE', isValidChecked: true, isInvalidChecked: false},
    { key: 'pays', value: 'PAYS', isValidChecked: true, isInvalidChecked: false},
    { key: 'numRue', value: 'NUM_RUE', isValidChecked: true, isInvalidChecked: false},
    { key: 'gender', value: 'GENDER', isValidChecked: true, isInvalidChecked: false},
    { key: 'codePostal', value: 'CODE_POSTAl', isValidChecked: true, isInvalidChecked: false},
    { key: 'numApp', value: 'NUM_APP', isValidChecked: true, isInvalidChecked: false},
    { key: 'citoyen', value: 'CITOYEN', isValidChecked: true, isInvalidChecked: false},
    { key: 'RECTO_CIN', value: 'RECTO_CIN', isValidChecked: true, isInvalidChecked: false},
    { key: 'VERSO_CIN', value: 'VERSO_CIN', isValidChecked: true, isInvalidChecked: false},
    { key: 'FACE', value: 'FACE', isValidChecked: true, isInvalidChecked: false},
    { key: 'SELFIE', value: 'SELFIE', isValidChecked: true, isInvalidChecked: false},
    { key: 'FIRST_PAGE_PASSPORT', value: 'FIRST_PAGE_PASSPORT', isValidChecked: true, isInvalidChecked: false},
    { key: 'BILL', value: 'BILL', isValidChecked: true, isInvalidChecked: false},
    { key: 'RESIDENCE_PERMIT_PHOTO', value: 'RESIDENCE_PERMIT_PHOTO', isValidChecked: true, isInvalidChecked: false},
    { key: 'RECTO_CIN_OWNER', value: 'RECTO_CIN_OWNER', isValidChecked: true, isInvalidChecked: false},
    { key: 'VERSO_CIN_OWNER', value: 'VERSO_CIN_OWNER', isValidChecked: true, isInvalidChecked: false},
    { key: 'ACCOMODATION_CERTIFICATE', value: 'ACCOMODATION_CERTIFICATE', isValidChecked: true, isInvalidChecked: false}
  ];

  constructor(private store: Store<AppState>, private rectificationStore: Store<RectificationState>,
              private dialog: MatDialog, private usersService: UsersService, private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    this.isPhotoLoading = true;
    this.user = JSON.parse(localStorage.getItem('user'));
    console.log(this.user.permissions);
    for (const permission of this.user.permissions) {
      if (permission === 'CLIENT_DOCUMENT_READ') {
        this.haveReadPermission = true;
      }
      if (permission === 'CLIENT_DOCUMENT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }
    this.documents = JSON.parse(localStorage.getItem('documents'));
    const document = JSON.parse(localStorage.getItem('document'));
    const client = JSON.parse(localStorage.getItem('client'));
    this.selectedDocument = document;
    this.documentIdentity = document.identity;
    this.documentName = document.name;
    this.userInfo = client;
    this.currentDocumentStatus = document.imageState;

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    this.store.select(getUserStatusName).pipe(filter(obj => obj !== undefined), take(1))
      .subscribe(status => {
        this.userStatusName = status;
        console.log('UserStatusName:', status);
      });

    this.store.dispatch(new GetVerifiedRectificationsAttemptedAction({idClient: client.id}));
    this.store.select(getVerifiedRectifications).pipe(filter(obj => obj !== undefined), take(1))
      .subscribe(rectifications => {
        if (rectifications) {
          console.log('LAST CLIENT RECTIFICATIONS : ', rectifications);
          this.latestClientRectifications = rectifications;
          this.storedRectifications = rectifications;
          this.setRectificationFields(rectifications);
        } else {
          console.log('error when getting latest client rectifs and data is ', rectifications);
        }
      });
    // this.store.dispatch(new GetRectificationsAttemptedAction({idClient: this.userInfo.id}));
    this.rectificationStore.select(getResetRectificationFields).subscribe(data => {
      if (data === true) {
        this.uncheckAllFields();
      }
    });
    const documentTag = JSON.parse(localStorage.getItem('document'));
    this.imageToShow = this.usersService.getDocumentImageByTag({idClient: this.userInfo.id, tag: documentTag.name})
      .subscribe(data => {
        this.createImageFromBlob(data);
        console.log(data);
      },
      (error) => {
        console.log('ERROR OCCURED WHILE READING IMAGE: ', error);
        this.isPhotoLoading = false;
      },
      () => { this.isPhotoLoading = false; console.log('image downloaded successfully'); });

  }

  onNextDocument(): void {
    const currentDocument = JSON.parse(localStorage.getItem('document'));
    let currentIndex = currentDocument.indexChild;
    if (currentIndex === this.documents.length - 1) {
      currentIndex = -1;
    }
    localStorage.setItem('document', JSON.stringify(this.documents[currentIndex + 1]));
    this.ngOnInit();
  }

  onPreviousDocument(): void {
    const currentDocument = JSON.parse(localStorage.getItem('document'));
    let currentIndex = currentDocument.indexChild;
    if (currentIndex === 0) {
      currentIndex = this.documents.length;
    }
    localStorage.setItem('document', JSON.stringify(this.documents[currentIndex - 1]));
    this.ngOnInit();
  }


  setRectificationFields(rectificationList: string[]): void {
    const document = JSON.parse(localStorage.getItem('document'));
    let j = 0;
    switch (document.identity) {
      case 'PASSPORT':
        if (document.name === 'FIRST_PAGE_PASSPORT') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'FIRST_PAGE_PASSPORT') {
              this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked =  true;
              this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.PASSPORT_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.PASSPORT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.PASSPORT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        } else if (document.name === 'FIRST_PAGE_SEJOUR') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'FIRST_PAGE_SEJOUR') {
              this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked =  true;
              this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.SEJOUR_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.SEJOUR_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.SEJOUR_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        }
        break;

        case 'CIN':
          if (document.name === 'RECTO_CIN') {
            rectificationList.forEach(value => {
              if (value.toUpperCase() === 'RECTO_CIN') {
                this.CIN_SCREEN_PHOTO_FIELDS[0].isInvalidChecked = true;
                this.CIN_SCREEN_PHOTO_FIELDS[0].isValidChecked = false;
                this.isPhotoRectificationChecked = true;
                this.rectifButtonDisabled = true;
              }
              j = 0;
              this.CIN_SCREEN_TEXT_FIELDS.forEach(obj => {
                if (value.toUpperCase() === obj.value) {
                  this.CIN_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                  this.CIN_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                  this.rectifButtonDisabled = true;
                }
                j++;
              });
            });
          } else if (document.name === 'VERSO_CIN') {
            rectificationList.forEach(value => {
              if (value.toUpperCase() === 'VERSO_CIN') {
                this.CIN_SCREEN_PHOTO_FIELDS[1].isInvalidChecked = true;
                this.CIN_SCREEN_PHOTO_FIELDS[1].isValidChecked = false;
                this.isPhotoRectificationChecked = true;
                this.rectifButtonDisabled = true;
                console.log(this.rectifButtonDisabled);
              }
              j = 0;
              this.CIN_SCREEN_TEXT_FIELDS.forEach(obj => {
                if (value.toUpperCase() === obj.value) {
                  this.CIN_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                  this.CIN_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                  this.rectifButtonDisabled = true;
                  console.log(this.rectifButtonDisabled);
                }
                j++;
              });
            });
          }
          break;

    case 'PORTRAIT':
        if (document.name === 'SELFIE') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'SELFIE') {
              this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked =  true;
              this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
          });
        } else if (document.name === 'FACE') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'FACE') {
              console.log('*******************************');
              console.log(document.name);
              this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked =  true;
              this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
          });
        } else {
          this.dialog.open(DialogComponent, {
            width: '30%',
          });
        }
        break;

      case 'HOSTED_HOME_DOCUMENT': /* RESIDENCE */
        if (document.name === 'RESIDENCE_PHOTO1' || document.name === 'BILL1') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'RESIDENCE_PHOTO1' || document.name === 'BILL1') {
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[0].isInvalidChecked =  true;
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[0].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        } else if (document.name === 'RESIDENCE_PHOTO2' || document.name === 'BILL2') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'RESIDENCE_PHOTO2' || document.name === 'BILL2') {
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[1].isInvalidChecked =  true;
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[1].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        } else if (document.name === 'RESIDENCE_PHOTO3' || document.name === 'BILL3') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'RESIDENCE_PHOTO3' || document.name === 'BILL3') {
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[2].isInvalidChecked =  true;
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[2].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        } else if (document.name === 'RESIDENCE_PHOTO4' || document.name === 'BILL4') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'RESIDENCE_PHOTO4' || document.name === 'BILL4') {
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[3].isInvalidChecked =  true;
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[3].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        } else if (document.name === 'RESIDENCE_PHOTO5' || document.name === 'BILL5') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'RESIDENCE_PHOTO5' || document.name === 'BILL5') {
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[4].isInvalidChecked =  true;
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[4].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        } else if (document.name === 'ACCOMODATION_CERTIFICATE') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'ACCOMODATION_CERTIFICATE') {
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[6].isInvalidChecked =  true;
              this.RESIDENCE_SCREEN_PHOTO_FIELDS[6].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.RESIDENCE_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
        }
        break;

      case 'HOSTED_OTHER_HOME_DOCUMENT': /* RESIDENCE_PERMIT */
      if (document.name === 'ACCOMODATION_CERTIFICATE') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'ACCOMODATION_CERTIFICATE') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[0].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      } else if (document.name === 'RECTO_CIN_OWNER') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'RECTO_CIN_OWNER') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[1].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      } else if (document.name === 'VERSO_CIN_OWNER') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'VERSO_CIN_OWNER') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[2].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[2].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      } else if (document.name === 'RESIDENCE_PHOTO1' || document.name === 'BILL1') {
          rectificationList.forEach(value => {
            if (value.toUpperCase() === 'RESIDENCE_PHOTO1' || value.toUpperCase() === 'BILL1') {
              this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[3].isInvalidChecked =  true;
              this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[3].isValidChecked = false;
              this.isPhotoRectificationChecked = true;
              this.rectifButtonDisabled = true;
            }
            j = 0;
            this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
              if (value.toUpperCase() === obj.value) {
                this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
                this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
                this.rectifButtonDisabled = true;
              }
              j++;
            });
          });
      } else if (document.name === 'RESIDENCE_PHOTO2' || document.name === 'BILL2') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'RESIDENCE_PHOTO2' || value.toUpperCase() === 'BILL2') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[4].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[4].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      } else if (document.name === 'RESIDENCE_PHOTO3' || document.name === 'BILL3') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'RESIDENCE_PHOTO3' || value.toUpperCase() === 'BILL3') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[5].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[5].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      } else if (document.name === 'RESIDENCE_PHOTO4' || document.name === 'BILL4') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'RESIDENCE_PHOTO4' || value.toUpperCase() === 'BILL4') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[6].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[6].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      } else if (document.name === 'RESIDENCE_PHOTO5' || document.name === 'BILL5') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'RESIDENCE_PHOTO5' || value.toUpperCase() === 'BILL5') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[7].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[7].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      } else if (document.name === 'RESIDENCE_PERMIT_PHOTO') {
        rectificationList.forEach(value => {
          if (value.toUpperCase() === 'RESIDENCE_PERMIT_PHOTO') {
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[8].isInvalidChecked =  true;
            this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[8].isValidChecked = false;
            this.isPhotoRectificationChecked = true;
            this.rectifButtonDisabled = true;
          }
          j = 0;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
            if (value.toUpperCase() === obj.value) {
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isInvalidChecked = true;
              this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[j].isValidChecked = false;
              this.rectifButtonDisabled = true;
            }
            j++;
          });
        });
      }
      break;
    }
  }




  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }



  enableModification(): void {
    const document = JSON.parse(localStorage.getItem('document'));
    this.isPhotoRectificationCheckedLastState = this.isPhotoRectificationChecked;
    switch (document.identity) {
      case 'HOSTED_OTHER_HOME_DOCUMENT':
        this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS_LAST_STATE = _.cloneDeep(this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS);
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS_LAST_STATE = _.cloneDeep(this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS);
        break;
      case 'CIN':
        this.CIN_SCREEN_TEXT_FIELDS_LAST_STATE = _.cloneDeep(this.CIN_SCREEN_TEXT_FIELDS);
        this.CIN_SCREEN_PHOTO_FIELDS_LAST_STATE = _.cloneDeep(this.CIN_SCREEN_PHOTO_FIELDS);
        break;
      case 'PASSPORT':
        if (document.name === 'FIRST_PAGE_PASSPORT') {
          this.PASSPORT_SCREEN_TEXT_FIELDS_LAST_STATE = _.cloneDeep(this.PASSPORT_SCREEN_TEXT_FIELDS);
          this.PASSPORT_SCREEN_PHOTO_FIELDS_LAST_STATE = _.cloneDeep(this.PASSPORT_SCREEN_PHOTO_FIELDS);
        }
        if (document.name === 'FIRST_PAGE_SEJOUR') {
          this.SEJOUR_SCREEN_TEXT_FIELDS_LAST_STATE = _.cloneDeep(this.SEJOUR_SCREEN_TEXT_FIELDS);
          this.PASSPORT_SCREEN_PHOTO_FIELDS_LAST_STATE = _.cloneDeep(this.PASSPORT_SCREEN_PHOTO_FIELDS);
        }
        break;
      case 'HOSTED_HOME_DOCUMENT':
        this.RESIDENCE_SCREEN_TEXT_FIELDS_LAST_STATE = _.cloneDeep(this.RESIDENCE_SCREEN_TEXT_FIELDS);
        this.RESIDENCE_SCREEN_PHOTO_FIELDS_LAST_STATE = _.cloneDeep(this.RESIDENCE_SCREEN_PHOTO_FIELDS);
        break;
      case 'PORTRAIT':
        this.PORTRAIT_SCREEN_PHOTO_FIELDS_LAST_STATE = _.cloneDeep(this.PORTRAIT_SCREEN_PHOTO_FIELDS);
    }
    this.rectifButtonDisabled = !this.rectifButtonDisabled;
    this.rectifModificationEnabled = true;
  }

  cancelModification(): void {
    const document = JSON.parse(localStorage.getItem('document'));
    switch (document.identity) {
      case 'HOSTED_OTHER_HOME_DOCUMENT':
        for (let i = 0; i < this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS_LAST_STATE.length; i++) {
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[i].isInvalidChecked =
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS_LAST_STATE[i].isInvalidChecked;
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[i].isValidChecked =
          this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS_LAST_STATE[i].isValidChecked;
        }
        for (let i = 0; i < this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS_LAST_STATE.length; i++) {
          this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[i].isInvalidChecked =
          this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS_LAST_STATE[i].isInvalidChecked;
          this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[i].isValidChecked =
          this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS_LAST_STATE[i].isValidChecked;
        }
        break;

      case 'CIN':
        for (let i = 0; i < this.CIN_SCREEN_TEXT_FIELDS_LAST_STATE.length; i++) {
          this.CIN_SCREEN_TEXT_FIELDS[i].isInvalidChecked = this.CIN_SCREEN_TEXT_FIELDS_LAST_STATE[i].isInvalidChecked;
          this.CIN_SCREEN_TEXT_FIELDS[i].isValidChecked = this.CIN_SCREEN_TEXT_FIELDS_LAST_STATE[i].isValidChecked;
        }
        for (let i = 0; i < this.CIN_SCREEN_PHOTO_FIELDS_LAST_STATE.length; i++) {
          this.CIN_SCREEN_PHOTO_FIELDS[i].isInvalidChecked = this.CIN_SCREEN_PHOTO_FIELDS_LAST_STATE[i].isInvalidChecked;
          this.CIN_SCREEN_PHOTO_FIELDS[i].isValidChecked = this.CIN_SCREEN_PHOTO_FIELDS_LAST_STATE[i].isValidChecked;
        }
        break;

      case 'PASSPORT':
        if (document.name === 'FIRST_PAGE_PASSPORT') {
          for (let i = 0; i < this.PASSPORT_SCREEN_TEXT_FIELDS_LAST_STATE.length; i++) {
            this.PASSPORT_SCREEN_TEXT_FIELDS[i].isInvalidChecked = this.PASSPORT_SCREEN_TEXT_FIELDS_LAST_STATE[i].isInvalidChecked;
            this.PASSPORT_SCREEN_TEXT_FIELDS[i].isValidChecked = this.PASSPORT_SCREEN_TEXT_FIELDS_LAST_STATE[i].isValidChecked;
          }
          this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked = this.PASSPORT_SCREEN_PHOTO_FIELDS_LAST_STATE[0].isInvalidChecked;
          this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isValidChecked = this.PASSPORT_SCREEN_PHOTO_FIELDS_LAST_STATE[0].isValidChecked;
        } else if (document.name === 'FIRST_PAGE_SEJOUR') {
          for (let i = 0; i < this.SEJOUR_SCREEN_TEXT_FIELDS_LAST_STATE.length; i++) {
            this.SEJOUR_SCREEN_TEXT_FIELDS[i].isInvalidChecked = this.SEJOUR_SCREEN_TEXT_FIELDS_LAST_STATE[i].isInvalidChecked;
            this.SEJOUR_SCREEN_TEXT_FIELDS[i].isValidChecked = this.SEJOUR_SCREEN_TEXT_FIELDS_LAST_STATE[i].isValidChecked;
          }
          this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked = this.PASSPORT_SCREEN_PHOTO_FIELDS_LAST_STATE[1].isInvalidChecked;
          this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isValidChecked = this.PASSPORT_SCREEN_PHOTO_FIELDS_LAST_STATE[1].isValidChecked;
        }
        break;

      case 'HOSTED_HOME_DOCUMENT':
        for (let i = 0; i < this.RESIDENCE_SCREEN_TEXT_FIELDS_LAST_STATE.length; i++) {
          this.RESIDENCE_SCREEN_TEXT_FIELDS[i].isInvalidChecked = this.RESIDENCE_SCREEN_TEXT_FIELDS_LAST_STATE[i].isInvalidChecked;
          this.RESIDENCE_SCREEN_TEXT_FIELDS[i].isValidChecked = this.RESIDENCE_SCREEN_TEXT_FIELDS_LAST_STATE[i].isValidChecked;
        }
        for (let i = 0; i < this.RESIDENCE_SCREEN_PHOTO_FIELDS_LAST_STATE.length; i++) {
          this.RESIDENCE_SCREEN_PHOTO_FIELDS[i].isInvalidChecked = this.RESIDENCE_SCREEN_PHOTO_FIELDS_LAST_STATE[i].isInvalidChecked;
          this.RESIDENCE_SCREEN_PHOTO_FIELDS[i].isValidChecked = this.RESIDENCE_SCREEN_PHOTO_FIELDS_LAST_STATE[i].isValidChecked;
        }
        break;
    }
    this.isPhotoRectificationChecked = this.isPhotoRectificationCheckedLastState;
    this.rectifButtonDisabled = !this.rectifButtonDisabled;
    this.rectifModificationEnabled = !this.rectifModificationEnabled;
  }


  checkInvalidField(field: string, document: string): void {
    let index: number;
    switch (document) {
      case 'RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS':
        index = this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.findIndex(obj => obj.key === field);
        this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[index].isValidChecked = !this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[index].isValidChecked;
        this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[index].isInvalidChecked = !this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[index].isInvalidChecked;
        break;
      case 'CIN_SCREEN_TEXT_FIELDS':
        index = this.CIN_SCREEN_TEXT_FIELDS.findIndex(obj => obj.key === field);
        this.CIN_SCREEN_TEXT_FIELDS[index].isValidChecked = !this.CIN_SCREEN_TEXT_FIELDS[index].isValidChecked;
        this.CIN_SCREEN_TEXT_FIELDS[index].isInvalidChecked = !this.CIN_SCREEN_TEXT_FIELDS[index].isInvalidChecked;
        break;
      case 'PASSPORT_SCREEN_TEXT_FIELDS':
        index = this.PASSPORT_SCREEN_TEXT_FIELDS.findIndex(obj => obj.key === field);
        this.PASSPORT_SCREEN_TEXT_FIELDS[index].isValidChecked = !this.PASSPORT_SCREEN_TEXT_FIELDS[index].isValidChecked;
        this.PASSPORT_SCREEN_TEXT_FIELDS[index].isInvalidChecked = !this.PASSPORT_SCREEN_TEXT_FIELDS[index].isInvalidChecked;
        break;
      case 'SEJOUR_SCREEN_TEXT_FIELDS':
        index = this.SEJOUR_SCREEN_TEXT_FIELDS.findIndex(obj => obj.key === field);
        this.SEJOUR_SCREEN_TEXT_FIELDS[index].isValidChecked = !this.SEJOUR_SCREEN_TEXT_FIELDS[index].isValidChecked;
        this.SEJOUR_SCREEN_TEXT_FIELDS[index].isInvalidChecked = !this.SEJOUR_SCREEN_TEXT_FIELDS[index].isInvalidChecked;
        break;
      case 'RESIDENCE_SCREEN_TEXT_FIELDS':
        index = this.RESIDENCE_SCREEN_TEXT_FIELDS.findIndex(obj => obj.key === field);
        this.RESIDENCE_SCREEN_TEXT_FIELDS[index].isValidChecked = !this.RESIDENCE_SCREEN_TEXT_FIELDS[index].isValidChecked;
        this.RESIDENCE_SCREEN_TEXT_FIELDS[index].isInvalidChecked = !this.RESIDENCE_SCREEN_TEXT_FIELDS[index].isInvalidChecked;
        break;
    }
  }



  uncheckAllFields(): void {
    this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.PORTRAIT_SCREEN_PHOTO_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.CIN_SCREEN_TEXT_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.CIN_SCREEN_PHOTO_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.PASSPORT_SCREEN_TEXT_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.PASSPORT_SCREEN_PHOTO_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.SEJOUR_SCREEN_TEXT_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
    this.RESIDENCE_SCREEN_PHOTO_FIELDS.forEach(obj => {
      obj.isValidChecked = false;
      obj.isInvalidChecked = true;
    });
  }

  validerTous(): void {
    const document = JSON.parse(localStorage.getItem('document'));
    switch (document.identity) {
      case 'RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS':
        console.log('A VALIDER TOUS:', this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS);
        break;
      case 'RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS':
        console.log('A VALIDER TOUS:', this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS);
        break;
      case 'PORTRAIT_SCREEN_PHOTO_FIELDS':
        console.log('A VALIDER TOUS:', this.PORTRAIT_SCREEN_PHOTO_FIELDS);
        break;
      case 'CIN_SCREEN_TEXT_FIELDS':
        console.log('A VALIDER TOUS:', this.CIN_SCREEN_TEXT_FIELDS);
        break;
      case 'CIN_SCREEN_PHOTO_FIELDS':
        console.log('A VALIDER TOUS:', this.CIN_SCREEN_PHOTO_FIELDS);
        break;
      case 'PASSPORT_SCREEN_TEXT_FIELDS':
        console.log('A VALIDER TOUS:', this.PASSPORT_SCREEN_TEXT_FIELDS);
        break;
      case 'PASSPORT_SCREEN_PHOTO_FIELDS':
        console.log('A VALIDER TOUS:', this.PASSPORT_SCREEN_PHOTO_FIELDS);
        break;
      case 'RESIDENCE_SCREEN_TEXT_FIELDS':
        console.log('A VALIDER TOUS:', this.RESIDENCE_SCREEN_TEXT_FIELDS);
        break;
      case 'RESIDENCE_SCREEN_PHOTO_FIELDS':
        console.log('A VALIDER TOUS:', this.RESIDENCE_SCREEN_PHOTO_FIELDS);
        break;
    }
  }



  addDocumentToRectificationList(): void {
    const document = JSON.parse(localStorage.getItem('document'));
    const docName = document.name;
    const client = JSON.parse(localStorage.getItem('client'));
    const email = client.email;
    const idClient = client.id;

    this.rectifButtonDisabled = true;
    let storedFieldExists = false;
    let nameExist = false;

    switch (document.identity) {
      /* FOLDER === PASSPORT */
      case 'PASSPORT':
        if (document.name === 'FIRST_PAGE_PASSPORT') {
          for (const field of this.PASSPORT_SCREEN_TEXT_FIELDS) {
            storedFieldExists = false;
            if (field.isInvalidChecked) {
              for (const clientLastRectif of this.latestClientRectifications) {
                if (field.value === clientLastRectif) {
                  storedFieldExists = true;
                }
              }
              if (!storedFieldExists) {
                this.storedRectifications.push(field.value);
              }
            } else {
              for (let i = 0; i < this.latestClientRectifications.length; i++) {
                if (field.value === this.latestClientRectifications[i]) {
                  this.storedRectifications.splice(i, 1);
                }
              }
              this.removedRectifications.push(field.value);
            }
          }
        } else if (document.name === 'FIRST_PAGE_SEJOUR') {
          for (const field of this.SEJOUR_SCREEN_TEXT_FIELDS) {
            storedFieldExists = false;
            if (field.isInvalidChecked) {
              for (const clientLastRectif of this.latestClientRectifications) {
                if (field.value === clientLastRectif) {
                  storedFieldExists = true;
                }
              }
              if (!storedFieldExists) {
                this.storedRectifications.push(field.value);
              }
            } else {
              for (let i = 0; i < this.latestClientRectifications.length; i++) {
                if (field.value === this.latestClientRectifications[i]) {
                  this.storedRectifications.splice(i, 1);
                }
              }
              this.removedRectifications.push(field.value);
            }
          }
        }
        if (this.isPhotoRectificationChecked) {
          console.log('ENTERING PHOTORECTIFICATION BLOCK');
          for (const clientLastRectif of this.latestClientRectifications) {
            console.log(clientLastRectif);
            if (clientLastRectif === document.name) {
              nameExist = true;
              break;
            }
          }
          if (!nameExist) {
            console.log('!!!!!!!!!!!! document name dosnt exist in clientLastRectif array');
            this.storedRectifications.push(document.name);
          }
        } else {
          console.log('µ************************ removing ', document.name);
          this.removedRectifications.push(document.name);
        }
        break;

        /* FOLDER === CIN */
        case 'CIN':
        for (const field of this.CIN_SCREEN_TEXT_FIELDS) {
          storedFieldExists = false;
          if (field.isInvalidChecked) {
            for (const clientLastRectif of this.latestClientRectifications) {
              if (field.value === clientLastRectif) {
                storedFieldExists = true;
              }
            }
            if (!storedFieldExists) {
              this.storedRectifications.push(field.value);
            }
          } else {
            for (let i = 0; i < this.latestClientRectifications.length; i++) {
              if (field.value === this.latestClientRectifications[i]) {
                this.storedRectifications.splice(i, 1);
              }
            }
            this.removedRectifications.push(field.value);
          }
        }
        if (this.isPhotoRectificationChecked) {
          for (const clientLastRectif of this.latestClientRectifications) {
            if (clientLastRectif === document.name) {
              nameExist = true;
              break;
            }
          }
          if (!nameExist) {
            this.storedRectifications.push(document.name);
          }
        } else {
          this.removedRectifications.push(document.name);
        }
        break;

        /* FOLDER === PORTRAIT */
        case 'PORTRAIT':
        if (this.isPhotoRectificationChecked) {
          for (const clientLastRectif of this.latestClientRectifications) {
            if (clientLastRectif === document.name) {
              nameExist = true;
              break;
            }
          }
          if (!nameExist) {
            this.storedRectifications.push(document.name);
          }
        } else {
          this.removedRectifications.push(document.name);
        }
        break;


      /* FOLDER === HOSTED_OTHER_HOME_DOCUMENT */
      case 'HOSTED_OTHER_HOME_DOCUMENT':
        for (const field of this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS) {
          storedFieldExists = false;
          if (field.isInvalidChecked) {
            for (const clientLastRectif of this.latestClientRectifications) {
              if (field.value === clientLastRectif) {
                storedFieldExists = true;
              }
            }
            if (!storedFieldExists) {
              this.storedRectifications.push(field.value);
            }
          } else {
            for (let i = 0; i < this.latestClientRectifications.length; i++) {
              if (field.value === this.latestClientRectifications[i]) {
                this.storedRectifications.splice(i, 1);
              }
            }
            this.removedRectifications.push(field.value);
          }
        }
        if (this.isPhotoRectificationChecked) {
          for (const clientLastRectif of this.latestClientRectifications) {
            if (clientLastRectif === document.name) {
              nameExist = true;
              break;
            }
          }
          if (!nameExist) {
            this.storedRectifications.push(document.name);
          }
        } else {
          this.removedRectifications.push(document.name);
        }
        break;

      /* FOLDER === HOSTED_HOME_DOCUMENT */
      case 'HOSTED_HOME_DOCUMENT':
        for (const field of this.RESIDENCE_SCREEN_TEXT_FIELDS) {
          storedFieldExists = false;
          if (field.isInvalidChecked) {
            for (const clientLastRectif of this.latestClientRectifications) {
              if (field.value === clientLastRectif) {
                storedFieldExists = true;
              }
            }
            if (!storedFieldExists) {
              this.storedRectifications.push(field.value);
            }
          } else {
            for (let i = 0; i < this.latestClientRectifications.length; i++) {
              if (field.value === this.latestClientRectifications[i]) {
                this.storedRectifications.splice(i, 1);
              }
            }
            this.removedRectifications.push(field.value);
          }
        }
        if (this.isPhotoRectificationChecked) {
          for (const clientLastRectif of this.latestClientRectifications) {
            if (clientLastRectif === document.name) {
              nameExist = true;
              break;
            }
          }
          if (!nameExist) {
            this.storedRectifications.push(document.name);
          }
        } else {
          this.removedRectifications.push(document.name);
        }
        break;
    }
    console.log(' ****** TO BE REMOVED RECTIFICATIONS : ', this.removedRectifications);
    console.log(' ****** TO BE STORED RECTIFICATIONS :', this.storedRectifications);

    /*
    if (this.storedRectifications.length > 0) {
      console.log('RECTIF OBJECT TO BE SENT IS : ', {idClient, documents: [document.name]});

      this.store.select(getVerifiedDocuments).pipe(take(2)).subscribe(data => {
        if (data) {
          console.log(' - CURRENT CHECKED DOCUMENTS ARE : ', data);
          this.store.dispatch(new UpdateCheckedDocumentsAttemptedAction({idClient, documents: [document.name]}));

        } else {
          console.log(' - CURRENT CHECKED DOCUMNETS IS EMPTY : ', data);
          this.store.dispatch(new AddCheckedDocumentsAttemptedAction({idClient, documents: [document.name]}));
        }
      });
    }
    */

    this.store.dispatch(new RemoveRectificationAttemptedAction({idClient, email, rectification: this.removedRectifications}));
    this.store.select(getRemoveRectificationsResponse).pipe(filter(data => data !== undefined), take(1)).subscribe(resp => {
      console.log('response is : ', resp);
      console.log('removed successfuly and response is :', resp);
      console.log('stored rectifs are : ', this.storedRectifications);
      this.removedRectifications.forEach(removedField => {
        const indexOfRemovedField = this.storedRectifications.indexOf(removedField);
        if (indexOfRemovedField !== -1) {
          this.storedRectifications.splice(indexOfRemovedField, 1);
        }
      });
      console.log('new stored rectifs are : ', this.storedRectifications);
      this.store.dispatch(new AddVerifiedRectificationAttemptedAction({idClient, email, rectification: this.storedRectifications}));
      this.store.select(getVerifiedRectifications).pipe(filter(data => data !== undefined), take(1)).subscribe(() => {
        console.log('added successfully and new verified rectids are:', this.storedRectifications);
      },
      () => {},
      () => {
        console.log('COMPLETED (add rectif)');
        localStorage.setItem('rectifications', JSON.stringify(this.storedRectifications));
        this.dialog.open(DialogComponent, {
        width: '50%',
        data: {
          title: this.translate.instant('RECTIFICATION'),
          message: this.translate.instant('ADD_RECTIFICATION_MESSAGE'),
          value: this.storedRectifications,
          buttons: 'add-rectif',
          confirmText: 'OK'
        }
        });
      });
    });

  }





  togglePhotoRectificationButton(): void {
    const document = JSON.parse(localStorage.getItem('document'));
    const name = document.name;
    if (document.identity === 'RESIDENCE_PERMIT') {
      if (name === 'HOSTED_OTHER_HOME_DOCUMENT') {
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked;
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[0].isValidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[0].isValidChecked;
      } else if (name === 'ACCOMODATION_CERTIFICATE') {
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked;
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[1].isValidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[1].isValidChecked;
      } else if (name === 'BILL') {
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[2].isInvalidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[2].isInvalidChecked;
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[2].isValidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[2].isValidChecked;
      } else if (name === 'RECTO_CIN_OWNER') {
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[3].isInvalidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[3].isInvalidChecked;
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[3].isValidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[3].isValidChecked;
      } else if (name === 'VERSO_CIN_OWNER') {
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[4].isInvalidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[4].isInvalidChecked;
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[4].isValidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[4].isValidChecked;
      } else if (name === 'RESIDENCE_PERMIT_PHOTO') {
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[5].isInvalidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[5].isInvalidChecked;
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[5].isValidChecked = !this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS[5].isValidChecked;
      }
      // handle neither residence_permit file tag is sent
    } else if (document.identity === 'PORTRAIT') {
      if (name === 'SELFIE') {
        this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked = !this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked;
        this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isValidChecked = !this.PORTRAIT_SCREEN_PHOTO_FIELDS[0].isValidChecked;
      } else if (name === 'FACE') {
        this.PORTRAIT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked = !this.PORTRAIT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked;
        this.PORTRAIT_SCREEN_PHOTO_FIELDS[1].isValidChecked = !this.PORTRAIT_SCREEN_PHOTO_FIELDS[1].isValidChecked;
      }
    } else if (document.identity === 'CIN') {
      if (name === 'RECTO_CIN') {
        this.CIN_SCREEN_PHOTO_FIELDS[0].isInvalidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[0].isInvalidChecked;
        this.CIN_SCREEN_PHOTO_FIELDS[0].isValidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[0].isValidChecked;
      } else if (name === 'VERSO_CIN') {
        this.CIN_SCREEN_PHOTO_FIELDS[1].isInvalidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[1].isInvalidChecked;
        this.CIN_SCREEN_PHOTO_FIELDS[1].isValidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[1].isValidChecked;
      } else if (name === 'RECTO_CIN_OWNER') {
        this.CIN_SCREEN_PHOTO_FIELDS[2].isInvalidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[2].isInvalidChecked;
        this.CIN_SCREEN_PHOTO_FIELDS[2].isValidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[2].isValidChecked;
      } else if (name === 'VERSO_CIN_OWNER') {
        this.CIN_SCREEN_PHOTO_FIELDS[3].isInvalidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[3].isInvalidChecked;
        this.CIN_SCREEN_PHOTO_FIELDS[3].isValidChecked = !this.CIN_SCREEN_PHOTO_FIELDS[3].isValidChecked;
      }
      console.log(this.CIN_SCREEN_PHOTO_FIELDS);
    } else if (document.identity === 'PASSPORT') {
      if (name === 'FIRST_PAGE_PASSPORT') {
        this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked = !this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isInvalidChecked;
        this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isValidChecked = !this.PASSPORT_SCREEN_PHOTO_FIELDS[0].isValidChecked;
      } else if (name === 'FIRST_PAGE_SEJOUR') {
        this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked = !this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isInvalidChecked;
        this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isValidChecked = !this.PASSPORT_SCREEN_PHOTO_FIELDS[1].isValidChecked;
      }
    } else if (document.identity === 'HOSTED_HOME_DOCUMENT') {
      if (name === 'RESIDENCE_PHOTO1') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[0].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[0].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[0].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[0].isValidChecked;
      } else if (name === 'RESIDENCE_PHOTO2') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[1].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[1].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[1].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[1].isValidChecked;
      } else if (name === 'RESIDENCE_PHOTO3') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[2].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[2].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[2].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[2].isValidChecked;
      } else if (name === 'RESIDENCE_PHOTO4') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[3].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[3].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[3].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[3].isValidChecked;
      } else if (name === 'RESIDENCE_PHOTO5') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[4].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[4].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[4].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[4].isValidChecked;
      } else if (name === 'RESIDENCE_PHOTO6') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[5].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[5].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[5].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[5].isValidChecked;
      } else if (name === 'ACCOMODATION_CERTIFICATE') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[6].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[6].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[6].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[6].isValidChecked;
      } else if (name === 'BILL') {
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[7].isInvalidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[7].isInvalidChecked;
        this.RESIDENCE_SCREEN_PHOTO_FIELDS[7].isValidChecked = !this.RESIDENCE_SCREEN_PHOTO_FIELDS[7].isValidChecked;
      }
      console.log(this.RESIDENCE_SCREEN_PHOTO_FIELDS);
    }
    this.isPhotoRectificationChecked = !this.isPhotoRectificationChecked;
  }

  getUserFieldName(field: string): any {
      switch (field) {
        case 'firstname': return this.userInfo.firstname;
        case 'lastname': return this.userInfo.lastname;
        case 'enabled': return this.userInfo.enabled;
        case 'status': return this.userInfo.status;
        case 'email': return this.userInfo.email;
        case 'numTel': return this.userInfo.numTel;
        case 'birthday': return this.userInfo.birthday;
        case 'creationDate': return this.userInfo.creationDate;
        case 'lastUserUpdate': return this.userInfo.lastUserUpdate;
        case 'cin': return this.userInfo.cin;
        case 'numPassport': return this.userInfo.numPassport;
        case 'numCarteSejour': return this.userInfo.numCarteSejour;
        case 'datePassport': return this.userInfo.datePassport;
        case 'dateSejour': return this.userInfo.dateSejour;
        case 'dateCin': return this.userInfo.dateCin;
        case 'userStatusName': return this.userInfo.userStatusName;
        case 'citizenship': return this.userInfo.citizenship;
        case 'hostEnum': return this.userInfo.hostEnum;
        case 'nature': return this.userInfo.nature;
        case 'rue': return this.userInfo.rue;
        case 'numRue': return this.userInfo.numRue;
        case 'ville': return this.userInfo.ville;
        case 'codePostal': return this.userInfo.codePostal;
        case 'numApp': return this.userInfo.numApp;
        case 'pays': return this.userInfo.pays;
        case 'rueNew': return this.userInfo.rueNew;
        case 'numRueNew': return this.userInfo.numRueNew;
        case 'villeNew': return this.userInfo.villeNew;
        case 'codePostalNew': return this.userInfo.codePostalNew;
        case 'numAppNew': return this.userInfo.numAppNew;
        case 'paysNew': return this.userInfo.paysNew;
        case 'codeParrainage': return this.userInfo.codeParrainage;
        case 'numFis': return this.userInfo.numFis;
        case 'paysFisc': return this.userInfo.paysFisc;
        case 'gender': return this.userInfo.gender;
        case 'citoyen': return this.userInfo.citoyen;
        case 'numTelNew': return this.userInfo.numTelNew;
        case 'lastLogin': return this.userInfo.lastLogin;
        case 'attempt': return this.userInfo.attempt;
        case 'newsLetterValid': return this.userInfo.newsLetterValid;
        case 'mailConfirmed': return this.userInfo.mailConfirmed;
        case 'telConfirmed': return this.userInfo.telConfirmed;
      }
  }

  checkAllFields(document: string): void {
    switch (document) {
      case 'RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS':
        this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS':
        this.RESIDENCE_PERMIT_SCREEN_PHOTO_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'PORTRAIT_SCREEN_PHOTO_FIELDS':
        this.PORTRAIT_SCREEN_PHOTO_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'CIN_SCREEN_TEXT_FIELDS':
        this.CIN_SCREEN_TEXT_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'CIN_SCREEN_PHOTO_FIELDS':
        this.CIN_SCREEN_PHOTO_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'PASSPORT_SCREEN_TEXT_FIELDS':
        this.PASSPORT_SCREEN_TEXT_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'PASSPORT_SCREEN_PHOTO_FIELDS':
        this.PASSPORT_SCREEN_PHOTO_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'RESIDENCE_SCREEN_TEXT_FIELDS':
        this.RESIDENCE_SCREEN_TEXT_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      case 'RESIDENCE_SCREEN_PHOTO_FIELDS':
        this.RESIDENCE_SCREEN_PHOTO_FIELDS.forEach(obj => {
          obj.isValidChecked = true;
          obj.isInvalidChecked = false;
        });
        break;
      }
  }

}
