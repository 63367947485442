<div class="content">
    <div class="full-height bg-white">
        <div class="card">
            <div class="card-header">
                <div class="h4 d-inline-block float-left">
                    {{'APPLICATION.MCC.HEADER' | translate}}
                </div>

                <ng-container *ngIf="haveReadPermission">
                    <div *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center float-left ml-4">
                        <p class="error">{{'ACCESS_CONTROL.NO_MCC_WRITE_PERMISSION' | translate}}</p> &nbsp;
                        <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                    </div>
                </ng-container>

                <button type="button" class="btn btn-sm border-gray float-right dropdown-toggle" #importMenuTrigger="matMenuTrigger" 
                        [matMenuTriggerFor]="importMenu" *ngIf="haveReadPermission" [disabled]="!haveWritePermission">
                    <i class="fa fa-upload octicon" aria-hidden="true"></i>
                    {{'BUTTONS.IMPORT_MCC' | translate}}
                </button>

                <mat-menu #importMenu="matMenu" xPosition="before">
                    <div class="import-mat-menu" (click)="$event.stopPropagation()">
                        <ul>
                            <li>
                                <input type="file" accept=".csv" hidden id="upload-csv-file" (change)="uploadExcel($event)">
                                <label for="upload-csv-file">
                                    <i class="fa fa-upload octicon-sm" aria-hidden="true"></i>
                                    {{'BUTTONS.CSV_FILE' | translate}}
                                </label>
                            </li>

                            <li>
                                <input type="file" accept=".xls,.xlsx" hidden id="upload-excel-file" (change)="uploadExcel($event)">
                                <label for="upload-excel-file">
                                    <i class="fa fa-upload octicon-sm" aria-hidden="true"></i>
                                    {{'BUTTONS.EXCEL_FILE' | translate}}
                                </label>
                            </li>
                        </ul>
                    </div>
                </mat-menu>
            </div>

            <div class="card-body">
                <div class="mcc-container" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                    <div class="spinner" *ngIf="loading" >
                        <mat-spinner [diameter]="40"></mat-spinner>
                    </div>

                    <div class="mccs" *ngIf="!loading" >
                        <div class="none-block" *ngIf="mccsLength == 0">
                            <img src="../../../assets/images/vide/vide@2x.png"
                                srcset="../../../assets/images/vide/vide@2x.png 2x, 
                                        ../../../assets/images/vide/vide@3x.png 3x"
                                class="none"/>
                            <p class="text none-text">{{'APPLICATION.MCC.NONE' | translate}}</p>
                        </div>

                        <div class="mccs-content" *ngIf="mccsLength > 0">
                            <div class="spinner" *ngIf="pageLoading" >
                                <mat-spinner [diameter]="40"></mat-spinner>
                            </div>
                            <div class="mcc-tab" *ngIf="!pageLoading" >
                                <table mat-table [dataSource]="mccs" class="mat-elevation-z8 tab-mccs">
                                    <ng-container matColumnDef="code">
                                        <th mat-header-cell *matHeaderCellDef>{{'APPLICATION.MCC.CODE' | translate}}</th>
                                        <td mat-mat-cell *matCellDef="let element">
                                            <span  class="text tab">{{element.code}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="category">
                                        <th mat-header-cell *matHeaderCellDef>{{'APPLICATION.MCC.CATEGORY' | translate}}</th>
                                        <td mat-mat-cell *matCellDef="let element">
                                            <span  class="text tab">{{element.category}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sousCategory">
                                        <th mat-header-cell *matHeaderCellDef>{{'APPLICATION.MCC.SUB_CATEGORY' | translate}}</th>
                                        <td mat-mat-cell *matCellDef="let element">
                                            <span  class="text tab">{{element.sousCategory}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="description">
                                        <th mat-header-cell *matHeaderCellDef>{{'APPLICATION.MCC.DESCRIPTION' | translate}}</th>
                                        <td mat-mat-cell *matCellDef="let element">
                                            <span  class="text tab">{{element.description}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="actions" >
                                        <th mat-header-cell *matHeaderCellDef></th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="mx-1 muted-link" (click)="onUpdateMcc(element)" title="{{'BUTTONS.UPDATE' | translate}}">
                                                <i class="fa fa-edit"></i>
                                            </span>
                                            <span class="mx-1 muted-link" (click)="onDeleteMcc(element)" title="{{'BUTTONS.DELETE' | translate}}">
                                                <i class="fa fa-trash"></i>
                                            </span>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="mccsDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: mccsDisplayedColumns;" class="table-row"></tr>
                                </table>

                                
                            </div>
                        </div>
                        <mat-paginator *ngIf="mccsLength > pageSize"  #paginator [showFirstLastButtons]="true" [length]="mccsLength" 
                                       [pageIndex]="pageIndex" [pageSize]="pageSize" [hidePageSize]="true"
                                       (page)="pageEvent = handlePage($event)">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #NO_READ_PERMISSION>
    <div class="mcc-container">
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../assets/images/vide/vide@2x.png"
                    srcset="../../../assets/images/vide/vide@2x.png 2x,
                    ../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
            </div>     
        </div>
    </div>
</ng-template>
