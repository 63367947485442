import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromPackage from './package.action';
import { PackageService } from '../../services/package/package.service';

@Injectable()
export class PackageEffects {

    constructor(private actions$: Actions, private packageService: PackageService) {}

    @Effect()
    loadMccAttempted$ = this.actions$.pipe(
        ofType<fromPackage.LoadPackagesAttemptedAction>(fromPackage.LOAD_PACKAGES_ATTEMPTED),
        switchMap((data: fromPackage.LoadPackagesAttemptedAction) => this.packageService.getPackages()
            .pipe(
                map(resp => new fromPackage.LoadPackagesSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromPackage.LoadPackagesFailedAction(err)); })
            )
        )
    );

    @Effect()
    addPackageAttempted$ = this.actions$.pipe(
        ofType<fromPackage.AddPackageAttemptedAction>(fromPackage.ADD_PACKAGE_ATTEMPTED),
        switchMap((data: fromPackage.AddPackageAttemptedAction) => this.packageService.add(data.payload)
            .pipe(
                map(resp => new fromPackage.AddPackageSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromPackage.AddPackageFailedAction(err)); })
            )
        )
    );

    @Effect()
    updatePackageAttempted$ = this.actions$.pipe(
        ofType<fromPackage.UpdatePackageAttemptedAction>(fromPackage.UPDATE_PACKAGE_ATTEMPTED),
        switchMap((data: fromPackage.UpdatePackageAttemptedAction) => this.packageService.update(data.idPackage, data.payload)
            .pipe(
                map(resp => new fromPackage.UpdatePackageSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromPackage.UpdatePackageFailedAction(err)); })
            )
        )
    );

    @Effect()
    deletePackageAttempted$ = this.actions$.pipe(
        ofType<fromPackage.DeletePackageAttemptedAction>(fromPackage.DELETE_PACKAGE_ATTEMPTED),
        switchMap((data: fromPackage.DeletePackageAttemptedAction) => this.packageService.delete(data.idPackage)
            .pipe(
                map(resp => new fromPackage.DeletePackageSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromPackage.DeletePackageFailedAction(err)); })
            )
        )
    );

    @Effect()
    affectClientsToPackageAttempted$ = this.actions$.pipe(
        ofType<fromPackage.AffectPackageToClientsAttemptedAction>(fromPackage.AFFECT_PACKAGE_CLIENTS_ATTEMPTED),
        switchMap((data: fromPackage.AffectPackageToClientsAttemptedAction) =>
        this.packageService.affectClientsToPackage(data.payload.idPackage, data.payload.clientIds)
            .pipe(
                map(resp => new fromPackage.AffectPackageToClientsSuccessfulAction(resp)),
                catchError(err => of (new fromPackage.AffectPackageToClientsFailedAction(err)))
            ))
    );

    @Effect()
    affectPackageToClientAttempted$ = this.actions$.pipe(
        ofType<fromPackage.AffectClientPackageAttemptedAction>(fromPackage.AFFECT_CLIENT_PACKAGE_ATTEMPTED),
        switchMap((data: fromPackage.AffectClientPackageAttemptedAction) =>
            this.packageService.affectPackageToClient(data.idClient, data.idPackageClient)
                .pipe(
                    map(resp => new fromPackage.AffectClientPackageSuccessfulAction(resp)),
                    catchError(err => of (new fromPackage.AffectClientPackageFailedAction(err)))
                ))
    );

    @Effect()
    loadClientPackageAttempted$ = this.actions$.pipe(
        ofType<fromPackage.LoadClientPackageAttemtedAction>(fromPackage.LOAD_CLIENT_PACKAGE_ATTEMPTED),
        switchMap((data: fromPackage.LoadClientPackageAttemtedAction) => this.packageService.getClientPackage(data.idClient)
            .pipe(
                map(resp => new fromPackage.LoadClientPackageSuccessfulAction(resp)),
                catchError(err => of (new fromPackage.LoadClientPackageFailedAction(err)))
            ))
    );

    /*
    @Effect()
    getPackageSelectedClientsAttempted$ = this.actions$.pipe(
        ofType<fromPackage.GetPackageSelectedClientsAttemptedAction>(fromPackage.GET_PACKAGE_SELECTED_CLIENTS_ATTEMPTED),
        switchMap((data: fromPackage.GetPackageSelectedClientsAttemptedAction) =>
        this.packageService.getPackageSelectedClients(data.idPackage)
            .pipe(
                map(resp => new fromPackage.GetPackageSelectedClientsSuccessfulAction(resp)),
                catchError(err => of (new fromPackage.GetPackageSelectedClientsFailedAction(err)))
            ))
    );
    */
}
