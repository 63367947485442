import { Action } from '@ngrx/store';


export enum UsersActionTypes {
    GET_USERS_ATTEMPTED = '[USERS] Get Users Attempted',
    GET_USERS_SUCCESS = '[Users] Get Users Success',
    GET_USERS_FAILURE = '[Users] Get Users Failure'
}

export class GetUsersAttemptedAction implements Action {
    readonly type = UsersActionTypes.GET_USERS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class GetUsersSuccessAction implements Action {
    readonly type = UsersActionTypes.GET_USERS_SUCCESS;
    constructor(public payload: any) {}
}

export class GetUsersFailureAction implements Action {
    readonly type = UsersActionTypes.GET_USERS_FAILURE;
    constructor(public payload: any) {}
}

export type UsersAction =
    |GetUsersAttemptedAction
    |GetUsersSuccessAction
    |GetUsersFailureAction
    ;
