import { IssuesService } from './../../services/issues/issues.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromIssues from './issues.actions';

@Injectable()
export class IssuesEffect {

    constructor(private actions$: Actions, private issuesService: IssuesService) { }

    @Effect()
    loadAllTicketsAttempted$ = this.actions$.pipe(
        ofType<fromIssues.LoadAllClientTicketsAttempted>(fromIssues.LOAD_ALL_TICKETS_ATTEMPTED),
        switchMap((data: fromIssues.LoadAllClientTicketsAttempted) => this.issuesService.getAllTickets(data.payload)
            .pipe(
                map(resp => new fromIssues.LoadAllClientTicketsSuccessful(resp)),
                catchError(err => of (new fromIssues.LoadAllClientTicketsFailed(err)))
            ))
    );
}
