<div class="content">
    <div class="full-height bg-white">
        <div class="card log-box" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
            <div class="card-header">
                {{'ACTIVITIES.HEADER' | translate}}:
                <span class="font name link" (click)="onNavClientInformations()">{{client.firstname}} {{client.lastname}}/{{client.id}}</span>
            </div>

            <div class="card-body">
                <div *ngIf="loading">
                    <div class="spinner">
                        <mat-spinner [diameter]="40"></mat-spinner>
                    </div>
                </div>

                <div *ngIf="!loading">
                    <div class="none-block" *ngIf="activitiesLength == 0">
                        <img src="../../../assets/images/vide/vide.png"
                            srcset="../../../assets/images/vide/vide@2x.png 2x,
                                    ../../../assets/images/vide/vide@3x.png 3x"
                            class="none"/>
                        <p class="text none-text">{{'ACTIVITIES.NONE' | translate}}</p>
                    </div>

                    <ul *ngIf="activitiesLength > 0" >
                        <li *ngFor="let activity of activities">{{activity}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div class="card log-box">
        <div class="card-header">
            {{'ACTIVITIES.HEADER' | translate}}
        </div>
        <div class="card-body">
            <div  class="none-block">
                <div class="none-inner-block">
                    <img src="../../../assets/images/vide/vide@2x.png"
                        srcset="../../../assets/images/vide/vide@2x.png 2x,
                        ../../../assets/images/vide/vide@3x.png 3x"
                        class="none" alt="None">
                    <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
                </div>     
            </div>
        </div>
    </div>
</ng-template>
