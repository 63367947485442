import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { LoadCommissionTypesAttemptedAction,
  AddCommissionTypeAttemptedAction,
  DeleteCommissionTypeAttemptedAction,
  UpdateCommissionTypeAttemptedAction } from '../../../core/store/commission/commission.action';
import { getCommissionTypes, getCommissionTypeAddResponse, getCommissionTypeDeleteResponse, getCommissionTypeUpdateResponse } from '../../../core/store/commission/commission.reducer';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { take, filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnInit, OnDestroy {

  haveReadPermission = true;

  haveWritePermission = true;

  private subscription;

  loading: boolean;

  commissionTypes: any[];

  commissiontypesLength: number;

  commissionTypesDisplayedColumns: string[] = ['type', 'value', 'actions'];

  commissionForm: FormGroup = new FormGroup({
    type: new FormControl(undefined, Validators.required),
    value: new FormControl(undefined, [Validators.required, Validators.min(0), Validators.max(100)])
  });

  action: string;

  selectedType: string;

  selectedCommission: any;

  private dialogSubscription$;

  private deleteSubscription$;

  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_PACKAGE_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_PACKAGE_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.action = 'add';
    this.store.dispatch(new LoadCommissionTypesAttemptedAction());
    this.subscription = this.store.select(getCommissionTypes).pipe(filter(data => data !== undefined)).subscribe(data => {
      console.log(data);
      this.commissionTypes = data;
      this.commissiontypesLength = data.length;
      this.loading = false;
    });
    this.deleteSubscription$ = this.store.select(getCommissionTypeDeleteResponse).pipe(filter(resp => resp !== undefined))
    .subscribe(data => {
      console.log(data);
      if (data.message !== 'DELETED') {
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            button: 'serverError'
          }
        });
      } else {
        this.store.dispatch(new LoadCommissionTypesAttemptedAction());
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.dialogSubscription$) {
      this.dialogSubscription$.unsubscribe();
    }
    if (this.deleteSubscription$) {
      this.deleteSubscription$.unsubscribe();
    }
  }

  onSubmit(): void {
    console.log(this.commissionForm.value);
    this.store.dispatch(new AddCommissionTypeAttemptedAction(this.commissionForm.value));
    this.store.select(getCommissionTypeAddResponse).pipe(filter(data => data !== undefined), take(1)).subscribe(resp => {
      if (resp.code === 200) {
        this.loading = true;
        this.store.dispatch(new LoadCommissionTypesAttemptedAction());
        this.commissionForm.reset();
        this.selectedType = null;
      } else {
        this.loading = false;
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {buttons: 'serverError'}
        });
      }
    });
  }

  onCommissionTypeChange(selectedType: string): void {
    if (selectedType === 'PERCENT') {
      this.selectedType = 'PERCENT';
    } else {
      this.selectedType = 'AMOUNT';
    }
  }

  isFieldValid(field: any): boolean {
    return true;
  }

  onUpdate(commission: any): void {
    this.action = 'update';
    this.selectedCommission = commission;
    this.selectedType = commission.type;
    this.commissionForm.patchValue({
      type: commission.type,
      value: commission.value
    });
  }

  onCancelUpdate(): void {
    this.action = 'add';
    this.commissionForm.reset();
  }

  updateCommission(): void {
    this.loading = true;
    const payload = {
      id: this.selectedCommission.id,
      type: this.commissionForm.get('type').value,
      value: this.commissionForm.get('value').value
    };
    console.log('new commission is', payload);
    this.store.dispatch(new UpdateCommissionTypeAttemptedAction(this.selectedCommission.id, payload));
    this.store.select(getCommissionTypeUpdateResponse).pipe(filter(data => data !== undefined), take(1)).subscribe(resp => {
      if (resp.code === 200 && resp.value !== null) {
        this.store.dispatch(new LoadCommissionTypesAttemptedAction());
        this.action = 'add';
        this.commissionForm.reset();
      } else {
        this.loading = false;
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {buttons: 'serverError'}
        });
      }
    });
  }

  deleteCommission(commission: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30%',
      data: {
        title: this.translate.instant('CONFIRMATION_CHOICE'),
        message: this.translate.instant('DELETE_COMMISSION_CONFIRMATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'DELETE_COMMISSION_CONFIRMATION',
        value: commission
      }
    });
    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.store.dispatch(new DeleteCommissionTypeAttemptedAction(commission.id));
      }
    });
  }

}
