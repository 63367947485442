<div class="content">
    <div class="full-height bg-white pt-1">
        <div class="container">
            <div class="card h-100">
                <div class="card-header bold">
                    <span class="float-left">{{'APPLICATION.NEWS.SUBHEADER' | translate}}</span>
                    <ng-container *ngIf="haveReadPermission">
                        <div *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center float-left ml-4">
                            <p class="error">{{'ACCESS_CONTROL.NO_NEWS_WRITE_PERMISSION' | translate}}</p> &nbsp;
                            <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                        </div>
                    </ng-container>
    
                    <button type="button" class="btn btn-sm btn-success float-right" *ngIf="haveReadPermission" [disabled]="!haveWritePermission" (click)="onAddNews()">
                        <i class="fa fa-plus" aria-hidden="true" width="16" height="16"></i> {{'BUTTONS.NEW' | translate}}
                    </button>
                    <div class="clearfix"></div>
                </div>
                <div class="card-body">
                    <ng-container *ngIf="newsLength > 0; else EMPTY_NEWS">
                        <div class="d-flex flex-items-strech flex-wrap ml-n2 mr-n2" *ngIf="haveReadPermission, else NO_READ_PERMISSION">
                            <div class="w-50 px-2 d-flex flex-row" *ngFor="let news of news|slice:0:show; let i = index">
                                <div class="Box rounded-2 p-4 f-flex mb-4" style="width: 100%; position: relative;">
                                    <span class="rang-rounded">{{news.rang}}</span>
                                    <div class="flex-auto">
                                        <div class="">
                                            <span class="f4 text-bold muted-link" (click)="onNewsDetails(news)">{{news.title}}</span>
                                            <span *ngIf="haveWritePermission"  class="muted-link ml-3 float-right" (click)="deleteNews(news)" title="{{'BUTTONS.DELETE' | translate}}">
                                                <i class="fa fa-trash" width="16" height="16"></i>
                                            </span>
            
                                            <span *ngIf="haveWritePermission"  class="muted-link ml-3 float-right" (click)="modifyNews(news)" title="{{'BUTTONS.UPDATE' | translate}}">
                                                <i class="fa fa-edit" width="16" height="16"></i>
                                            </span>
                                        </div>
                                        <div class="text-small text-gray-light">{{'APPLICATION.NEWS.CREATED_ON' | translate}}: {{news.crationDate | date:'short'}}</div>
                                        <div class="f6 my-6">{{news.description}}</div>
                                        <!-- <span (click)="onNewsDetails(news)" class="link">{{'APPLICATION.NEWS.SEE' | translate}}</span> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-center link" (click)="showMore()" *ngIf="newsLength > 6" >{{showText}}</div>
                    </ng-container>
                    <ng-template #EMPTY_NEWS>
                        <div class="none-block" *ngIf="newsLength == 0">
                            <img src="../../../assets/images/vide/vide.png"
                                srcset="../../../assets/images/vide/vide@2x.png 2x, ../../../assets/images/vide/vide@3x.png 3x"
                                class="none"/>
                            <p class="text none-text">{{'APPLICATION.NEWS.NONE' | translate}}</p>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
                srcset="../../../assets/images/vide/vide@2x.png 2x,
                ../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
    </div>
</ng-template>
