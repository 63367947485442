<div class="d-flex align-center justify-center">
    <div class="alert alert-success alert-dismissible w-70" role="alert" *ngIf="requestSuccessful">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.isResetFailed = false"><span aria-hidden="true">&times;</span></button>
        {{'HOME.CHILD_DOCUMENTS.DOCS_VALIDATED' | translate}}
    </div>
    <div class="alert alert-danger alert-dismissible w-70" role="alert" *ngIf="requestFailed">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.isResetFailed = false"><span aria-hidden="true">&times;</span></button>
        {{'HOME.CHILD_DOCUMENTS.DOCS_NOT_VALIDATED' | translate}}
    </div>
</div>

<div class="content" fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="2%">
    <div class="spinner" *ngIf="loading">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>
    <div class="d-flex justify-center w-100" *ngIf="!loading">
        <div class="box image-viewer-box" fxFlex="50%" [ngClass]="{'border': !isPhotoRectificationChecked}">
            <img [src]="photo" class="image" [alt]="childDocument.name" />
        </div>

        <div fxFlex="35%" class="d-flex flex-columns align-start mx-0" *ngIf="this.childDocument">
            <div class="panel panel-default border">
                <div class="panel-heading font">
                    <div>
                        <div class="panel-title">
                            <span class="bold biggie">{{'HOME.CHILD_DOCUMENTS.CHILD' | translate}}: </span>
                            <span class="small link">{{selectedChild.firstname}} {{selectedChild.lastname}}</span>
                        </div>

                        <div class="panel-title">
                            <span class="bold biggie">{{'HOME.CHILD_DOCUMENTS.CHILD_EMAIL' | translate}}: </span>
                            <span class="small link">{{selectedChild.email}}</span>
                        </div>

                        <div class="panel-title">
                            <span class="bold biggie">{{'HOME.CHILD_DOCUMENTS.DOC_STATE' | translate}}: </span>
                            <span class="small link">
                                <app-label [value]="childDocument.imageState"></app-label>
                            </span>
                        </div>

                        <div class="panel-title">
                            <span class="bold biggie">{{'HOME.CHILD_DOCUMENTS.DOC_NAME' | translate}}: </span>
                            <span class="small link">{{childDocument.name}}</span>
                        </div>
                    </div>

                    <div style="margin-top: 16px;">
                        <div class="panel-title">
                            <span class="bold biggie">{{'HOME.CHILD_DOCUMENTS.PARENT_NAME' | translate}}: </span>
                            <span class="small link">{{client.firstname}} {{client.lastname}}</span>
                        </div>

                        <div class="panel-title">
                            <span class="bold biggie">{{'HOME.CHILD_DOCUMENTS.PARENT_EMAIL' | translate}}: </span>
                            <span class="small link">{{client.email}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-0 d-flex flex-columns align-start" *ngIf="selectedChild.userStatusName === 'KYC_PENDING'">
                <button type="text" class="btn btn-sm btn-white w-0" (click)="validateChildDocument()">{{'BUTTONS.VALIDATE' | translate}} </button>
                <button type="text" class="btn btn-sm btn-danger w-0" (click)="rectifyChildDocument()">{{'BUTTONS.DECLINE' | translate}} </button>
            </div>
        </div>
    </div>
</div>