import { Component, OnInit, OnDestroy } from '@angular/core';
import { getBackofficeUpdateResponse, getBackofficePriviligeResponse, getBackofficeSelectedUser, getUserProfile } from '../../core/store/user-managements/user-management-reducer';
import { filter } from 'rxjs/operators';
import {
  SetBackofficeUserPriviligeAttemptedAction,
  LoadBackofficeUserProfileAttemptedAction,
  UpdateBackofficeUserAccountAttemptedAction } from '../../core/store/user-managements/user-management.action';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppState } from '../../core/store/index';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { times } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

class BOUser {
  id: string;
  email: string;
  fullname: string;
  activated: string;
  role: string;
  authorities: string[];

  constructor() {}
}

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit, OnDestroy {

  profileForm: FormGroup;

  user: BOUser;

  loading = false;

  private userProfileSubscription;

  private privilegeUpdateSubscription;

  private userUpdateSubscription;

  userUpdating: boolean;

  userSubmissionSuccess = false;

  userSubmissionFailed = false;

  private dialogSubscription;

  privileges = [
    {value: 'READ_PRIVILEGE', isChecked: false},
    {value: 'WRITE_PRIVILEGE', isChecked: false},
    {value: 'READ_USER', isChecked: false},
    {value: 'WRITE_USER', isChecked: false},
    {value: 'CLIENT_READ', isChecked: false},
    {value: 'CLIENT_WRITE', isChecked: false},
    {value: 'CLIENT_DOCUMENT_READ', isChecked: false},
    {value: 'CLIENT_DOCUMENT_WRITE', isChecked: false},
    {value: 'CLIENT_INFO_READ', isChecked: false},
    {value: 'CLIENT_INFO_WRITE', isChecked: false},
    {value: 'CLIENT_ACCOUNT_READ', isChecked: false},
    {value: 'CLIENT_ACCOUNT_WRITE ', isChecked: false},
    {value: 'CLIENT_TRANSACTIONS_READ ', isChecked: false},
    {value: 'CLIENT_TRANSACTIONS_WRITE ', isChecked: false},
    {value: 'CLIENT_CARD_READ ', isChecked: false},
    {value: 'CLIENT_CARD_WRITE ', isChecked: false},
    {value: 'CLIENT_POT_READ', isChecked: false},
    {value: 'CLIENT_POT_WRITE ', isChecked: false},
    {value: 'CLIENT_SAFEDEPOSIT_READ ', isChecked: false},
    {value: 'CLIENT_SAFEDEPOSIT_WRITE ', isChecked: false},
    {value: 'CLIENT_OPPOSED_READ ', isChecked: false},
    {value: 'CLIENT_OPPOSED_WRITE ', isChecked: false},
    {value: 'CLIENT_BENIFICIAIRY_READ ', isChecked: false},
    {value: 'CLIENT_BENIFICIARY_WRITE ', isChecked: false},
    {value: 'CLIENT_LOG_READ ', isChecked: false},
    {value: 'CLIENT_CONTACT', isChecked: false},
    {value: 'APPLICATION_NEWS_READ ', isChecked: false},
    {value: 'APPLICATION_NEWS_WRITE ', isChecked: false},
    {value: 'APPLICATION_OFFERT_READ ', isChecked: false},
    {value: 'APPLICATION_OFFERT_WRITE ', isChecked: false},
    {value: 'APPLICATION_MCC_READ ', isChecked: false},
    {value: 'APPLICATION_MCC_WRITE ', isChecked: false},
    {value: 'APPLICATION_SETTINGS_READ  ', isChecked: false},
    {value: 'APPLICATION_SETTINGS_WRITE ', isChecked: false},
  ];

  constructor(private store: Store<AppState>, private router: Router, private fb: FormBuilder, private dialog: MatDialog, private translate: TranslateService) {
    this.profileForm = this.fb.group({
      id: [{value: null, disabled: true}],
      fullname: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      activated: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    this.loading = true;
    this.store.dispatch(new LoadBackofficeUserProfileAttemptedAction());
    this.userProfileSubscription = this.store.select(getUserProfile).pipe(filter(resp => resp !== undefined))
    .subscribe((profile: BOUser) => {
      console.log(profile);
      if (profile !== undefined) {
        console.log(profile);
        this.user = profile;
        this.profileForm.patchValue({
          id: profile.id,
          fullname: profile.fullname,
          email: profile.email,
          activated: profile.activated,
          role: profile.role
        });
        profile.authorities.forEach(authority => {
          for (let i =0; i < this.privileges.length; i++) {
            if (authority === this.privileges[i].value) {
              this.privileges[i].isChecked = true;
            }
          }
        });
        this.loading = false;
      } else {
        this.router.navigate(['/settings/users']);
      }
    });

    this.userUpdateSubscription = this.store.select(getBackofficeUpdateResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(data => {
        this.userUpdating = false;
        this.loading = false;
        let dialogRef = null;
        if (data.code === 200) {
          dialogRef = this.dialog.open(DialogComponent, {
            width: '30%',
            data: {
              buttons: 'info',
              message: this.translate.instant('PROFILE_UPDATING_MESSAGE'),
              type: 'PROFILE_UPDATING',
              value: true,
            }
          });
        } else {
          dialogRef = this.dialog.open(DialogComponent, {
            width: '30%',
            data: {
              button: 'info',
              message: this.translate.instant('PROFILE_UPDATING_ERROR'),
              type: 'PROFILE_UPDATING',
              value: false
            }
          });
        }
        /*
        this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
          this.ngOnInit();
        });
        */
      });
  }

  ngOnDestroy(): void {
    if (this.userProfileSubscription) {
      this.userProfileSubscription.unsubscribe();
    }
    if (this.privilegeUpdateSubscription) {
      this.privilegeUpdateSubscription.unsubscribe();
    }
    if (this.userUpdateSubscription) {
      this.userUpdateSubscription.unsubscribe();
    }
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  isFieldValid(field: any): boolean {
    return true;
  }

  updateProfil(): void {
    this.loading = true;
    console.log(this.profileForm.value);
    this.userUpdating = true;
    const payload = {
      email: this.profileForm.get('email').value,
      fullName: this.profileForm.get('fullname').value
    };
    console.log('PAYLOAD IS :', payload);
    this.store.dispatch(new UpdateBackofficeUserAccountAttemptedAction(payload));
  }

}
