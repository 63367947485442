import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromBeneficiaries from './beneficiaries.actions';

export interface BeneficiariesState {
    data: any[];
    selected: any;
    loading: boolean;
    error: string;
}

export const initialState: BeneficiariesState = {
    data: undefined,
    selected: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state = initialState, action: fromBeneficiaries.BeneficiariesActions): any {
    switch (action.type) {
        case fromBeneficiaries.LOAD_BENEFICIARIES_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined
            };
        case fromBeneficiaries.LOAD_BENEFICIARIES_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value.beneficiaries
            };
        case fromBeneficiaries.LOAD_BENEFICIARIES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getBeneficiariesState = createFeatureSelector<BeneficiariesState>('beneficiaries');

export const getBeneficiaries = createSelector(getBeneficiariesState, (state: BeneficiariesState) => state.data);