import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RectificationAction, RectificationActionTypes } from './rectification.action';


export interface RectificationState {
    loading: boolean;
    email: string;
    newRectificationFields: [];
    resetRectificationFields: boolean;
    latestRectifications: [];
    verifiedRectifications: [];
    isRectificationSubmitted: boolean;
    verifiedDocuments: any[];
    removeRectificationsResponse: any;
    updateCheckedDocumentsResponse: any;
    kycSendResponse: any;
    documentsValidationResponse: any;
}

export const initialState: RectificationState = {
    loading: false,
    email: undefined,
    newRectificationFields: [],
    resetRectificationFields: false,
    latestRectifications: undefined,
    verifiedRectifications: undefined,
    isRectificationSubmitted: false,
    verifiedDocuments: undefined,
    removeRectificationsResponse: undefined,
    updateCheckedDocumentsResponse: undefined,
    kycSendResponse: undefined,
    documentsValidationResponse: undefined,
};

export function reducer(state: RectificationState = initialState, action: RectificationAction): any {
    switch (action.type) {
        case RectificationActionTypes.RectifyAttempted:
            return {
                ...state,
                loading: action.payload.loading,
                email: action.payload.email,
                rectificationFields: action.payload.rectification,
                isRectificationSubmitted: false
            };
        case RectificationActionTypes.RectifySuccessful:
            return {
                ...state,
                loading: false,
                latestRectifications: undefined,
                verifiedRectifications: [],
                isRectificationSubmitted: true,
            };
        case RectificationActionTypes.ResetRectificationFields:
            return {
                ...state,
                resetRectificationFields: true
            };
        case RectificationActionTypes.GET_VERIFIED_RECTIFICATIONS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                verifiedRectifications: undefined
            };
        case RectificationActionTypes.GET_VERIFIED_RECTIFICATIONS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                verifiedRectifications: action.payload.resp.value
            };
        case RectificationActionTypes.ADD_VERIFIED_RECTIFICATION_ATTEMPTED:
            return {
                ...state,
                loading: true,
                email: action.payload.email,
                newRectificationFields: action.payload.rectification,
            };
        case RectificationActionTypes.ADD_VERIFIED_RECTIFICATION_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                newRectificationFields: [],
                verifiedRectifications: action.payload.resp.value,
            };

        case RectificationActionTypes.REMOVE_RECTIFICATION_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case RectificationActionTypes.REMOVE_RECTIFICATION_SUCCESSFUL:
            return {
                ...state,
                removeRectificationsResponse: action.payload,
            };
        case RectificationActionTypes.REMOVE_RECTIFICATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
    /*

        case RectificationActionTypes.GET_CHECKED_DOCUMENTS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                verifiedDocuments: undefined
            };
        case RectificationActionTypes.GET_CHECKED_DOCUMENTS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                verifiedDocuments: action.payload.value.documents
            };
        case RectificationActionTypes.GET_CHECKED_DOCUMENTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case RectificationActionTypes.ADD_CHECKED_DOCUMENTS_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case RectificationActionTypes.ADD_CHECKED_DOCUMENTS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
            };
        case RectificationActionTypes.ADD_CHECKED_DOCUMENTS_FAILED:
            return {
                ...state,
                loading: false,
            };
        case RectificationActionTypes.UPDATE_CHECKED_DOCUMENTS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                updateCheckedDocumentsResponse: undefined,
            };
        case RectificationActionTypes.UPDATE_CHECKED_DOCUMENTS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                updateCheckedDocumentsResponse: undefined,
            };
        case RectificationActionTypes.UPDATE_CHECKED_DOCUMENTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case RectificationActionTypes.REMOVE_CHECKED_DOCUMENTS_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };

        case RectificationActionTypes.REMOVE_CHECKED_DOCUMENTS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
            };
        case RectificationActionTypes.REMOVE_CHECKED_DOCUMENTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case RectificationActionTypes.CLEAR_CHECKED_DOCUMENTS_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case RectificationActionTypes.CLEAR_CHECKED_DOCUMENTS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
            };
        case RectificationActionTypes.CLEAR_CHECKED_DOCUMENTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            */
        case RectificationActionTypes.SEND_TO_KYC_ATTEMPTED:
            return {
                ...state,
                loading: true,
                kycSendResponse: undefined,
            };
        case RectificationActionTypes.SEND_TO_KYC_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                kycSendResponse: action.payload
            };
        case RectificationActionTypes.SEND_TO_KYC_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case RectificationActionTypes.VALIDATE_CLIENT_DOCUMENTS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                documentsValidationResponse: undefined,
            };
        case RectificationActionTypes.VALIDATE_CLIENT_DOCUMENTS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                documentsValidationResponse: action.payload
            };
        case RectificationActionTypes.VALIDATE_CLIENT_DOCUMENTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getRectificationState = createFeatureSelector<RectificationState>('rectification');

export const getResetRectificationFields = createSelector(getRectificationState,
                                                          (state: RectificationState) => state.resetRectificationFields);
export const getVerifiedRectifications = createSelector(getRectificationState, (state: RectificationState) => state.verifiedRectifications);
export const getVerifiedDocuments = createSelector(getRectificationState, (state: RectificationState) => state.verifiedDocuments);
export const getRemoveRectificationsResponse =
    createSelector(getRectificationState, (state: RectificationState) => state.removeRectificationsResponse);
export const getUpdateCheckedDocumentsReponse =
    createSelector(getRectificationState, (state: RectificationState) => state.updateCheckedDocumentsResponse);
export const getKycSendResponse = createSelector(getRectificationState, (state: RectificationState) => state.kycSendResponse);
export const getDocumentsValidationResponse =
    createSelector(getRectificationState, (state: RectificationState) => state.documentsValidationResponse);
export const getIsRectificationSubmitted =
    createSelector(getRectificationState, (state: RectificationState) => state.isRectificationSubmitted);

