<ng-progress id="progressBar" [color]="'#3bd3af'"></ng-progress>
<mat-toolbar>
    <mat-toolbar-row class="top-navbar">
        <span>Ferdaws Back-Office</span>

        <div  fxFlex fxLayout="row" fxLayoutAlign="end center">
            <ul fxLayout="row" fxLayoutGap="20px">
                <li>
                    <button mat-icon-button>
                        <mat-icon matBadge="1" matBadgeColor="warn">notifications_outline</mat-icon>
                    </button>
                </li>

                <li>
                    <button mat-icon-button (click)="logout()">
                        <mat-icon>power_settings_new</mat-icon>
                    </button>
                </li>
            </ul>
        </div>
    </mat-toolbar-row>
  </mat-toolbar>
  