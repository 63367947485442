import { filter, debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { GetVerifiedRectificationsAttemptedAction } from './../../core/store/rectification/rectification.action';
import { AppState } from './../../core/store/index';
import { UserDetailsState } from './../../core/store/user-details/user-details.reducer';
import { GetUserDetailsAttemptedAction } from './../../core/store/user-details/user-details.action';
import { GetUsersAttemptedAction } from './../../core/store/users/users.action';
import { getUsers } from './../../core/store/users/users.reducer';
import { User } from './../../core/models/user.model';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Store } from '@ngrx/store';
import { ColumnMode , SelectionType } from '@swimlane/ngx-datatable';
import { DateFormatterService } from '../../core/services/shared/date-formatter.service';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';
import { LoadBackofficeUserProfileAttemptedAction } from '../../core/store/user-managements/user-management.action';
import { Subject } from 'rxjs';
import { getUsersStatus } from '../../core/store/users/users.reducer';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [DatePipe]
})


export class UsersComponent implements OnInit, OnDestroy {

  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    cle: '',
    startDate: null,
    endDate: null,
    status: []
  };

  messages = {
    emptyMessage: 'Aucune donnée trouvée',
    totalMessage: 'utilisateurs trouvés',
    // selectedMessage: 'sélectionné'
  };

  ColumnMode = ColumnMode;

  selected = [];

  SelectionType = SelectionType;

  rowClass = 'row';

  @ViewChild(MatMenuTrigger, {static: false}) trigger: MatMenuTrigger;

  loading: boolean;

  key = '';

  users: User[];

  private subscription;

  statusChecked = [];

  statusCheckedValue = [];

  statusSelectable = true;

  statusRemovable = true;

  searchButtonClicked = false;

  dateForm: FormGroup = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl()
  });

  private clientNameTerms$ = new Subject<string>();

  status = [
    {id: 0,  value: 'GUEST', isSelected: false},
    {id: 1,  value: 'TEL_CONFIRMED', isSelected: false},
    {id: 2,  value: 'MAIL_NOTCONFIRMED', isSelected: false},
    {id: 3,  value: 'IDENTITY_CONFIRMED', isSelected: false},
    {id: 4,  value: 'IDENTITY_PENDING', isSelected: false},
    {id: 5,  value: 'RECTIFICATION', isSelected: false},
    {id: 6,  value: 'RECTIFICATION_IDENTITY', isSelected: false},
    {id: 7,  value: 'RECTIFICATION_FIELDS', isSelected: false},
    {id: 8,  value: 'PIN_CONFIRMED', isSelected: false},
    {id: 9, value: 'ENABLED', isSelected: false},
    {id: 10, value: 'KYC_PENDING', isSelected: false},
    {id: 11, value: 'MISSING_PHOTO', isSelected: false},
    {id: 12, value: 'SUCCESS_PHOTO', isSelected: false},
    {id: 13, value: 'MISSING_CODEPIN', isSelected: false},
    {id: 14, value: 'BLOCKED', isSelected: false},
    {id: 15, value: 'ADDRESS_CONFIRMED', isSelected: false},
    {id: 16, value: 'ADDRESS_PENDING', isSelected: false},
    {id: 17, value: 'NEW_EMAIL_CONFIRMED', isSelected: false},
    {id: 18, value: 'KYC_NEW_ADDRESS_PENDING', isSelected: false},
    {id: 19, value: 'KYC_NEW_ADDRESS_CONFIRMED', isSelected: false},
    {id: 20, value: 'NEW_EMAIL_NOT_CONFIRMED', isSelected: false},
    {id: 21, value: 'NEW_TEL_NOT_CONFIRMED', isSelected: false},
    {id: 22, value: 'UNRECOGNIZED_DEVICE', isSelected: false}
  ];

  constructor(private store: Store<AppState>, private storeUserDetails: Store<UserDetailsState>,
              private dateFormatter: DateFormatterService, private _datePipe: DatePipe, private translate: TranslateService) {
      this.page.pageNumber = 0;
      this.page.size = 10;
   }

   ngOnDestroy(): void {
     this.subscription.unsubscribe();
   }

  ngOnInit(): void {
    this.store.dispatch(new LoadBackofficeUserProfileAttemptedAction());
    this.subscription = this.store.select(getUsers).pipe(filter(resp => resp !== undefined)).subscribe((data: any) => {
      console.log(data);
      this.users = data.content;
      this.page.totalElements = data.totalElements;
      this.page.totalPages = data.totalPages;
      this.page.size = data.size;
      this.loading = false;
    });
    this.clientNameTerms$.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      this.setPage({offset: 0}, false);
    });
    this.setPage({offset: 0}, true);
  }

  setPage(pageInfo: any, isFirstLoad: boolean): void {
    this.loading = true;
    if (isFirstLoad) {
      this.store.select(getUsersStatus).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
        console.log('SAVED STATUS : ', data);
        if (data && data.length > 0) {
          this.page.status = data;
          this.statusChecked = data;
          this.statusChecked.forEach(checkedStatus => {
            this.status.forEach(statu => {
              if (checkedStatus === statu.value) {
                statu.isSelected = true;
              }
            });
          });
        }
      });
    } else {
      this.page.status = this.statusChecked;
    }
    this.page.pageNumber = pageInfo.offset;
    this.page.cle = this.key;
    this.page.startDate = this.dateFormatter.formatWithHash(this.dateForm.get('startDate').value);
    this.page.endDate = this.dateFormatter.formatWithHash(this.dateForm.get('endDate').value);
    console.log('** page is ', this.page);
    this.store.dispatch(new GetUsersAttemptedAction(this.page));
  }

   userDetails({ selected }): void {
     console.log('FROM USERDETAILS ', selected[0].id);
     this.store.dispatch(new GetVerifiedRectificationsAttemptedAction({idClient: selected[0].id}));
     this.storeUserDetails.dispatch(new GetUserDetailsAttemptedAction({idClient: selected[0].id}));
   }

   /* KEY WORD FILTER */
   /*
   filter(): void {
    this.setPage({offset: 0});
   }
   */

   onClientNameKeyup(event: any): void {
     this.loading = true;
     this.clientNameTerms$.next(event.target.value);
   }

   /*  STATUS FILTER */
   search(): void {
    console.log('status chekced', this.statusChecked);
    this.setPage({offset: 0}, false);
    this.trigger.closeMenu();
  }

  addStatus(data: any, statu: any): void {
    if (data.checked) {
      this.statusChecked = this.statusChecked.concat(statu.value);
      this.status[statu.id].isSelected = true;
    }
    else {
      const indexOfStatuu = this.statusChecked.findIndex(value => statu.value === value);
      if (indexOfStatuu >= 0) {
        this.statusChecked.splice(indexOfStatuu, 1);
      }
      console.log(this.statusChecked);
    }
  }

  removeStatus(statuChecked: string): void {
    const copy: any[] = [...this.statusChecked];
    const indexOfStatuChecked = copy.findIndex(value => statuChecked === value);
    if (indexOfStatuChecked >= 0) {
      copy.splice(indexOfStatuChecked, 1);
      this.status.forEach(status => {
        if (status.value === statuChecked) {
          status.isSelected = false;
        }
      });
      this.statusChecked = copy;
    }
    this.setPage({offset: 0}, false);
  }


  annuler(): void {
    this.status.forEach(entry => { entry.isSelected = false; });
    this.statusChecked = [];
    this.setPage({offset: 0}, false);
    this.trigger.closeMenu();
  }

  datePipe(): any {
    return {transform: (value) => this._datePipe.transform(value, 'M/d/yy, h:mm a')};
  }

  getRowClass(row: any): any {
    return 'rows';
  }

}
