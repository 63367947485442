import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromDeposit from './deposit.actions';

import { DepositService } from '../../services/deposit/deposit.service';
import { LoadDepositHistory, LoadDeposits } from './deposit.actions';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';


@Injectable()
export class DepositEffects {

    constructor(private actions$: Actions, private depositService: DepositService, public dialog: MatDialog) {}

    @Effect()
    loadDeposits$ = this.actions$.pipe(
        ofType(fromDeposit.LOAD_DEPOSITS),
        switchMap((data: LoadDeposits) => {
            console.log('FROM NGRX EFFECT : ', data,);
            return this.depositService.getDeposits(data.payload, data.page)
                .pipe(
                    map(resp => new fromDeposit.LoadDepositsSuccess(resp)),
                    catchError(error => of(new fromDeposit.LoadDepositsFail(error)))
                );
        })
    );

    @Effect()
    loadDepositHistory$ = this.actions$.pipe(
        ofType(fromDeposit.LOAD_DEPOSIT_HISTORY),
        switchMap((data: LoadDepositHistory) => {
            return this.depositService.getHistory(data.idClient, data.idSafeDeposit)
            .pipe(
                map(resp => new fromDeposit.LoadDepositHistorySuccess(resp)),
                catchError(error => of (new fromDeposit.LoadDepositHistoryFail(error)))
            );
        })
    );
}
