import { Deposit } from '../../models/deposit.model';
import { Action } from '@ngrx/store';

export const LOAD_DEPOSITS = '[Deposits] Load Deposits';
export const LOAD_DEPOSITS_FAIL = '[Deposits] Load Deposits Fail';
export const LOAD_DEPOSITS_SUCCESS = '[Deposits] Load Deposits Success';

export const LOAD_DEPOSIT_HISTORY = '[Deposits] Load Deposits History';
export const LOAD_DEPOSIT_HISTORY_FAIL = '[Deposits] Load Deposits History Fail';
export const LOAD_DEPOSIT_HISTORY_SUCCESS = '[Deposits] Load Deposits Hitory Success';

export const SCREEN_DEPOSIT = '[Deposits] Screen Deposit';
export const SCREEN_DEPOSIT_HISTORY = '[Deposits] Screen Deposit History';
export const SCREEN_DEPOSIT_DETAILS = '[Deposits] Screen Deposit Details';
export const SCREEN_DEPOSIT_END = '[Deposits] Screen Deposit End';


export const SELECT_DEPOSIT = '[Deposits] Select Deposit';
export const UNSELECT_DEPOSIT = '[Deposits] Unselect Deposit';


//
export class LoadDepositHistory implements Action {
    readonly type = LOAD_DEPOSIT_HISTORY;
    constructor(public idClient: string, public idSafeDeposit: string) {}
}
export class LoadDepositHistoryFail implements Action {
    readonly type = LOAD_DEPOSIT_HISTORY_FAIL;
    constructor(public payload: any) {}
}
export class LoadDepositHistorySuccess implements Action {
    readonly type = LOAD_DEPOSIT_HISTORY_SUCCESS;
    constructor(public payload: any) {}
}
export class LoadDeposits implements Action {
    readonly type = LOAD_DEPOSITS;
    constructor(public payload: any, public page: any) {}
}
export class LoadDepositsFail implements Action {
    readonly type = LOAD_DEPOSITS_FAIL;
    constructor(public payload: any) {}
}
export class LoadDepositsSuccess implements Action {
    readonly type = LOAD_DEPOSITS_SUCCESS;
    constructor(public payload: any) {}
}


//
export class ScreenDeposit implements Action {
    readonly type = SCREEN_DEPOSIT;
}
export class ScreenDepositHistory implements Action {
    readonly type = SCREEN_DEPOSIT_HISTORY;
}
export class ScreenDepositDetails implements Action {
    readonly type = SCREEN_DEPOSIT_DETAILS;
}
export class ScreenDepositEnd implements Action {
    readonly type = SCREEN_DEPOSIT_END;
}

//
export class SelectDeposit implements Action {
    readonly type = SELECT_DEPOSIT;
    constructor(public payload: Deposit) {}
}
export class UnselectDeposit implements Action {
    readonly type = UNSELECT_DEPOSIT;
}



export type DepositActions =
    | ScreenDeposit
    | ScreenDepositHistory
    | ScreenDepositDetails
    | ScreenDepositEnd
    | SelectDeposit
    | UnselectDeposit
    | LoadDepositHistory
    | LoadDepositHistoryFail
    | LoadDepositHistorySuccess
    | LoadDeposits
    | LoadDepositsFail
    | LoadDepositsSuccess
    ;
