import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UsersService} from '../../services/users/users.service';
import {
  AddIdUserAction,
  GetUserAttemptedAction,
  GetUserDetailsAttemptedAction,
  GetUserDetailsFailureAction,
  GetUserDetailsSuccessAction,
  GetUserFailedAction,
  GetUserSuccessfulAction,
  UserDetailsActionTypes
} from './user-details.action';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';


@Injectable()
export class UserDetailsEffects {

  constructor(private actions$: Actions, private usersService: UsersService, private router: Router) {}

  @Effect()
  getUserDetailsAttempted$ = this.actions$.pipe(
    ofType<GetUserDetailsAttemptedAction>(UserDetailsActionTypes.GET_USER_DETAILS_ATTEMPTED),
    switchMap((data: GetUserDetailsAttemptedAction) => this.usersService.getUserDetails(data.payload)
      .pipe(
        map( (resp) => new GetUserDetailsSuccessAction(resp)),
        catchError( (err) => { console.log(err); return of (new GetUserDetailsFailureAction(err)); })
      )
    ),
  );

  @Effect({dispatch: false})
  getUserDetailsSuccessful$: Observable<any> = this.actions$.pipe(
    ofType<GetUserDetailsSuccessAction>(UserDetailsActionTypes.GET_USER_DETAILS_SUCCESS),
    tap(data => {
      this.router.navigate(['/clients/details/info']);
      localStorage.setItem('client', JSON.stringify(data.payload.value));
    })
  );

  @Effect()
  getUserAttempted$ = this.actions$.pipe(
    ofType<GetUserAttemptedAction>(UserDetailsActionTypes.GET_USER_ATTEMPTED),
    switchMap((data: GetUserAttemptedAction) => this.usersService.getUserDetails(data.payload)
      .pipe(
        map( (resp) => new GetUserSuccessfulAction(resp)),
        catchError( (err) => of (new GetUserFailedAction(err)))
      )
    )
  );

  @Effect()
  getUserIdSucces$s: Observable<any> = this.actions$.pipe(
    ofType<AddIdUserAction>(UserDetailsActionTypes.ADD_ID_USER),
    tap(data => new AddIdUserAction(data))
  );
}
