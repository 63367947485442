import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core/store/index';
import { getWallet } from '../../../core/store/cards/cards.reducer';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { Router, ActivatedRoute } from '@angular/router';
import { GetWalletAttemptedAction } from '../../../core/store/cards/cards.actions';
import { filter, take } from 'rxjs/operators';
import { getSelectedDeposit } from '../../../core/store/deposit/deposit.reducer';

@Component({
  selector: 'app-deposit-wallet-details',
  templateUrl: './deposit-wallet-details.component.html',
  styleUrls: ['./deposit-wallet-details.component.scss']
})
export class DepositWalletDetailsComponent implements OnInit, OnDestroy {

  loading = false;

  private walletSubscription: any;

  wallet: any;

  deposit: any;

  constructor(private store: Store<AppState>, private router: Router, private route: ActivatedRoute) { }

  ngOnDestroy(): void {
    if (this.walletSubscription) {
      this.walletSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.select(getSelectedDeposit).pipe(take(1)).subscribe(selected => {
      console.log('SELECTED DEPOSITS: ', selected);
      if (selected === undefined || selected === null) {
        this.router.navigate(['/clients/details/deposits']);
        return;
      }
      this.deposit = selected;
    });

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    const walletId = this.route.snapshot.paramMap.get('walletId');
    console.log('selected wallet id is : ', walletId);
    this.store.dispatch(new GetWalletAttemptedAction({idClient: client.id, idWallet: walletId}));
    this.walletSubscription = this.store.select(getWallet).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('wallet is :', data);
      this.wallet = data;
      this.loading = false;
    });
  }

  onBack(): void {
    this.router.navigate(['/clients/details/deposits']);
  }

}
