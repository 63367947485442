import { Action } from '@ngrx/store';

export const LOAD_OFFERS_ATTEMPTED = '[Offers] Load Offers Attempted';
export const LOAD_OFFERS_SUCCESSFUL = '[Offers] Load Offers Successful';
export const LOAD_OFFERS_FAILED = '[Offers] Load Offers Failed';

export const ADD_OFFER_ATTEMPTED = '[Offers] Add Offer Attempted';
export const ADD_OFFER_SUCCESSFUL = '[Offers] Add Offer Successful';
export const ADD_OFFER_FAILED = '[Offers] Add Offer Failed';

export const DELETE_OFFER_ATTEMPTED = '[Offers] Delete Offer Attempted';
export const DELETE_OFFER_SUCCESSFUL = '[Offers] Delete Offer Successful';
export const DELETE_OFFER_FAILED = '[Offers] Delete offer Failed';

export const UPDATE_OFFER_ATTEMPTED = '[Offers] Update offer attempted';
export const UPDATE_OFFER_SUCCESSFUL = '[Offers] Update offer successful';
export const UPDATE_OFFER_FAILED = '[Offers] Update offer failed';

export const SELECT_OFFER = '[Offers] Select user selected offer';


export class LoadOffersAttemptedAction implements Action {
    readonly type = LOAD_OFFERS_ATTEMPTED;
    constructor() {}
}

export class LoadOffersSuccessfulAction implements Action {
    readonly type = LOAD_OFFERS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadOffersFailedAction implements Action {
    readonly type = LOAD_OFFERS_FAILED;
    constructor(public payload: any) {}
}

export class AddOfferAttemptedAction implements Action {
    readonly type = ADD_OFFER_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddofferSuccessfulAction implements Action {
    readonly type = ADD_OFFER_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddOfferFailedAction implements Action {
    readonly type = ADD_OFFER_FAILED;
    constructor(public payload: any) {}
}

export class DeleteOfferAttemptedAction implements Action {
    readonly type = DELETE_OFFER_ATTEMPTED;
    constructor(public payload: any) {}
}

export class DeleteOfferSuccesffulAction {
    readonly type = DELETE_OFFER_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeleteOfferFailedAction {
    readonly type = DELETE_OFFER_FAILED;
    constructor(public payload: any) {}
}

export class UpdateOfferAttemtpedAction implements Action {
    readonly type = UPDATE_OFFER_ATTEMPTED;
    constructor(public id: string, public payload: any) {}
}

export class UpdateOfferSuccessfulAction implements Action {
    readonly type = UPDATE_OFFER_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateOfferFailedAction implements Action {
    readonly type = UPDATE_OFFER_FAILED;
    constructor(public payload: any) {}
}

export class SelectOfferAction implements Action {
    readonly type = SELECT_OFFER;
    constructor(public payload: any, public action: string) {}
}

export type OffersActions =
    |LoadOffersAttemptedAction
    |LoadOffersSuccessfulAction
    |LoadOffersFailedAction
    |AddOfferAttemptedAction
    |AddofferSuccessfulAction
    |AddOfferFailedAction
    |DeleteOfferAttemptedAction
    |DeleteOfferSuccesffulAction
    |DeleteOfferFailedAction
    |UpdateOfferAttemtpedAction
    |UpdateOfferSuccessfulAction
    |UpdateOfferFailedAction
    |SelectOfferAction
    ;

