import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BeneficiariesService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getBeneficiaries(payload: {idClient: string}): any {
    console.log('beneficiaries web service called with payload: ', payload);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/beneficiaries`);
  }

}
