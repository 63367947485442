import { LogoutAction } from './../../core/store/auth/auth.action';
import { Store } from '@ngrx/store';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/core/store';
import { LoadMccAttemptedAction } from '../../core/store/mcc/mcc.action';

export interface DialogData {
  title: string;
  message: string;
  buttons: string;
  value: any;
  confirmText: string;
  cancelText: string;

  type: string;
  align: string;
  id: string;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  constructor(@Inject (MAT_DIALOG_DATA) public data: DialogData,
              private dialogRef: MatDialogRef<DialogComponent>,
              private store: Store<AppState>) { }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.close(false);
  }

  close(value: any): void {
    this.dialogRef.close(value);
  }

  onConfirmSubmitRectification(): void {
    if (this.data.type === 'SUBMIT_RECTIFICATION_CONFIRMATION') {
      /*
      const idClient = this.data.value.idClient;
      const email = this.data.value.email;
      const rectifications = this.data.value.rectifications;
      console.log('RECTIFY DISPATCHED FROM DIALOG WITH: ', {idClient, email, rectification: rectifications});
      */
      this.close(true);
      // location.reload();
    }
  }

  onConfirm(): void {
    if (this.data.buttons === 'add-rectif') {
      this.close(true);
    }
  }

  onError(): void {
    this.close(false);
    // this.store.dispatch(new LogoutAction());
  }

  @HostListener('keydown.esc')
  onEsc(): void {
    this.close(false);
  }

  onNoClicked(): void {
    this.close(false);
  }

  onYesClicked(): void {
    if (this.data.type === 'DELETE_NEWS_CONFIRMATION') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'DELETE_OFFER_CONFIRMATION') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'PACKAGE_CLIENT_ASSIGNMENT') {
      console.log('CALLING WEB SERVICE FOR ASSIGNING CLIENT TO PACKAGE WITH PAYLOAD: ', this.data.value);
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'CLIENT_MODULE_PACKAGE_ASSIGNMENT') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'ADDRESS_UPDATE_VALIDATION') {
      this.dialogRef.close(true);
    }
    if (this.data.type === 'ADDRESS_UPDATE_INVALIDATION') {
      this.dialogRef.close(true);
    }
    if (this.data.type === 'DELETE_MCC_CONFIRMATION') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'DELETE_PACKAGE_CONFIRMATION') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'DELETE_OPERATION_CONFIRMATION') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'DELETE_COMMISSION_CONFIRMATION') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'DELETE_BO_USER_CONFIRMATION') {
      this.dialogRef.close(this.data.value);
    }
    if (this.data.type === 'CHILD_DOCUMENT_VALIDATION') {
      this.dialogRef.close(true);
    }
    if (this.data.type === 'CHILD_DOCUMENT_RECTIFICATION') {
      this.dialogRef.close(true);
    }
  }

  onOk(): void {
    if (this.data.type === 'MCC_UPLOAD') {
      console.log('MCC_UPLAOD ERROR !!!!!!!!!!!');
      this.close(false);
      this.store.dispatch(new LoadMccAttemptedAction({pageIndex: 0, pageSize: 10}));
    }
    if (this.data.type === 'PROFILE_UPDATING') {
      if (this.data.value) {
        this.close(true);
      } else {
        this.close(false);
      }
    }
    if (this.data.type === 'USER_PRIVILEGES_UPDATE') {
      if (this.data.value) {
        this.close(true);
      } else {
        this.close(false);
      }
    }
  }

}
