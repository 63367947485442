import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromMcc from './mcc.action';

export interface MccState {
    data: any;
    addResponse: any;
    deleteResponse: any;
    updateResponse: any;
    selectedMcc: any;
    action: string;
    loading: boolean;
    error: string;
}

export const initialState: MccState = {
    data: undefined,
    addResponse: undefined,
    deleteResponse: undefined,
    updateResponse: undefined,
    selectedMcc: undefined,
    action: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: MccState = initialState, action: fromMcc.MccActions): any {
    switch (action.type) {
        case fromMcc.LOAD_MCC_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined
            };
        case fromMcc.LOAD_MCC_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value
            };
        case fromMcc.LOAD_MCC_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

            case fromMcc.ADD_MCC_ATTEMPTED:
                return {
                    ...state,
                    loading: true
                };
            case fromMcc.ADD_MCC_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    addResponse: action.payload.value
                };
            case fromMcc.ADD_MCC_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                };

            case fromMcc.ADD_MULTIPLE_MCC_ATTEMPTED:
                return {
                    ...state,
                    loading: true
                };
            case fromMcc.ADD_MULTIPLE_MCC_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    addResponse: action.payload
                };
            case fromMcc.ADD_MULTIPLE_MCC_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                };

            case fromMcc.UPDATE_MCC_ATTEMTPED:
                return {
                    ...state,
                    loading: true
                };
            case fromMcc.UPDATE_MCC_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    updateResponse: action.payload
                };
            case fromMcc.UPDATE_MCC_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                };

            case fromMcc.DELETE_MCC_ATTEMPTED:
                return {
                    ...state,
                    loading: true
                };
            case fromMcc.DELETE_MCC_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    deleteResponse: action.payload.value
                };
            case fromMcc.DELETE_MCC_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload
                };
            case fromMcc.SET_MCC:
                return {
                    ...state,
                    selectedMcc : action.payload
                };
            default:
                return state;
    }
}

export const getMccState = createFeatureSelector<MccState>('mcc');

export const getMccs = createSelector(getMccState, (state: MccState) => state.data);
export const getMccsAddResponse = createSelector(getMccState, (state: MccState) => state.addResponse);
export const getMccsUpdateResponse = createSelector(getMccState, (state: MccState) => state.updateResponse);
export const getMccsDeleteResponse = createSelector(getMccState, (state: MccState) => state.deleteResponse);
export const getSelectedMcc = createSelector(getMccState, (state: MccState) => state.selectedMcc);
