
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCards from './cards.actions';

export interface CardsState {
    loading: boolean;
    cardsScreen: number;
    physicalCards: any[];
    virtualCards: any[];
    childs: any[];
    selectedCard: any;
    childCards: any[];
    selectedChild: any;
    wallet: any;
    error: string;
}

export const initialState: CardsState = {
    loading: false,
    cardsScreen: 1,
    physicalCards: undefined,
    virtualCards: undefined,
    childs: undefined,
    selectedCard: undefined,
    childCards: undefined,
    selectedChild: undefined,
    wallet: undefined,
    error: undefined,
};


export function reducer(state: CardsState = initialState, action: fromCards.PotsActions): any {
    switch (action.type) {
        case fromCards.LOAD_PHYSICAL_CARDS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                childs: undefined,
                physicalCards: undefined,
                virtualCards: undefined,
                childCards: undefined,
                selectedChild: undefined,
                wallet: undefined
            };
        case fromCards.LOAD_PHYSICAL_CARDS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                physicalCards: action.payload.value
            };
        case fromCards.LOAD_PHYSICAL_CARDS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromCards.LOAD_VIRTUAL_CARDS_ATTEMPTED:
            return {
                ...state,
                loading: true
            };
        case fromCards.LOAD_VIRTUAL_CARDS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                virtualCards: action.payload.value
            };
        case fromCards.LOAD_VIRTUAL_CARDS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromCards.LOAD_CHILDS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                childs: undefined
            };
        case fromCards.LOAD_CHILDS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                childs: action.payload.value,
                selectedChild: action.payload.value[0]
            };
            case fromCards.LOAD_CHILDS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
            case fromCards.LOAD_CHILD_CARDS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                childCards: undefined
            };
        case fromCards.LOAD_CHILD_CARDS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                childCards: action.payload.value
            };
            case fromCards.LOAD_CHILD_CARDS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromCards.CARDS_SCREEN:
            return {
                ...state,
                loading: false,
                cardsScreen: action.payload,
                wallet: undefined
            };
        case fromCards.SELECT_CHILD:
            return {
                ...state,
                selectedChild: action.payload
            };
        case fromCards.SELECT_CARD:
            return {
                ...state,
                selectedCard: action.payload
            };
        case fromCards.GET_WALLET_ATTEMPTED:
            return {
                ...state,
                loading: true,
                wallet: undefined
            };
        case fromCards.GET_WALLET_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                wallet: action.payload.value
            };
        case fromCards.GET_WALLET_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getCardsState = createFeatureSelector < CardsState > ('cards');

export const getPhysicalCards = createSelector(getCardsState, (state: CardsState) => state.physicalCards);
export const getVirtualCards = createSelector(getCardsState, (state: CardsState) => state.virtualCards);
export const getChilds = createSelector(getCardsState, (state: CardsState) => state.childs);
export const getChildCards = createSelector(getCardsState, (state: CardsState) => state.childCards);
export const getCardsScreen = createSelector(getCardsState, (state: CardsState) => state.cardsScreen);
export const getSelectedChild = createSelector(getCardsState, (state: CardsState) => state.selectedChild);
export const getSelectedCard = createSelector(getCardsState, (state: CardsState) => state.selectedCard);
export const getWallet = createSelector(getCardsState, (state: CardsState) => state.wallet);

