import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getAllTickets(payload: any): any {
    const body = {
      cle: payload.searchKey,
      clientFirstName: payload.clientFirstName,
      createdByName: payload.createdByName,
      page: payload.pageNumber,
      size: payload.size,
      status: payload.status,
      subject: payload.subject
    };
    console.log('tickets web service called with payload: ', body);
    return this.http.post(this.baseUrl + `/tickets`, body);
  }
}
