<div class="content">
    <div class="full-height bg-white">
        <div class="container-fluid">
            <div class="settings-container">
                <div class="nav-wrapper">
                    <nav class="SideNav" aria-label="settings">
                        <span class="SideNav-item muted-link" [class.disabled]="haveReadPermission ? null : true" [routerLink]="['/application/settings/date-time']" [routerLinkActive]="['border-left']">{{'APPLICATION.SETTINGS.TIMEZONE.NAV' | translate}}</span>
                        <span class="SideNav-item muted-link" [class.disabled]="haveReadPermission ? null : true" [routerLink]="['/application/settings/language']" [routerLinkActive]="['border-left']">{{'APPLICATION.SETTINGS.LANGUAGE.NAV' | translate}}</span>
                        <span class="SideNav-item muted-link" [class.disabled]="haveReadPermission ? null : true" [routerLink]="['/application/settings/currency']" [routerLinkActive]="['border-left']">{{'APPLICATION.SETTINGS.CURRENCY.NAV' | translate}}</span>
                    </nav>
                </div>

                <div class="settings-wrapper">
                    <div class="Box">
                        <router-outlet></router-outlet>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
