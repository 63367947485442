import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OppositionService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getOppositions(payload: {idClient: string}): any {
    console.log('web service called with payload :', payload);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/objections`);
  }

}
