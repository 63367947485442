import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { getBackofficeSelectedUser } from 'src/app/core/store/user-managements/user-management-reducer';
import { Router } from '@angular/router';
import { SetBackofficeUserPriviligeAttemptedAction,
         UpdateBackofficeUserCredentialsAttemptedAction } from '../../core/store/user-managements/user-management.action';
import { getBackofficePriviligeResponse, getBackofficeUpdateResponse } from '../../core/store/user-managements/user-management-reducer';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

class BOUser {
  id: string;
  email: string;
  fullname: string;
  activated: string;
  role: string;
  authorities: string[];

  constructor() {}
}

@Component({
  selector: 'app-backoffice-user-details',
  templateUrl: './backoffice-user-details.component.html',
  styleUrls: ['./backoffice-user-details.component.scss']
})
export class BackofficeUserDetailsComponent implements OnInit, OnDestroy {

  haveUserReadPermission = false;

  haveUserWritePermission = false;

  havePrivilegeReadPermission = false;

  havePrivilegeWritePermission = false;

  disablePermissionCheckboxes = false;

  userForm: FormGroup;

  user: BOUser;

  loading = false;

  private selectedUserSubscription;

  private privilegeUpdateSubscription;

  private userUpdateSubscription;

  privilegesLoading: boolean;

  privilegesDisabled: boolean;

  userUpdating: boolean;

  @ViewChild('userDetailsForm') userDetailsForms;

  selectAllIsChecked = false;

  privileges = [
    {value: 'READ_PRIVILEGE', isChecked: false},
    {value: 'WRITE_PRIVILEGE', isChecked: false},
    {value: 'READ_USER', isChecked: false},
    {value: 'WRITE_USER', isChecked: false},
    {value: 'CLIENT_READ', isChecked: false},
    {value: 'CLIENT_WRITE', isChecked: false},
    {value: 'CLIENT_DOCUMENT_READ', isChecked: false},
    {value: 'CLIENT_DOCUMENT_WRITE', isChecked: false},
    {value: 'CLIENT_INFO_READ', isChecked: false},
    {value: 'CLIENT_INFO_WRITE', isChecked: false},
    {value: 'CLIENT_ACCOUNT_READ', isChecked: false},
    {value: 'CLIENT_ACCOUNT_WRITE ', isChecked: false},
    {value: 'CLIENT_TRANSACTIONS_READ ', isChecked: false},
    {value: 'CLIENT_TRANSACTIONS_WRITE ', isChecked: false},
    {value: 'CLIENT_CARD_READ ', isChecked: false},
    {value: 'CLIENT_CARD_WRITE ', isChecked: false},
    {value: 'CLIENT_POT_READ', isChecked: false},
    {value: 'CLIENT_POT_WRITE ', isChecked: false},
    {value: 'CLIENT_SAFEDEPOSIT_READ ', isChecked: false},
    {value: 'CLIENT_SAFEDEPOSIT_WRITE ', isChecked: false},
    {value: 'CLIENT_OPPOSED_READ ', isChecked: false},
    {value: 'CLIENT_OPPOSED_WRITE ', isChecked: false},
    {value: 'CLIENT_BENIFICIAIRY_READ ', isChecked: false},
    {value: 'CLIENT_BENIFICIARY_WRITE ', isChecked: false},
    {value: 'CLIENT_LOG_READ ', isChecked: false},
    {value: 'CLIENT_CONTACT', isChecked: false},
    {value: 'APPLICATION_NEWS_READ ', isChecked: false},
    {value: 'APPLICATION_NEWS_WRITE ', isChecked: false},
    {value: 'APPLICATION_OFFERT_READ ', isChecked: false},
    {value: 'APPLICATION_OFFERT_WRITE ', isChecked: false},
    {value: 'APPLICATION_MCC_READ ', isChecked: false},
    {value: 'APPLICATION_MCC_WRITE ', isChecked: false},
    {value: 'APPLICATION_SETTINGS_READ  ', isChecked: false},
    {value: 'APPLICATION_SETTINGS_WRITE ', isChecked: false},
  ];

  constructor(private store: Store<AppState>, private router: Router, private fb: FormBuilder, private dialog: MatDialog,
              private translate: TranslateService) {
    this.userForm = this.fb.group({
      fullname: [null, Validators.compose([Validators.required])],
      email: [null, Validators.compose([Validators.required])],
      activated: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnInit(): void {
    this.loading = true;

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'READ_USER') {
        this.haveUserReadPermission = true;
      }
      if (permission.trim() === 'WRITE_USER') {
        this.haveUserWritePermission = true;
      }
      if (permission.trim() === 'READ_PRIVILEGE') {
        this.havePrivilegeReadPermission = true;
      }
      if (permission.trim() === 'WRITE_PRIVILEGE') {
        this.havePrivilegeWritePermission = true;
      }
    }
    if (!this.haveUserReadPermission) {
      return;
    }

    this.privilegesDisabled = true;
    this.selectedUserSubscription = this.store.select(getBackofficeSelectedUser)
    .subscribe((user) => {
      console.log(user);
      if (user !== undefined) {
        console.log(user);
        this.user = user;
        this.userForm.patchValue({
          id: user.id,
          fullname: user.fullname,
          email: user.email,
          activated: user.activated,
          role: user.role
        });
        let checkboxesSelectedCount = 0;
        user.authorities.forEach((authority: string) => {
          for (let i = 0; i < this.privileges.length; i++) {
            if (authority === this.privileges[i].value) {
              this.privileges[i].isChecked = true;
              checkboxesSelectedCount++;
            }
          }
        });
        console.log('SELECTED CHECKBOXES COUNT AND PRIVILEGES LENGTH ARE: ', checkboxesSelectedCount, this.privileges.length);
        if (checkboxesSelectedCount === this.privileges.length) {
          this.selectAllIsChecked = true;
        }

        if (!this.haveUserWritePermission) {
          this.userForm.disable();
        }

        if (!this.havePrivilegeWritePermission) {
          this.disablePermissionCheckboxes = true;
        }

        this.loading = false;
      } else {
        this.router.navigate(['/settings/users']);
      }
    });

    this.privilegeUpdateSubscription = this.store.select(getBackofficePriviligeResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(data => {
        this.privilegesLoading = false;
        if (data.code === 200) {
          this.dialog.open(DialogComponent, {
            width: '30%',
            data: {
              message: this.translate.instant('SETTINGS.USER_DETAILS.UPDATED_PRIVILEGES'),
              buttons: 'info',
              type: 'USER_PRIVILEGES_UPDATE',
              value: true
            }
          });
        } else {
          this.dialog.open(DialogComponent, {
            width: '30%',
            data: {
              message: this.translate.instant('SETTINGS.USER_DETAILS.ERROR_OCCURED'),
              buttons: 'info',
              type: 'USER_PRIVILEGES_UPDATE',
              value: false
            }
          });
        }
      });
    this.userUpdateSubscription = this.store.select(getBackofficeUpdateResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(data => {
        this.userUpdating = false;
        if (data.code === 200) {
          this.dialog.open(DialogComponent, {
            width: '30%',
            data: {
              message: this.translate.instant('SETTINGS.USER_DETAILS.UPDATED_PRIVILEGES'),
              buttons: 'info',
              type: 'USER_PRIVILEGES_UPDATE',
              value: true
            }
          });
        } else {
          this.dialog.open(DialogComponent, {
            width: '30%',
            data: {
              message: this.translate.instant('SETTINGS.USER_DETAILS.ERROR_OCCURED'),
              buttons: 'info',
              type: 'USER_PRIVILEGES_UPDATE',
              value: false
            }
          });
        }
      });
  }

  ngOnDestroy(): void {
    if (this.selectedUserSubscription) {
      this.selectedUserSubscription.unsubscribe();
    }
    if (this.privilegeUpdateSubscription) {
      this.privilegeUpdateSubscription.unsubscribe();
    }
    if (this.userUpdateSubscription) {
      this.userUpdateSubscription.unsubscribe();
    }
  }

  isFieldValid(field: any): boolean {
    return true;
  }

  updateUser(): void {
    console.log(this.userForm.value);
    this.userUpdating = true;
    this.store.dispatch(new UpdateBackofficeUserCredentialsAttemptedAction(this.user.id, this.userForm.value));
  }

  onCheckChange(event: any, index: number): void {
    console.log('PRIV CHECKBOX: ', event.checked, index);
    this.privilegesDisabled = false;
    this.privileges[index].isChecked = event.checked;
    let checkedCheckboxesCount = 0;
    this.privileges.forEach(privilege => {
      if (privilege.isChecked) {
        checkedCheckboxesCount++;
      }
    });
    console.log('CHECKBOXES COUNTS ARE: ', checkedCheckboxesCount, this.privileges.length);
    if (checkedCheckboxesCount === this.privileges.length) {
      this.selectAllIsChecked = true;
    } else {
      this.selectAllIsChecked = false;
    }
  }

  updateUserPrivileges(): void {
    this.privilegesLoading = true;
    let authorities = [];
    this.privileges.forEach(privilege => {
      if (privilege.isChecked) {
        authorities.push(privilege.value);
      }
    });
    console.log(authorities);
    this.store.dispatch(new SetBackofficeUserPriviligeAttemptedAction(this.user.id, authorities));
  }

  onSelectionToggle(e: any): void {
    console.log(e.checked);
    if (e.checked) {
      this.privileges.forEach(privilege => privilege.isChecked = true );
    } else {
      this.privileges.forEach(privilege => privilege.isChecked = false);
    }
    this.privilegesDisabled = false;
  }

}
