import { ImageState } from './../../enums/imageState.enum';
import { DocumentActions, DocumentActionTypes } from './document.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';


export interface DocumentState {
    client: any;
    userStatusName: string;
    docs: any;
    childDocs: any;
    document: any;
    image: any;
    imageState: ImageState;
    childDocumentRectificationResponse: any;
    childDocumentValidationResponse: any;
    childDocPhoto: any;
    addressModificationDocuments: any;
    addressModificationDocumentsAttempt: any;
    validationAddressModificationResponse: any;
    invalidationAddressModificationResponse: any;
    loading: boolean;
    error: Error;
}

export const initialState: DocumentState = {
    client: undefined,
    userStatusName: undefined,
    docs: undefined,
    childDocs: undefined,
    document: undefined,
    image: undefined,
    imageState: undefined,
    childDocumentRectificationResponse: undefined,
    childDocumentValidationResponse: undefined,
    childDocPhoto: undefined,
    addressModificationDocuments: undefined,
    addressModificationDocumentsAttempt: undefined,
    validationAddressModificationResponse: undefined,
    invalidationAddressModificationResponse: undefined,
    loading: false,
    error: undefined
};

export function reducer(state: DocumentState = initialState, action: DocumentActions): any {
    switch (action.type) {
      case DocumentActionTypes.SAVE_USER_DOCUMENT:
        return {
          ...state,
          loading: false,
          document: action.payload,
          imageState: action.payload.imageState
        };
      case DocumentActionTypes.GET_USER_DOCUMENTS_ATTEMPTED:
        return {
          ...state,
          userStatusName: action.payload.userStatusName,
          docs: undefined,
          loading: true
        };
      case DocumentActionTypes.GET_USER_DOCUMENTS_SUCCESSFUL:
        return {
          ...state,
          loading: false,
          docs: action.payload.value
        };
      case DocumentActionTypes.GET_USER_DOCUMENTS_FAILED:
        return {
          ...state,
          loading: false,
          error: action.payload.error
        };
        case DocumentActionTypes.LOAD_DOCUMENT_IMAGE_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case DocumentActionTypes.LOAD_DOCUMENT_IMAGE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                image: action.payload
            };
        case DocumentActionTypes.RESET_STORE_STATE:
          return initialState;
        case DocumentActionTypes.LOAD_CHILD_DOCUMENTS_ATTEMPTED:
          return {
            ...state,
            loading: true,
            childDocs: undefined
          };
        case DocumentActionTypes.LOAD_CHILD_DOCUMENTS_SUCCESSFUL:
          return {
            ...state,
            loading: false,
            childDocs: action.payload.resp.value.documents
          };
        case DocumentActionTypes.LOAD_CHILD_DOCUMENTS_FAILED:
          return {
            ...state,
            loading: false,
            error: action.payload
          };
        case DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_ATTEMPTED:
          return {
            ...state,
            loading: true,
            childDocumentRectificationResponse: undefined
          };
        case DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_SUCCESSFUL:
          return {
            ...state,
            loading: false,
            childDocumentRectificationResponse: action.payload.resp
          };
        case DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_FAILED:
          return {
            ...state,
            loading: false,
            error: action.payload
          };
        case DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_ATTEMPTED:
          return {
            ...state,
            loading: true,
            childDocumentValidationResponse: undefined
          };
        case DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_SUCCEESFUL:
          return {
            ...state,
            loading: false,
            childDocumentValidationResponse: action.payload.resp
          };
          case DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_FAILED:
            return {
              ...state,
              loading: false,
              error: action.payload
            };
          case DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_ATTEMTPED:
            return {
              ...state,
              loading: true,
              childDocPhoto: undefined,
            };
          case DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_SUCCESSFUL:
            return {
              ...state,
              loading: false,
              childDocPhoto: action.payload
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED:
            return {
              ...state,
              loading: true,
              addressModificationDocuments: undefined,
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL:
            return {
              ...state,
              loading: false,
              addressModificationDocuments: action.payload.resp.value
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_FAILED:
            return {
              ...state,
              loading: false,
              error: action.payload
            };
          case DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED:
            return {
              ...state,
              loading: true,
              invalidationAddressModificationResponse: undefined,
            };
          case DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL:
            return {
              ...state,
              loading: false,
              invalidationAddressModificationResponse: action.payload.resp,
            };
          case DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_FAILED:
            return {
              ...state,
              loading: false,
              invalidationAddressModificationResponse: action.payload,
            };
          case DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED:
            return {
              ...state,
              loading: true,
              validationAddressModificationResponse: undefined,
            };
          case DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL:
            return {
              ...state,
              loading: false,
              validationAddressModificationResponse: action.payload.resp,
            };
          case DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_FAILED:
            return {
              ...state,
              loading: false,
              validationAddressModificationResponse: action.payload,
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_ATTEMPTED:
            return {
              ...state,
              loading: true,
              addressModificationDocumentsAttempt: undefined,
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_SUCCESSFUL:
            return {
              ...state,
              loading: false,
              addressModificationDocumentsAttempt: action.payload,
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_FAILED:
            return {
              ...state,
              loading: false,
              addressModificationDocumentsAttempt: action.payload,
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_PHOTO_ATTEMTPED:
            return {
              ...state,
              loading: true,
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_PHOTO_SUCCESSFUL:
            return {
              ...state,
              loading: false,
              image: action.payload
            };
          case DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_PHOTO_FAILED:
            return {
              ...state,
              loading: false,
              error: action.payload
            };
      default:
        return state;
    }
  }

export const getDocumentState = createFeatureSelector<DocumentState>('document');

export const getUserStatusName = createSelector(getDocumentState, (state: DocumentState) => state.userStatusName);
export const getUserDocuments = createSelector(getDocumentState, (state: DocumentState) => state.docs);
export const getUserSelectedDocument = createSelector(getDocumentState, (state: DocumentState) => state.document);
export const getSelectedDocumentImage = createSelector(getDocumentState, (state: DocumentState) => state.image);
export const getImageState = createSelector(getDocumentState, (state: DocumentState) => state.imageState);
export const getChildDocuments = createSelector(getDocumentState, (state: DocumentState) => state.childDocs);
export const getchildDocumentRectificationResponse =
  createSelector(getDocumentState, (state: DocumentState) => state.childDocumentRectificationResponse);
export const getchildDocumentValidationResponse =
  createSelector(getDocumentState, (state: DocumentState) => state.childDocumentValidationResponse);
export const getChildDocumentsPhoto = createSelector(getDocumentState, (state: DocumentState) => state.childDocPhoto);
export const getAddressModificationDocuments =
  createSelector(getDocumentState, (state: DocumentState) => state.addressModificationDocuments);
export const getAddressModificationDocumentsAttempt =
  createSelector(getDocumentState, (state: DocumentState) => state.addressModificationDocumentsAttempt);
export const getValidationAddressModificationResponse =
  createSelector(getDocumentState, (state: DocumentState) => state.validationAddressModificationResponse);
export const getInvalidationAddressModificationResponse =
  createSelector(getDocumentState, (state: DocumentState) => state.invalidationAddressModificationResponse);
