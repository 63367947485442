import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { LoadBeneficiariesAttemptedAction } from '../../core/store/beneficiaries/beneficiaries.actions';
import { getBeneficiaries } from '../../core/store/beneficiaries/beneficiaries.reducer';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-beneficiaires',
  templateUrl: './beneficiaires.component.html',
  styleUrls: ['./beneficiaires.component.scss']
})
export class BeneficiairesComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  private subscription;

  loading: boolean;

  beneficiaries: any[];

  beneficiariesLength: number;

  beneficiariesDisplayedColumns: string[] = [
    'name',
    'iban',
    'bic'
  ];

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_BENIFICIAIRY_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_BENIFICIAIRY_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadBeneficiariesAttemptedAction({idClient: client.id}));
    this.subscription = this.store.select(getBeneficiaries).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      this.beneficiaries = data;
      this.beneficiariesLength = data.length;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  editBenif(): void {}

  deleteBenif(): void {}

  activateBenif(): void {}

}
