<div class="content">
    <mat-card>
        <mat-card-title>{{'LOGIN.PWD_RESET_TITLE' | translate}}</mat-card-title>
        <mat-card-content class="my-0">
            <div class="d-flex direction-column align-center justify-center" *ngIf="isResetRequestSuccess || isResetRequestFailed">
                <div class="alert alert-success alert-dismissible w-70" role="alert" *ngIf="isResetRequestSuccess">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <strong>{{'LOGIN.SUCCESS' | translate}}</strong> {{'LOGIN.CHECK_MAILBOX' | translate}}
                </div>
    
                <div class="alert alert-danger alert-dismissible w-70" role="alert" *ngIf="isResetRequestFailed">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                    <strong>{{'LOGIN.FAIL' | translate}}</strong> {{'LOGIN.PWS_RESET_ERROR' | translate}}<br />
                    {{'LOGIN.PROBLEM_PERSIST' | translate}} <span class="link">{{'LOGIN.CONTACT_SUPPORT' | translate}}</span>
                </div>
            </div>

            <form [formGroup]="pwResetForm" (submit)="onSubmit(); form.resetForm();" #form="ngForm" class="w-100">
                <p>{{'LOGIN.PWD_RESET_TEXT' | translate}}</p>
                <mat-form-field>
                    <input matInput fullWidth type="email" formControlName="emailFormControl" #emailRef id="email" name="email" 
                        pattern="[a-zA-Z0-9-.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                        placeholder=" {{'LOGIN.EMAIL' | translate}}" autocomplete="off" (keyup)="onEnter($event)" />
                    <mat-hint>{{'LOGIN.EMAIL_HINT' | translate}}</mat-hint>
                    <mat-error *ngIf="(this.pwResetForm.controls['emailFormControl'].hasError('email') && isSubmitClicked )">
                        {{'LOGIN.EMAIL_INVALID' | translate}}
                    </mat-error>
                    <mat-error *ngIf="this.pwResetForm.controls['emailFormControl'].hasError('required') && isSubmitClicked">
                        {{'LOGIN.EMAIL_CLEAR' | translate}}
                    </mat-error>
                </mat-form-field>

                <span class="spinner" *ngIf="sending" >
                    <mat-spinner [diameter]="20"></mat-spinner>
                </span>

                <br />

                <button mat-raised-button type="submit" color="primary" fullWidth status="success" [class.btn-pulse]="submitted" >
                    {{'LOGIN.SEND' | translate}}
                </button>
                

                <div class="d-flex direction-column align-center justify-center f3 my-1">
                    <p class="m-0 link" (click)="onConnect()">{{'LOGIN.LOGIN' | translate}}</p>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>
