<ng-progress id="progressBar" [color]="'#3bd3af'"></ng-progress>
<div class="content" fxFlex fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
        <form [formGroup]="loginForm" aria-labelledby="title" class="w-100">
            <mat-card-title>
                {{'LOGIN.LOGIN' | translate}}
            </mat-card-title>
            <mat-card-content>
                <div class="d-flex direction-column align-center justify-center my-0">
                    <div class="alert alert-danger alert-dismissible w-70" role="alert" *ngIf="isUnauthorized">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.isResetFailed = false"><span aria-hidden="true">&times;</span></button>
                        <strong>{{'LOGIN.FAIL' | translate}}</strong> L'addresse e-mail ou le mot de passe que vous avez entrer ne correspond à aucun compte.
                    </div>
                </div>

                <mat-form-field>
                    <input matInput fullWidth type="email" [formControl]="emailFormControl" #email name="email" id="input-email" 
                            pattern="[a-zA-Z0-9-.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}" placeholder=" {{'LOGIN.EMAIL' | translate}} " 
                            autocomplete="on" (keyup)="onEnter($event)" />
                    <mat-hint>{{'LOGIN.EMAIL_HINT' | translate}}</mat-hint>

                    <mat-error *ngIf="(!email.checkValidity() && !emailFormControl.hasError('required') && isSubmittedClicked )">
                        {{'LOGIN.EMAIL_INVALID' | translate}}
                    </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required') && isSubmittedClicked">
                        {{'LOGIN.EMAIL_CLEAR' | translate}}
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field>
                    <input matInput fullWidth [formControl]="passwordFormControl" #password name="password" type="password"
                        id="input-password" placeholder="{{'LOGIN.PWD' | translate}}" autocomplete="on"
                        [required]=true (keyup)="onEnter($event)" />
                    <mat-hint>{{'LOGIN.PWD_HINT' | translate}}</mat-hint>

                    <mat-error *ngIf="(!password.checkValidity() && !passwordFormControl.hasError('required') && isSubmittedClicked )">
                        {{ 'LOGIN.PWD_INVALID' | translate }}
                    </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required') && isSubmittedClicked">
                        {{ 'LOGIN.PWD_CLEAR' | translate }}
                    </mat-error>
    
                </mat-form-field>
                <br />
                <button mat-raised-button color="primary" fullWidth status="success" [class.btn-pulse]="submitted"
                    (click)="onSubmit()" >
                    {{'LOGIN.CONNECT' | translate}}
                </button>

                <div class="d-flex direction-columns align-center justify-center f3 my-1">
                    <!-- <p class="m-0">Vous n'avez pas un compte ? <span class="link">Créer un compte</span></p> -->
                    <p class="m-0 link" (click)="onForgotPassword()">Mot de passe oublié ?</p>
                </div>
            </mat-card-content>
        </form>
    </mat-card>
</div>