import { Action } from '@ngrx/store';

export const GET_APP_CURRENCY_ATTEMPTED = '[Currency] Get application currency attempted';
export const GET_APP_CURRENCY_SUCCESSFUL = '[Currency] Get application currency successful';
export const GET_APP_CURRENCY_FAILED = '[Currency] Get application currency failed';

export const UPDATE_APP_CURRENCY_ATTEMPTED = '[Currency] Update application currency attempted';
export const UPDATE_APP_CURRENCY_SUCCESSFUL = '[Currency] Update application currency successful';
export const UPDATE_APP_CURRENCY_FAILED = '[Currency] Update application currency failed';

export const GET_APP_LANGUAGE_ATTEMPTED = '[Language] Get application language attempted';
export const GET_APP_LANGUAGE_SUCCESSFUL = '[Language] Get application language successful';
export const GET_APP_LANGUAGE_FAILED = '[Language] Get application language failed';

export const UPDATE_APP_LANGUAGE_ATTEMPTED = '[Language] Update application language attempted';
export const UPDATE_APP_LANGUAGE_SUCCESSFUL = '[Language] Update application language successful';
export const UPDATE_APP_LANGUAGE_FAILED = '[Language] Update application language failed';

export const GET_APP_TIMEZONE_ATTEMPTED = '[Timezone] Get application timezone attempted';
export const GET_APP_TIMEZONE_SUCCESSFUL = '[Timezone] Get application timezone successful';
export const GET_APP_TIMEZONE_FAILED = '[Timezone] Get application timezone failed';

export const UPDATE_APP_TIMEZONE_ATTEMPTED = '[Timezone] Update application timezone attempted';
export const UPDATE_APP_TIMEZONE_SUCCESSFUL = '[Timezone] Update application timezone successful';
export const UPDATE_APP_TIMEZONE_FAILED = '[Timezone] Update application timezone failed';

export class GetAppCurrencyAttemptedAction implements Action {
    readonly type = GET_APP_CURRENCY_ATTEMPTED;
    constructor() {}
}

export class GetAppCurrencySuccessfulAction implements Action {
    readonly type = GET_APP_CURRENCY_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetAppCurrencyFailedAction implements Action {
    readonly type = GET_APP_CURRENCY_FAILED;
    constructor(public payload: any) {}
}

export class UpdateAppCurrencyAttemptedAction implements Action {
    readonly type = UPDATE_APP_CURRENCY_ATTEMPTED;
    constructor(public payload: any) {}
}

export class UpdateAppCurrencySuccessfulAction implements Action {
    readonly type = UPDATE_APP_CURRENCY_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateAppCurrencyFailedAction implements Action {
    readonly type = UPDATE_APP_CURRENCY_FAILED;
    constructor(public payload: any) {}
}


export class GetAppLanguageAttemptedAction implements Action {
    readonly type = GET_APP_LANGUAGE_ATTEMPTED;
    constructor() {}
}

export class GetAppLanguageSuccessfulAction implements Action {
    readonly type = GET_APP_LANGUAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetAppLanguageFailedAction implements Action {
    readonly type = GET_APP_LANGUAGE_FAILED;
    constructor(public payload: any) {}
}

export class UpdateAppLanguageAttemptedAction implements Action {
    readonly type = UPDATE_APP_LANGUAGE_ATTEMPTED;
    constructor(public payload: any) {}
}

export class UpdateAppLanguageSuccessfulAction implements Action {
    readonly type = UPDATE_APP_LANGUAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateAppLanguageFailedAction implements Action {
    readonly type = UPDATE_APP_LANGUAGE_FAILED;
    constructor(public payload: any) {}
}

export class GetAppTimezoneAttemptedAction implements Action {
    readonly type = GET_APP_TIMEZONE_ATTEMPTED;
    constructor() {}
}

export class GetAppTimezoneSuccessfulAction implements Action {
    readonly type = GET_APP_TIMEZONE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetAppTimezoneFailedAction implements Action {
    readonly type = GET_APP_TIMEZONE_FAILED;
    constructor(public payload: any) {}
}

export class UpdateAppTimezoneAttemptedAction implements Action {
    readonly type = UPDATE_APP_TIMEZONE_ATTEMPTED;
    constructor(public payload: any) {}
}

export class UpdateAppTimezoneSuccessfulAction implements Action {
    readonly type = UPDATE_APP_TIMEZONE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateAppTimezoneFailedAction implements Action {
    readonly type = UPDATE_APP_TIMEZONE_FAILED;
    constructor(public payload: any) {}
}

export type AppConfigTypes =
    |GetAppCurrencyAttemptedAction
    |GetAppCurrencySuccessfulAction
    |GetAppCurrencyFailedAction
    |UpdateAppCurrencyAttemptedAction
    |UpdateAppCurrencySuccessfulAction
    |UpdateAppCurrencyFailedAction
    |GetAppLanguageAttemptedAction
    |GetAppLanguageSuccessfulAction
    |GetAppLanguageFailedAction
    |UpdateAppLanguageAttemptedAction
    |UpdateAppLanguageSuccessfulAction
    |UpdateAppLanguageFailedAction
    |GetAppTimezoneAttemptedAction
    |GetAppTimezoneSuccessfulAction
    |GetAppTimezoneFailedAction
    |UpdateAppTimezoneAttemptedAction
    |UpdateAppTimezoneSuccessfulAction
    |UpdateAppTimezoneFailedAction
;
