import {createFeatureSelector, createSelector} from '@ngrx/store';
import { from } from 'rxjs';
import * as fromOffers from './offers.actions';

export interface OffersState {
    data: any;
    addResponse: any;
    deleteOfferResponse: any;
    updateResponse: any;
    selectedOffer: any;
    action: string;
    loading: boolean;
    error: string;
}

export const initialState: OffersState = {
    data: undefined,
    addResponse: undefined,
    deleteOfferResponse: undefined,
    updateResponse: undefined,
    selectedOffer: undefined,
    action: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: OffersState = initialState, action: fromOffers.OffersActions): any {
    switch (action.type) {
        case fromOffers.LOAD_OFFERS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined,
                addResponse: undefined,
                deleteOfferResponse: undefined,
                updateResponse: undefined,
                selectedOffer: undefined,
                action: undefined
            };
        case fromOffers.LOAD_OFFERS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value
            };
        case fromOffers.LOAD_OFFERS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromOffers.ADD_OFFER_ATTEMPTED:
            return {
                ...state,
                loading: true,
                addResponse: undefined
            };
        case fromOffers.ADD_OFFER_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                addResponse: action.payload.value
            };
        case fromOffers.SELECT_OFFER:
            return {
                ...state,
                selectedOffer: action.payload,
                action: action.action
            };
        case fromOffers.DELETE_OFFER_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromOffers.DELETE_OFFER_SUCCESSFUL:
            return {
                ...state,
                laoding: false,
                deleteOfferResponse: action.payload
            };
        case fromOffers.DELETE_OFFER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromOffers.UPDATE_OFFER_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromOffers.UPDATE_OFFER_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                updateResponse: action.payload.value
            };
        case fromOffers.UPDATE_OFFER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getOffersState = createFeatureSelector<OffersState>('offers');

export const getOffers = createSelector(getOffersState, (state: OffersState) => state.data);
export const getAddResponse = createSelector(getOffersState, (state: OffersState) => state.addResponse);
export const getSelectedOffer = createSelector(getOffersState, (state: OffersState) => {
    return {
        offer: state.selectedOffer,
        action: state.action
    };
});
export const getDeleteOfferResponse = createSelector(getOffersState, (state: OffersState) => state.deleteOfferResponse);
export const getUpdateResponse = createSelector(getOffersState, (state: OffersState) => state.updateResponse);
