<div class="content">
    <div class="full-height bg-white">
        <div class="details-container" *ngIf="!loading">
            <div class="Box Box-statement">
                <div class="card">
                    <div class="card-header h5">
                        {{'OPPOSITIONS.STATEMENT_INFO' | translate}}: <span class="link bold f12">{{client.firstname}} {{client.lastname}} / {{client.id}}</span>
                    </div>
                    <div class="card-body">
                        <div class="Table">
                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.CATEGORY' | translate}} : </div>
                                <div class="Column w-60 text-grey">
                                    <img src="../../../../assets/images/home/transactions-category/{{statement.category}}@2x.png"
                                        srcset="../../../../assets/images/home/transactions-category/{{statement.category}}@2x.png 2x,
                                                ../../../../assets/images/home/transactions-category/{{statement.category}}@3x.png 3x"
                                        class="ic-category" alt="Categorie Icon">
                                    {{statement.category}}
                                </div>
                            </div>

                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.OPERATIONS_ID' | translate}} : </div>
                                <div class="Column w-60 text-grey">{{statement.transactionId}}</div>
                            </div>

                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.NAME' | translate}}  : </div>
                                <div class="Column w-60 text-grey">{{statement.name ? statement.name : '-'}}</div>
                            </div>

                            <div class="Row">
                                <div class="Column w-40 h5 of-sy">{{'OPPOSITIONS.DESCRIPTION' | translate}} : </div>
                                <div class="Column w-60 text-grey">{{statement.description ? statement.description : '-'}} </div>
                            </div>

                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.CREATION_DATE' | translate}} : </div>
                                <div class="Column w-60 text-grey">{{statement.executionDate | date:'short'}}</div>
                            </div>

                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.FINISH_DATE' | translate}} : </div>
                                <div class="Column w-60 text-grey">{{statement.finishDate | date:'short'}}</div>
                            </div>

                            <!--
                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.AMOUNT' | translate}} : </div>
                                <div class="Column w-60 text-grey">{{wallet.amount ? wallet.amount + (wallet.currency | currencySymbol) : '-'}}</div>
                            </div>
                            -->
                                
                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.OP_STATUS' | translate}} : </div>
                                <div class="Column w-60 text-grey"><span class="status green">{{statement.statue}}</span></div>
                            </div>

                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.TRANS_ONJECT' | translate}} : </div>
                                <div class="Column w-60 text-grey">{{statement.transObject}}</div>
                            </div>

                            <div class="Row">
                                <div class="Column w-40 h5">{{'OPPOSITIONS.SAVED_FROM' | translate}} : </div>
                                <div class="Column w-60 text-grey">{{statement.savedFrom}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            
            -->
        </div>
        <!--
        <div class="card" *ngIf="haveReadPermission; else NO_READ_PERMISSION" >
            <div class="card-header h4">
                {{'OPPOSITIONS.STATEMENT_DETAILS' | translate}}
            </div>

            <div class="spinner" *ngIf="loading">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>

            
        </div>
    -->
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div class="card">
        <div class="card-header h5">
            {{'OPPOSITIONS.STATEMENT_DETAILS' | translate}}
        </div>
        <div class="card-body">
            <div  class="none-block">
                <div class="none-inner-block">
                    <img src="../../../assets/images/vide/vide@2x.png"
                        srcset="../../../assets/images/vide/vide@2x.png 2x,
                        ../../../assets/images/vide/vide@3x.png 3x"
                        class="none" alt="None">
                    <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
                </div>     
            </div>
        </div>
    </div>
</ng-template>
