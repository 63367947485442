<div class="content">
    <div class="full-height bg-white">
        <div class="transactions-content card">
            <div class="title-div card-header">
                <div class="d-inline-block">
                    <div class="text title-text d-inline-block">
                        {{'TRANSACTIONS.LATEST_OPERATIONS' | translate}}
                    </div>
    
                    <div class="transactions-actions d-inline-block" *ngIf="haveReadPermission">
                        <div style="display: flex; flex-direction: row;">
                            <div class="filter">
                                <div class="filter-summary">
                                    {{'TRANSACTIONS.CATEGORY' | translate}}:
                                    <span class="text-bold dropdown-toggle" [matMenuTriggerFor]="categorieMenu">{{selectedCategory}}</span>
                                </div>
                                <mat-menu #categorieMenu="matMenu" xposition="before">
                                    <div class="filter-menu category-filter-menu">
                                        <ul>
                                            <!--
                                            <li *ngIf="selectedCategory !== 'aucune'" (click)="filterCategory('aucune')">
                                                <span class="text filter-by"><mat-icon style="font-size: 12px;">clear</mat-icon>{{'TRANSACTIONS.NONE' | translate}}</span>
                                            </li>
                                            -->
                                            <li (click)="filterCategory('DATE')">
                                                <span class="text filter-by">{{'TRANSACTIONS.BY_DATE' | translate}}</span>
                                                <img src="../../../assets/images/ic-check/ic-check@2x.png"
                                                     class="ic ic-check" alt="Check Icon" *ngIf="filterByCategory === 'DATE'">
                                                <img src="../../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterByCategory === 'DATE'">
                                            </li>
                                            
                                            <li (click)="filterCategory('OPERATION_TYPE')">
                                                <span class="text filter-by">{{'TRANSACTIONS.BY_OPERATION_TYPE' | translate}}</span>
                                                <img src="../../../assets/images/ic-check/ic-check@2x.png"
                                                     class="ic ic-check" alt="check" *ngIf="filterByCategory === 'OPERATION_TYPE'">
                                                <img src="../../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterByCategory === 'OPERATION_TYPE'">
                                            </li>
                                        </ul>
                                    </div>
                                </mat-menu>
                            </div>
        
                            <div class="filter">
                                <div class="filter-summary">
                                    {{'TRANSACTIONS.FILTER_SUMMARY' | translate}}:
                                    <span class="text-bold dropdown-toggle" [matMenuTriggerFor]="dateAmountMenu" #dateAmountMenuTrigger="matMenuTrigger">{{selectedDateAmount}}</span>
                                </div>
                                <mat-menu #dateAmountMenu="matMenu" xposition="before">
                                    <div class="filter-menu date-filter-menu" (click)="$event.stopPropagation()">
                                        <form [formGroup]="searchForm">
                                            <ul>
                                                <li class="clearable" *ngIf="selectedDateAmount !== 'aucune'"  (click)="filterDateAmount('aucune')">
                                                    <span class="text filter-by"><mat-icon style="font-size: 12px;">clear</mat-icon>{{'TRANSACTIONS.CLEAR' | translate}}</span>
                                                </li>
                                                <li>
                                                    <label class="margin-right">{{'TRANSACTIONS.FROM' | translate}}:</label>
                                                    <mat-form-field appearance="standard">
                                                        <mat-label>{{'TRANSACTIONS.DATE' | translate}}</mat-label>
                                                        <input matInput formControlName="startDate" autocomplete="off" [matDatepicker]="startpicker">
                                                        <mat-datepicker-toggle matSuffix [for]="startpicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #startpicker></mat-datepicker>
                                                    </mat-form-field>
            
                                                    <label class="margin-right">{{'TRANSACTIONS.TO' | translate}}:</label>
                                                    <mat-form-field appearance="standard">
                                                        <mat-label>{{'TRANSACTIONS.DATE' | translate}}</mat-label>
                                                        <input matInput formControlName="endDate" autocomplete="off" [matDatepicker]="endpicker">
                                                        <mat-datepicker-toggle matSuffix [for]="endpicker"></mat-datepicker-toggle>
                                                        <mat-datepicker #endpicker></mat-datepicker>
                                                    </mat-form-field>
                                                </li>
            
                                                <li>
                                                    <mat-form-field class="amount">
                                                        <input matInput formControlName="amount" placeholder="Montant" autocomplete="off">
                                                        <span matSuffix class="text currency">&nbsp;&nbsp;EUR&nbsp;&nbsp;</span>
                                                    </mat-form-field>
                                                </li>
            
                                                <li style="text-align: center;"><button class="btn-search" (click)="filterDateAmount('date/montant')">{{'BUTTONS.SEARCH' | translate}}</button></li>
                                            </ul>
                                        </form>
                                    </div>
                                </mat-menu>
                            </div>
        
                            <div class="filter">
                                <div class="filter-summary">
                                    {{'TRANSACTIONS.OPERATION_FILTER' | translate}}:
                                    <span class="text-bold dropdown-toggle" [matMenuTriggerFor]="typeMenu">{{selectedType}}</span>
                                </div>
                                <mat-menu #typeMenu="matMenu" xposition="before">
                                    <div class="filter-menu type-filter-menu">
                                        <ul>
                                            <li *ngIf="selectedType !== 'aucun'"  (click)="filterType('aucun')">
                                                <span class="text filter-by"><mat-icon style="font-size: 12px;">clear</mat-icon>{{'TRANSACTIONS.CLEAR_OPERATION_FILTER' | translate}}</span>
                                            </li>
        
                                            <li (click)="filterType('DEBIT')">
                                                <span class="text filter-by">{{'TRANSACTIONS.TRANSFER' | translate}}</span>
                                                <img src="../../../assets/images/ic-check/ic-check@2x.png"
                                                     class="ic ic-check" alt="check" *ngIf="filterByType === 'DEBIT'">
                                                <img src="../../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterByType == 'DEBIT'">
                                            </li>
            
                                            <li (click)="filterType('CREDIT')">
                                                <span class="text filter-by">{{'TRANSACTIONS.DEPOSIT' | translate}}</span>
                                                <img src="../../../assets/images/ic-check/ic-check@2x.png"
                                                     class="ic ic-check" alt="Check Icon" *ngIf="filterByType === 'CREDIT'">
                                                <img src="../../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterByType === 'CREDIT'">
                                            </li>
                                        </ul>
                                    </div>
                                </mat-menu>
                            </div>
                        </div>
                    </div>
                </div>

                <ng-container *ngIf="haveReadPermission">
                    <div *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center">
                        <p class="error">{{'ACCESS_CONTROL.NO_EXPORT_PERMISSION' | translate}}</p> &nbsp;
                        <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                    </div>
                </ng-container>

                <button class="btn btn-sm border-gray float-right" *ngIf="haveReadPermission" [disabled]="!haveWritePermission" (click)="exportAsXLSX()">
                    <i class="fa fa-file-export octicon" aria-hidden="true"></i>
                    {{'TRANSACTIONS.EXPORT_TO_EXCEL' | translate}}
                </button>
            </div>


            <ng-container *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                <div class="spinner" *ngIf="this.loading">
                    <mat-spinner [diameter]="40"></mat-spinner>
                </div>
    
                <div class="transactions" *ngIf="!this.loading">
                    <div class="none-div" *ngIf="transactionsLength == 0">
                        <img src="../../../assets/images/vide/vide.png"
                            srcset="../../../assets/images/vide/vide@2x.png 2x, ../../../assets/images/vide/vide@3x.png 3x"
                            class="none"/>
                        <p class="text none-text">{{'TRANSACTIONS.NO_OPERATIONS' | translate}}</p>
                    </div>
    
                    <ngx-simplebar class="transaction-content" *ngIf="transactionsLength > 0">
                        <div class="transaction-tab">
                            <div *ngFor="let transactionList of transactions">
                                <span class="text transactions-filter-type" *ngIf="this.selectedCategory === 'Par type d\'opération'; else FILTER_TYPE_OPERATION">{{transactionList.date}}</span>
                                <ng-template #FILTER_TYPE_OPERATION>
                                    <span class="text transactions-filter-type">{{transactionList.date}}</span>
                                </ng-template>
                                <table mat-table [dataSource]="transactionList.transactions" class="mat-elevation-z8 tab-transaction">
                                    <ng-container matColumnDef="category_transaction">
                                        <td mat-cell *matCellDef="let element">
                                            <img src="../../../assets/images/home/transactions-category/{{element.historySafeDepositType}}@2x.png"
                                                srcset="../../../assets/images/home/transactions-category/{{element.historySafeDepositType}}@2x.png 2x,
                                                        ../../../assets/images/home/transactions-category/{{element.historySafeDepositType}}@3x.png 3x"
                                                class="ic-category" alt="Category Icon" />
                                        </td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="label_transaction">
                                        <td mat-cell *matCellDef="let element">
                                            <div class="titles">
                                                <span class="text transactions-title">{{element.label}}</span>
                                                <span class="text transactions-subtitle-date" *ngIf="this.selectedCategory =='Par date'">{{element.date}}</span>
                                                <span class="text transactions-subtitle-date" *ngIf="this.selectedCategory =='Par type d\'opération'">{{element.historyType}}</span>
                                              </div>
                                        </td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="amount_transaction">
                                        <td mat-cell *matCellDef="let element">
                                            <div class="amount-cell">
                                                <span class="text no-wrap transactions-amount" *ngIf="element.historySafeDepositType == 'WITHDRAWAL'">-</span>
                                                <span class="text no-wrap transactions-amount"> {{ element.amount }} </span>
                                                <span class="text no-wrap transactions-amount" >{{ element.currency | currencySymbol}}</span>
                                              </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-row *matRowDef="let row; columns: transactionsDisplayedColumns;" class="table-row"></tr>
                                </table>
                            </div> 
                        </div>
                    </ngx-simplebar>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
            srcset="../../../assets/images/vide/vide@2x.png 2x,
            ../../../assets/images/vide/vide@3x.png 3x"
            class="none" alt="None">
        <p class="text none-text">{{'ACCESS_CONTROL.NO_ACCESS' | translate}}</p> 
        </div>     
    </div>
</ng-template>