import { LogService } from './../../services/log/log.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromLog from './log.actions';

@Injectable()
export class LogEffects {

    constructor(private actions$: Actions, private logService: LogService) {}

    @Effect()
    loadClientActivitiesAttempted$ = this.actions$.pipe(
        ofType<fromLog.LoadClientActivitiesAttemptedAction>(fromLog.LOAD_CLIENT_ACTIVITIES_ATTEMPTED),
        switchMap((data: fromLog.LoadClientActivitiesAttemptedAction) => this.logService.getActivities(data.payload)
            .pipe(
                map(resp => new fromLog.LoadClientActivitiesSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromLog.LoadClientActivitiesFailedAction(err)); })
            )
        )
    );
}
