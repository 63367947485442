<div class="operation-container">
    <div class="card">
        <div class="card-header">
            <div class="bold float-left">{{'PACKAGES.OPERATIONS_TITLE' | translate}}</div>
            <button type="button" class="btn btn-sm btn-success float-right" *ngIf="haveReadPermission" [disabled]="!haveWritePermission"  (click)="onNewOperation()">
                <i class="fa fa-plus" aria-hidden="true" width="16" height="16"></i>&nbsp; {{'BUTTONS.CREATE' | translate}}
            </button>
        </div>

        <div class="card-body">
            <div class="operations-content" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                <div class="spinner" *ngIf="loading" >
                    <mat-spinner [diameter]="40"></mat-spinner>
                </div>

                <div class="operations" *ngIf="!loading" >
                    <div class="none-block" *ngIf="operationsLength <= 0" >
                        <img src="../../../../assets/images/vide/vide.png"
                            srcset="../../../../assets/images/vide/vide@2x.png 2x, 
                            ../../../../assets/images/vide/vide@3x.png 3x"
                            class="none" alt="none">
                        <p class="text none-text">{{'PACKAGES.NONE' | translate}}</p>
                    </div>

                    <ngx-simplebar class="operation-content" *ngIf="operationsLength > 0" >
                        <div class="operation-tab">
                            <table mat-table [dataSource]="operations" class="mat-elevation-z8 tab-operation">
                                <!-- Name Column -->
                                <ng-container matColumnDef="name" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.NAME' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span  class="text tab">{{element.name}}</span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="operation" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.OPERATION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.operation}}</span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="paidType" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.PAIDTYPE' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.paidType}}</span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="commission" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.COMMISSION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.commissionType.value}} <span>{{element.commissionType.type === 'PERCENT' ? '%' : 'EUR'}}</span></span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="description" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.DESCRIPTION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.description}}</span>
                                    </td>
                                </ng-container>

                                <!-- actions Column -->
                                <ng-container matColumnDef="actions" >
                                    <th mat-header-cell *matHeaderCellDef> </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="muted-link ml-3" style="font-size: 12px;" (click)="deleteOperation(element)" title="{{'BUTTONS.DELETE' | translate}}">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="operationsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: operationsDisplayedColumns;"  class="table-row"></tr>
                            </table>
                        </div>
                    </ngx-simplebar>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #NO_READ_PERMISSION>
    <div class="operations-content">
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../assets/images/vide/vide@2x.png"
                    srcset="../../../assets/images/vide/vide@2x.png 2x,
                    ../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
            </div>     
        </div>
    </div>
</ng-template>