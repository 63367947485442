import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getUsers(): any {
    return this.http.get(this.baseUrl + `/setting/users`);
  }

  addUser(user: any): any {
    return this.http.post(this.baseUrl + `/setting/users`, user);
  }

  setPrivilige(idUser: string, priviliges: string[]): any {
    const params = new HttpParams().set('idUser', idUser);
    const body = { idUser, privileges: priviliges };
    return this.http.put(this.baseUrl + `/setting/users/privileges/${idUser}`, body, {params});
  }

  deleteUser(idUser: string): any {
    return this.http.delete(this.baseUrl + `/setting/users/${idUser}`);
  }

  updateUserCredentials(idUser: string, payload: any): any {
    return this.http.put(this.baseUrl + `/setting/users/${idUser}`, payload);
  }


  getAccount(): any {
    return this.http.get(this.baseUrl + `/application/account`);
  }

  updateProfile(payload: any): any {
    return this.http.put(this.baseUrl + `/application/account`, payload);
  }

  searchByName(nameKey: string): any {
    return this.http.get(this.baseUrl + `/setting/users/${nameKey}`);
  }
}
