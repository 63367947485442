import { UserManagementService } from './../../services/user-management/user-management.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as fromUserManagement from './user-management.action';

@Injectable()
export class UserManagementEffects {

    constructor(private actions$: Actions, private userManagementService: UserManagementService) {}

    @Effect()
    loadBackofficeUsersAttempted$ = this.actions$.pipe(
        ofType<fromUserManagement.LoadBackofficeUsersAttempted>(fromUserManagement.LOAD_BACKOFFICE_USERS_ATTEMPTED),
        switchMap(() => this.userManagementService.getUsers()
            .pipe(
                map(resp => new fromUserManagement.LoadBackofficeUsersSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromUserManagement.LoadBackofficeUsersFailedAction(err)); })
            )
        )
    );

    @Effect()
    addBackofficeUserAttempted$ = this.actions$.pipe(
        ofType<fromUserManagement.AddBackofficeUserAttemptedAction>(fromUserManagement.ADD_BACKOFFICE_USER_ATTEMPTED),
        switchMap((data: fromUserManagement.AddBackofficeUserAttemptedAction) => this.userManagementService.addUser(data.payload)
            .pipe(
                map(resp => new fromUserManagement.AddBackofficeUserSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromUserManagement.AddBackofficeUserFailedAction(err)); })
            )
        )
    );

    @Effect()
    setBackofficeUserPriviligeAttempted$ = this.actions$.pipe(
        ofType<fromUserManagement.SetBackofficeUserPriviligeAttemptedAction>(fromUserManagement.SET_BACKOFFICE_USER_PRIVILIGE_ATTEMPTED),
        switchMap((data: fromUserManagement.SetBackofficeUserPriviligeAttemptedAction) => 
        this.userManagementService.setPrivilige(data.idUser, data.payload)
            .pipe(
                map(resp => new fromUserManagement.SetBackofficeUserPriviligeSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromUserManagement.SetBackofficeUserPriviligeFailedAction(err)); })
            )
        )
    );

    @Effect()
    deleteBackofficeUserAttempted$ = this.actions$.pipe(
        ofType<fromUserManagement.DeleteBackofficeUserAttemptedAction>(fromUserManagement.DELETE_BACKOFFICE_USER_ATTEMPTED),
        switchMap((data: fromUserManagement.DeleteBackofficeUserAttemptedAction) => this.userManagementService.deleteUser(data.idUser)
            .pipe(
                map(resp => new fromUserManagement.DeleteBackofficeUserSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromUserManagement.DeleteBackofficeUserFailedAction(err)); })
            )
        )
    );

    @Effect()
    updateBackofficeUserProfilAttemtped$ = this.actions$.pipe(
        ofType<fromUserManagement.UpdateBackofficeUserAccountAttemptedAction>(fromUserManagement.UPDATE_BACKOFFICE_USER_ACCOUNT_ATTEMPTED),
        switchMap((data: fromUserManagement.UpdateBackofficeUserAccountAttemptedAction) =>
        this.userManagementService.updateProfile(data.payload)
            .pipe(
                map(resp => new fromUserManagement.UpdateBackofficeUserAccountSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromUserManagement.UpdateBackofficeUserAccountFailedAction(err)); })
            )
        )
    );

    @Effect()
    loadBackofficeUserProfileAttempted$ = this.actions$.pipe(
        ofType<fromUserManagement.LoadBackofficeUserProfileAttemptedAction>(fromUserManagement.LOAD_BACKOFFICE_USER_PROFILE_ATTEMPTED),
        switchMap((data: fromUserManagement.LoadBackofficeUserProfileAttemptedAction) => this.userManagementService.getAccount()
            .pipe(
                map(resp => new fromUserManagement.LoadBackofficeUserProfileSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromUserManagement.LoadBackofficeUserProfileFailedAction(err)); })
            )
        )
    );

    @Effect({dispatch: false})
    loadBackofficeUserProfileSuccessful$ = this.actions$.pipe(
        ofType<fromUserManagement.LoadBackofficeUserProfileSuccessfulAction>(fromUserManagement.LOAD_BACKOFFICE_USER_PROFILE_SUCCESSFUL),
        tap((data: fromUserManagement.LoadBackofficeUserProfileSuccessfulAction) => {
            localStorage.setItem('account', JSON.stringify(data.payload.value));
        })
    );

    @Effect()
    updateBackofficeUserCredentialsAttempted$ = this.actions$.pipe(
        ofType<fromUserManagement.UpdateBackofficeUserCredentialsAttemptedAction>
        (fromUserManagement.UPDATE_BACKOFFICE_USER_CREDENTIALS_ATTEMPTED),
        switchMap((data: fromUserManagement.UpdateBackofficeUserCredentialsAttemptedAction) =>
        this.userManagementService.updateUserCredentials(data.idUser, data.payload)
            .pipe(
                map(resp => new fromUserManagement.UpdateBackofficeUserCredentialsSuccessfulAction(resp)),
                catchError(err => of (new fromUserManagement.UpdateBackofficeUserCredentialsFailedAction(err)))
            )
        )
    );

    @Effect()
    searchBackofficeUsersByNameAttempted$ = this.actions$.pipe(
        ofType<fromUserManagement.SearchBackofficeUsersByNameAttemptedAction>(fromUserManagement.SEARCH_BACKOFFICE_USERS_BY_NAME_ATTEMPTED),
        switchMap((data: fromUserManagement.SearchBackofficeUsersByNameAttemptedAction) =>
            this.userManagementService.searchByName(data.nameKey)
            .pipe(
                map(resp => new fromUserManagement.SearchBackofficeUsersByNameSuccessfulAction(resp)),
                catchError(err => of (new fromUserManagement.SearchBackofficeUsersByNameSuccessfulAction(err)))
            ))
    );

}
