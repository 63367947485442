import { UsersService } from './../../services/users/users.service';
import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {GetUsersAttemptedAction, GetUsersFailureAction, GetUsersSuccessAction, UsersAction, UsersActionTypes} from './users.action';
import { catchError, map, mergeMap, switchMap, debounceTime } from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class UsersEffects {

  constructor(private actions$: Actions, private usersService: UsersService) {}

  @Effect() getUsers$ = this.actions$.pipe(
    ofType<GetUsersAttemptedAction>(UsersActionTypes.GET_USERS_ATTEMPTED),
    // debounceTime(2000),
    switchMap(data => this.usersService.getUsers(data.payload)
        .pipe(
          map((resp) => new GetUsersSuccessAction(resp)),
          catchError(error =>  of(new GetUsersFailureAction(error)))
        )
      )
  );
}
