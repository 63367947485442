<div class="Details-container">
    <div class="Details-wrapper">
        <h1 class="Details-header">{{'SETTINGS.USER_DETAILS.HEADER' | translate}}</h1>
        <hr />
        <div *ngIf="haveUserReadPermission; else NO_READ_PERMISSION">
            <div class="spinner" *ngIf="loading">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>

            <ng-container *ngIf="!loading">
                <div *ngIf="!haveUserWritePermission && !this.havePrivilegeWritePermission; else NO_USER_AND_PRIVILEGE_WRITE_PERMISSION" class="d-flex justify-center mt-2">
                    <p class="error">{{'SETTINGS.USER_DETAILS.NO_PERMISSION' | translate}} <b><u>{{'SETTINGS.USER_DETAILS.INFOS' | translate}}</u></b> {{'SETTINGS.USER_DETAILS.OF_USER' | translate}} <b>{{'SETTINGS.USER_DETAILS.HIS_PRIVILEGES' | translate}}</b>.</p>
                </div>
                <ng-template #NO_USER_AND_PRIVILEGE_WRITE_PERMISSION>
                    <div *ngIf="!this.havePrivilegeWritePermission" class="d-flex justify-center mt-2">
                        <p class="error">{{'SETTINGS.USER_DETAILS.NO_PERMISSION' | translate}} <b><u>{{'SETTINGS.USER_DETAILS.PRIVILEGES' | translate}}</u></b> {{'SETTING.USER_DETAILS.USER' | translate}}</p>
                    </div>
    
                    <div *ngIf="!this.haveUserWritePermission" class="d-flex justify-center mt-2">
                        <p class="error">{{'SETTINGS.USER_DETAILS.NO_PERMISSION' | translate}} <b><u>{{'SETTINGS.USER_DETAILS.INFOS' | translate}}</u> </b> {{'SETTINGS.USER_DETAILS.USER' | translate}}</p>
                    </div>
                </ng-template>
            </ng-container>
            
            <div class="flex-row" *ngIf="!loading">
                <div class="w-50">
                    <form [formGroup]="userForm" #userDetailsForm id="user-form" style="width: 100%;">
                        <dl class="form-group mt-1">
                            <dt class="input-label">
                                <label aria-autocomplete="off" for="user-fullname">{{'SETTINGS.PROFILE.USERNAME' | translate}}<span class="error"> * </span></label>
                            </dt>
                            <dd>
                                <input class="form-control long" formControlName="fullname" autocomplete="off" type="text" id="user-fullname">
                            </dd>
                        </dl>
        
                        <dl class="form-group mt-1">
                            <dt class="input-label">
                                <label aria-autocomplete="off" for="user-email">{{'SETTINGS.USER_DETAILS.EMAIL_ADDRESS' | translate}}<span class="error"> * </span></label>
                            </dt>
                            <dd>
                                <input class="form-control long" formControlName="email" autocomplete="off" type="text" id="user-email">
                            </dd>
                        </dl>
                        <hr />
                        <dl class="form-group mt-1">
                            <dt class="input-label">
                                <label aria-autocomplete="off" for="user-role">{{'SETTINGS.PROFILE.ROLE' | translate}}<span class="error"> * </span></label>
                            </dt>
                            <dd>
                                <select class="form-control long" formControlName="role" autocomplete="off" id="user-role">
                                    <option value="SUPER_ADMIN">SUPER_ADMIN</option>
                                    <option value="ADMIN">ADMIN</option>
                                    <option value="USER">USER</option>
                                </select>
                            </dd>
                        </dl>
                        <div class="d-flex direction-rows mt-3">
                            <button type="button" class="btn btn-sm btn-success"  [disabled]="!haveUserWritePermission || (haveUserWritePermission && userForm.invalid)" (click)="updateUser()">{{'BUTTONS.UPDATE_USER' | translate}}</button>
                            <span *ngIf="userUpdating"  style="margin-left: 6px; padding-top: 6px;"><mat-spinner [diameter]="15"></mat-spinner></span>
                        </div>
                    </form>
                </div>

                <div class="w-30" *ngIf="havePrivilegeReadPermission">
                    <div id="user-privilege-user">
                        <h3 class="h5">{{'SETTINGS.USER_DETAILS.USER_PRIVILEGES' | translate}}</h3>
                        <div class="permission-checkboxes-wrapper">
                            <mat-checkbox class="Checkbox" color="primary" [disabled]="disablePermissionCheckboxes" [checked]="selectAllIsChecked" (change)="onSelectionToggle($event)">
                                {{'SETTINGS.USER_DETAILS.SELECT_ALL' | translate}}
                            </mat-checkbox>
                        </div>
                        <ngx-simplebar style="height: 322px;">
                            <div *ngFor="let privilege of privileges; let index = index">
                                <mat-checkbox class="Checkbox" color="primary" [disabled]="disablePermissionCheckboxes" [checked]="privilege.isChecked" (change)="onCheckChange($event, index)">
                                    {{privilege.value}}
                                </mat-checkbox>
                            </div>
                        </ngx-simplebar>
                        <div class="d-flex direction-rows mt-3">
                            <button type="button" class="btn btn-sm btn-success" [disabled]="!havePrivilegeWritePermission || (havePrivilegeWritePermission && privilegesDisabled)" (click)="updateUserPrivileges()">{{'BUTTONS.UPDATE_PRIVILEGES' | translate}}</button>
                            <span *ngIf="privilegesLoading"  style="margin-left: 6px; padding-top: 6px;"><mat-spinner [diameter]="15"></mat-spinner></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../../assets/images/vide/vide@2x.png"
            srcset="../../../../assets/images/vide/vide@2x.png 2x,
            ../../../../assets/images/vide/vide@3x.png 3x"
            class="none" alt="None">
        <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
    </div>
</ng-template>
