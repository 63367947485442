import { filter, take } from 'rxjs/operators';
import { getSelectedDeposit, getDepositScreen } from './../../../core/store/deposit/deposit.reducer';
import { Deposit } from './../../../core/models/deposit.model';
import { ScreenDeposit } from './../../../core/store/deposit/deposit.actions';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';


@Component({
  selector: 'app-deposit-details',
  templateUrl: './deposit-details.component.html',
  styleUrls: ['./deposit-details.component.scss']
})
export class DepositDetailsComponent implements OnInit {

  deposit: Deposit;

  depositScreen: number;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.store.select(getSelectedDeposit).pipe(take(1)).subscribe(selected => {
      console.log('SELECTED DEPOSITS: ', selected);
      if (selected === undefined || selected === null) {
        this.router.navigate(['/clients/details/deposits']);
        return;
      }
      this.deposit = selected;
    });
    this.store.select(getDepositScreen).pipe(filter(resp => resp !== undefined), take(1)).subscribe(screen => this.depositScreen = screen);
  }

  onBack(): void {
    this.router.navigate(['/clients/details/deposits']);
  }

}
