import { Action } from '@ngrx/store';

export const LOAD_MCC_ATTEMPTED = '[MCC] Load mccs attempted';
export const LOAD_MCC_SUCCESSFUL = '[MCC] Load mccs successful';
export const LOAD_MCC_FAILED = '[MCC] Load mccs failed';

export const ADD_MCC_ATTEMPTED = '[MCC] Add mcc attempted';
export const ADD_MCC_SUCCESSFUL = '[MCC] Add mcc successful';
export const ADD_MCC_FAILED = '[MCC] Add mcc failed';

export const ADD_MULTIPLE_MCC_ATTEMPTED = '[MCC] Add multiple mcc attemtped';
export const ADD_MULTIPLE_MCC_SUCCESSFUL = '[MCC] Add multiple mcc successful';
export const ADD_MULTIPLE_MCC_FAILED = '[MCC] Add multiple mcc failed';

export const DELETE_MCC_ATTEMPTED = '[MCC] Delete mcc attempted';
export const DELETE_MCC_SUCCESSFUL = '[MCC] Delete mcc successful';
export const DELETE_MCC_FAILED = '[MCC] Delete mcc failed';

export const UPDATE_MCC_ATTEMTPED = '[MCC] Update mcc attempted';
export const UPDATE_MCC_SUCCESSFUL = '[MCC] Update mcc successful';
export const UPDATE_MCC_FAILED = '[MCC] Update mcc failed';

export const SET_MCC = '[MCC] Set selected mcc';

export class LoadMccAttemptedAction implements Action {
    readonly type = LOAD_MCC_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadMccSuccessfulAction implements Action {
    readonly type = LOAD_MCC_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadMccFailedAction implements Action {
    readonly type = LOAD_MCC_FAILED;
    constructor(public payload: any) {}
}

export class AddMccAttemptedAction implements Action {
    readonly type = ADD_MCC_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddMccSuccessfulAction implements Action {
    readonly type = ADD_MCC_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddMccFailedAction implements Action {
    readonly type = ADD_MCC_FAILED;
    constructor(public payload: any) {}
}

export class AddMultipleMccAttemptedAction implements Action {
    readonly type = ADD_MULTIPLE_MCC_ATTEMPTED;
    constructor(public formData: FormData) {}
}

export class AddMultipleMccSuccessfulAction implements Action {
    readonly type = ADD_MULTIPLE_MCC_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddMultipleMccFailedAction implements Action {
    readonly type = ADD_MULTIPLE_MCC_FAILED;
    constructor(public payload: any) {}
}

export class DeleteMccAttemptedAction implements Action {
    readonly type = DELETE_MCC_ATTEMPTED;
    constructor(public idMcc: string) {}
}

export class DeleteMccSuccessfulAction implements Action {
    readonly type = DELETE_MCC_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeleteMccFailedAction implements Action {
    readonly type = DELETE_MCC_FAILED;
    constructor(public payload: any) {}
}

export class UpdateMccAttemptedAction implements Action {
    readonly type = UPDATE_MCC_ATTEMTPED;
    constructor(public idMcc: string, public payload: any) {}
}

export class UpdateMccSuccessfulAction implements Action {
    readonly type = UPDATE_MCC_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateMccFailedAction implements Action {
    readonly type = UPDATE_MCC_FAILED;
    constructor(public payload: any) {}
}

export class SetMccAction implements Action {
    readonly type = SET_MCC;
    constructor(public payload: any) {}
}


export type MccActions =
    |LoadMccAttemptedAction
    |LoadMccFailedAction
    |LoadMccSuccessfulAction
    |AddMccAttemptedAction
    |AddMccFailedAction
    |AddMccSuccessfulAction
    |AddMultipleMccAttemptedAction
    |AddMultipleMccFailedAction
    |AddMultipleMccSuccessfulAction
    |DeleteMccAttemptedAction
    |DeleteMccFailedAction
    |DeleteMccSuccessfulAction
    |UpdateMccAttemptedAction
    |UpdateMccFailedAction
    |UpdateMccSuccessfulAction
    |SetMccAction
;
