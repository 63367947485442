import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromNews from './news.actions';

export interface NewsState {
    data: any[];
    selectedNews: any;
    action: string;
    addResponse: any;
    updateResponse: any;
    deleteNewsResponse: any;
    idNews: string;
    loading: boolean;
    error: string;
}

export const initialState: NewsState = {
    data: undefined,
    selectedNews: undefined,
    action: undefined,
    addResponse: undefined,
    updateResponse: undefined,
    deleteNewsResponse: undefined,
    idNews: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: NewsState = initialState, action: fromNews.NewsActions): any {
    switch (action.type) {
        case fromNews.LOAD_NEWS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined,
                updateResponse: undefined
            };
        case fromNews.LOAD_NEWS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value
            };
        case fromNews.LOAD_NEWS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromNews.ADD_NEWS_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromNews.ADD_NEWS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                addResponse: action.payload.value
            };
        case fromNews.ADD_NEWS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromNews.UPDATE_NEWS_ATTEMPTED:
            return {
                ...state,
                loading:  true
            };

        case fromNews.UPDATE_NEWS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                updateResponse: action.payload
            };
        case fromNews.UPDATE_NEWS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromNews.DELETE_NEWS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                idNews: action.idNews
            };
        case fromNews.DELETE_NEWS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                deleteNewsResponse: action.payload,
                // data: state.data.filter((news) => news.id !== state.idNews),
                idNews: undefined
            };
        case fromNews.DELETE_NEWS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromNews.SELECT_NEWS:
            return {
                ...state,
                selectedNews: action.payload,
                action: action.action
            };
        default:
            return state;
    }
}

export const getNewsState = createFeatureSelector<NewsState>('news');

export const getNews = createSelector(getNewsState, (state: NewsState) => state.data);
export const getAddResponse = createSelector(getNewsState, (state: NewsState) => state.addResponse);
export const getUpdateResponse = createSelector(getNewsState, (state: NewsState) => state.updateResponse);
export const getDeleteNewsResponse = createSelector(getNewsState, (state: NewsState) => state.deleteNewsResponse);
export const getSelectedNews = createSelector(getNewsState, (state: NewsState) => {
    return {
        news: state.selectedNews,
        action: state.action
    };
});
