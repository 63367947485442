import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadClientActivitiesAttemptedAction } from '../../core/store/log/log.actions';
import { getClientActivities } from '../../core/store/log/log.reducer';
import { filter } from 'rxjs/operators';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  loading: boolean;

  activities: any;

  activitiesLength = 0;

  client: any;

  private activitiesSbscription$;


  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnDestroy(): void {
    if (this.activitiesSbscription$) {
      this.activitiesSbscription$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.client = client;

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_LOG_READ') {
        this.haveReadPermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadClientActivitiesAttemptedAction({idClient: client.id}));
    this.activitiesSbscription$ = this.store.select(getClientActivities).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      this.activities = data;
      this.loading = false;
    },
    (err) => console.log(err),
    () => this.loading = false);
  }

  onNavClientInformations(): void {
    this.router.navigate(['/clients/details/info']);
  }

}
