import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromBeneficiaries from './beneficiaries.actions';
import { BeneficiariesService } from '../../services/beneficiaries/beneficiaries.service';

@Injectable()
export class BeneficiariesEffects {

    constructor(private actions$: Actions, private beneficiariesService: BeneficiariesService) {}

    @Effect()
    loadBeneficiariesAttempted$ = this.actions$.pipe(
        ofType(fromBeneficiaries.LOAD_BENEFICIARIES_ATTEMPTED),
        switchMap((data: fromBeneficiaries.LoadBeneficiariesAttemptedAction) => {
            return this.beneficiariesService.getBeneficiaries(data.payload)
                .pipe(
                    map(resp => new fromBeneficiaries.LoadBeneficiariesSuccesfulAction(resp)),
                    catchError(error => of(new fromBeneficiaries.LoadBeneficiariesFailedAction(error)))
                );
        })
    );
}
