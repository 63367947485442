import { GetUserAttemptedAction } from './../../../core/store/user-details/user-details.action';
import { DialogComponent } from './../../../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from './../../../core/models/user.model';
import { AppState } from 'src/app/core/store';
import { GetUserDocumentsAttemptedAction } from './../../../core/store/document/document.action';
import { Router } from '@angular/router';
import { getUserDocuments } from './../../../core/store/document/document.reducer';
import { Store } from '@ngrx/store';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { SaveUserSelectedDocumentAction } from '../../../core/store/document/document.action';
import { filter, take } from 'rxjs/operators';
import { Subscription, Observable } from 'rxjs';
import { getIsRectificationSubmitted, getVerifiedRectifications } from 'src/app/core/store/rectification/rectification.reducer';
import { GetVerifiedRectificationsAttemptedAction, RectifyAttemptedAction, ValidateClientDocumentsAttemptedAction } from '../../../core/store/rectification/rectification.action';
import { getUser } from '../../../core/store/user-details/user-details.reducer';
import { getDocumentsValidationResponse, getKycSendResponse } from '../../../core/store/rectification/rectification.reducer';
import { SendToKYCAttemptedAction } from '../../../core/store/rectification/rectification.action';
import { TranslateService } from '@ngx-translate/core';

interface PeriodicElement {
  indexChild?: number;
  identity?: string;
  name?: string;
  imageState?: string;
  found?: boolean;
  fileUrl?: string;
  children?: PeriodicElement[];
}

interface PeriodicElement1 {
  indexParent?: number;
  identity?: string;
  name?: string;
  behindFilesCount?: number;
  aheadFilesCount?: number;
  behindBarWidth?: string;
  aheadBarWidth?: string;
  title?: string;
  files?: [{
    name: string;
    imageState: string;
    found: boolean;
    fileUrl: string;
  }];
  children?: PeriodicElement[];
}

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>;
  expanded?: boolean;
}

@Component({
  selector: 'app-registration-documents',
  templateUrl: './registration-documents.component.html',
  styleUrls: ['./registration-documents.component.scss']
})
export class RegistrationDocumentsComponent implements OnInit, OnDestroy {

  // verifiedDocuments: any[] = [];

  user: any;

  haveDocumentsReadPermission = false;

  haveDocumentsWritePermission = false;

  client: User;

  userStatusName: string;

  verifiedRectifications: any[] = [];

  displayedColumns: string[] = ['name', 'count'];

  treeControl = new NestedTreeControl<PeriodicElement>(node => node.children);

  dataSource = new MatTreeNestedDataSource<PeriodicElement1>();

  dbdata: any;

  treeData: PeriodicElement1[] = [];

  loading = true;

  folderEmpty = true;

  documentFilesCount = [];

  documents$: Observable<any>;

  private clientSubscription$;

  private documentsSubscription: Subscription;

  private verifiedRectificationsSubscription$;

  private documentsValidationSubscription$;

  private kycSendingSubscription$;

  private submitDialogRectifications$;

  private rectificationSubmissionSubscription$;

  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) {
    this.dataSource.data = [];
  }

  hasChild = (_: number, node: PeriodicElement) => !!node.children && node.children.length >= 0;

  ngOnDestroy(): void {
    if (this.documentsSubscription) {
      this.documentsSubscription.unsubscribe();
    }
    if (this.documentsValidationSubscription$) {
      this.documentsValidationSubscription$.unsubscribe();
    }
    if (this.kycSendingSubscription$) {
      this.kycSendingSubscription$.unsubscribe();
    }
    if (this.clientSubscription$) {
      this.clientSubscription$.unsubscribe();
    }
    if (this.verifiedRectificationsSubscription$) {
      this.verifiedRectificationsSubscription$.unsusbcribe();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.dataSource.data = [];
    this.user = JSON.parse(localStorage.getItem('user'));
    for (const permission of this.user.permissions) {
      if (permission.trim() === 'CLIENT_DOCUMENT_READ') {
        this.haveDocumentsReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_DOCUMENT_WRITE') {
        this.haveDocumentsWritePermission = true;
      }
    }
    const userId = JSON.parse(localStorage.getItem('client')).id;
    const old: User = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetUserAttemptedAction({idClient: old.id}));
    this.clientSubscription$ = this.store.select(getUser).pipe(filter(user => user !== undefined)).subscribe(user => {
      this.client = user;
      this.userStatusName = user.userStatusName;
    });

    this.rectificationSubmissionSubscription$ = this.store.select(getIsRectificationSubmitted).pipe(filter(resp => resp !== undefined))
      .subscribe(result => {
        if (result) {
          console.log('RECTIFICATION SUBMITTED SUCCESSFULLY');
          this.store.dispatch(new GetUserAttemptedAction({idClient: this.client.id}));
          this.loading = false;
          // this.store.dispatch(new RemoveRectificationAttemptedAction())
        }
    });

    /* getting all verified rectification attributed (FIRSTNAME, LASTNAME, ...) */
    this.store.dispatch(new GetVerifiedRectificationsAttemptedAction({idClient: userId}));
    this.store.select(getVerifiedRectifications).pipe(filter(resp => resp !== undefined), take(1))
      .subscribe(verifiedRectifs => {
        console.log('** LAST VERIFIED RECTIFS: ', verifiedRectifs);
        this.populateVerifiedRectifications(verifiedRectifs);
      });

    /* GETTING DOCUMENT NAMES THAT HAVE ERRORS */
    /*
    this.store.dispatch(new GetCheckedDocumentsAttemptedAction(userId));
    this.store.select(getVerifiedDocuments).pipe(filter(resp => resp !== undefined), take(2)).subscribe(data => {
      if (data) {
        console.log('** CHECKED DOCUMENTS', data);
        this.verifiedDocuments = data;
      }
    });
*/

    this.store.dispatch(new GetUserDocumentsAttemptedAction({idClient: userId, userStatusName: this.userStatusName}));
    this.documentsSubscription = this.store.select(getUserDocuments).pipe(filter(object => object !== undefined))
      .subscribe(foldersObject => {
        if ((foldersObject.passport && foldersObject.passport.length > 0) || (foldersObject.cin && foldersObject.cin.length > 0)
        || (foldersObject.portrait && foldersObject.portrait.length > 0)
        || (foldersObject.hostedHomeDocuments && foldersObject.hostedHomeDocument.length > 0) ||
          (foldersObject.hostedOtherHomeDocuments && foldersObject.hostedOtherHomeDocuments.length > 0)) {
          this.dbdata = foldersObject;
          this.folderEmpty = false;
          console.log('POPULATING TREE AND FOLDER TREE IS: ', foldersObject);
          this.populateFolderTree();
          console.log('** FOLDERS : ', foldersObject);
        }
        console.log('** EMPTY FOLDER : ', foldersObject);
        this.loading = false;
      });

    this.documentsValidationSubscription$ = this.store.select(getDocumentsValidationResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(res => {
        this.store.dispatch(new GetUserAttemptedAction({idClient: this.client.id}));
        console.log('VALIDATION RESPONSE IS: ', res);
      });

    this.kycSendingSubscription$ = this.store.select(getKycSendResponse).pipe(filter(resp => resp !== undefined)).subscribe(res => {
      if (res.code === 200) {
        this.loading = false;
        this.store.dispatch(new GetUserAttemptedAction({idClient: this.client.id}));
      } else {
        // throw error
      }
    });
  }


  populateFolderTree(): any {
    let fileIndex = 0;
    let folderIndex = 0;
    let verifiedFilesCount = 0;
    let folderFilesCount: number;
    let notValidWidth: number;
    let validWidth: number;
    if (this.dbdata !== undefined) {
      if (this.dbdata.passport && this.dbdata.passport.length > 0) {
        notValidWidth = 0;
        validWidth = 0;
        verifiedFilesCount = folderFilesCount = 0;
        const children: PeriodicElement [] = [];
        this.dbdata.passport.forEach((file: any) => {
          // tslint:disable-next-line: max-line-length
          children.push({indexChild: fileIndex++, identity: 'PASSPORT', name: file.name, imageState: file.imageState, found: file.found, fileUrl: file.fileUrl});
          // id document is confirmed (confirmed only by backoffice but still not KYC confirmed)
          /*
          this.verifiedDocuments.forEach(verifiedDocumentName => {
            if (file.name === verifiedDocumentName) {
              verifiedFilesCount++;
            }
          });

          folderFilesCount++;
          */
        });
        /*
        if (folderFilesCount !== 0) {
          notValidWidth = ((folderFilesCount - verifiedFilesCount) * 100) / folderFilesCount;
          validWidth = (verifiedFilesCount * 100) / folderFilesCount;
        }
        */
        // const title = (folderFilesCount - verifiedFilesCount) + ' document(s) non vérifié(s), '
        // + verifiedFilesCount + ' documents vérifié(s)';
        this.treeData.push({
          indexParent: folderIndex++,
          identity: 'PASSEPORT',
          // behindFilesCount: (folderFilesCount - verifiedFilesCount),
          // aheadFilesCount: verifiedFilesCount,
          // behindBarWidth: notValidWidth + '%',
          // aheadBarWidth: validWidth + '%',
          // title,
          name: '',
          children
        });
      }

      if (this.dbdata.portrait && this.dbdata.portrait.length > 0) {
        notValidWidth = 0;
        validWidth = 0;
        verifiedFilesCount = folderFilesCount = 0;
        const children: PeriodicElement [] = [];
        this.dbdata.portrait.forEach((file: any) => {
          // tslint:disable-next-line: max-line-length
          children.push({indexChild: fileIndex++, identity: 'PORTRAIT', name: file.name, imageState: file.imageState, found: file.found, fileUrl: file.fileUrl});
          // id document is confirmed (confirmed only by backoffice but still not KYC confirmed)
          /*
          this.verifiedDocuments.forEach(verifiedDocumentName => {
            if (file.name === verifiedDocumentName) {
              verifiedFilesCount++;
            }
          });
          folderFilesCount++;
          */
        });
        /*
        if (folderFilesCount !== 0) {
          notValidWidth = ((folderFilesCount - verifiedFilesCount) * 100) / folderFilesCount;
          validWidth = (verifiedFilesCount * 100) / folderFilesCount;
        }
        */
        // const title = (folderFilesCount - verifiedFilesCount) + ' document(s) non vérifié(s), '
        // + verifiedFilesCount + ' documents vérifié(s)';
        this.treeData.push({
          indexParent: folderIndex++,
          identity: 'PORTRAIT',
          // behindFilesCount: (folderFilesCount - verifiedFilesCount),
          // aheadFilesCount: verifiedFilesCount,
          // behindBarWidth: notValidWidth + '%',
          // aheadBarWidth: validWidth + '%',
          // title,
          name: '',
          children
        });
      }

      if (this.dbdata.cin && this.dbdata.cin.length > 0) {
        const children: PeriodicElement [] = [];
        this.dbdata.cin.forEach((file: any) => {
          // tslint:disable-next-line: max-line-length
          children.push({indexChild: fileIndex++, identity: 'CIN', name: file.name, imageState: file.imageState, found: file.found, fileUrl: file.fileUrl});
        });
        this.treeData.push({indexParent: folderIndex++,
          identity: 'CIN',
          name: '',
          children
        });
      }

      if (this.dbdata.hostedHomeDocuments && this.dbdata.hostedHomeDocuments.length > 0) {
        const children: PeriodicElement [] = [];
        this.dbdata.hostedHomeDocuments.forEach((file: any) => {
          // tslint:disable-next-line: max-line-length
          children.push({indexChild: fileIndex++, identity: 'HOSTED_HOME_DOCUMENT', name: file.name, imageState: file.imageState, found: file.found, fileUrl: file.fileUrl});
        });
        this.treeData.push({
          indexParent: folderIndex++,
          identity: 'HOSTED_HOME_DOCUMENT',
          name: '',
          children
        });
      }

      if (this.dbdata.hostedOtherHomeDocuments && this.dbdata.hostedOtherHomeDocuments.length > 0) {
        const children: PeriodicElement [] = [];
        this.dbdata.hostedOtherHomeDocuments.forEach((file: any) => {
          // tslint:disable-next-line: max-line-length
          children.push({indexChild: fileIndex++, identity: 'HOSTED_OTHER_HOME_DOCUMENT', name: file.name, imageState: file.imageState, found: file.found, fileUrl: file.fileUrl});

        });
        this.treeData.push({
          indexParent: folderIndex++,
          identity: 'HOSTED_OTHER_HOME_DOCUMENT',
          name: '', children
        });
      }
    }
    this.dataSource.data = this.treeData;
    const normalizedDocuments = this.concatenateFolders();
    localStorage.setItem('documents', JSON.stringify(normalizedDocuments));
    return this.treeData;
  }

  private concatenateFolders(): any[] {
    let result = [];
    for (let i = 0; i < this.treeData.length; i++) {
      result.push(...this.treeData[i].children);
    }
    return result;
  }


  envoyerRectification(): void {
    const client = JSON.parse(localStorage.getItem('client'));
    const idClient = client.id;
    const email = client.email;
    let rectifications: string[] = null;
    this.store.select(getVerifiedRectifications).pipe(filter(obj => obj !== undefined), take(1))
      .subscribe(verifiedRectifs => {
        if (verifiedRectifs) {
          console.log('sending rectifs ...', verifiedRectifs);
          rectifications = verifiedRectifs;
        } else {
          console.log('ERROR WHILE SENDING VERFIED RECTIFICATIONS');
        }
    });

    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translate.instant('RECTIFICATION'),
        message: this.translate.instant('RECTIFICATION_MESSAGE'),
        buttons: 'send-rectif',
        type: 'SUBMIT_RECTIFICATION_CONFIRMATION',
        value: {idClient, email, rectification: rectifications},
        confirmText: this.translate.instant('BUTTONS.SUBMIT'),
        cancelText: this.translate.instant('BUTTONS.ABANDON')
      }
    });

    this.submitDialogRectifications$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.store.dispatch(new RectifyAttemptedAction({idClient, email, rectification: rectifications}));
        // reset verified docs and fields
      } else {
        // reset verified docs and fields
      }
    });

/*
    this.store.dispatch(new RectifyAttemptedAction({idClient, email, rectification: rectifications}));
    this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translate.instant('RECTIFICATION'),
        message: this.translate.instant('RECTIFICATION_MESSAGE'),
        buttons: 'send-rectif',
        value: {idClient, email, rectification: rectifications},
        cancelText: this.translate.instant('BUTTONS.CANCEL'),
        confirmText: 'OK'
      }
    });
    */
  }

  populateVerifiedRectifications(verifiedRectifs: string[]): void {
    if (verifiedRectifs !== undefined || verifiedRectifs !== null || verifiedRectifs.length > 0) {
      verifiedRectifs.forEach(verifiedRectif => {
        switch (verifiedRectif) {
        case 'FIRSTNAME':
          this.verifiedRectifications.push({isText: true, value: 'FIRSTNAME'});
          break;
        case 'LASTNAME':
          this.verifiedRectifications.push({isText: true, value: 'LASTNAME'});
          break;
        case 'BIRTHDAY':
          this.verifiedRectifications.push({isText: true, value: 'BIRTHDAY'});
          break;
        case 'GENDER':
          this.verifiedRectifications.push({isText: true, value: 'GENDER'});
          break;
        case 'CIN':
          this.verifiedRectifications.push({isText: true, value: 'CIN'});
          break;
        case 'CIN_DATE':
          this.verifiedRectifications.push({isText: true, value: 'CIN_DATE'});
          break;
        case 'CITOYEN':
          this.verifiedRectifications.push({isText: true, value: 'CITOYEN'});
          break;
          case 'NUM_PASSPORT':
          this.verifiedRectifications.push({isText: true, value: 'NUM_PASSPORT'});
          break;
        case 'NUM_CARTE_SEJOUR':
          this.verifiedRectifications.push({isText: true, value: 'NUM_CARTE_SEJOUR'});
          break;
        case 'PASSPORT_DATE':
          this.verifiedRectifications.push({isText: true, value: 'PASSPORT_DATE'});
          break;
        case 'CARTE_SEJOUR_DATE':
          this.verifiedRectifications.push({isText: true, value: 'CARTE_SEJOUR_DATE'});
          break;
        case 'RUE':
          this.verifiedRectifications.push({isText: true, value: 'RUE'});
          break;
        case 'NUM_RUE':
          this.verifiedRectifications.push({isText: true, value: 'NUM_RUE'});
          break;
        case 'VILLE':
          this.verifiedRectifications.push({isText: true, value: 'VILLE'});
          break;
          case 'PAYS':
          this.verifiedRectifications.push({isText: true, value: 'PAYS'});
          break;
          case 'NUM_APP':
          this.verifiedRectifications.push({isText: true, value: 'NUM_APP'});
          break;
          case 'CODE_POSTAL':
          this.verifiedRectifications.push({isText: true, value: 'CODE_POSTAL'});
          break;
          default:
            this.verifiedRectifications.push({isText: false, value: verifiedRectif});
        }

      });
      console.log('VERIFIED RECTIF AFTER POPULATE:', this.verifiedRectifications);
    }
  }


  visualiseDocument(document: any): void {
    const user = JSON.parse(localStorage.getItem('user'));
    if (this.haveDocumentsReadPermission) {
      this.store.dispatch(new SaveUserSelectedDocumentAction(document));
      localStorage.setItem('document', JSON.stringify(document));
    }
    this.router.navigate(['/clients/details/documents/informations']);
  }

  onFileLinkClicked(documentName: string): void {
    const documents: any[] = JSON.parse(localStorage.getItem('documents'));
    const document = documents.find(doc => doc.name === documentName);
    localStorage.setItem('document', JSON.stringify(document));
    this.store.dispatch(new SaveUserSelectedDocumentAction(document));
    this.router.navigate(['/clients/details/documents/informations']);
  }

  validate(): void {
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new ValidateClientDocumentsAttemptedAction(client.id));
  }

  sendToKYC(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new SendToKYCAttemptedAction(client.id));
  }

  resetRectifications(): void {
  }

}
