import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UserManagementEffects } from '../core/store/user-managements/user-management.effects';
import { BackofficeUsersComponent } from './backoffice-users/backoffice-users.component';
import { BackofficeUserDetailsComponent } from './backoffice-user-details/backoffice-user-details.component';
import { ProfilComponent } from './profil/profil.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    BackofficeUsersComponent,
    BackofficeUserDetailsComponent,
    ProfilComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDatatableModule,
    MatProgressSpinnerModule,
    SimplebarAngularModule,
    MatCheckboxModule,
    TranslateModule,
    EffectsModule.forFeature([
      UserManagementEffects
    ])
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SettingsModule { }
