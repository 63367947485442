import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getOffers(): any {
    return this.http.get(this.baseUrl + `/application/offertui`);
  }

  add(payload: {title: string, expiryDate: any, contentType: any, content: any}): any {
    return this.http.post(this.baseUrl + `/application/offertui`, payload);
  }

  delete(idOffer: string): any {
    return this.http.delete(this.baseUrl + `/application/offertui/${idOffer}`);
  }

  modify(idOffer: string, payload: {title: string, expiryDate: any, contentType: any, content: any}): any {
    return this.http.put(this.baseUrl + `/application/offertui/${idOffer}`, payload);
  }
}
