import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { LoadPackagesAttemptedAction, SelectPackageAction, LoadClientPackageAttemtedAction, AffectClientPackageAttemptedAction } from '../../core/store/package/package.action';
import { getPackages, getClientPackage, getAffectClientPackageResponse } from '../../core/store/package/package.reducer';
import { filter } from 'rxjs/operators';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-packages-client',
  templateUrl: './packages-client.component.html',
  styleUrls: ['./packages-client.component.scss']
})
export class PackagesClientComponent implements OnInit, OnDestroy {

  haveReadPermission = true;

  haveWritePermission = true;

  private packagesSubscription;

  private clientPackageSubscription;

  loading: boolean;

  clientPackageLoading: boolean;

  client: any;

  clientPackage: any;

  packageAssigned = false;

  clientHaveAssignedPackage = false;

  packages: any[];

  packagesLength: number;

  packagesDisplayedColumns: string[] = ['label', 'description', 'operations', 'actions'];

  operationsDisplayedColumns: string[] = ['operationName', 'operationCommissionType', 'operationDescription', 'operationActions'];

  private confirmationDialogSubscription;

  private packageAssignmentSubscription;

  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;
    this.clientPackageLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    this.client = client;
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_PACKAGE_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_PACKAGE_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadPackagesAttemptedAction());
    this.packagesSubscription = this.store.select(getPackages).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('* PACKAGES: ', data);
      this.packages = data;
      this.packagesLength = data.length;
      console.log(this.packagesLength);
      this.loading = false;
    });
    this.store.dispatch(new LoadClientPackageAttemtedAction(client.id));
    this.clientPackageSubscription = this.store.select(getClientPackage).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('* CLIENT PACKAGE: ', data);
      this.clientPackage = data;
      this.clientHaveAssignedPackage = true;
      this.clientPackageLoading = false;
    });
    this.packageAssignmentSubscription = this.store.select(getAffectClientPackageResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(resp => {
        console.log('PACKAGE ASSIGNED RESPONSE: ', resp);
        this.reload();
      });
  }

  ngOnDestroy(): void {
    if (this.packagesSubscription) {
      this.packagesSubscription.unsubscribe();
    }
    if (this.clientPackageSubscription) {
      this.clientPackageSubscription.unsubscribe();
    }
    if (this.confirmationDialogSubscription) {
      this.confirmationDialogSubscription.unsubscribe();
    }
    if (this.packageAssignmentSubscription) {
      this.packageAssignmentSubscription.unsubscribe();
    }
  }

  private reload(): void {
    /*
    this.packageAssigned = true;
    setTimeout(() => { this.packageAssigned = false; }, 3000);
    */
    this.store.dispatch(new LoadClientPackageAttemtedAction(this.client.id));
    this.store.dispatch(new LoadPackagesAttemptedAction());
  }

  seeOperationDetails(operation: any): void{
    console.log(operation);
  }

  onAffectPackage(paquet: any): void {
    console.log(paquet);
    this.store.dispatch(new AffectClientPackageAttemptedAction(this.client.id, paquet.id));
  }

  onNavClientInformations(): void {
    this.router.navigate(['/clients/details/info']);
  }

  onChangeClientPackage(seletedPackage: any): void {
    const confirmationDialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        buttons: 'oui-non',
        title: this.translate.instant('CONFIRMATION'),
        message: this.translate.instant('ASSING_PACKAGE') + this.clientPackage.label + this.translate.instant('TO_CLIENT') +
          this.client.firstname + ' ' + this.client.lastname + '?',
        type: 'CLIENT_MODULE_PACKAGE_ASSIGNMENT',
        value: seletedPackage
      }
    });
    this.confirmationDialogSubscription = confirmationDialogRef.afterClosed().subscribe(paquet => {
      if (paquet) {
        this.loading = true;
        console.log(paquet, this.client.id);
        this.store.dispatch(new AffectClientPackageAttemptedAction(this.client.id, paquet.id));
      }
    });
  }

}
