<div class="content">
    <div class="full-height bg-white">
        <div class="Nav">
            <div class="Nav-item pointer" [routerLink]="['/application/packages/packages']" [routerLinkActive]="['border-bottom']">
                <span class="Nav-label">{{'PACKAGES.HEADER' | translate}}</span>
            </div>

            <div class="Nav-item pointer" [routerLink]="['/application/packages/operations']" [routerLinkActive]="['border-bottom']">
                <span class="Nav-label">{{'PACKAGES.OPERATIONS' | translate}}</span>
            </div>

            <div class="Nav-item pointer" [routerLink]="['/application/packages/commissions']" [routerLinkActive]="['border-bottom']">
                <span class="Nav-label">{{'PACKAGES.COMMISSIONS' | translate}}</span>
            </div>
        </div>

        <div class="container-fluid pt-1">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
