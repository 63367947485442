import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadOperationsAttemptedAction } from '../../../../core/store/operation/operation.action';
import { getOperations, getOperationAddResponse } from '../../../../core/store/operation/operation.reducer';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AddPackageAttemptedAction } from '../../../../core/store/package/package.action';
import { getPackageAddResponse } from '../../../../core/store/package/package.reducer';

@Component({
  selector: 'app-new-package',
  templateUrl: './new-package.component.html',
  styleUrls: ['./new-package.component.scss']
})
export class NewPackageComponent implements OnInit, OnDestroy {

  loading: boolean;

  showError = false;

  packageForm: FormGroup;

  private operationsSubscription;

  private addingPackageSubscription;

  operations: any;

  operationsLength: number;

  selectedOperationIndex = -1;

  selectedOperation: any;

  operationsDisplayedColumns: string[] = ['checkbox', 'name', 'operation', 'paidType', 'commission', 'description'];

  constructor(private fb: FormBuilder, private store: Store<AppState>, private router: Router) {
    this.packageForm = this.fb.group({
      label: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      operation: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnDestroy(): void {
    if (this.operationsSubscription) {
      this.operationsSubscription.unsubscribe();
    }
    if (this.addingPackageSubscription) {
      this.addingPackageSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.store.dispatch(new LoadOperationsAttemptedAction());
    this.operationsSubscription = this.store.select(getOperations).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      this.operations = data;
      this.operationsLength = data.length;
    });
    this.addingPackageSubscription = this.store.select(getPackageAddResponse).pipe(filter(resp => resp !== undefined)).subscribe(res => {
      if (res.code === 200) {
        this.router.navigate(['/application/packages/packages']);
      } else {
        this.showError = true;
        this.packageForm.reset();
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        setTimeout(() => {
          this.showError = false;
        }, 3000);
      }
    });
  }

  onSubmit(): void {
    const payload = {
      label: this.packageForm.get('label').value,
      description: this.packageForm.get('description').value,
      operations: [
        {
          description: this.selectedOperation.description,
          id: this.selectedOperation.id,
          name: this.selectedOperation.name,
          operation: this.selectedOperation.operation,
          paidType: this.selectedOperation.paidType,
          commissionType: {
            id: this.selectedOperation.commissionType.id,
            type: this.selectedOperation.commissionType.type,
            value: this.selectedOperation.commissionType.value
          }
        }
      ]
    };
    this.store.dispatch(new AddPackageAttemptedAction(payload));
    // console.log('PAYLOAD IS: ', payload);
  }

  selectOperation(operation: any, index: number): void {
    this.selectedOperationIndex = index;
    this.selectedOperation = operation;
  }

}
