import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { UpdateNewsAttemptedAction, SelectNewsAction, LoadNewsAttemptedAction } from '../../../core/store/news/news.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { getSelectedNews, getUpdateResponse, getNews } from '../../../core/store/news/news.reducer';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-news',
  templateUrl: './update-news.component.html',
  styleUrls: ['./update-news.component.scss']
})
export class UpdateNewsComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  selectedNews: any;

  isLinkedChecked = false;

  isnotLinkedChecked = false;

  link: string;

  newsForm: FormGroup;

  action: string;

  isUpdateClicked: boolean;

  news: any[];

  newsLength: number;

  rankList: number[] = [-1, 0, 1, 2, 3];

  private updateSubscription$;

  constructor(private store: Store<AppState>, private router: Router, private fb: FormBuilder) {
    this.newsForm = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      rang: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      tag: [null, Validators.compose([Validators.required])],
      linkedObject: (null)
    });
  }

  ngOnDestroy(): void {
    if (this.updateSubscription$) {
      this.updateSubscription$.unsubscribe();
    }
  }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_NEWS_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_NEWS_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.store.dispatch(new LoadNewsAttemptedAction());
    this.store.select(getNews).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      this.news = data;
      this.newsLength = data.length;
      console.log('NEWS: ', data);
      if (this.newsLength > 0) {
        for (let i = 0; i < this.newsLength; i++) {
          for (let j = 0; j < this.rankList.length; j++) {
            console.log(this.news[i].rang, this.rankList[j]);
            if (this.news[i].rang === -1 && this.news[i].rang === this.rankList[j]) {
              console.log('spliced ', this.rankList[j]);
              this.rankList.splice(j, 1);
            }
          }
        }
      }
      console.log('RANKS AFTER :', this.rankList);
    });
    this.store.select(getSelectedNews).pipe(take(1)).subscribe(resp => {
      console.log(resp);
      this.selectedNews = resp.news;
      if (!this.selectedNews) {
        this.router.navigate(['/application/news']);
        return;
      }
      this.action = resp.action;
      this.newsForm.patchValue({
        title: this.selectedNews.title,
        rang: this.selectedNews.rang,
        description: this.selectedNews.description,
        tag: this.selectedNews.tag,
        linkedObject: this.selectedNews.linkedObject
      });
      if (this.selectedNews.tag === 'LINKED') {
        this.isLinkedChecked = true;
      }
      if (resp.action === 'show') {
        this.newsForm.get('title').disable();
        this.newsForm.get('rang').disable();
        this.newsForm.get('description').disable();
        this.newsForm.get('tag').disable();
        this.newsForm.get('linkedObject').disable();
      }
      if (resp.action === 'update') {
        this.isUpdateClicked = true;
      }
    });

    this.updateSubscription$ = this.store.select(getUpdateResponse).pipe(filter(resp => resp !== undefined)).subscribe(resp => {
      console.log('RESPPPPPP : ', resp);
      console.log('navigating ...');
      this.router.navigate(['/application/offers']);
    });
  }

  enableUpdate(): void {
    if (!this.haveWritePermission) {
      return;
    }
    this.store.dispatch(new SelectNewsAction(this.selectedNews, 'update'));
    this.action = 'update';
    this.newsForm.get('title').enable();
    this.newsForm.get('rang').enable();
    this.newsForm.get('description').enable();
    this.newsForm.get('tag').enable();
    this.newsForm.get('linkedObject').enable();
    this.isUpdateClicked = true;
  }

  cancelUpdate(): void {
    this.store.dispatch(new SelectNewsAction(this.selectedNews, 'show'));
    this.action = 'show';
    this.newsForm.get('title').disable();
    this.newsForm.get('rang').disable();
    this.newsForm.get('description').disable();
    this.newsForm.get('tag').disable();
    this.newsForm.get('linkedObject').disable();
    this.isUpdateClicked = false;
  }

  onSubmit(): void {
    const payload = {
      id: this.selectedNews.id,
      title: this.newsForm.get('title').value,
      rang: this.newsForm.get('rang').value,
      description: this.newsForm.get('description').value,
      tag: this.newsForm.get('tag').value,
      linkedObject: this.newsForm.get('linkedObject').value
    };
    this.store.dispatch(new UpdateNewsAttemptedAction(this.selectedNews.id, payload));
  }

  onTagCheck(tag: string): void {
    if (tag === 'LINKED') {
      this.isLinkedChecked = true;
      this.isnotLinkedChecked = false;
    } else {
      this.newsForm.get('linkedObject').reset();
      this.isLinkedChecked = false;
      this.isnotLinkedChecked = true;
    }
  }

  onSeePermissions(): void {}

}
