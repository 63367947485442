import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class RectifyService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  rectify(payload: {idClient: string, email: string, rectification: any[]}): any {
    const body = {email: payload.email, rectification: payload.rectification};
    return this.http.post(this.baseUrl + `/clients/${payload.idClient}/documents/sendRectifications`, body);
  }

  getVerifiedRectifications(payload: {idClient: string}): any {
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/documents/getRectifications`);
  }

  addVerifiedRectification(payload: {idClient: string, email: string, rectification: any[]}): any {
    const body = {email: payload.email, rectification: payload.rectification};
    return this.http.post(this.baseUrl + `/clients/${payload.idClient}/documents/addRectification`, body);
  }

  getDocumentImage(payload: {idClient: string, tag: string}): any {
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/documents/${payload.tag}`);
  }

  removeTemporaryRectification(payload: {idClient: string, email: string, rectification: any[]}): any {
    const body = {
      email: payload.email,
      rectification: payload.rectification
    };
    console.log('web service called with payload: ', body);
    return this.http.post(this.baseUrl + `/clients/${payload.idClient}/documents/deleteRectification`, body);
  }


  getCheckedDocuments(idClient: string): any {
    console.log('ws get verified docs called with idClient ', idClient);
    return this.http.get(this.baseUrl + `/tempo/rectificationDocument/${idClient}`);
  }

  addCheckedDocuments(payload: {idClient: string, documents: any[]}): any {
    console.log('****** ADD CHECKED WS CALLED WITH PAYLOAD: ', payload);
    return this.http.post(this.baseUrl + `/tempo/rectificationDocument`, payload);
  }

  updateCheckedDocuments(payload: {idClient: string, documents: any[]}): any {
    console.log('**** UPDATE CHECKED WS CALLED WITH PAYLOAD : ', payload);
    return this.http.post(this.baseUrl + `/tempo/rectificationDocument/${payload.idClient}`, payload);
  }

/*
  removeCheckedDocuments(payload: {idClient: string, documents: string[]}): any {
    return this.http.post(this.baseUrl + `/tempo/rectificationDocument/${payload.idClient}`, payload);
  }
*/

  clearCheckedDocuments(idClient: string): any {
    return this.http.delete(this.baseUrl + `/tempo/rectificationDocument/${idClient}`);
  }

  sendToKYC(idClient: string): any {
    return this.http.post(this.baseUrl + `/clients/${idClient}/documents/sendToKYC`, {});
  }

  validateClientDocuments(idClient: string): any {
    return this.http.post(this.baseUrl + `/clients/${idClient}/documents/validateClientDocuments`, {});
  }
}
