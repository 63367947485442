import { CommissionService } from './../../services/commissions/commission.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromCommission from './commission.action';

@Injectable()
export class CommissionEffects {

    constructor(private actions$: Actions, private commissionService: CommissionService) {}

    @Effect()
    loadMccAttempted$ = this.actions$.pipe(
        ofType<fromCommission.LoadCommissionTypesAttemptedAction>(fromCommission.LOAD_COMMISSION_TYPES_ATTEMPTED),
        switchMap((data: fromCommission.LoadCommissionTypesAttemptedAction) => this.commissionService.getCommissionTypes()
            .pipe(
                map(resp => new fromCommission.LoadCommissionTypesSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCommission.LoadCommissionTypesFailedAction(err)); })
            )
        )
    );

    @Effect()
    addPackageAttempted$ = this.actions$.pipe(
        ofType<fromCommission.AddCommissionTypeAttemptedAction>(fromCommission.ADD_COMMISSION_TYPE_ATTEMPTED),
        switchMap((data: fromCommission.AddCommissionTypeAttemptedAction) => this.commissionService.add(data.payload)
            .pipe(
                map(resp => new fromCommission.AddCommissionTypeSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCommission.AddCommissionTypeFailedAction(err)); })
            )
        )
    );

    @Effect()
    updatePackageAttempted$ = this.actions$.pipe(
        ofType<fromCommission.UpdateCommissionTypeAttemptedAction>(fromCommission.UPDATE_COMMISSION_TYPE_ATTEMPTED),
        switchMap((data: fromCommission.UpdateCommissionTypeAttemptedAction) =>
        this.commissionService.update(data.idCommissionType, data.payload)
            .pipe(
                map(resp => new fromCommission.UpdateCommissionTypeSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCommission.UpdateCommissionTypeFailedAction(err)); })
            )
        )
    );

    @Effect()
    deletePackageAttempted$ = this.actions$.pipe(
        ofType<fromCommission.DeleteCommissionTypeAttemptedAction>(fromCommission.DELETE_COMMISSION_TYPE_ATTEMPTED),
        switchMap((data: fromCommission.DeleteCommissionTypeAttemptedAction) => this.commissionService.delete(data.idCommissionType)
            .pipe(
                map(resp => new fromCommission.DeleteCommissionTypeSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCommission.DeleteCommissionTypeFailedAction(err)); })
            )
        )
    );
}