import { CardsComponent } from './home/cards/cards.component';
import { UsersComponent } from './home/users/users.component';
import { PotsComponent } from './home/pots/pots.component';
import { SafeDepositComponent } from './home/safe-deposit/safe-deposit.component';
import { AuthGuardService } from './core/services/auth/auth-guard.service';
import { UserInfosComponent } from './home/user-details/user-infos/user-infos.component';
import { UserVisualDocumentsComponent } from './home/user-details/user-visual-documents/user-visual-documents.component';
import { UserDocumentsInfoComponent } from './home/user-details/user-documents-info/user-documents-info.component';
import { UserDetailsComponent } from './home/user-details/user-details.component';
import { SigninComponent } from './signin/signin.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PhysicalComponent } from './home/cards/physical/physical.component';
import { VirtualComponent } from './home/cards/virtual/virtual.component';
import { ChildComponent } from './home/cards/child/child.component';
import { TransactionsComponent } from './home/transactions/transactions.component';
import { AccountComponent } from './home/account/account.component';
import { OppositionsComponent } from './home/oppositions/oppositions.component';
import { BeneficiairesComponent } from './home/beneficiaires/beneficiaires.component';
import { LogsComponent } from './home/logs/logs.component';
import { ContactComponent } from './home/contact/contact.component';
import { ApplicationComponent } from './application/application.component';
import { NewsComponent } from './application/news/news.component';
import { MccComponent } from './application/mcc/mcc.component';
import { OffersComponent } from './application/offers/offers.component';
import { NewOfferComponent } from './application/offers/new-offer/new-offer.component';
import { UpdateOfferComponent } from './application/offers/update-offer/update-offer.component';
import { AddNewsComponent } from './application/news/add-news/add-news.component';
import { UpdateNewsComponent } from './application/news/update-news/update-news.component';
import { StatementComponent } from './home/oppositions/statement/statement.component';
import { PackagesComponent } from './application/packages/packages.component';
import { OperationComponent } from './application/packages/operation/operation.component';
import { CommissionComponent } from './application/packages/commission/commission.component';
import { PackageComponent } from './application/packages/package/package.component';
import { TimeLanguageCurrencyComponent } from './application/time-language-currency/time-language-currency.component';
import { DateTimeComponent } from './application/time-language-currency/date-time/date-time.component';
import { LanguageComponent } from './application/time-language-currency/language/language.component';
import { CurrencyComponent } from './application/time-language-currency/currency/currency.component';
import { SettingsComponent } from './settings/settings.component';
import { BackofficeUsersComponent } from './settings/backoffice-users/backoffice-users.component';
import { BackofficeUserDetailsComponent } from './settings/backoffice-user-details/backoffice-user-details.component';
import { ProfilComponent } from './settings/profil/profil.component';
import { PackageClientAssignmentComponent } from './application/packages/package-client-assignment/package-client-assignment.component';
import { PasswordResetComponent } from './signin/password-reset/password-reset.component';
import { PasswordForgotComponent } from './signin/password-forgot/password-forgot.component';
import { PackagesClientComponent } from './home/packages-client/packages-client.component';
import { IssuesComponent } from './issues/issues.component';
import { CreateOperationComponent } from './application/packages/operation/create-operation/create-operation.component';
import { NewPackageComponent } from './application/packages/package/new-package/new-package.component';
import { ChildDocumentsViewerComponent } from './home/user-details/child-documents-viewer/child-documents-viewer.component';
import { RegistrationDocumentsComponent } from './home/user-details/registration-documents/registration-documents.component';
import { AddressDocumentsComponent } from './home/user-details/address-documents/address-documents.component';
import { ChildDocumentsComponent } from './home/user-details/child-documents/child-documents.component';
import { DepositDetailsComponent } from './home/safe-deposit/deposit-details/deposit-details.component';
import { DepositHistoryComponent } from './home/safe-deposit/deposit-history/deposit-history.component';
import { AddressDocumentViewerComponent } from './home/user-details/address-documents/address-document-viewer/address-document-viewer.component';
import { DepositWalletDetailsComponent } from './home/safe-deposit/deposit-wallet-details/deposit-wallet-details.component';


const routes: Routes = [
  {path: 'signin', component: SigninComponent},
  {path: 'forgot-password', component: PasswordForgotComponent},
  {path: 'changepasswordfinal', component: PasswordResetComponent},
  {path: '', component: HomeComponent, canActivate: [AuthGuardService], children: [
    {path: '', redirectTo: '/clients', pathMatch: 'full'},
    {path: 'clients', component: UsersComponent},
    {path: 'clients/details', component: UserDetailsComponent, children: [
      {path: '', redirectTo: 'info', pathMatch: 'full'},
      {path: 'info', component: UserInfosComponent},
      {path: 'documents', component: UserDocumentsInfoComponent, children: [
        {path: '', redirectTo: 'registration-documents', pathMatch: 'full'},
        {path: 'registration-documents', component: RegistrationDocumentsComponent},
        {path: 'child-documents', component: ChildDocumentsComponent},
        {path: 'child-documents/viewer', component: ChildDocumentsViewerComponent},
        {path: 'address-documents', component: AddressDocumentsComponent}
      ]},
      {path: 'documents/informations', component: UserVisualDocumentsComponent},
      {path: 'documents/address-documents/viewer', component: AddressDocumentViewerComponent},
      {path: 'deposits', component: SafeDepositComponent},
      {path: 'deposits/deposit-details', component: DepositDetailsComponent},
      {path: 'deposits/deposit-history/:idSafeDeposit', component: DepositHistoryComponent},
      {path: 'deposits/wallet-details/:walletId', component: DepositWalletDetailsComponent},
      {path: 'pots', component: PotsComponent},
      {path: 'cards', children: [
        {path: '', component: CardsComponent},
        {path: 'physical', component: PhysicalComponent},
        {path: 'virtual', component: VirtualComponent},
        {path: 'childs', component: ChildComponent}
      ]},
      {path: 'transactions', component: TransactionsComponent},
      {path: 'account', component: AccountComponent},
      {path: 'oppositions', children: [
        {path: '', component: OppositionsComponent},
        {path: 'statement', component: StatementComponent}
      ]},
      {path: 'beneficiaries', component: BeneficiairesComponent},
      {path: 'packages', component: PackagesClientComponent},
      {path: 'logs', component: LogsComponent},
      {path: 'contact', component: ContactComponent}
    ]},
    {path: 'application', component: ApplicationComponent, children: [
      {path: '', redirectTo: 'news', pathMatch: 'full'},
      {path: 'news', component: NewsComponent},
      {path: 'news/add', component: AddNewsComponent},
      {path: 'news/update', component: UpdateNewsComponent},
      {path: 'offers', component: OffersComponent},
      {path: 'offers/new', component: NewOfferComponent},
      {path: 'offers/update', component: UpdateOfferComponent},
      {path: 'packages', component: PackagesComponent, children: [
        {path: '', redirectTo: 'packages', pathMatch: 'full'},
        {path: 'packages', component: PackageComponent},
        {path: 'packages/add-package', component: NewPackageComponent},
        {path: 'operations', component: OperationComponent},
        {path: 'operations/create', component: CreateOperationComponent},
        {path: 'commissions', component: CommissionComponent},
        {path: 'client-assignment', component: PackageClientAssignmentComponent}
      ]},
      {path: 'mcc', component: MccComponent},
      {path: 'settings', component: TimeLanguageCurrencyComponent, children: [
        {path: '', redirectTo: 'date-time', pathMatch: 'full'},
        {path: 'date-time', component: DateTimeComponent},
        {path: 'language', component: LanguageComponent},
        {path: 'currency', component: CurrencyComponent}
      ]}
    ]},
    {path: 'settings', component: SettingsComponent, children: [
      {path: '', redirectTo: 'profile', pathMatch: 'full'},
      {path: 'profile', component: ProfilComponent},
      {path: 'users', children: [
        {path: '', component: BackofficeUsersComponent},
        {path: 'details', component: BackofficeUserDetailsComponent}
      ]}
    ]},
    {path: 'issues', component: IssuesComponent}
  ]},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
