import { User } from './../../models/user.model';
import { UsersAction, UsersActionTypes } from './users.action';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface UsersState {
    page: number;
    size: number;
    cle: string;
    status: [];
    data: any;
    loading: boolean;
    error: Error;
  }

export const initialState: UsersState = {
    page: 0,
    size: 5,
    cle: '',
    status: undefined,
    data: undefined,
    loading: false,
    error: undefined
  };

export function reducer(state: UsersState = initialState, action: UsersAction): any {
    switch (action.type) {
      case UsersActionTypes.GET_USERS_ATTEMPTED:
        return {
          ...state,
          page: action.payload.page,
          size: action.payload.size,
          cle: action.payload.cle,
          status: action.payload.status,
          loading: true,
        };
      case UsersActionTypes.GET_USERS_SUCCESS:
        return {
          ...state,
          data: action.payload.value,
          loading: false,
        };
      case UsersActionTypes.GET_USERS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  }

export const getUsersState = createFeatureSelector<UsersState>('users');

export const getUsersPage = createSelector(getUsersState, (state: UsersState) => state.page);
export const getUsersSize = createSelector(getUsersState, (state: UsersState) => state.size);
export const getUsersStatus = createSelector(getUsersState, (state: UsersState) => state.status);
export const getUsers = createSelector(getUsersState, (state: UsersState) => state.data);
export const getUsersLoading = createSelector(getUsersState, (state: UsersState) => state.loading);
export const getUsersError = createSelector(getUsersState, (state: UsersState) => state.error);
