import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromLog from './log.actions';

export interface LogState {
    data: any;
    loaded: boolean;
    loading: boolean;
    error: string;
}

export const initialState: LogState = {
    data: undefined,
    loaded: false,
    loading: false,
    error: undefined,
};

export function reducer(state: LogState = initialState, action: fromLog.LogActions): any {
    switch (action.type) {
        case fromLog.LOAD_CLIENT_ACTIVITIES_ATTEMPTED:
            return {
                ...state,
                loading: true
            };
        case fromLog.LOAD_CLIENT_ACTIVITIES_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload.value
            };
        case fromLog.LOAD_CLIENT_ACTIVITIES_FAILED:
            return {
                ...state,
                loaded : false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getLogState = createFeatureSelector<LogState>('logs');

export const getClientActivities = createSelector(getLogState, (state: LogState) => state.data);
