import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OperationService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getOperations(): any {
    return this.http.get(this.baseUrl + `/application/operation`);
  }

  add(payload: any): any {
    return this.http.post(this.baseUrl + `/application/operation`, payload);
  }

  update(idOperation: string, payload: any): any {
    return this.http.put(this.baseUrl + `/application/operation/${idOperation}`, payload);
  }

  delete(idOpration: string): any {
    return this.http.delete(this.baseUrl + `/application/operation/${idOpration}`);
  }
}
