import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadChildDocumentsAttemptedAction,
  ValidateChildDocumentsAttemptedAction, RectifyChildDocumentsAttemptedAction } from '../../../core/store/document/document.action';
import { getChildDocuments, getChildDocumentsPhoto, getchildDocumentValidationResponse, getchildDocumentRectificationResponse } from '../../../core/store/document/document.reducer';
import { filter } from 'rxjs/operators';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { UsersService } from '../../../core/services/users/users.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-child-documents-viewer',
  templateUrl: './child-documents-viewer.component.html',
  styleUrls: ['./child-documents-viewer.component.scss']
})
export class ChildDocumentsViewerComponent implements OnInit, OnDestroy {

  loading = false;

  isPhotoRectificationChecked: boolean;

  userStatusName: string;

  photo: any;

  documentName: string;

  rectifButtonDisabled = false;

  private selectedChildSubscription$;

  private photoSubscription$;

  private childDocValidationSubscription$;

  private childDocRectifSubscription$;

  private childDocsSubscription$;

  selectedChild: any;

  childDocument: any;

  requestText: string;

  requestSuccessful = false;

  requestFailed = false;

  client: any;

  private dialogSubscription$;


  constructor(private store: Store<AppState>, private usersService: UsersService, private dialog: MatDialog,
              private translate: TranslateService, private router: Router) { }

  ngOnDestroy(): void {
    if (this.selectedChildSubscription$) {
      this.selectedChildSubscription$.unsubscribe();
    }
    if (this.childDocsSubscription$) {
      this.childDocsSubscription$.unsubscribe();
    }
    if (this.photoSubscription$) {
      this.photoSubscription$.unsubscribe();
    }
    if (this.childDocValidationSubscription$) {
      this.childDocValidationSubscription$.unsubscribe();
    }
    if (this.childDocRectifSubscription$) {
      this.childDocRectifSubscription$.unsubscribe();
    }
    if (this.dialogSubscription$) {
      this.dialogSubscription$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.client = client;
    const child = JSON.parse(localStorage.getItem('selectedChild'));
    this.selectedChild = child;
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    this.childDocValidationSubscription$ = this.store.select(getchildDocumentValidationResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(res => {
        console.log('child doc validation response is : ', res);
        this.loading = false;
        this.router.navigate(['/clients/details/documents/child-documents']);
    });

    this.childDocRectifSubscription$ = this.store.select(getchildDocumentRectificationResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(res => {
        console.log('child doc rectification response is : ', res);
        this.loading = false;
        this.router.navigate(['/clients/details/documents/child-documents']);
    });

    console.log(' 1- SELECTED CHILD IS: ', child);
    this.store.dispatch(new LoadChildDocumentsAttemptedAction(client.id, child.id));

    this.childDocsSubscription$ = this.store.select(getChildDocuments).pipe(filter(resp => resp !== undefined)).subscribe(documents => {
      this.childDocument = documents[0].files[0];
      console.log(' 2- CHILD FILE IS : ', this.childDocument);
      console.log('- client id: ', client.id);
      console.log('- child id: ', this.selectedChild.id);
      console.log('- tag: ', this.childDocument.name);
      this.usersService.getChildDocumentsPhoto(client.id, child.id, 'CHILD_IDENTITY').subscribe(res => {
        this.createImageFromBlob(res);
        console.log(res);
        this.loading = false;
      }
      );
    });
  }

  validateChildDocument(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translate.instant('CONFIRMATION'),
        message: this.translate.instant('CHILD_DOCUMENT_VALIDATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'CHILD_DOCUMENT_VALIDATION'
      }
    });
    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        const client = JSON.parse(localStorage.getItem('client'));
        this.store.dispatch(new ValidateChildDocumentsAttemptedAction(client.id, this.selectedChild.id));
      }
    });
  }

  rectifyChildDocument(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translate.instant('CONFIRMATION'),
        message: this.translate.instant('CHILD_DOCUMENT_RECTIFICATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'CHILD_DOCUMENT_RECTIFICATION'
      }
    });

    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        const client = JSON.parse(localStorage.getItem('client'));
        this.store.dispatch(new RectifyChildDocumentsAttemptedAction(client.id, this.selectedChild.id));
      }
    });
  }

  private createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.photo = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

}
