import { Pot } from './../../models/pot';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromPots from './pot.actions';

export interface PotsState {
    loaded: boolean;
    loading: boolean;
    data: Pot[];
    selected: Pot;
    error: string;
}

export const initialState: PotsState = {
    loaded: false,
    loading: false,
    data: undefined,
    selected: undefined,
    error: undefined,
};


export function reducer(state: PotsState = initialState, action: fromPots.PotsActions): any {
    switch (action.type) {
        case fromPots.LOAD_POTS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined
            };
        case fromPots.LOAD_POTS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value.pots
            };
        case fromPots.LOAD_POTS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getPotsState = createFeatureSelector < PotsState > ('pots');

export const getPots = createSelector(getPotsState, (state: PotsState) => state.data);

