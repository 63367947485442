<div class="packages-container">
    <div class="card">
        <div class="card-header">
            <div class="bold float-left">{{'PACKAGES.PACKAGES' | translate}}</div>
            <button type="button" class="btn btn-sm btn-success float-right" *ngIf="haveReadPermission" [disabled]="!haveWritePermission"  (click)="onNewPackage()">
                <i class="fa fa-plus" aria-hidden="true"></i> {{'BUTTONS.ADD' | translate}}
            </button>
        </div>

        <div class="card-body">
            <div class="packages-content" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                <div class="spinner" *ngIf="loading">
                    <mat-spinner [diameter]="40"></mat-spinner>
                </div>

                <div class="packages" *ngIf="!loading">
                    <div class="none-block" *ngIf="packagesLength <= 0" >
                        <img src="../../../../assets/images/vide/vide.png"
                            srcset="../../../../assets/images/vide/vide@2x.png 2x, 
                            ../../../../assets/images/vide/vide@3x.png 3x"
                            class="none" alt="none">
                        <p class="text none-text">{{'PACKAGES.NONE' | translate}}</p>
                    </div>

                    <ngx-simplebar class="package-content" *ngIf="packagesLength > 0">
                        <div class="package-tab">
                            <table mat-table [dataSource]="packages" class="mat-elevation-z8 tab-package">
                                <!-- Label Column -->
                                <ng-container matColumnDef="label" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.LABEL' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span  class="text tab">{{element.label}}</span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="description" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.DESCRIPTION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span  class="text tab">{{element.description}}</span>
                                    </td>
                                </ng-container>

                                <!-- Operations Column -->
                                <ng-container matColumnDef="operations" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.OPERATIONS' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.operations.length > 0">
                                            <span class="text tab link" *ngIf="element"  [matMenuTriggerFor]="operationsMenu" #menuTrigger="matMenuTrigger">
                                                <i class="fa fa-tasks" aria-hidden="true" width="16" height="16"></i>
                                                {{element.operations.length}}
                                            </span>

                                            <mat-menu #operationsMenu="matMenu" xPosition="after">
                                                <div class="operation-mat-menu" (click)="$event.stopPropagation()">
                                                    <div class="operation-header">
                                                      <span class="operation-title">
                                                      <i class="fa fa-tasks" aria-hidden="true" width="16" height="16"></i>
                                                        {{'PACKAGES.OPERATIONS' | translate}}
                                                      </span>
                                                    </div>
                                                    
                                                    <div class="operation-container">
                                                      <ul>
                                                          <li>
                                                              <ngx-simplebar class="operation-content">
                                                                  <div class="operation-tab">
                                                                    <table mat-table [dataSource]="element.operations" class="mat-elevation-z8 tab-operation">
                                                                      <ng-container matColumnDef="operationName" >
                                                                          <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.NAME' | translate}}</th>
                                                                          <td mat-cell *matCellDef="let element">
                                                                              <span  class="text operation-text">{{element.name}}</span>
                                                                          </td>
                                                                      </ng-container>
                                                                      
                                                                      <ng-container matColumnDef="operationCommissionType" >
                                                                          <th mat-header-cell *matHeaderCellDef> {{'PACKAGES.COMMISSION' | translate}}</th>
                                                                          <td mat-cell *matCellDef="let element">
                                                                              <span  class="text operation-text">{{element.commissionType.value}} {{element.commissionType.type === 'PERCENT' ? '%' : 'EUR'}}</span>
                                                                          </td>
                                                                      </ng-container>
      
                                                                      <ng-container matColumnDef="operationDescription" >
                                                                          <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.DESCRIPTION' | translate}}</th>
                                                                          <td mat-cell *matCellDef="let element">
                                                                              <span  class="text operation-text">{{element.description}} </span>
                                                                          </td>
                                                                      </ng-container>

                                                                      
      
                                                                      <tr mat-header-row *matHeaderRowDef="operationsDisplayedColumns"></tr>
                                                                      <tr mat-row *matRowDef="let row; columns: operationsDisplayedColumns;"  class="operation-table-row"></tr>
                                                                    </table>
                                                                  </div>
                                                              </ngx-simplebar>
                                                          </li>
                                                      </ul>
                                                    </div>
                                    
                                                  </div>
                                            </mat-menu>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <!-- Actions Column -->
                                <ng-container matColumnDef="actions" >
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <!--
                                        <button type="button" class="btn btn-sm btn-white mx-1" (click)="onAffectClients(element)">
                                            <i class="fa fa-plus" aria-hidden="true" style="font-size: 10px;"></i> <span style="font-size: 12px;">&nbsp; {{'BUTTONS.AFFECT_CLIENTS' | translate}} </span>
                                        </button>
                                        -->
                                        <span class="muted-link" style="font-size: 12px;" title="{{'BUTTONS.DELETE' | translate}}" (click)="onDeletePackage(element)">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="packagesDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: packagesDisplayedColumns;"  class="table-row"></tr>

                            </table>
                        </div>
                    </ngx-simplebar>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #NO_READ_PERMISSION>
    <div class="packages-content">
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../assets/images/vide/vide@2x.png"
                    srcset="../../../assets/images/vide/vide@2x.png 2x,
                    ../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
            </div>     
        </div>
    </div>
</ng-template>
