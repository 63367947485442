import { NgProgressModule } from '@ngx-progressbar/core';
import { MatInputModule } from '@angular/material/input';
import { AuthEffects } from './../core/store/auth/auth.effects';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MatButtonModule } from '@angular/material/button';
import { SigninComponent } from './signin.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppRoutingModule } from './../app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthReducer } from '../core/store/auth/auth.reducer';
import { TranslateModule } from '@ngx-translate/core';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { PasswordForgotComponent } from './password-forgot/password-forgot.component';
import {MatIconModule} from '@angular/material/icon';



@NgModule({
  declarations: [
    SigninComponent,
    PasswordResetComponent,
    PasswordForgotComponent
  ],
  imports: [
    StoreModule.forFeature('auth', AuthReducer),
    EffectsModule.forFeature([AuthEffects]),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatCardModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    NgProgressModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatIconModule
  ]
})
export class SigninModule { }
