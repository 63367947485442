<div class="content">
    <div class="full-height bg-white">
        <div class="container" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
            <form [formGroup]="newsForm" id="news-form" aria-label="Créer une nouvelle annonnce" style="padding-bottom: 18px;">
                <div class="Subhead mb-5">
                    <h1 class="Subhead-heading">{{'APPLICATION.NEWS.UPDATE_SUBHEADER' | translate}}
                        <span class="ml-3 f1" [ngClass]="{'muted-link': haveWritePermission, 'disabled': !haveWritePermission}" *ngIf="action === 'show'" (click)="enableUpdate()">
                            <i class="fa fa-edit" width="16" height="16"></i>
                            {{'BUTTONS.UPDATE' | translate}}
                        </span>
                        <span *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center float-right ml-4">
                            <p class="error">{{'ACCESS_CONTROL.NO_NEWS_WRITE_PERMISSION' | translate}}</p> &nbsp;
                            <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                        </span>
                    </h1>
                    <div class="Subhead-description">{{'APPLICATION.NEWS.NEWS_CREATED_ON' | translate}} : {{this.selectedNews.crationDate | date:'short'}}</div>
                </div>

                <div class="clearfix">
                    <dl class="form-group float-left mt-1">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="news-title">{{'APPLICATION.NEWS.TITLE' | translate}} <span class="error">*</span></label>
                        </dt>
                        <dd>
                            <input [ngClass]="{'border-error' : this.newsForm.controls['title'].hasError('required')}" class="form-control long" formControlName="title" autocomplete="off" type="text" id="offer-title">
                        </dd>
                    </dl>
    
                    <span class="float-left pt-4 mx-2 f2 mt-1">/</span>

                    <dl class="form-group float-left mt-1">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="offer-expiration">{{'APPLICATION.NEWS.RANK' | translate}} <span class="error">*</span></label>
                        </dt>
                        <dd>
                            <select [ngClass]="{'border-error' : this.newsForm.controls['rang'].hasError('required')}" class="form-control short" formControlName="rang" id="rang">
                                <option *ngFor="let rank of rankList" value={{rank}}>{{rank}}</option>
                            </select>
                        </dd>
                    </dl>
                </div>

                <dl class="form-group">
                    <dt class="input-label">
                        <label aria-autocomplete="off" for="news-description">{{'APPLICATION.NEWS.DESCRIPTION' | translate}} <span class="error">*</span></label>
                    </dt>
                    <dd>
                        <div class="card">
                            <div class="card-body">
                                <textarea #textarea [ngClass]="{'border-error': this.newsForm.controls['description'].hasError('required')}" class="form-control" formControlName="description" style="min-width: 100%; border: none;" rows="5"></textarea>
                            </div>
                        </div>
                    </dd>
                </dl>

                <div>
                    <hr aria-hidden="true">
                    <h3 class="h5">{{'APPLICATION.NEWS.TYPE_CHOICE' | translate}} <span class="error">*</span></h3>
                    <!-- <p class="text-gray">{{'APPLICATION.NEWS.ONE_TYPE' | translate}}</p> -->
                    <div class="form-checkbox">
                        <label (click)="onTagCheck('LINKED')">
                            <input type="radio" value="LINKED" formControlName="tag">
                            {{'APPLICATION.NEWS.LINKED' | translate}}
                        </label>
                        <!-- <span class="note">{{'APPLICATION.NEWS.LINKED_DESC' | translate}}</span> -->
                        <span class="tabpanel" *ngIf="isLinkedChecked">
                            <div class="input-group">
                                <select class="form-control link-object-select" formControlName="linkedObject">
                                    <option value="POT">{{'APPLICATION.NEWS.POT' | translate}}</option>
                                    <option value="DEPOSIT">{{'APPLICATION.NEWS.DEPOSIT' | translate}}</option>
                                </select>
                            </div>
                            <p class="mt-2 mb-0 f6 text-gray">{{'APPLICATION.NEWS.CHOOSE_LINK' | translate}}</p>
                        </span>
                    </div>

                    <div class="form-checkbox">
                        <label (click)="onTagCheck('NOTLINKED')">
                            <input type="radio" value="NOTLINKED" formControlName="tag">
                            {{'APPLICATION.NEWS.NOT_LINKED' | translate}}
                        </label>
                        <!-- <span class="note">{{'APPLICATION.NEWS.NOTLINKED_DESC' | translate}}</span> -->
                    </div>
                </div>

                <hr aria-hidden="true">

                <button *ngIf="action === 'update' && isUpdateClicked"  type="button" class="btn btn-sm btn-success first-in-line" [disabled]="!newsForm.valid" (click)="onSubmit()">Modifier l'annonce</button>
                <button *ngIf="isUpdateClicked"  type="button" class="btn btn-sm btn-white first-in-line ml-6" (click)="cancelUpdate()">{{'BUTTONS.CANCEL_MODIFICATION' | translate}}</button>
            </form>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div class="container">
        <div class="Subhead mb-5">
            <h1 class="Subhead-heading">{{'APPLICATION.NEWS.UPDATE_SUBHEADER' | translate}}</h1>
        </div>
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../assets/images/vide/vide@2x.png"
                    srcset="../../../assets/images/vide/vide@2x.png 2x,
                    ../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
            </div>     
        </div>
        <div *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center mt-1">
            <p class="error">{{'ACCESS_CONTROL.NO_ACCESS' | translate}}</p> &nbsp;
            <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
        </div>
    </div>
</ng-template>