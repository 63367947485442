import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromTransactions from './transaction.actions';
import { TransactionsService } from '../../services/transactions/transactions.service';


@Injectable()
export class TransactionEffects {

    constructor(private actions$: Actions, private transactionsService: TransactionsService) {}

    @Effect()
    loadTransactionsAttempted$ = this.actions$.pipe(
        ofType(fromTransactions.LOAD_TRANSACTIONS_ATTEMTPED),
        switchMap((data: fromTransactions.LoadTransactionsAttemptedAction) => {
            return this.transactionsService.getTransactions(data.payload)
                .pipe(
                    map(resp => new fromTransactions.LoadTransactionsSuccessfulAction(resp)),
                    catchError(error => of(new fromTransactions.LoadTransactionsFailedAction(error)))
                );
        })
    );

    @Effect()
    loadTransactionFilteredAttempted$ = this.actions$.pipe(
        ofType(fromTransactions.LOAD_TRANSACTIONS_FILTERED_ATTEMTPED),
        switchMap((data: fromTransactions.LoadTransactionsFilteredAttemptedAction) => {
            return this.transactionsService.getTransactionsFiltered(data.idClient, data.payload)
                .pipe(
                    map(resp => new fromTransactions.LoadTransactionsFilteredSuccessfulAction(resp)),
                    catchError(error => of(new fromTransactions.LoadTransactionsFilteredFailedAction(error)))
                );
        })
    );

    @Effect()
    loadTransactionsByCategoryAttempted$ = this.actions$.pipe(
        ofType(fromTransactions.LOAD_TRANSACTIONS_BY_CATEGORY_ATTEMPTED),
        switchMap((data: fromTransactions.LoadTransactionsByCategoryAttemptedAction) => {
            return this.transactionsService.getTransactions(data.payload)
                .pipe(
                    map(resp => new fromTransactions.LoadTransactionsByCategorySuccessfulAction(resp)),
                    catchError(error => of(new fromTransactions.LoadTransactionsByCategoryFailedAction(error)))
                );
        })
    );

}
