import { AccountEffects } from './../core/store/account/account.effects';
import { TransactionEffects } from './../core/store/transactions/transaction.effects';
import { CardsEffects } from './../core/store/cards/cards.effects';
import { PotEffects } from './../core/store/pot/pot.effects';
import { DepositEffects } from './../core/store/deposit/deposit.effects';
import { DocumentsEffects } from './../core/store/document/document.effects';
import { RectifyService } from './../core/services/rectification/rectify.service';
import { RectificationEffects } from './../core/store/rectification/rectification.effects';
import { UserDetailsEffects } from './../core/store/user-details/user-details.effects';
import { UsersService } from './../core/services/users/users.service';
import { UsersEffects } from './../core/store/users/users.effects';
import { EffectsModule } from '@ngrx/effects';
import { MatDividerModule } from '@angular/material/divider';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HomeComponent } from './home.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './users/users.component';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UserInfosComponent } from './user-details/user-infos/user-infos.component';
import { UserDocumentsInfoComponent } from './user-details/user-documents-info/user-documents-info.component';
import { UserVisualDocumentsComponent } from './user-details/user-visual-documents/user-visual-documents.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SafeDepositComponent } from './safe-deposit/safe-deposit.component';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { DepositDetailsComponent } from './safe-deposit/deposit-details/deposit-details.component';
import { DepositHistoryComponent } from './safe-deposit/deposit-history/deposit-history.component';
import { ApplicationPipesModule } from '../core/pipes/application-pipes.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PotsComponent } from './pots/pots.component';
import { PotsDescriptionComponent } from './pots/pots-description/pots-description.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { SharedModule } from './../shared/shared.module';
import { CardsComponent } from './cards/cards.component';
import { PhysicalComponent } from './cards/physical/physical.component';
import { VirtualComponent } from './cards/virtual/virtual.component';
import { ChildComponent } from './cards/child/child.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { AccountComponent } from './account/account.component';
import { OppositionsComponent } from './oppositions/oppositions.component';
import { BeneficiairesComponent } from './beneficiaires/beneficiaires.component';
import { LogsComponent } from './logs/logs.component';
import { LogEffects } from '../core/store/log/log.effects';
import { ContactComponent } from './contact/contact.component';
import { ContactEffects } from '../core/store/contact/contact.effects';
import { BeneficiariesEffects } from '../core/store/beneficiaries/beneficiaries.effects';
import { OppositionEffects } from '../core/store/opposition/oppositions.effects';

import { NO_ERRORS_SCHEMA} from '@angular/core';
import { StatementComponent } from './oppositions/statement/statement.component';
import { PackagesClientComponent } from './packages-client/packages-client.component';
import { ClientTicketsComponent } from './contact/client-tickets/client-tickets.component';
import { NewTicketDialogComponent } from './contact/new-ticket-dialog/new-ticket-dialog.component';
import { TicketDialogComponent } from './contact/client-tickets/ticket-dialog/ticket-dialog.component';
import { ChildDocumentsViewerComponent } from './user-details/child-documents-viewer/child-documents-viewer.component';
import { RegistrationDocumentsComponent } from './user-details/registration-documents/registration-documents.component';
import { AddressDocumentsComponent } from './user-details/address-documents/address-documents.component';
import { ChildDocumentsComponent } from './user-details/child-documents/child-documents.component';
import { AddressDocumentViewerComponent } from './user-details/address-documents/address-document-viewer/address-document-viewer.component';
import { DepositWalletDetailsComponent } from './safe-deposit/deposit-wallet-details/deposit-wallet-details.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LabelComponent } from './components/label/label.component';

@NgModule({
  declarations: [
    HomeComponent,
    UsersComponent,
    UserInfosComponent,
    UserDocumentsInfoComponent,
    UserVisualDocumentsComponent,
    UserDetailsComponent,
    SafeDepositComponent,
    DepositDetailsComponent,
    DepositHistoryComponent,
    PotsComponent,
    PotsDescriptionComponent,
    CardsComponent,
    PhysicalComponent,
    VirtualComponent,
    ChildComponent,
    TransactionsComponent,
    AccountComponent,
    OppositionsComponent,
    BeneficiairesComponent,
    LogsComponent,
    ContactComponent,
    StatementComponent,
    PackagesClientComponent,
    ClientTicketsComponent,
    NewTicketDialogComponent,
    TicketDialogComponent,
    ChildDocumentsViewerComponent,
    RegistrationDocumentsComponent,
    AddressDocumentsComponent,
    ChildDocumentsComponent,
    AddressDocumentViewerComponent,
    DepositWalletDetailsComponent,
    LabelComponent
  ],
  imports: [
    EffectsModule.forFeature([
      UsersEffects,
      UserDetailsEffects,
      DocumentsEffects,
      RectificationEffects,
      DepositEffects,
      PotEffects,
      CardsEffects,
      TransactionEffects,
      AccountEffects,
      LogEffects,
      ContactEffects,
      BeneficiariesEffects,
      OppositionEffects
    ]),
    CommonModule,
    SharedModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDividerModule,
    MatMenuModule,
    MatCheckboxModule,
    RouterModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatTreeModule,
    MatTableModule,
    MatChipsModule,
    NgxDatatableModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    SimplebarAngularModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
    ApplicationPipesModule,
    MatTooltipModule,
    MatSidenavModule,
    MatListModule,
    InfiniteScrollModule,
    MatPaginatorModule
  ],
  providers: [
    UsersService,
    RectifyService,
    MatDatepickerModule
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class HomeModule { }
