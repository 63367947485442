import { ClearStateAction } from './../core/store/app/app.action';
import { Router } from '@angular/router';
import { AppState } from './../core/store/index';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isExpanded = false;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
  }

  onLogout(): void {
    this.store.dispatch(new ClearStateAction());
    localStorage.clear();
    this.router.navigate(['/signin']);
  }

}
