<div class="wrapper">
  <div class="spinner" *ngIf="loading" >
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>

  <div class="childs" *ngIf="!loading" >
      <div class="none-block" *ngIf="childsLength === 0">
          <div class="none-inner-block">
            <img src="../../../../assets/images/vide/vide@2x.png"
                 srcset="../../../../assets/images/vide/vide@2x.png 2x,
                 ../../../../assets/images/vide/vide@3x.png 3x"
                 class="none" alt="None">
            <p class="text none-text">{{'HOME.CHILD_DOCUMENTS.NO_DOCUMENTS' | translate}}</p>
          </div>
      </div>

      <div class="child-content" *ngIf="childsLength > 0">
          <div class="child-tab">
            <table mat-table [dataSource]="childs" class="mat-elevation-z8 tab-child">
              <ng-container matColumnDef="firstname_child">
                <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.FIRSTNAME' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <div class="title-cell">
                    <span class="text child-title">{{element.firstname}}</span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="lastname_child">
                <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.LASTNAME' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <div class="title-cell">
                    <span class="text child-title">{{element.lastname}}</span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="email_child">
                <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.EMAIL' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <div class="title-cell">
                    <span class="text child-title">{{element.email}}</span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="birthday_child">
                <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.BIRTHDAY' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <div class="title-cell">
                    <span class="text child-title">{{element.birthday}}</span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="citoyen_child">
                <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.CITOYEN' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <div class="title-cell">
                    <span class="text child-title">{{element.citoyen}}</span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="userStatusName_child">
                <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.STATUS' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <div class="title-cell">
                    <!-- <span class="text child-title bold">{{element.userStatusName}}</span> -->
                    <app-label [value]="element.userStatusName" [color]="element.userStatusName === 'KYC_PENDING' ? 'orange' : 'grey'"></app-label>
                  </div>
                </td>
              </ng-container>
        
              <tr mat-header-row *matHeaderRowDef="childsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: childsDisplayedColumns;"
                  class="childs-table-row" 
                  (click)="(row.userStatusName === 'ENABLED' || row.userStatusName === 'KYC_PENDING') && onChildClicked(row)"
                  [ngClass]="{
                    'row-disabled' : (row.userStatusName !== 'ENABLED' && row.userStatusName !== 'KYC_PENDING')}"></tr>
            </table>
          </div>
        </div>
  </div>

</div>