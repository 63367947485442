import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/index';
import { DatePipe } from '@angular/common';
import { LoadBackofficeUsersAttempted, SelectBackofficeUserAction, DeleteBackofficeUserAttemptedAction } from '../../core/store/user-managements/user-management.action';
import { getBackofficeUsers, getBackofficeDeleteResponse } from '../../core/store/user-managements/user-management-reducer';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-backoffice-users',
  templateUrl: './backoffice-users.component.html',
  styleUrls: ['./backoffice-users.component.scss'],
  providers: [DatePipe]
})
export class BackofficeUsersComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  users: any;

  messages = {
    emptyMessage: 'Aucune donnée trouvée',
    totalMessage: 'totale',
    selectedMessage: 'sélectionné'
  };

  ColumnMode = ColumnMode;

  selected = [];

  SelectionType = SelectionType;

  rowClass = 'row';

  private usersSubscription;

  currentTab = 0;

  loading = false;

  private dialogSubscription$;

  private deleteSubscription$;

  constructor(private store: Store<AppState>, private _datePipe: DatePipe, private router: Router, private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'READ_USER') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'WRITE_USER') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.store.dispatch(new LoadBackofficeUsersAttempted());
    this.usersSubscription = this.store.select(getBackofficeUsers).pipe(filter(resp => resp !== undefined))
      .subscribe(data => {
        console.log(data);
        this.users = data;
        this.loading = false;
    });
    this.deleteSubscription$ = this.store.select(getBackofficeDeleteResponse).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      if (data.message !== 'DELETED') {
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            buttons: 'serverError'
          }
        });
      } else {
        this.store.dispatch(new LoadBackofficeUsersAttempted());
      }
    });
  }

  ngOnDestroy(): void {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }
  }

  userDetails({ selected }): void {
    this.store.dispatch(new SelectBackofficeUserAction(selected[0]));
    this.router.navigate(['/settings/users/details']);
  }

  datePipe(): any {
    return {transform: (value) => this._datePipe.transform(value, 'M/d/yy, h:mm a')};
  }

  updateUser(user: any): void {
    this.store.dispatch(new SelectBackofficeUserAction(user));
    this.router.navigate(['/settings/users/details']);
  }

  deleteUser(user: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30%',
      data: {
        title: this.translate.instant('CONFIRMATION_CHOICE'),
        message: this.translate.instant('DELETE_BO_USER_CONFIRMATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'DELETE_BO_USER_CONFIRMATION',
        value: user
      }
    });
    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DeleteBackofficeUserAttemptedAction(user.id));
      }
    });
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }

}
