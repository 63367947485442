import { NewsService } from './../../services/news/news.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromNews from './news.actions';

@Injectable()
export class NewsEffects {

    constructor(private actions$: Actions, private newsService: NewsService) {}

    @Effect()
    loadNewsAttempted$ = this.actions$.pipe(
        ofType<fromNews.LoadNewsAttemptedAction>(fromNews.LOAD_NEWS_ATTEMPTED),
        switchMap((data: fromNews.LoadNewsAttemptedAction) => this.newsService.getNews()
            .pipe(
                map(resp => new fromNews.LoadNewsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromNews.LoadNewsFailedAction(err)); })
            )
        )
    );

    @Effect()
    addNewsAttempted$ = this.actions$.pipe(
        ofType<fromNews.AddNewsAttemptedAction>(fromNews.ADD_NEWS_ATTEMPTED),
        switchMap((data: fromNews.AddNewsAttemptedAction) => this.newsService.add(data.payload)
            .pipe(
                map(resp => new fromNews.AddNewsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromNews.AddNewsFailedAction(err)); })
            )
        )
    );

    @Effect()
    updateNewsAttempted$ = this.actions$.pipe(
        ofType<fromNews.UpdateNewsAttemptedAction>(fromNews.UPDATE_NEWS_ATTEMPTED),
        switchMap((data: fromNews.UpdateNewsAttemptedAction) => this.newsService.update(data.idNews, data.payload)
            .pipe(
                map(resp => new fromNews.UpdateNewsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromNews.UpdateNewsFailedAction(err)); })
            )
        )
    );

    @Effect()
    deleteNewsAttempted$ = this.actions$.pipe(
        ofType<fromNews.DeleteNewsAttemptedAction>(fromNews.DELETE_NEWS_ATTEMPTED),
        switchMap((data: fromNews.DeleteNewsAttemptedAction) => this.newsService.delete(data.idNews)
            .pipe(
                map(resp => new fromNews.DeleteNewsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of(new fromNews.DeleteNewsFailedAction(err)); })
            )
        )
    );
}