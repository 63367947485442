<div class="content" fxLayoutGap="18px" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
    <div fxFlex="60%" class="full-height border bg-white">
        <div class="text childs-title">{{'CARDS.MY_CHILDS' | translate}}</div>

        <div *ngIf="loading" class="spinner">
            <mat-spinner [diameter]="45"></mat-spinner>
        </div>

        <div class="childs" *ngIf="!loading" >
            <div class="none-block" *ngIf="childsLength === 0">
                <img src="../../../../assets/images/vide/vide@2x.png"
                     srcset="../../../../assets/images/vide/vide@2x.png 2x,
                     ../../../../assets/images/vide/vide@3x.png 3x"
                     class="none" alt="None">
                <p class="text none-text">{{ 'CARDS.CHILDS_NONE' | translate }} </p>
            </div>

            <div class="child-content" *ngIf="childsLength > 0">
                <div class="child-tab">
                  <table mat-table [dataSource]="childs$" class="mat-elevation-z8 tab-child">
                    <ng-container matColumnDef="firstname_child">
                      <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.FIRSTNAME' | translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="title-cell">
                          <span class="text child-title">{{element.firstname}}</span>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="lastname_child">
                      <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.LASTNAME' | translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="title-cell">
                          <span class="text child-title">{{element.lastname}}</span>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="email_child">
                      <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.EMAIL' | translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="title-cell">
                          <span class="text child-title">{{element.email}}</span>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="birthday_child">
                      <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.BIRTHDAY' | translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="title-cell">
                          <span class="text child-title">{{element.birthday}}</span>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="citoyen_child">
                      <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.CITOYEN' | translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="title-cell">
                          <span class="text child-title">{{element.citoyen}}</span>
                        </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="status_child">
                      <th mat-header-cell *matHeaderCellDef class="header-cell">{{'HOME.USER_INFOS.STATUS' | translate}}</th>
                      <td mat-cell *matCellDef="let element">
                        <div class="title-cell">
                          <!-- <span class="text child-title">{{element.userStatusName}}</span> -->
                          <app-label [value]="element.userStatusName"></app-label>
                        </div>
                      </td>
                    </ng-container>
        
                    <tr mat-header-row *matHeaderRowDef="childsDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: childsDisplayedColumns;" 
                    class="childs-table-row"
                    (click)="row.userStatusName === 'ENABLED' && onSeeMoreClicked(row)" 
                    [ngStyle]="row.userStatusName !== 'ENABLED' && {'background-color': 'lightgrey', 'cursor': 'default'}"></tr>
                  </table>
                </div>
              </div>
        </div>
    </div>

    <div fxFlex="40%" class="full-height border bg-white" *ngIf="screen === 1" >
      <div class="none-block" *ngIf="!selectedChild">
        <img src="../../../../assets/images/vide/vide@2x.png"
             srcset="../../../../assets/images/vide/vide@2x.png 2x,
                     ../../../../assets/images/vide/vide@3x.png 3x"
             class="none-selected" alt="None">
        <p class="text none-selected-text">{{ 'CARDS.SELECT' | translate }} </p>
      </div>

      <div *ngIf="cardsLoading" class="spinner">
        <mat-spinner [diameter]="45"></mat-spinner>
      </div>

      <div class="childCards-header" *ngIf="!cardsLoading && selectedChild">
        <span class="text childCards-title" >{{ 'CARDS.MY_CHILD_CARDS' | translate }}</span>
        <span class="childCards-actions">
          <button class="primary-btn dropdown-toggle" [matMenuTriggerFor]="walletMenu" #menuTrigger="matMenuTrigger" (click)="onOpenWallet()" 
          [disabled]="cardsLoading  || !selectedChild" [ngClass]="{'disabled' : cardsLoading  || !selectedChild}"> 
            <img src="../../../../assets/images/ic-wallet/ic-wallet.png"
              srcset="../../../../assets/images/ic-wallet/ic-wallet@2x.png 2x,
                      ../../../../assets/images/ic-wallet/ic-wallet@3x.png 3x"
              class="ic-wallet" />
            {{'CARDS.WALLET' | translate}}
          </button>
          <mat-menu #walletMenu="matMenu" xPosition="before">
            <div class="wallet-mat-menu" (click)="$event.stopPropagation()">
              <div class="wallet-header">
                <span class="wallet-title">
                  <img src="../../../../assets/images/ic-wallet/ic-wallet.png"
                    srcset="../../../../assets/images/ic-wallet/ic-wallet@2x.png 2x,
                    ../../../../assets/images/ic-wallet/ic-wallet@3x.png 3x" 
                    class="ic-wallet"/>
                  {{'CARDS.WALLET' | translate}}
                </span>
                <span class="wallet-icon">
                  <button mat-icon-button (click)="onRefresh()">
                    <img src="../../../../assets/images/ic-refresh/ic-refresh.png"
                      srcset="../../../../assets/images/ic-refresh/ic-refresh@2x.png 2x,
                            ../../../../assets/images/ic-refresh/ic-refresh@3x.png 3x"
                      class="ic-wallet" />
                  </button>
                </span>
              </div>

              <div *ngIf="walletIsLoading" class="spinner">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
              
              <div class="wallet-container" *ngIf="!walletIsLoading && wallet" >
                <ul>
                  <li><span class="highlight">{{'CARDS.CURRENT_BALANCE' | translate}}: </span><span class="highlight">{{wallet.currentBalance ? wallet.currentBalance + ' ' + (wallet.currency | currencySymbol) : '-'}}</span></li>
                  <li><span class="highlight">{{'CARDS.AUTHORIZATIONS' | translate}}: </span><span class="highlight">{{wallet.authorizations ? wallet.authorizations + ' ' + (wallet.currency | currencySymbol) : '-'}}</span></li>
                  <li><span class="highlight">{{'CARDS.AUTHORIZED_BALANCE' | translate}}: </span><span class="highlight">{{wallet.authorizedBalance ? wallet.authorizedBalance + ' ' + (wallet.currency | currencySymbol) : '-'}}</span></li>
                  <li><span class="highlight">{{'CARDS.CALCULATION_DATE' | translate}}: </span><span class="highlight">{{wallet.calculationDate ? wallet.calculationDate : '-'}}</span>
                  </li>
                </ul>
              </div>

            </div>
          </mat-menu>
        </span>
      </div>

      <div class="childCards" *ngIf="!cardsLoading" >
          <ngx-simplebar class="childCards-content" *ngIf="childCardsLength > 0">
            <div class="childCards-tab">
              <table mat-table [dataSource]="childCards$" class="mat-elevation-z8 tab-childCards">
    
                <ng-container matColumnDef="card_child">
                  <td mat-cell *matCellDef="let element">
                    <img src="../../../../assets/images/home/cards/physical/physical-card.png"
                         srcset="../../../../assets/images/home/cards/physical/physical-card@2x.png 2x,
                         ../../../../assets/images/home/cards/physical/physical-card@3x.png 3x"
                         class="card-preview" alt="Card preview">
                  </td>
                </ng-container>

                <ng-container matColumnDef="embossedName_child">
                  <td mat-cell *matCellDef="let element">
                    <div class="title-cell">
                      <span class="text childCard-amount">{{element.embossedName === null ? '-' : element.embossedName}}</span>
                    </div>
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="status_child">
                  <td mat-cell *matCellDef="let element">
                    <div class="status-title-cell">
                      <span class="text childCard-title">{{'CARDS.STATUS' | translate}}: {{element.cardStatus}}</span>
                      <span class="text childCard-amount">{{element.cardDeliveringStatus}}</span>
                    </div>
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="expiration_child">
                  <td mat-cell *matCellDef="let element">
                    <div class="title-cell">
                      <span class="text childCard-title">{{element.expiryDate === null ? '-' : element.expiryDate}}</span>
                    </div>
                  </td>
                </ng-container>
    
                <ng-container matColumnDef="more_child">
                  <td mat-cell *matCellDef="let element">
                    <img src="../../../../assets/images/home/more@2x.png"
                         srcset="../../../../assets/images/home/more@2x.png 2x,
                                ../../../../assets/images/home/more@3x.png 3x"
                         class="more" alt="See more" (click)="onChildCardDetails(element)">
                  </td>
                </ng-container>
    
                <tr mat-row *matRowDef="let row; columns: childCardsDisplayedColumns;"  class="table-row"></tr>
              </table>
            </div>
          </ngx-simplebar>
        </div>
    </div>

    <div fxFlex="40%" class="min-full-height border bg-white" *ngIf="screen === 2" >
      <div class="spinner" *ngIf="cardDetailsLoading">
        <mat-spinner [diameter]="25"></mat-spinner>
      </div>
        
      <div class="flexed-column-center" *ngIf="!cardDetailsLoading">
        <div class="header">
          <h3 class="text header-title" *ngIf="isCardSelected" >{{'CARDS.DETAILS' | translate}}</h3>
        </div>
  
        <div class="none-block" *ngIf="!isCardSelected" >
          <img src="../../../../assets/images/vide/vide@2x.png"
              srcset="../../../../assets/images/vide/vide@2x.png 2x,
                  ../../../../assets/images/vide/vide@3x.png 3x"
              class="none-selected" alt="None">
          <p class="text none-selected-text">{{ 'CARDS.SELECT' | translate }} </p>
        </div>
  
        <div class="details" *ngIf="isCardSelected" >
            <p><span class="text details-title">{{'CARDS.CARD_TAG' | translate}} : </span> <span class="text details-text">{{card.cardTag ? card.cardTag : '-'}}</span> </p>
            <p><span class="text details-title">{{'CARDS.CARD_DELIVERING_STATUS' | translate}} : </span><span class="text details-text">{{card.cardDeliveringStatus ? card.cardDeliveringStatus : '-'}}</span> </p>
            <p><span class="text details-title">{{'CARDS.CARD_STATUS' | translate}} : </span><span class="text details-text">{{card.cardStatus ? card.cardStatus : '-'}}</span></p>
            <p><span class="text details-title">{{'CARDS.EMBOSSED_NAME' | translate}} : </span><span class="text details-text">{{card.embossedName ? card.embossedName : '-'}}</span> </p>
            <mat-divider style="margin-top: 8px; margin-bottom: 8px;"></mat-divider>
            <p><span class="text details-title">{{'CARDS.EXPIRY_DATE' | translate}} : </span><span class="text details-text">{{card.expiryDate ? card.expiryDate : '-'}}</span> </p>
            <p><span class="text details-title">{{'CARDS.PERMS_GROUP' | translate}} : </span><span class="text details-text">{{card.permsGroup ? card.permsGroup : '-'}}</span> </p>
            <p><span class="text details-title">{{'CARDS.CARD_PRINT' | translate}} : </span><span class="text details-text">{{card.cardPrint ? card.cardPrint : '-'}}</span></p>
            <p><span class="text details-title">{{'CARDS.CREATED_BY' | translate}} : </span> <span class="text details-text">{{card.createdBy ? card.createdBy : '-'}}</span></p>
            <p><span class="text details-title">{{'CARDS.CREATED_DATE' | translate}} : </span> <span class="text details-text">{{card.createdDate ? card.createdDate : '-'}}</span></p>
            <p><span class="text details-title">{{'CARDS.MODIFIED_BY' | translate}} : </span> <span class="text details-text">{{card.modifiedBy ? card.modifiedBy : '-'}}</span></p>
            <p><span class="text details-title">{{'CARDS.MODIFIED_DATE' | translate}} : </span> <span class="text details-text">{{card.modifiedDate ? card.modifiedDate : '-'}}</span></p>
            <p><span class="text details-title">{{'CARDS.PAYMENT_LIMIT' | translate}} : </span> <span class="text details-text">{{card.paymentLimit ? card.paymentLimit : '-'}}</span></p>
            <p><span class="text details-title">{{'CARDS.WITHDRAWAL_LIMIT' | translate}} : </span> <span class="text details-text">{{card.withdrawalLimit ? card.withdrawalLimit : '-'}}</span></p>
            <mat-divider style="margin-top: 8px; margin-bottom: 8px;"></mat-divider>
            <p><span class="text details-title">{{'CARDS.LAST_MODIFICATION_DATE' | translate}} : </span><span class="text details-text">{{card.lastModificationDate ? card.lastModificationDate : '-'}}</span> </p>
            <p><span class="text details-title">{{'CARDS.CREATION_DATE' | translate}} : </span><span class="text details-text">{{card.creationDate ? card.creationDate : '-'}}</span> </p>
            <p><span class="text details-title">{{'CARDS.OPPOSED_DATE' | translate}} : </span><span class="text details-text">{{card.opposedDate ? (card.opposedDate | dateTime) : '-'}}</span> </p>
        </div> 
      </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
  <div class="none-block-content">
    <div class="Full-height bg-white">
      <div class="childCards-header">
        <span class="text childCards-title">{{'CARDS_MY_CHILD_CARDS' | translate}}</span>
      </div>
      <div class="none-block">
        <div class="none-inner-block">
            <img src="../../../../assets/images/vide/vide@2x.png"
            srcset="../../../../assets/images/vide/vide@2x.png 2x,
            ../../../../assets/images/vide/vide@3x.png 3x"
            class="none" alt="None">
          <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
      </div>
    </div>
  </div>
</ng-template>