<div class="Users-container">
    <div class="Users-wrapper">
        <h1 class="Users-header">{{'SETTINGS.USERS.TITLE' | translate}}</h1>
        <hr/>
        <div *ngIf="haveReadPermission; else NO_READ_PERMISSION">
            <div class="spinner" *ngIf="loading">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
            <ng-container *ngIf="!loading">
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center">
                    <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                </div>
            </ng-container>
            <ngx-datatable *ngIf="!loading"  class="bootstrap d-flex align-center justify-center"
                            [rows]="users" 
                            [columnMode]="ColumnMode.force" 
                            [messages]="messages"
                            [headerHeight]="50" 
                            [footerHeight]="0"
                            rowHeight="auto" 
                            [selected]="selected" 
                            [selectionType]="SelectionType.single"
                            (select)="userDetails($event)">

                <ngx-datatable-column name="{{'SETTINGS.USERS.NAME' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.fullname}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{'SETTINGS.USERS.EMAIL' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.email}}
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{'SETTINGS.USERS.ACTIVATED' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <ng-container *ngIf="row.activated; else DEACTIVATED">{{'SETTINGS.USERS.ACTIVATED' | translate}}</ng-container>
                        <ng-template #DEACTIVATED>{{'SETTINGS.USERS.DEACTIVATED' | translate}}</ng-template>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{'SETTINGS.USERS.PRIVILEGES' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <ng-container>
                            {{row.authorities[0]}} <span *ngIf="row.authorities.length > 1"> , ... </span>
                        </ng-container>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="{{'SETTINGS.USERS.ACTIONS' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <span class="muted-link mx-2" [class.disabled]="!haveWritePermission ? true : null" 
                            (click)="$event.preventDefault(); $event.stopPropagation(); updateUser(row)" title="{{'BUTTONS.UPDATE' | translate}}" >
                            <i class="fa fa-edit" width="16" height="16"></i>
                        </span>
                        <span class="muted-link mx-2" [class.disabled]="!haveWritePermission ? true : null" 
                            (click)="$event.preventDefault(); $event.stopPropagation(); deleteUser(row)" title="{{'BUTTONS.DELETE' | translate}}">
                            <i class="fa fa-trash" width="16" height="16"></i>
                        </span>
                    </ng-template>
                </ngx-datatable-column>

            </ngx-datatable>
        </div>
    </div>
</div>
<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
                srcset="../../../assets/images/vide/vide@2x.png 2x,
                ../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
    </div>
</ng-template>