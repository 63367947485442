import { PotsService } from './../../services/pots/pots.service';
import { MatDialog } from '@angular/material/dialog';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromPots from './pot.actions';

import { LoadPotsAttemptedAction, LoadPotsSuccessfulAction, LoadPotsFailedAction } from './pot.actions';

@Injectable()
export class PotEffects {

    constructor(private actions$: Actions, private potsService: PotsService, public dialog: MatDialog) {}

    @Effect()
    loadPotsAttempted$ = this.actions$.pipe(
        ofType<LoadPotsAttemptedAction>(fromPots.LOAD_POTS_ATTEMPTED),
        switchMap((data: LoadPotsAttemptedAction) => this.potsService.getPots(data.payload)
            .pipe(
                map(resp => new LoadPotsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new LoadPotsFailedAction(err)); })
            )
        )
    );
}