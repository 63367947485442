<div class="close-button" xmlns="http://www/w3.org/1999/html">
    <img src="../../../assets/images/ic-close/close-btn.png"
        srcset="../../../assets/images/ic-close/close-btn@2x.png 2x,
                ../../../assets/images/ic-close/close-btn@3x.png 3x"
        class="close-btn"
        alt="close error"
        (click)="close(false)" />
</div>

<h1 mat-dialog-title><span>{{selectedTicket.numero}}</span> / {{selectedTicket.title}}</h1>
<div mat-dialog-content>
    <div class="dialog-wrapper">
        <div class="Subhead mb-5">
            <div class="Subhead-description">{{'ISSUES.OPENED_BY' | translate}} : <i class="underlined"> {{selectedTicket.createdByName}} </i> {{'ISSUES.IN' | translate}} <i class="underlined">{{selectedTicket.openDate | date:'medium'}}</i></div>
        </div>

        <div class="w-70">
            <div class="Formgroup">
                <div class="subtitle d-inline-block mr-0">{{'ISSUES.STATE' | translate}}</div>
                <button type="button" [ngClass]="{'bg-blue': selectedTicket.status === 'OPEN', 'bg-crimson': selectedTicket.status === 'BLOCKED', 'bg-green': selectedTicket.status === 'CLOSED'}" class="btn btn-sm border-gray dropdown-toggle" [matMenuTriggerFor]="statusMenu">
                    {{'ISSUES.STATUS_' + selectedTicket.status | translate}}
                </button>
                <mat-menu #statusMenu="matMenu" xPosition="after">
                    <div class="status-mat-menu" (click)="$event.stopPropagation()">
                        <ul>
                            <ng-container  *ngFor="let state of status">
                                <li *ngIf="state !== selectedTicket.status">
                                    <label [ngClass]="{'blue': state === 'OPEN', 'crimson': state === 'BLOCKED', 'green': state === 'CLOSED'}">{{'ISSUES.STATUS_'+ state | translate}}</label>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </mat-menu>
            </div>

            <div class="Formgroup">
                <div class="subtitle d-inline-block mr-0">{{'ISSUES.DESCRIPTION' | translate}}</div>
                <p class="note">{{selectedTicket.content}}</p>
            </div>

            <div class="Formgroup">
                <div class="subtitle d-inline-block mr-0">{{'ISSUES.TYPE' | translate}}</div>
                <div class="note d-inline-block">{{selectedTicket.subject}}</div>
            </div>

            <div class="Formgroup">
                <div class="subtitle d-inline-block mr-0">{{'ISSUES.RESPONSIBLE' | translate}}</div>
                <p class="note d-inline-block">{{selectedTicket.createdByName}}</p>
            </div>

            <div class="Formgroup">
                <div class="subtitle d-inline-block mr-0">{{'ISSUES.CLIENT' | translate}}</div>
                <p class="note d-inline-block">{{selectedTicket.clientFirstName}} {{selectedTicket.clientLastName}}</p>
            </div>

            <div class="Formgroup">
                <div class="subtitle d-inline-block mr-0">{{'ISSUES.OPENING_DATE' | translate}}</div>
                <p class="note d-inline-block">{{selectedTicket.openDate | date: 'medium'}}</p>
            </div>
        </div>
    </div>
</div>
