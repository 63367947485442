import { DialogComponent } from './../../shared/dialog/dialog.component';
import { catchError, map, retry } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LogoutAction } from './../store/auth/auth.action';
import { AuthService } from './../services/auth/auth.service';
import { Actions } from '@ngrx/effects';
import { AppState } from './../store/index';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MatDialog } from '@angular/material/dialog';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private authService: AuthService;

  constructor(private injector: Injector,
              public jwtHelper: JwtHelperService,
              private router: Router,
              private store: Store<AppState>,
              private actions$: Actions)
  {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    const token: string = this.authService.getToken();
    if (token) {
      if (!this.jwtHelper.isTokenExpired(token)) {
        request = request.clone({
          headers : new HttpHeaders({
            Authorization: `Bearer ${token}`
          })
        });
      } else {
        console.log('TOKEN EXPIRED, LOGIN OUT...');
        this.store.dispatch(new LogoutAction());
      }
    }
    return next.handle(request);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, public dialog: MatDialog, private translate: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          /*
          if (error instanceof HttpErrorResponse && error.status === 401) {
            const dialogRef = this.dialog.open(DialogComponent, {
              width: '30%',
              data: {message: this.translate.instant('DIALOGS.UNAUTHORIZED'), buttons: 'serverError'}
            });
            dialogRef.afterClosed().subscribe(result => {
              // this.router.navigate(['/login']);
            });
          } else
          */
          if (error instanceof HttpErrorResponse && error.status === 403) {
            const dialogRef = this.dialog.open(DialogComponent, {
              width: '30%',
              data: {message: this.translate.instant('DIALOGS.FORBIDDEN'), buttons: 'serverError'}
            });
            dialogRef.afterClosed().subscribe(result => {
              // this.router.navigate(['/login']);
            });
          } else if (error instanceof HttpErrorResponse && (error.status === 404 ||
            (error.status.toString().charAt(0) === '5' && error.error.message !== 'UsernameNotFoundException'))) {
            const dialogRef = this.dialog.open(DialogComponent, {
              width: '30%',
              data: {message: this.translate.instant('DIALOGS.UNAVAILABLE'), buttons: 'serverError', value: error.error.message}
            });
            dialogRef.afterClosed().subscribe(result => {
              // this.router.navigate(['/login']);
            });
          }
          return throwError(error);
        }));
  }
}

