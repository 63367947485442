import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DepositService {

  baseUrl = environment.baseUrl;

  constructor(private httpClient: HttpClient) { }

  getDeposits(payload: {idClient: string}, page: any): any {
    console.log('CALLING GET DEPOSITS WITH PAYLOAD : ', payload.idClient, page);
    const params = new HttpParams().set('page', page.pageIndex).set('size', page.pageSize);
    return this.httpClient.get(this.baseUrl + `/clients/${payload.idClient}/safedeposits`, {params});
  }

  getHistory(idClient: string, idSafeDeposit: string): any {
    return this.httpClient.get(this.baseUrl + `/clients/${idClient}/safedeposits/safeDepositHistory/${idSafeDeposit}`);
  }
}
