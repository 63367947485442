import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCommission from './commission.action';

export interface CommissionState {
    data: any[];
    addResponse: any;
    deleteResponse: any;
    updateResponse: any;
    selectedCommission: any;
    action: string;
    loading: boolean;
    error: string;
}

export const initialState: CommissionState = {
    data: undefined,
    addResponse: undefined,
    deleteResponse: undefined,
    updateResponse: undefined,
    selectedCommission: undefined,
    action: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: CommissionState = initialState, action: fromCommission.CommissionTypes): any {
    switch (action.type) {
        case fromCommission.LOAD_COMMISSION_TYPES_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined
            };
        case fromCommission.LOAD_COMMISSION_TYPES_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value,
            };
        case fromCommission.LOAD_COMMISSIONS_TYPES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fromCommission.ADD_COMMISSION_TYPE_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromCommission.ADD_COMMISSION_TYPE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                addResponse: action.payload,
            };
        case fromCommission.ADD_COMMISSION_TYPE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fromCommission.UPDATE_COMMISSION_TYPE_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromCommission.UPDATE_COMMISSION_TYPE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                updateResponse: action.payload,
            };
        case fromCommission.UPDATE_COMMISSION_TYPE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fromCommission.DELETE_COMMISSION_TYPE_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromCommission.DELETE_COMMISSION_TYPE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                deleteResponse: action.payload,
            };
        case fromCommission.DELETE_COMMISSION_TYPE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getCommissionTypesState = createFeatureSelector<CommissionState>('commissions');

export const getCommissionTypes = createSelector(getCommissionTypesState, (state: CommissionState) => state.data);
export const getCommissionTypeAddResponse = createSelector(getCommissionTypesState, (state: CommissionState) => state.addResponse);
export const getCommissionTypeUpdateResponse = createSelector(getCommissionTypesState, (state: CommissionState) => state.updateResponse);
export const getCommissionTypeDeleteResponse = createSelector(getCommissionTypesState, (state: CommissionState) => state.deleteResponse);
