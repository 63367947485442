import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getActivities(payload: {idClient: string}): any {
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/activities`);
  }
}
