import { Action } from '@ngrx/store';

export enum RectificationActionTypes {
    RectifyAttempted = '[UserVisualDocuments] Rectify - Attempted',
    RectifyFailed = '[UserVisualDocuments] Rectify - Failed',
    RectifySuccessful = '[UserVisualDocuments] Rectify - Successful',

    ResetRectificationFields = '[UserVisualDocuments] Resets the isInvalidChecked value of fields to fasle',

    GET_VERIFIED_RECTIFICATIONS_ATTEMPTED = '[UserVisualDocuments] get fields verified by BO and staged to be rectified - attempted',
    GET_VERIFIED_RECTIFICATIONS_SUCCESSFUL = '[UserVisualDocuments] client verified rectifications loaded - success',
    GET_VERIFIED_RECTIFICATIONS_FAILED = '[UserVisualDocuments] client verified rectifications failed',

    ADD_VERIFIED_RECTIFICATION_ATTEMPTED = '[UserVisualDocuments] Add verified rectification that user did - attempted',
    ADD_VERIFIED_RECTIFICATION_SUCCESSFUL = '[UserVisualDocuments] Add verified rectification - succesful',
    ADD_VERIFIED_RECTIFICATION_FAILED = '[UserVisualDocuments] Add verified rectification failed',

    REMOVE_RECTIFICATION_ATTEMPTED = '[UserVisualDocuments] Removes rectified field or document from list of rectification - attempted',
    REMOVE_RECTIFICATION_SUCCESSFUL = '[UserVisualDocuments] Removes rectified field or document from list of rectifications - successful',
    REMOVE_RECTIFICATION_FAILED  = '[UserVisualDocuments] Removes rectified field or document from list of rectifications - failed',
/*
    GET_CHECKED_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] Get verified documents attempted',
    GET_CHECKED_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] Get verified documents successful',
    GET_CHECKED_DOCUMENTS_FAILED = '[UserVisualDocuments] Get verified documents failed',

    ADD_CHECKED_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] add document that admin have verified (to be rectified) to verified documents list (for graph representation)',
    ADD_CHECKED_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] add verified document to list verified documents list - successful',
    ADD_CHECKED_DOCUMENTS_FAILED = '[UserVisualDocuments] add verified document to list verified documents list - failed',

    UPDATE_CHECKED_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] updates and pushes a new document name to checked list - attempted',
    UPDATE_CHECKED_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] updates and pushes a new document name to checked list - sucessful',
    UPDATE_CHECKED_DOCUMENTS_FAILED = '[UserVisualDocuments] updates and pushes a new document name to checked list - failed',

    REMOVE_CHECKED_DOCUMENTS_ATTEMPTED = '[User Visual Document] Remove a document (file name) that does not have errors anymore - attempted',
    REMOVE_CHECKED_DOCUMENTS_SUCCESSFUL = '[User Visual Document] Remove a document (file name) that does not have errors - successful',
    REMOVE_CHECKED_DOCUMENTS_FAILED = '[User Visual Document] Remove a document that does not have errors - failed',

    CLEAR_CHECKED_DOCUMENTS_ATTEMPTED = '[User Visual Document] Clear all verified documents after rectifications is sent to client - attempted',
    CLEAR_CHECKED_DOCUMENTS_SUCCESSFUL = '[User Visual Document] Clear all verified documents after rectifications is sent to client - successful',
    CLEAR_CHECKED_DOCUMENTS_FAILED = '[User Visual Document] Clear all verified documents after rectifications is sent to client - failed',
*/
    SEND_TO_KYC_ATTEMPTED = '[User Visual Document] Send Documents to KYC - attepmted',
    SEND_TO_KYC_SUCCESSFUL = '[User Visual Document] Send Documents to KYC - successful (status to ENABLED if KYC confirmed files)',
    SEND_TO_KYC_FAILED = '[User Visual Document] Send Documents to KYC - failed',

    VALIDATE_CLIENT_DOCUMENTS_ATTEMPTED = '[User Visual Document] Validate client documents - attempted (status becomes IDENTITY_PENDING)',
    VALIDATE_CLIENT_DOCUMENTS_SUCCESSFUL = '[User Visual Document] Validate client documents - successful',
    VALIDATE_CLIENT_DOCUMENTS_FAILED = '[User Visual Document] Validate client documents - failed'
}

export class RectifyAttemptedAction implements Action {
    readonly type = RectificationActionTypes.RectifyAttempted;
    constructor(public payload: any) {}
}

export class RectifySuccessfulAction implements Action {
    readonly type = RectificationActionTypes.RectifySuccessful;
    constructor(public payload: any) {}
}

export class RectifyFailedAction implements Action {
    readonly type = RectificationActionTypes.RectifyFailed;
    constructor(public error: any) {}
}

export class ResetRectificationFieldsAction implements Action {
    readonly type = RectificationActionTypes.ResetRectificationFields;
}

export class GetVerifiedRectificationsAttemptedAction implements Action {
    readonly type = RectificationActionTypes.GET_VERIFIED_RECTIFICATIONS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class GetVerifiedRectificationsSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.GET_VERIFIED_RECTIFICATIONS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetVerifiedRectificationsFailedAction implements Action {
    readonly type = RectificationActionTypes.GET_VERIFIED_RECTIFICATIONS_FAILED;
    constructor(public error: any) {}
}

export class AddVerifiedRectificationAttemptedAction implements Action {
    readonly type = RectificationActionTypes.ADD_VERIFIED_RECTIFICATION_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddVerifiedRectificationSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.ADD_VERIFIED_RECTIFICATION_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddVerifiedRectificationFailedAction implements Action {
    readonly type = RectificationActionTypes.ADD_VERIFIED_RECTIFICATION_FAILED;
    constructor(public payload: any) {}
}

export class RemoveRectificationAttemptedAction implements Action {
    readonly type = RectificationActionTypes.REMOVE_RECTIFICATION_ATTEMPTED;
    constructor(public payload: any) {}
}

export class RemoveRectificationSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.REMOVE_RECTIFICATION_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class RemoveRectificationFailedAction implements Action {
    readonly type = RectificationActionTypes.REMOVE_RECTIFICATION_FAILED;
    constructor(public payload: any) {}
}
/*
export class GetCheckedDocumentsAttemptedAction implements Action {
    readonly type = RectificationActionTypes.GET_CHECKED_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class GetCheckedDocumentsSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.GET_CHECKED_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetCheckedDocumentsFailedAction implements Action {
    readonly type = RectificationActionTypes.GET_CHECKED_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class AddCheckedDocumentsAttemptedAction implements Action {
    readonly type = RectificationActionTypes.ADD_CHECKED_DOCUMENTS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddCheckedDocumentsSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.ADD_CHECKED_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddCheckedDocumentsFailedAction implements Action {
    readonly type = RectificationActionTypes.ADD_CHECKED_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class UpdateCheckedDocumentsAttemptedAction implements Action {
    readonly type = RectificationActionTypes.UPDATE_CHECKED_DOCUMENTS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class UpdateCheckedDocumentsSucessfulAction implements Action {
    readonly type = RectificationActionTypes.UPDATE_CHECKED_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateCheckedDocumentsFailedAction implements Action {
    readonly type = RectificationActionTypes.UPDATE_CHECKED_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class RemoveCheckedDocumentsAttemptedAction implements Action {
    readonly type = RectificationActionTypes.REMOVE_CHECKED_DOCUMENTS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class RemoveCheckedDocumentsSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.REMOVE_CHECKED_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class RemoveCheckedDocumentsFailedAction implements Action {
    readonly type = RectificationActionTypes.REMOVE_CHECKED_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class ClearCheckedDocumentsAttemptedAction implements Action {
    readonly type = RectificationActionTypes.CLEAR_CHECKED_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class ClearCheckedDocumentsSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.CLEAR_CHECKED_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class ClearCheckedDocumentFailedAction implements Action {
    readonly type = RectificationActionTypes.CLEAR_CHECKED_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}
*/
export class SendToKYCAttemptedAction implements Action {
    readonly type = RectificationActionTypes.SEND_TO_KYC_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class SendToKYCSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.SEND_TO_KYC_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class SendToKYCFailedAction implements Action {
    readonly type = RectificationActionTypes.SEND_TO_KYC_FAILED;
    constructor(public payload: any) {}
}

export class ValidateClientDocumentsAttemptedAction implements Action {
    readonly type = RectificationActionTypes.VALIDATE_CLIENT_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class ValidateClientDocumentsSuccessfulAction implements Action {
    readonly type = RectificationActionTypes.VALIDATE_CLIENT_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class ValidateClientDocumentsFailedAction implements Action {
    readonly type = RectificationActionTypes.VALIDATE_CLIENT_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}


export type RectificationAction =
    |RectifyAttemptedAction
    |RectifyFailedAction
    |RectifySuccessfulAction
    |ResetRectificationFieldsAction
    |GetVerifiedRectificationsAttemptedAction
    |GetVerifiedRectificationsSuccessfulAction
    |GetVerifiedRectificationsFailedAction
    |AddVerifiedRectificationAttemptedAction
    |AddVerifiedRectificationSuccessfulAction
    |AddVerifiedRectificationFailedAction
    |RemoveRectificationAttemptedAction
    |RemoveRectificationFailedAction
    |RemoveRectificationSuccessfulAction
    /*
    |GetCheckedDocumentsAttemptedAction
    |GetCheckedDocumentsFailedAction
    |GetCheckedDocumentsSuccessfulAction
    |AddCheckedDocumentsAttemptedAction
    |AddCheckedDocumentsFailedAction
    |AddCheckedDocumentsSuccessfulAction
    |UpdateCheckedDocumentsAttemptedAction
    |UpdateCheckedDocumentsFailedAction
    |UpdateCheckedDocumentsSucessfulAction
    |RemoveCheckedDocumentsAttemptedAction
    |RemoveCheckedDocumentsFailedAction
    |RemoveCheckedDocumentsSuccessfulAction
    |ClearCheckedDocumentsAttemptedAction
    |ClearCheckedDocumentsSuccessfulAction
    |ClearCheckedDocumentFailedAction
    */
    |SendToKYCAttemptedAction
    |SendToKYCFailedAction
    |SendToKYCSuccessfulAction
    |ValidateClientDocumentsAttemptedAction
    |ValidateClientDocumentsSuccessfulAction
    |ValidateClientDocumentsFailedAction
    ;
