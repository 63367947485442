import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { UpdateAppCurrencyAttemptedAction, GetAppCurrencyAttemptedAction } from '../../../core/store/app-config/app-config.actions';
import { getCurrencyUpdateResponse, getCurrency } from '../../../core/store/app-config/app-config.reducer';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

const CURRENCIES = ['EUR (€)', 'USD (US$)', 'GBP (£)', 'CAD (C$)'];

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  error = false;

  currencies: any[] = CURRENCIES;

  currencyForm: FormGroup = new FormGroup({
    currency: new FormControl(null)
  });

  isCurrencySubmitting: boolean;

  submittedSuccess = false;

  private currencyUpdateSubscription$;

  private selectedCurrencySubscription$;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_SETTINGS_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_SETTINGS_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.store.dispatch(new GetAppCurrencyAttemptedAction());
    this.selectedCurrencySubscription$ = this.store.select(getCurrency).pipe(filter(resp => resp !== undefined)).subscribe(resp => {
      console.log(resp);
      this.currencyForm.patchValue({currency: resp.message});
    });
    this.currencyUpdateSubscription$ = this.store.select(getCurrencyUpdateResponse).pipe(filter(resp => resp !== undefined))
    .subscribe(resp => {
      console.log(resp);
      this.isCurrencySubmitting = false;
      if (resp.code !== 200) {
        this.error = true;
      } else {
        this.submittedSuccess = true;
        setTimeout(() => {
          this.submittedSuccess = false;
        }, 2000);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.selectedCurrencySubscription$) {
      this.selectedCurrencySubscription$.unsubscribe();
    }
    if (this.currencyUpdateSubscription$) {
      this.currencyUpdateSubscription$.unsubscribe();
    }
  }

  submitCurrency(event: any): void {
    this.isCurrencySubmitting = true;
    this.submittedSuccess = false;
    console.log(event.target.value);
    this.store.dispatch(new UpdateAppCurrencyAttemptedAction(event.target.value));
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }

}
