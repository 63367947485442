import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { LoadOppositionsAttemptedAction, SetStatementAction } from '../../core/store/opposition/oppositions.actions';
import { getOppositions } from '../../core/store/opposition/oppositions.reducer';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-oppositions',
  templateUrl: './oppositions.component.html',
  styleUrls: ['./oppositions.component.scss']
})
export class OppositionsComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  private subscription;

  loading: boolean;

  oppositions: any[];

  oppositionslength: number;

  oppositionDisplayedColumns: string[] = ['category', 'type', 'creationDate', 'label', 'statements'];

  statementsLoading: boolean;

  statements: any[];

  statementDisplayedColumns: string[] = ['statementCategorieIcon', 'statementCategory', 'name', 'amount', 'status', 'actions'];

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_OPPOSED_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_OPPOSED_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadOppositionsAttemptedAction({idClient: client.id}));
    this.subscription = this.store.select(getOppositions).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      this.oppositions = data;
      this.oppositionslength = data.length;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openMenu(element: any): void {
    console.log(element.statements);
  }

  seeStatementDetails(element: any): void {
    this.store.dispatch(new SetStatementAction(element));
    this.router.navigate(['/clients/details/oppositions/statement']);
  }

  seeMore(element: any): void {
    console.log(element);
  }

}
