<div class="close-button" xmlns="http://www/w3.org/1999/html">
    <img src="../../../assets/images/ic-close/close-btn.png"
        srcset="../../../assets/images/ic-close/close-btn@2x.png 2x,
                ../../../assets/images/ic-close/close-btn@3x.png 3x"
        class="close-btn"
        alt="close error"
        (click)="close(false)" />
</div>

<!-- SENDING RECTIFICATION DIALOG-->
<h1 mat-dialog-title *ngIf="data.buttons === 'send-rectif'">{{data.title}}</h1>
<div mat-dialog-content *ngIf="data.buttons === 'send-rectif'">
    <p class="dialog-message">{{data.message}}</p>
    <p class="dialog-message">Client: {{data.value.email}}</p>
    <p class="dialog-message">Les champs à corriger sont : {{data.value.rectification}}</p>
</div>
<div mat-dialog-actions class="mat-dialog-actions" *ngIf="data.buttons === 'send-rectif'">
    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="onConfirmSubmitRectification()">{{data.confirmText}}</button>
    <button mat-raised-button  (click)="onCancel()">{{data.cancelText}}</button>
</div>

<!-- ADDING RECTIFICATON FIELD DIALOG-->
<h1 mat-dialog-title *ngIf="data.buttons === 'add-rectif'">{{data.title}}</h1>
<div mat-dialog-content *ngIf="data.buttons === 'add-rectif'">
    <p class="dialog-message">{{data.message}}</p>
    <p class="dialog-message">
        La liste courante des champs a réctifier est :
        <span *ngFor="let rectif of this.data.value; let last = last"> {{rectif}} <span *ngIf="!last">&nbsp;,&nbsp;</span></span>
        
    </p>
</div>
<div mat-dialog-actions class="mat-dialog-actions" *ngIf="data.buttons === 'add-rectif'">
    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="onConfirm()">{{data.confirmText}}</button>
</div>


<!-- ERROR rectif-->
<h2 class="erreur-title" *ngIf="data.buttons === 'error-rectif'">{{data.title}}</h2>
<div mat-dialog-content *ngIf="data.buttons === 'error-rectif'">
    <p class="{{data.align === 'left' ? 'erreur-txt left' : 'erreur-txt'}}">{{data.message}}</p>
</div>

<!-- OUI-NON DIALOG-->
<h2 class="erreur-title" *ngIf="data.buttons === 'oui-non'">{{data.title}}</h2>
<div mat-dialog-content *ngIf="data.buttons === 'oui-non'">
    <p class="{{data.align === 'left' ? 'erreur-txt left' : 'erreur-txt'}}">{{data.message}}</p>
</div>
<div mat-dialog-actions class="button-flexed" *ngIf="data.buttons === 'oui-non'">
    <button data-dismiss="modal" mat-raised-button color="primary" class="btn-one" (click)="onYesClicked()">{{'BUTTONS.YES' | translate}}</button>
    <button data-dismiss="modal" mat-raised-button class="btn-two" (click)="onNoClicked()">{{'BUTTONS.NO' | translate}}</button>
</div>

<!-- INFO DIALOG -->
<div mat-dialog-content  *ngIf="data.buttons === 'info'">
    <p class="{{data.align === 'left' ? 'erreur-txt left' : 'erreur-txt'}}">{{data.message}}</p>
</div>
<div mat-dialog-actions class="mat-dialog-actions" *ngIf="data.buttons === 'info'">
    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="onOk()">OK</button>
</div>


<!-- ERROR SERVEUR-->
<h2 class="erreur-title" *ngIf="data.buttons === 'ok'">{{data.title}}</h2>
<div mat-dialog-content  *ngIf="data.buttons === 'ok'">
    <p class="erreur-txt">{{data.message}}</p>
</div>
<div mat-dialog-actions class="mat-dialog-actions" *ngIf="data.buttons === 'ok'">
    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="onOk()">OK</button>
</div>


<!-- ERROR SERVEUR-->
<h2 class="erreur-title" *ngIf="data.buttons === 'serverError'">ERREUR</h2>
<div mat-dialog-content  *ngIf="data.buttons === 'serverError'">
    <!-- <p class="{{data.align === 'left' ? 'erreur-txt left' : 'erreur-txt'}}">{{'DIALOGS.SERVER_ERROR' | translate}}</p> -->
    <p class="{{data.align === 'left' ? 'erreur-txt left' : 'erreur-txt'}}">Message d'erreur : {{data.value}}</p>
</div>
<div mat-dialog-actions class="mat-dialog-actions" *ngIf="data.buttons === 'serverError'">
    <button mat-raised-button color="primary" style="margin-right: 10px;" (click)="onError()">OK</button>
</div>