<div class="commissions-container">
    <div class="Cards">
        <div class="card w-60">
            <div class="card-header bold">
                {{'PACKAGES.COMMISSION_HEADER' | translate}}
            </div>

            <div class="card-body">
                <div class="commissions-content" *ngIf="haveReadPermission; else NO_READ_PERMISSION" >
                    <div class="spinner" *ngIf="loading" >
                        <mat-spinner [diameter]="40"></mat-spinner>
                    </div>
    
                    <div class="commissions" *ngIf="!loading" >
                        <div class="none-block" *ngIf="commissiontypesLength <= 0" >
                            <img src="../../../../assets/images/vide/vide.png"
                                srcset="../../../../assets/images/vide/vide@2x.png 2x, 
                                ../../../../assets/images/vide/vide@3x.png 3x"
                                class="none" alt="none">
                            <p class="text none-text">{{'PACKAGES.COMMISSION_NONE' | translate}}</p>
                        </div>
    
                        <ngx-simplebar class="commission-content" *ngIf="commissiontypesLength > 0" >
                            <div class="commission-tab">
                                <table mat-table [dataSource]="commissionTypes" class="mat-elevation-z8 tab-commission">
                                    <!-- Type Column -->
                                    <ng-container matColumnDef="type" >
                                        <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.TYPE' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span  class="text tab">{{element.type}}</span>
                                        </td>
                                    </ng-container>
    
                                    <!-- Type Column -->
                                    <ng-container matColumnDef="value" >
                                        <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.VALUE' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span  class="text tab" [ngClass]="{'green': element.type === 'PERCENT'}">{{element.value}} {{element.type === 'PERCENT' ? '%' : 'EUR'}}</span>
                                        </td>
                                    </ng-container>

                                    <!-- actions Column -->
                                    <ng-container matColumnDef="actions" >
                                        <th mat-header-cell *matHeaderCellDef> </th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="muted-link ml-3" style="font-size: 12px;" (click)="onUpdate(element)" title="{{'BUTTONS.UPDATE' | translate}}">
                                                <i class="fa fa-edit"></i>
                                            </span>
                                            
                                            <span class="muted-link ml-3" style="font-size: 12px;" (click)="deleteCommission(element)" title="{{'BUTTONS.DELETE' | translate}}">
                                                <i class="fa fa-trash"></i>
                                            </span>
                                        </td>
                                    </ng-container>
    
                                    <tr mat-header-row *matHeaderRowDef="commissionTypesDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: commissionTypesDisplayedColumns;"  class="table-row"></tr>
                                </table>
                            </div>
                        </ngx-simplebar>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="haveReadPermission">
            <div class="card w-40" *ngIf="action === 'add'" >
                <div class="card-header bold">
                    {{'PACKAGES.ADD_COMMISSION' | translate}}
                </div>
    
                <div class="card-body">
                    <form [formGroup]="commissionForm" id="new-commission" aria-label="Créer un nouveau commission">
                        <div class="Subhead mb-5">
                            <h1 class="Subhead-heading">{{'PACKAGES.COMMISSION_CREATION' | translate}}</h1>
                            <div class="Subhead-description">{{'PACKAGES.COMMISSION_CREATION_NOTE' | translate}}</div>
                        </div>
        
                        <div class="clearfix">
                            <dl class="form-group float-left mt-1">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="commission-type">{{'PACKAGES.COMMISSION_BY' | translate}}<span class="error"> * </span></label>
                                </dt>
                                <dd>
                                    <select [ngClass]="{'border-error' : this.isFieldValid('type')}" class="form-control long" formControlName="type" id="commission-type"
                                            (change)="onCommissionTypeChange($event.target.value)">
                                        <option value="PERCENT">{{'PACKAGES.PERCENTAGE' | translate}}</option>
                                        <option value="AMOUNT">{{'PACKAGES.VALUE' | translate}}</option>
                                    </select>
                                </dd>
                            </dl>
        
                            <dl class="form-group float-left mt-1" *ngIf="selectedType" >
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="commission-value">{{'PACKAGES.COMMISSION_VALUE' | translate}}<span class="error">*</span></label>
                                </dt>
                                <dd *ngIf="selectedType === 'PERCENT' else AMOUNT" >
                                    <input type="number" id="commission-value" [ngClass]="{'border-error' : this.isFieldValid('value')}" class="form-control long d-inline tbr-radius-none" formControlName="value" placeholder="{{'PACKAGES.PERCENTAGE' | translate}}" autocomplete="off">
                                    <span class="text currency small">%</span>
                                </dd>
                                <ng-template #AMOUNT>
                                    <dd>
                                        <input type="number" id="commission-value" [ngClass]="{'border-error' : this.isFieldValid('value')}" class="form-control long d-inline tbr-radius-none" formControlName="value" placeholder="{{'PACKAGES.VALUE' | translate}}" autocomplete="off">
                                        <span class="text currency small">EUR</span>
                                    </dd>
                                </ng-template>
                            </dl>
                        </div>
    
                        <hr aria-hidden="true">
    
                <button type="button" class="btn btn-sm btn-success" [disabled]="!commissionForm.valid" (click)="onSubmit()">{{'BUTTONS.ADD' | translate}}</button>
                    </form>
                </div>
            </div>
    
            <div class="card w-40" *ngIf="action === 'update'" >
                <div class="card-header bold">
                    {{'BUTTONS.UPDATE_COMMISSION' | translate}}
                </div>
    
                <div class="card-body">
                    <form [formGroup]="commissionForm" id="new-commission" aria-label="Créer un nouveau commission">
                        <div class="Subhead mb-5">
                            <h1 class="Subhead-heading">{{'PACKAGES.COMMISSION_UPDATE' | translate}}</h1>
                            <div class="Subhead-description">{{'PACKAGES.COMMISSION_UPDATE_DESC' | translate}}</div>
                        </div>
        
                        <div class="clearfix">
                            <dl class="form-group float-left mt-1">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="commission-type">{{'PACKAGES.COMMISSION_BY' | translate}}<span class="error"> * </span></label>
                                </dt>
                                <dd>
                                    <select [ngClass]="{'border-error' : this.isFieldValid('type')}" class="form-control long" formControlName="type" id="commission-type"
                                            (change)="onCommissionTypeChange($event.target.value)">
                                        <option value="PERCENT">{{'PACKAGES.PERCENTAGE' | translate}}</option>
                                        <option value="AMOUNT">{{'PACKAGES.VALUE' | translate}}</option>
                                    </select>
                                </dd>
                            </dl>
        
                            <dl class="form-group float-left mt-1" *ngIf="selectedType" >
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="commission-value">{{'PACKAGES.commission8value' | translate}}<span class="error">*</span></label>
                                </dt>
                                <dd *ngIf="selectedType === 'PERCENT' else AMOUNT" >
                                    <input type="number" id="commission-value" [ngClass]="{'border-error' : this.isFieldValid('value')}" class="form-control long d-inline tbr-radius-none" formControlName="value" placeholder="{{'PACKAGES.PERCENTAGE' | translate}}" autocomplete="off">
                                    <span class="text currency small">%</span>
                                </dd>
                                <ng-template #AMOUNT>
                                    <dd>
                                        <input type="number" id="commission-value" [ngClass]="{'border-error' : this.isFieldValid('value')}" class="form-control long d-inline tbr-radius-none" formControlName="value" placeholder="{{'PACKAGES.VALUE' | translate}}" autocomplete="off">
                                        <span class="text currency small">EUR</span>
                                    </dd>
                                </ng-template>
                            </dl>
                        </div>
    
                        <hr aria-hidden="true">
    
                        <button type="button" class="btn btn-sm btn-success" [disabled]="!commissionForm.valid" (click)="updateCommission()">{{'BUTTONS.UPDATE' | translate}}</button>
                        <button type="button" class="btn btn-sm border-gray ml-3" [disabled]="!commissionForm.valid" (click)="onCancelUpdate()">{{'BUTTONS.CANCEL' | translate}}</button>
                    </form>
                </div>
            </div>
        </ng-container>

    </div>
</div>
<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
                srcset="../../../assets/images/vide/vide@2x.png 2x,
                ../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
    </div>
</ng-template>
