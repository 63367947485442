<div *ngIf="haveReadPermission; else NO_READ_PERMISSION">
    <div class="content" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="2%">
        <div class="box image-viewer-box border" fxFlex="50%" (mouseenter)="toggleArrows = true" (mouseleave)="toggleArrows = false">
            <img [src]="photo" class="image" [alt]="documentName" *ngIf="!isPhotoLoading; else noImageFound"/>
            <ng-template #noImageFound>
                <mat-spinner [diameter]="45"></mat-spinner>
                <!--
                <img src="../../../../assets/images/vide/vide.png" srcset="../../../../assets/images/vide/vide@2x.png,../../../...assets/images/vide/vide@3x.png" class="plus" /> 
                -->
            </ng-template>
            <div class="nav-arrow float-left case-left" *ngIf="toggleArrows" (click)="onPreviousDocument()"><mat-icon class="ic-arrow">arrow_back</mat-icon> </div>
            <div class="nav-arrow float-right case-right" *ngIf="toggleArrows"><mat-icon class="ic-arrow" (click)="onNextDocument()">arrow_forward</mat-icon></div>
        </div>

        <!-- DOCUMENT SCREEN HOSTED_HOME_DOCUMENT (RESIDENCE) -->
        <div fxFlex="50%" class="box document-info-box bg-white border">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">{{'HOME.ADDRESS_DOCUMENTS.TITLE' | translate}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{this.selectedDocument.name}}</div>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.IMAGE_STATE' | translate}}: {{this.currentDocumentStatus.toString().toUpperCase()}} <!--{{'HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.' + this.currentDocumentStatus.toString().toUpperCase() | translate}}--></div>
                </div>
            </div>
    
            <div class="d-flex direction-columns">
                <div class="details">
                    <div class="details-title-column">
                        <span *ngFor="let field of RESIDENCE_TEXT_FIELDS" class="text details-title">{{'HOME.USER_INFOS.' + field.key.toUpperCase() | translate}}:</span>
                    </div>
        
                    <div class="details-text-column">
                        <span *ngFor="let field of RESIDENCE_TEXT_FIELDS" class="text details-text">{{getUserFieldName(field.key) === null || getUserFieldName(field.key) === '' ? '-' : getUserFieldName(field.key)}}</span>
                    </div>
                </div>
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center mt-1">
                    <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div class="content"fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="2%">
        <div class="full-height bg-white">
            <div  class="none-block">
                <div class="none-inner-block">
                    <img src="../../../../assets/images/vide/vide@2x.png"
                    srcset="../../../../assets/images/vide/vide@2x.png 2x,
                    ../../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
                </div>     
            </div>
        </div>
    </div>
</ng-template>