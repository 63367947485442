<div class="content">
    <div class="full-height bg-white">
        <div class="spinner" *ngIf="this.loading">
            <mat-spinner [diameter]="45"></mat-spinner>
        </div>

        <div class="box margin-auto" *ngIf="!loading" >
            <div class="card">
                <div class="card-header" *ngIf="haveReadPermission">
                    {{'ACCOUNT.HEADER' | translate}} : 
                    <span class="font name link" (click)="onNavClientInformations()">{{client.firstname}} {{client.lastname}}/{{client.id}}</span>
                    <span class="font last-login" *ngIf="account.lastLoginDate" >{{'ACCOUNT.LAST_LOGIN_DATE' | translate}}: {{account.lastLoginDate | date: 'short'}}</span>
                </div>

                <div class="card-header" *ngIf="!haveReadPermission">
                    {{'ACCOUNT.HEADER' | translate}}
                </div>
    
                <ul class="list-group list-group-flush" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                    <li class="list-group-item">{{'ACCOUNT.BALANCE' | translate}} : <span class="h4 m-left-6">{{account.currentBalance + ' ' + ('EUR' | currencySymbol)}}</span></li>
                    <li class="list-group-item">{{'ACCOUNT.INCOMING' | translate}} : <span class="red h4 m-left-6"> - {{account.comingAmount + ' ' + ('EUR' | currencySymbol)}}</span></li>
                    <li class="list-group-item">{{'ACCOUNT.IBAN' | translate}} : <span class="h4 m-left-6">{{account.iban ? account.iban : '-'}}</span></li>
                    <li class="list-group-item">{{'ACCOUNT.BIC' | translate}} : <span class="h4 m-left-6">{{account.bic ? account.bic : '-'}}</span></li>
                    <li class="box-row p-0">
                        <ul class="list-style-none text center d-flex flex-wrap">
                            <li class="box-width p-3 border-right">
                                <span class="d-block h4 text-gray-dark">
                                    <img src="../../../assets/images/ic-cagnotte/ic-cagnotte.png" class="octicon" width="18" height="18" aria-hidden="true">
                                    {{account.safeDepositAmount + ' ' + ('EUR' | currencySymbol)}}
                                </span>
                                <span class="text-gray box-title">{{'ACCOUNT.SAFE_DEPOSIT_BALANCE' | translate}}</span>
                                <span class="nav d-inline link" (click)="onNavCoffreFort()">{{'ACCOUNT.SEE' | translate}}</span>
                            </li>
                            <li class="box-width p-3 border-right">
                                <span class="d-block h4 text-gray-dark">
                                    <img src="../../../assets/images/ic-carte/ic-carte.png" class="octicon" width="18" height="18" aria-hidden="true">
                                    {{account.childCardAmount + ' ' + ('EUR' | currencySymbol)}}
                                </span>
                                <span class="text-gray box-title">{{'ACCOUNT.CHILD_CARDS_BALANCE' | translate}}</span>
                                <span class="nav d-inline link" (click)="onNavChildCards()">{{'ACCOUNT.SEE' | translate}}</span>
                            </li>
                            <li class="box-width p-3 border-right">
                                <span class="d-block h4 text-gray-dark">
                                    <img src="../../../assets/images/ic-carte/ic-carte.png" class="octicon" width="18" height="18" aria-hidden="true">
                                    {{account.virtualCardAmount + ' ' + ('EUR' | currencySymbol)}}
                                </span>
                                <span class="text-gray box-title">{{'ACCOUNT.VIRTUAL_CARDS_BALANCE' | translate}}</span>
                                <span class="nav d-inline link" (click)="onNavVirtualCards()">{{'ACCOUNT.SEE' | translate}}</span>
                            </li>
                            <!--
                            <li class="box-width p-3">
                                <span class="d-block h4 text-gray-dark">
                                    <img src="../../../assets/images/ic-cagnotte/ic-cagnotte.png" class="octicon" width="18" height="18" aria-hidden="true">
                                    {{account.potAmount ? account.potAmount + ' ' + ('EUR' | currencySymbol) : '-'}}
                                </span>
                                <span class="text-gray box-title">{{'ACCOUNT.POTS_BALANCE' | translate}}</span>
                                <span class="nav d-inline link" (click)="onNavCagnottes()">{{'ACCOUNT.SEE' | translate}}</span>
                            </li>
                            -->
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
            srcset="../../../assets/images/vide/vide@2x.png 2x,
            ../../../assets/images/vide/vide@3x.png 3x"
            class="none" alt="None">
        <p class="text none-text">{{'ACCOUNT.ACCESS_CONTROL.NO_ACCESS' | translate}}</p> 
        </div>     
    </div>
</ng-template>
