import { getPhysicalCards } from './../../../core/store/cards/cards.reducer';
import { take, filter } from 'rxjs/operators';
import { LoadVirtualCardsAttemptedAction } from './../../../core/store/cards/cards.actions';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/core/store';
import { getVirtualCards, getWallet } from '../../../core/store/cards/cards.reducer';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { Router } from '@angular/router';
import { GetWalletAttemptedAction } from '../../../core/store/cards/cards.actions';

@Component({
  selector: 'app-virtual',
  templateUrl: './virtual.component.html',
  styleUrls: ['./virtual.component.scss']
})
export class VirtualComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  walletIsLoading: boolean;

  wallet: any;

  loading: boolean;

  virtualCards$: any;

  virtualLength: number;

  virtualCardsDisplayedColumns: string[] = [
    'card_virtual',
    'embossedName_virtual',
    'status_virtual',
    'expiration_virtual',
    'wallet_virtual',
    'more_virtual'
  ];

  statements$: any;

  statementsLength = 0;

  statementsDisplayedColumns: string[] = ['title', 'date'];

  card: any;

  isCardSelected: boolean;

  cardDetailsLoading = false;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.walletIsLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_CARD_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_CARD_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.store.dispatch(new LoadVirtualCardsAttemptedAction({idClient: client.id}));
    this.store.select(getVirtualCards).pipe(filter(data => data !== undefined), take(1)).subscribe(cards => {
      console.log(cards);
      console.log(cards.length);
      this.virtualCards$ = cards;
      this.virtualLength = cards.length;
    },
    (err) => {console.log(err); },
    () => { this.loading = false; console.log('loading is ', this.loading); });

  }

  onSeeMore(element: any): void {
    this.cardDetailsLoading = true;
    this.card = element;
    this.isCardSelected = true;
    setTimeout(() => {
      this.card = element;
      this.isCardSelected = true;
      this.cardDetailsLoading = false;
    }, 150);
  }

  onRefresh(id: string): void {
    this.walletIsLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    console.log('wallet payload is: ', {idClient: client.id, idWallet: id});
    this.store.dispatch(new GetWalletAttemptedAction({idClient: client.id, idWallet: id}));
    this.store.select(getWallet).pipe(filter(data => data !== undefined), take(1)).subscribe(obj => {
      console.log('WALLET: ', obj);
      this.wallet = obj;
      setTimeout(() => {
        this.walletIsLoading = false;
      }, 400);
    });
  }

  onSeeOperations(): void {
    this.router.navigate(['/clients/details/cards']);
  }

  onSeeWallet(): void {
    this.router.navigate(['/clients/details/cards']);
  }

  onOpenWallet(id: string): void {
    this.walletIsLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetWalletAttemptedAction({idClient: client.id, idWallet: id}));
    this.store.select(getWallet).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
      console.log('WALLET :', this.wallet);
      this.wallet = data;
      this.walletIsLoading = false;
    });

  }

}
