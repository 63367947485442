import { Deposit } from './../../models/deposit.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromDeposit from './deposit.actions';

export interface DepositState {
    depositScreen: number;
    data: any;
    history: any;
    selected: Deposit;
    deposit: Deposit;
    idSafeDeposit: string;
    amount: number;
    loaded: boolean;
    loading: boolean;
    error: string;
}

export const initialState: DepositState = {
    depositScreen: undefined,
    data: undefined,
    history: undefined,
    selected: undefined,
    deposit: undefined,
    idSafeDeposit: undefined,
    amount: undefined,
    loaded: false,
    loading: false,
    error: undefined,
};



export function reducer(
    state = initialState,
    action: fromDeposit.DepositActions
): DepositState {
    switch (action.type) {
        case fromDeposit.LOAD_DEPOSITS: {
            return {
                ...state,
                loading: true,
                data: undefined
            };
        }
        case fromDeposit.LOAD_DEPOSITS_SUCCESS: {
            return {
                ...state,
                loaded: true,
                loading: false,
                data: action.payload.value,
            };
        }
        case fromDeposit.LOAD_DEPOSITS_FAIL: {
            return {
                ...state,
                loaded: false,
                loading: false,
            };
        }
        case fromDeposit.LOAD_DEPOSIT_HISTORY: {
            return {
                ...state,
                loading: true,
                history: undefined,
                depositScreen: 2,
            };
        }
        case fromDeposit.LOAD_DEPOSIT_HISTORY_SUCCESS: {
            return {
                ...state,
                loaded: true,
                loading: false,
                history: action.payload.value,
            };
        }
        case fromDeposit.LOAD_DEPOSIT_HISTORY_FAIL: {
            return {
                ...state,
                loaded: false,
                loading: false,
            };
        }
        case fromDeposit.SCREEN_DEPOSIT: {
            return {
                ...state,
                depositScreen: 1,
                data : undefined
            };
        }
        case fromDeposit.SCREEN_DEPOSIT_HISTORY: {
            return {
                ...state,
                depositScreen: 2,
            };
        }
        case fromDeposit.SCREEN_DEPOSIT_DETAILS: {
            return {
                ...state,
                depositScreen: 0,
            };
        }
        case fromDeposit.SCREEN_DEPOSIT_END: {
            return {
                ...state,
                depositScreen: -1,
            };
        }
        case fromDeposit.SELECT_DEPOSIT: {
            return {
                ...state,
                selected: action.payload,
            };
        }
        case fromDeposit.UNSELECT_DEPOSIT: {
            return {
                ...state,
                selected: null,
            };
        }
    }
    return state;
}

export const getDepositsState = createFeatureSelector < DepositState > ('deposit');

export const getDeposits = createSelector(getDepositsState, (state: DepositState) => state.data);
export const getDepositsHistory = createSelector(getDepositsState, (state: DepositState) => state.history);
export const getSelectedDeposit = createSelector(getDepositsState, (state: DepositState) => state.selected);
export const getDepsoitError = createSelector(getDepositsState, (state: DepositState) => state.error);
export const getDepositScreen = createSelector(getDepositsState, (state: DepositState) => state.depositScreen);
export const getDepositsLoaded = createSelector(getDepositsState, (state: DepositState) => state.loaded);
export const getDepositsLoading = (state: DepositState) => state.loading;
