<div *ngIf="haveReadPermission; else NO_READ_PERMISSION">
    <div class="content" fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="2%">
        <div class="box image-viewer-box" fxFlex="50%" [ngClass]="{'border-red': isPhotoRectificationChecked && (userStatusName === 'IDENTITY_PENDING' || userStatusName === 'RECTIFICATION'), 'border': !isPhotoRectificationChecked}"
            (mouseenter)="toggleArrows = true" (mouseleave)="toggleArrows = false">
            <img [src]="imageToShow" class="image" [alt]="documentName" *ngIf="!isPhotoLoading; else noImageFound"/>
            <ng-template #noImageFound>
                <mat-spinner [diameter]="45"></mat-spinner>
                <!--
                <img src="../../../../assets/images/vide/vide.png" srcset="../../../../assets/images/vide/vide@2x.png,../../../...assets/images/vide/vide@3x.png" class="plus" /> 
                -->
            </ng-template>
            <ng-container *ngIf="haveWritePermission && !this.rectifButtonDisabled">
                <div *ngIf="!isPhotoLoading && (this.userStatusName.toString() === 'IDENTITY_PENDING')" class="rectif-img-btn">
                    <button mat-raised-button [ngClass]="{'invalid-img-checked': isPhotoRectificationChecked}" (click)="togglePhotoRectificationButton()">
                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIF_IMAGE' | translate}}
                        <mat-icon>highlight_off</mat-icon>
                    </button>
                </div>
            </ng-container>
            <div class="nav-arrow float-left case-left" *ngIf="toggleArrows" (click)="onPreviousDocument()"><mat-icon class="ic-arrow">arrow_back</mat-icon> </div>
            <div class="nav-arrow float-right case-right" *ngIf="toggleArrows"><mat-icon class="ic-arrow" (click)="onNextDocument()">arrow_forward</mat-icon></div>
        </div>
    
        <!-- DOCUMENT SCREEN HOSTED_OTHER_HOME_DOCUMENT (RESIDENCE_PERMIT) EXCEPT RECTO_CIN_OWNER && VERSO_CIN_OWNER -->
        <div *ngIf="documentIdentity === 'HOSTED_OTHER_HOME_DOCUMENT' && documentName !== 'RECTO_CIN_OWNER' && documentName !== 'VERSO_CIN_OWNER'" fxFlex="50%" class="box document-info-box bg-white border">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">{{this.selectedDocument.identity}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{this.selectedDocument.name}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.LAST_RECTIF' | translate}}: {{this.userInfo.lastUserUpdate | date:'short'}}</div>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.IMAGE_STATE' | translate}}: {{this.currentDocumentStatus.toString().toUpperCase()}}</div>
                </div>
            </div>
    
            <div class="d-flex direction-columns">
                <div class="details">
                    <div class="details-title-column">
                        <span *ngFor="let field of RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS" class="text details-title">{{'HOME.USER_INFOS.' + field.key.toUpperCase() | translate}}:</span>
                    </div>
        
                    <div class="details-text-column">
                        <span *ngFor="let field of RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS" class="text details-text">{{getUserFieldName(field.key) === null || getUserFieldName(field.key) === '' ? '-' : getUserFieldName(field.key)}}</span>
                    </div>
            
                    <ng-container *ngIf="this.userStatusName && this.userStatusName !== 'ENABLED' && this.userStatusName !== 'KYC_CONFIRMED'">
                        <div class="details-actions-unvalid-column" *ngIf="this.haveWritePermission && !this.rectifButtonDisabled && this.userStatusName !== 'ENABLED'; else NO_WRITE_PERMISSION">
                            <ng-container *ngFor="let field of RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer" *ngIf="this.userStatusName.toString() === 'RECTIFICATION' || this.userStatusName === 'KYC_PENDING' else activateRectification"
                                        [ngClass]="{'last-field-rectification': this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFIED_FIELD' | translate}}
                                </span>
                                <ng-template #activateRectification>
                                    <span class="text details-action Pointer" [ngClass]="{'invalid-checked': this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[i].isInvalidChecked}" 
                                        (click)="checkInvalidField(field.key, 'RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS')">
                                        <i class="fa fa-times-circle"></i>
                                        <ng-container *ngIf="userStatusName === 'IDENTITY_PENDING' else NOT_IDENTITY_PENDING" >{{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}</ng-container>
                                    </span>  
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #NO_WRITE_PERMISSION>
                        <div class="details-actions-unvalid-column">
                            <ng-container *ngFor="let field of RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer grayed" [ngClass]="{'invalid-checked': this.RESIDENCE_PERMIT_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                    <i class="fa fa-times-circle"></i>
                                    {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}
                                </span>  
                            </ng-container>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center mt-1">
                    <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                </div>
            </div>
        </div>
    
        <!-- DOCUMENT SCREEN CIN-->
        <div *ngIf="documentIdentity === 'CIN'" fxFlex="50%" class="box document-info-box bg-white border">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-heading">
                        <div class="panel-title">{{this.selectedDocument.identity}}</div>
                        <span class="document-info-divider"></span>
                        <div class="panel-title">{{this.selectedDocument.name}}</div>
                        <span class="document-info-divider"></span>
                        <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.LAST_RECTIF' | translate}}: {{this.userInfo.lastUserUpdate | date:'short'}}</div>
                        <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.IMAGE_STATE' | translate}}: {{this.currentDocumentStatus.toString().toUpperCase()}}</div>
                    </div>
                </div>
            </div>
    
            <div class="d-flex direction-columns">
                <div class="details">
                    <div class="details-title-column">
                        <span *ngFor="let field of CIN_SCREEN_TEXT_FIELDS" class="text details-title">{{'HOME.USER_INFOS.' + field.key.toUpperCase() | translate}}:</span>
                    </div>
        
                    <div class="details-text-column">
                        <span *ngFor="let field of CIN_SCREEN_TEXT_FIELDS" class="text details-text">{{getUserFieldName(field.key) === null || getUserFieldName(field.key) === '' ? '-' : getUserFieldName(field.key)}}</span>
                    </div>
        
                    <ng-container *ngIf="this.userStatusName && this.userStatusName !== 'ENABLED' && this.userStatusName !== 'KYC_CONFIRMED'">
                        <div class="details-actions-unvalid-column" *ngIf="this.haveWritePermission && !this.rectifButtonDisabled; else NO_WRITE_PERMISSION">
                            <ng-container *ngFor="let field of CIN_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer" *ngIf="this.userStatusName.toString() === 'RECTIFICATION' || this.userStatusName === 'KYC_PENDING'  else activateRectification"
                                        [ngClass]="{'last-field-rectification': this.CIN_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFIED_FIELD' | translate}}
                                </span>
                                <ng-template #activateRectification>
                                    <span class="text details-action Pointer" [ngClass]="{'invalid-checked': this.CIN_SCREEN_TEXT_FIELDS[i].isInvalidChecked}" 
                                        (click)="checkInvalidField(field.key, 'CIN_SCREEN_TEXT_FIELDS')">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}
                                    </span>  
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #NO_WRITE_PERMISSION>
                        <div class="details-actions-unvalid-column">
                            <ng-container *ngFor="let field of CIN_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer grayed" [ngClass]="{'invalid-checked': this.CIN_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                    <i class="fa fa-times-circle"></i>
                                    <ng-container *ngIf="userStatusName === 'IDENTITY_PENDING' else NOT_IDENTITY_PENDING">{{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}</ng-container>
                                </span> 
                            </ng-container>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center mt-1">
                    <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                </div>
            </div>
        </div>
    
        <!-- DOCUMENT SCREEN PASSPORT-->
        <div *ngIf="documentIdentity === 'PASSPORT' && documentName === 'FIRST_PAGE_PASSPORT'" fxFlex="50%" class="box document-info-box bg-white border">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">{{this.selectedDocument.identity}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{this.selectedDocument.name}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.LAST_RECTIF' | translate}}: {{this.userInfo.lastUserUpdate | date:'short'}}</div>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.IMAGE_STATE' | translate}}: {{this.currentDocumentStatus.toString().toUpperCase()}}</div>
                </div>
            </div>

            <div class="d-flex direction-columns">
                <div class="details">
                    <div class="details-title-column">
                        <span *ngFor="let field of PASSPORT_SCREEN_TEXT_FIELDS" class="text details-title">{{'HOME.USER_INFOS.' + field.key.toUpperCase() | translate}}:</span>
                    </div>
        
                    <div class="details-text-column">
                        <span *ngFor="let field of PASSPORT_SCREEN_TEXT_FIELDS" class="text details-text">{{getUserFieldName(field.key) === null || getUserFieldName(field.key) === '' ? '-' : getUserFieldName(field.key)}}</span>
                    </div>

                    <ng-container *ngIf="this.userStatusName && this.userStatusName !== 'ENABLED' && this.userStatusName !== 'KYC_CONFIRMED'">
                        <div class="details-actions-unvalid-column" *ngIf="this.haveWritePermission && !this.rectifButtonDisabled; else NO_WRITE_PERMISSION">
                            <ng-container *ngFor="let field of PASSPORT_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer" 
                                        *ngIf="this.userStatusName.toString() === 'RECTIFICATION' 
                                         
                                        || this.userStatusName.toString() === 'KYC_PENDING'  else activateRectification"
                                        [ngClass]="{'last-field-rectification': this.PASSPORT_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFIED_FIELD' | translate}}
                                </span>
                                <ng-template #activateRectification>
                                    <span class="text details-action Pointer" [ngClass]="{'invalid-checked': this.PASSPORT_SCREEN_TEXT_FIELDS[i].isInvalidChecked}" 
                                        (click)="checkInvalidField(field.key, 'PASSPORT_SCREEN_TEXT_FIELDS')">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}
                                    </span>  
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #NO_WRITE_PERMISSION>
                        <div class="details-actions-unvalid-column">
                            <ng-container *ngFor="let field of PASSPORT_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer grayed" [ngClass]="{'invalid-checked': this.PASSPORT_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                    <i class="fa fa-times-circle"></i>
                                    <ng-container *ngIf="userStatusName === 'IDENTITY_PENDING' else NOT_IDENTITY_PENDING">{{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}</ng-container>
                                </span> 
                            </ng-container>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center mt-1">
                    <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1"> {{'ACCESS_CONTROL.MY_PERMISSIOS' | translate}} </span>
                </div>
            </div>
        </div>

        <!-- DOCUMENT SCREEN SEJOUR-->
        <div *ngIf="documentIdentity === 'PASSPORT' && documentName === 'FIRST_PAGE_SEJOUR'" fxFlex="50%" class="box document-info-box bg-white border">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">{{this.selectedDocument.identity}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{this.selectedDocument.name}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.LAST_RECTIF' | translate}}: {{this.userInfo.lastUserUpdate | date:'short'}}</div>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.IMAGE_STATE' | translate}}: {{this.currentDocumentStatus.toString().toUpperCase()}}</div>
                </div>
            </div>

            <div class="d-flex direction-columns">
                <div class="details">
                    <div class="details-title-column">
                        <span *ngFor="let field of SEJOUR_SCREEN_TEXT_FIELDS" class="text details-title">{{'HOME.USER_INFOS.' + field.key.toUpperCase() | translate}}:</span>
                    </div>
        
                    <div class="details-text-column">
                        <span *ngFor="let field of SEJOUR_SCREEN_TEXT_FIELDS" class="text details-text">{{getUserFieldName(field.key) === null || getUserFieldName(field.key) === '' ? '-' : getUserFieldName(field.key)}}</span>
                    </div>

                    <ng-container *ngIf="this.userStatusName && this.userStatusName !== 'ENABLED' && this.userStatusName !== 'KYC_CONFIRMED'">
                        <div class="details-actions-unvalid-column" *ngIf="this.haveWritePermission && !this.rectifButtonDisabled; else NO_WRITE_PERMISSION">
                            <ng-container *ngFor="let field of SEJOUR_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer" *ngIf="this.userStatusName.toString() === 'RECTIFICATION' || this.userStatusName === 'KYC_PENDING'  else activateRectification"
                                        [ngClass]="{'last-field-rectification': this.SEJOUR_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFIED_FIELD' | translate}}
                                </span>
                                <ng-template #activateRectification>
                                    <span class="text details-action Pointer" [ngClass]="{'invalid-checked': this.SEJOUR_SCREEN_TEXT_FIELDS[i].isInvalidChecked}" 
                                        (click)="checkInvalidField(field.key, 'SEJOUR_SCREEN_TEXT_FIELDS')">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}
                                    </span>  
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #NO_WRITE_PERMISSION>
                        <div class="details-actions-unvalid-column">
                            <ng-container *ngFor="let field of SEJOUR_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer grayed" [ngClass]="{'invalid-checked': this.SEJOUR_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                    <i class="fa fa-times-circle"></i>
                                    <ng-container *ngIf="userStatusName === 'IDENTITY_PENDING' else NOT_IDENTITY_PENDING">{{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}</ng-container>
                                </span> 
                            </ng-container>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center mt-1">
                    <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                </div>
            </div>
        </div>
    
        <!-- DOCUMENT SCREEN HOSTED_HOME_DOCUMENT (RESIDENCE) -->
        <div *ngIf="documentIdentity === 'HOSTED_HOME_DOCUMENT'" fxFlex="50%" class="box document-info-box bg-white border">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="panel-title">{{this.selectedDocument.identity}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{this.selectedDocument.name}}</div>
                    <span class="document-info-divider"></span>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.LAST_RECTIF' | translate}}: {{this.userInfo.lastUserUpdate | date:'short'}}</div>
                    <div class="panel-title">{{'HOME.USER_VISUAL_DOCUMENTS.IMAGE_STATE' | translate}}: {{this.currentDocumentStatus.toString().toUpperCase()}} <!--{{'HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.' + this.currentDocumentStatus.toString().toUpperCase() | translate}}--></div>
                </div>
            </div>
    
            <div class="d-flex direction-columns">
                <div class="details">
                    <div class="details-title-column">
                        <span *ngFor="let field of RESIDENCE_SCREEN_TEXT_FIELDS" class="text details-title">{{'HOME.USER_INFOS.' + field.key.toUpperCase() | translate}}:</span>
                    </div>
        
                    <div class="details-text-column">
                        <span *ngFor="let field of RESIDENCE_SCREEN_TEXT_FIELDS" class="text details-text">{{getUserFieldName(field.key) === null || getUserFieldName(field.key) === '' ? '-' : getUserFieldName(field.key)}}</span>
                    </div>

                    <ng-container *ngIf="this.userStatusName && this.userStatusName !== 'ENABLED' && this.userStatusName !== 'KYC_CONFIRMED'">
                        <div class="details-actions-unvalid-column" *ngIf="this.haveWritePermission && !this.rectifButtonDisabled; else NO_WRITE_PERMISSION">
                            <ng-container *ngFor="let field of RESIDENCE_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer" *ngIf="this.userStatusName === 'RECTIFICATION' else activateRectification"
                                        [ngClass]="{'last-field-rectification': this.RESIDENCE_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFIED_FIELD' | translate}}
                                </span>
                                <ng-template #activateRectification>
                                    <span class="text details-action Pointer" [ngClass]="{'invalid-checked': this.RESIDENCE_SCREEN_TEXT_FIELDS[i].isInvalidChecked}" 
                                        (click)="checkInvalidField(field.key, 'RESIDENCE_SCREEN_TEXT_FIELDS')">
                                        <i class="fa fa-times-circle"></i>
                                        {{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}
                                    </span>  
                                </ng-template>
                            </ng-container>
                        </div>
                    </ng-container>
                    <ng-template #NO_WRITE_PERMISSION>
                        <div class="details-actions-unvalid-column">
                            <ng-container *ngFor="let field of RESIDENCE_SCREEN_TEXT_FIELDS; let i = index">
                                <span class="text details-action No-pointer grayed" [ngClass]="{'invalid-checked': this.RESIDENCE_SCREEN_TEXT_FIELDS[i].isInvalidChecked}">
                                    <i class="fa fa-times-circle"></i>
                                    <ng-container *ngIf="userStatusName === 'IDENTITY_PENDING' else NOT_IDENTITY_PENDING">{{'HOME.USER_VISUAL_DOCUMENTS.RECTIFY_FIELD' | translate}}</ng-container>
                                </span>  
                            </ng-container>
                        </div>
                    </ng-template>
                </div>
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center mt-1">
                    <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                </div>
            </div>
        </div>
    </div>
    
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="userStatusName === 'IDENTITY_PENDING'">
        <button mat-raised-button class="form-button" [disabled]="!haveWritePermission || (haveWritePermission && this.userStatusName === 'RECTIFICATION') 
                || (haveWritePermission && this.rectifButtonDisabled)" (click)="addDocumentToRectificationList()">
            {{'HOME.USER_VISUAL_DOCUMENTS.ADD_RECTIF' | translate}}
        </button>
        <button mat-raised-button class="form-button" *ngIf="haveWritePermission && this.rectifButtonDisabled" (click)="enableModification()">
            {{'BUTTONS.UPDATE' | translate}}
        </button>
        <button mat-raised-button class="form-button" *ngIf="haveWritePermission && this.rectifModificationEnabled" (click)="cancelModification()">
            {{'BUTTONS.CANCEL' | translate}}
        </button>
    </div>
</div>

<ng-template #NOT_IDENTITY_PENDING>{{'HOME.USER_VISUAL_DOCUMENTS.RECTIFIED_FIELD' | translate}}</ng-template>

<ng-template #NO_READ_PERMISSION>
    <div class="content"fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="2%">
        <div class="full-height bg-white">
            <div  class="none-block">
                <div class="none-inner-block">
                    <img src="../../../../assets/images/vide/vide@2x.png"
                    srcset="../../../../assets/images/vide/vide@2x.png 2x,
                    ../../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                    <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
                </div>     
            </div>
        </div>
    </div>
</ng-template>
