import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {UsersService} from '../../services/users/users.service';
import {
  GetUserDocumentsAttemptedAction,
  GetUserDocumentsSuccessAction,
  GetUserDocumentsFailedAction,
  LoadDocumentImageAttemptedAction,
  LoadDocumentImageFailed,
  DocumentActionTypes,
  LoadChildDocumentsAttemptedAction,
  LoadChildDocumentsFailedAction,
  RectifyChildDocumentsAttemptedAction,
  RectifyChildDocumentsFailedAction,
  ValidateChildDocumentsAttemptedAction,
  ValidateChildDocumentsFailedAction,
  LoadChildDocumentsPhotoAttemptedAction,
  LoadChildDocumentsPhotoFailedAction,
  InvalidateAddressModificationDocumentsAttemptedAction,
  InvalidateAddressModificationDocumentsFailedAction,
  ValidateAddressModificationDocumentsAttemptedAction,
  ValidateAddressModificationDocumentsFailedAction,
  LoadAddressModificationPhotoAttemptedAction,
  LoadAddressModificationPhotoFailedAction
} from './document.action';
import {catchError, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import { LoadAddressModificationDocumentsAttemptedAction, LoadAddressModificationDocumentsFailedAction } from './document.action';

@Injectable()
export class DocumentsEffects {

    constructor(private actions$: Actions, private usersService: UsersService, private router: Router) {}

  @Effect()
  getUserDocumentsAttempted$: Observable<any> = this.actions$.pipe(
    ofType<GetUserDocumentsAttemptedAction>(DocumentActionTypes.GET_USER_DOCUMENTS_ATTEMPTED),
    switchMap((data: GetUserDocumentsAttemptedAction) => this.usersService.getUserDocuments(data.payload)
      .pipe(
        map(resp => new GetUserDocumentsSuccessAction(resp)),
        catchError(err => {console.log(err); return of (new GetUserDocumentsFailedAction(err)); })
      )
    )
  );


  @Effect()
    LoadDocumentImageAttempted$ = this.actions$.pipe(
        ofType<LoadDocumentImageAttemptedAction>(DocumentActionTypes.LOAD_DOCUMENT_IMAGE_ATTEMPTED),
        switchMap(data => this.usersService.getDocumentImageByTag(data.payload)
            .pipe(
                map(resp => {
                  console.log('IMAGE RESPONSE IS: ', resp);
                  return {
                      type: DocumentActionTypes.LOAD_DOCUMENT_IMAGE_SUCCESSFUL,
                      payload: {resp}
                  };
                }),
                catchError((error: any) => of(new LoadDocumentImageFailed(error)))
            )
        )
    );

    @Effect()
    loadChildDocumentsAttempted$ = this.actions$.pipe(
      ofType<LoadChildDocumentsAttemptedAction>(DocumentActionTypes.LOAD_CHILD_DOCUMENTS_ATTEMPTED),
      switchMap(data => this.usersService.getChildDocuments(data.idClient, data.idChild)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.LOAD_CHILD_DOCUMENTS_SUCCESSFUL,
              payload: {resp}
            };
          }),
          catchError((error: any) => of(new LoadChildDocumentsFailedAction(error)))
        ))
    );

    @Effect()
    rectifyChildDocuments$ = this.actions$.pipe(
      ofType<RectifyChildDocumentsAttemptedAction>(DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_ATTEMPTED),
      switchMap(data => this.usersService.rectifyChildDocuments(data.idClient, data.idChild)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_SUCCESSFUL,
              payload: {resp}
            };
          }),
          catchError((error: any) => of(new RectifyChildDocumentsFailedAction(error)))
        ))
    );

    @Effect()
    validateChildDocuments$ = this.actions$.pipe(
      ofType<ValidateChildDocumentsAttemptedAction>(DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_ATTEMPTED),
      switchMap(data => this.usersService.validateChildDocuments(data.idClient, data.idChild)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_SUCCEESFUL,
              payload: {resp}
            };
          }),
        catchError((error: any) => of(new ValidateChildDocumentsFailedAction(error)))
        ))
    );

    @Effect()
    loadChildDocumentsPhoto$ = this.actions$.pipe(
      ofType<LoadChildDocumentsPhotoAttemptedAction>(DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_ATTEMTPED),
      switchMap(data => this.usersService.getChildDocumentsPhoto(data.idClient, data.idChild, data.tag)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_SUCCESSFUL,
              payload: {resp}
            };
          }),
          catchError((error: any) => of(new LoadChildDocumentsPhotoFailedAction(error)))
        ))
    );

    @Effect()
    loadAddressModificationDocsAttempted$ = this.actions$.pipe(
      ofType<LoadAddressModificationDocumentsAttemptedAction>(DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED),
      switchMap(data => this.usersService.getAddressDocuments(data.idClient)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL,
              payload: {resp}
            };
          }),
          catchError((error: any) => of(new LoadAddressModificationDocumentsFailedAction(error)))
        ))
    );

    @Effect()
    invalidateAddressModificationDocumentsAttempted$ = this.actions$.pipe(
      ofType<InvalidateAddressModificationDocumentsAttemptedAction>
      (DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED),
      switchMap(data => this.usersService.invalidateAddressModificationDocuments(data.idClient)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL,
              payload: {resp}
            };
          }),
          catchError((error: any) => of(new InvalidateAddressModificationDocumentsFailedAction(error)))
        )
      )
    );

    @Effect()
    validateAddressModificationDocumentsAttemtped$ = this.actions$.pipe(
      ofType<ValidateAddressModificationDocumentsAttemptedAction>(DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED),
      switchMap(data => this.usersService.validateAddressModificationDocuments(data.idClient)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL,
              payload: {resp}
            };
          }),
          catchError((error: any) => of(new ValidateAddressModificationDocumentsFailedAction(error)))
        )
      )
    );

    @Effect()
    loadAddressModificationPhotoAttempted$ = this.actions$.pipe(
      ofType<LoadAddressModificationPhotoAttemptedAction>(DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_PHOTO_ATTEMTPED),
      switchMap(data => this.usersService.getAddressModificationPhoto(data.idClient, data.attempt, data.tag)
        .pipe(
          map(resp => {
            return {
              type: DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_ATTEMTPED,
              payload: {resp}
            };
          }),
          catchError((error: any) => of(new LoadAddressModificationPhotoFailedAction(error)))
        ))
    );

}
