<div class="content" fxFlex>
    <div fxFlex="73.5%" class="bg-white full-height">
        <div class="header" *ngIf="deposit" >
            <img src="../../../../assets/images/back-btn/back-btn@2x.png"
               srcset="../../../../assets/images/back-btn/back-btn@2x.png 2x,
                 ../../../../assets/images/back-btn/back-btn@3x.png 3x"
               class="back" alt="Back Button" (click)="onBack()">
            <h3 class="text header-title">{{'DEPOSIT.HISTORY' | translate}} "{{deposit.title}}"</h3>
        </div>

        <div *ngIf="loading" class="spinner">
            <mat-spinner [diameter]="45"></mat-spinner>
        </div>
    
        <div class="history-content">
            <div *ngIf="!loading" >
                <div class="none-block" *ngIf="transactionsLength == 0">
                    <img src="../../../../assets/images/vide/vide@2x.png"
                        srcset="../../../../assets/images/vide/vide@2x.png 2x,
                                ../../../../assets/images/vide/vide@3x.png 3x"
                        class="none" alt="None">
                    <p class="text none-text"> {{'DEPOSIT.NONE_HISTORY' | translate}}</p>
                </div>

                <div class="history-content" *ngIf="transactionsLength > 0" data-simplebar>
                    <div class="history-content-tab">
                        <table mat-table [dataSource]="transactions" class="mat-elevation-z8 history-tab">

                            <ng-container matColumnDef="title-history">
                                <td mat-cell *matCellDef="let element">
                                    <span class="text history-title" *ngIf="element.historySafeDepositType === 'WITHDRAWAL'">{{'DEPOSIT.WITHDRAW' | translate}}</span>
                                    <span class="text history-title" *ngIf="element.historySafeDepositType === 'PONCTUALDEPOSIT'">{{'DEPOSIT.FEED_PON' | translate}}</span>
                                    <span class="text history-title" *ngIf="element.historySafeDepositType === 'PERIODICDEPOSIT'">{{'DEPOSIT.FEED_PER' | translate}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="date-history">
                                <td mat-cell *matCellDef="let element">
                                    <span class="text history-date">{{element.date}}</span>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="amount-history">
                                <td mat-cell *matCellDef="let element">
                                    <span class="text history-amount" *ngIf="element.historySafeDepositType === 'WITHDRAWAL'">- {{element.amount}} {{element.currency | currencySymbol}}</span>
                                    <span class="text history-amount colored" *ngIf="element.historySafeDepositType !== 'WITHDRAWAL'">+ {{element.amount}} {{element.currency | currencySymbol}}</span>
                                </td>
                            </ng-container>

                            <tr mat-row *matRowDef="let row; columns: historyDisplayedColumns;" class="table-row-history"></tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--
    <div fxFlex="25.3%" class="bg-white">
        <app-deposit-description></app-deposit-description>
    </div>
    -->
</div>
