import { Action } from '@ngrx/store';

export enum AuthActionTypes {
    LOGIN_ATTEMPTED = '[AUTH] Login Attempted',
    LOGIN_SUCCESS = '[AUTH] Login Success',
    LOGIN_FAILED = '[AUTH] Login Failed',

    LOGOUT = '[AUTH] Logout',

    GET_USER_ATTEMPTED = '[AUTH] Get User - Attempted to get user with token',
    GET_USER_SUCCESSFUL = '[AUTH] Get User - Successful => local storage : user saved',
    GET_USER_FAILED = '[AUTH] Get User - Failed',

    PASSWORD_RESET_REQUEST_ATTEMPTED = '[AUTH] sends pwd reset request - attempted',
    PASSWORD_RESET_REQUEST_SUCCESSFUL = '[AUTH] sends pwd reset request - successful',
    PASSWORD_RESET_REQUEST_FAILED = '[AUTH] sends pwd reset request - failed',

    RESET_PASSWORD_ATTEMPTED = '[AUTH] resets pwd using key - attempted',
    RESET_PASSWORD_SUCCESSFUL = '[AUTH] resets pwd using key - successful',
    RESET_PASSWORD_FAILED = '[AUTH] resets pwd using key - failed'
}

export class LoginAttemptedAction implements Action {
    readonly type = AuthActionTypes.LOGIN_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoginSuccessAction implements Action {
    readonly type = AuthActionTypes.LOGIN_SUCCESS;
    constructor(public payload: any) {}
}

export class LoginFailedAction implements Action {
    readonly type = AuthActionTypes.LOGIN_FAILED;
    constructor(public payload: any) {}
}

export class LogoutAction implements Action {
    readonly type = AuthActionTypes.LOGOUT;
}

export class GetUserAttempted implements Action {
    readonly type = AuthActionTypes.GET_USER_ATTEMPTED;
    constructor(public payload: any) {}
}

export class GetUserSuccessful implements Action {
    readonly type = AuthActionTypes.GET_USER_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetUserFailed implements Action {
    readonly type = AuthActionTypes.GET_USER_FAILED;
    constructor(public payload: any) {}
}

export class PasswordResetRequestAttemptedAction implements Action {
    readonly type = AuthActionTypes.PASSWORD_RESET_REQUEST_ATTEMPTED;
    constructor(public payload: any) {}
}

export class PasswordResetRequestSuccessfulAction implements Action {
    readonly type = AuthActionTypes.PASSWORD_RESET_REQUEST_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class PasswordResetRequestFailedAction implements Action {
    readonly type = AuthActionTypes.PASSWORD_RESET_REQUEST_FAILED;
    constructor(public payload: any) {}
}

export class ResetPasswordAttemptedAction implements Action {
    readonly type = AuthActionTypes.RESET_PASSWORD_ATTEMPTED;
    constructor(public key: string, public payload: any) {}
}

export class ResetPasswordSuccessfulAction implements Action {
    readonly type = AuthActionTypes.RESET_PASSWORD_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class ResetPasswordFailedAction implements Action {
    readonly type = AuthActionTypes.RESET_PASSWORD_FAILED;
    constructor(public payload: any) {}
}


export type AuthAction =
    |LoginAttemptedAction
    |LoginSuccessAction
    |LoginFailedAction
    |LogoutAction
    |GetUserAttempted
    |GetUserSuccessful
    |GetUserFailed
    |PasswordResetRequestAttemptedAction
    |PasswordResetRequestFailedAction
    |PasswordResetRequestSuccessfulAction
    |ResetPasswordAttemptedAction
    |ResetPasswordFailedAction
    |ResetPasswordSuccessfulAction
    ;
