import { Component, OnInit, OnDestroy } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { GetUserAttempted } from '../../../core/store/auth/auth.action';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { getUsers } from '../../../core/store/users/users.reducer';
import { filter, take, map, timeout } from 'rxjs/operators';
import { GetUsersAttemptedAction } from '../../../core/store/users/users.action';
import { getPackageSelectedClients, getSelectedPackage } from '../../../core/store/package/package.reducer';
import { AffectPackageToClientsAttemptedAction,
  GetPackageSelectedClientsAttemptedAction } from '../../../core/store/package/package.action';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/dialog/dialog.component';

@Component({
  selector: 'app-package-client-assignment',
  templateUrl: './package-client-assignment.component.html',
  styleUrls: ['./package-client-assignment.component.scss']
})
export class PackageClientAssignmentComponent implements OnInit, OnDestroy {

  loading: boolean;

  clientsLoading: boolean;

  showPopupMessage: boolean;

  selectedPackage: any;

  selectedClients: any[];

  private clientsSubscription;

  private selectedClientsSubscription;

  assignCompleted = false;

  assignDisabled = false;

  enableUpdateClicked = false;

  key = '';

  statusChecked = ['ENABLED'];

  clients: any;

  messages = {
    emptyMessage: 'Aucune donnée trouvée',
    totalMessage: 'totale',
    selectedMessage: 'sélectionné'
  };

  ColumnMode = ColumnMode;

  selected = [];

  SelectionType = SelectionType;

  rowClass = 'row';

  columns = [
    {
      prop: 'selected',
      name: '',
      sortable: false,
      canAutoResize: false,
      draggable: false,
      resizable: false,
      headerCheckboxable: true,
      checkboxable: true,
      width: 30
    },
    {prop: 'id', name: 'ID'},
    {prop: 'firstname', name: 'Prénom'},
    {prop: 'lastname', name: 'Nom'},
    {prop: 'email', name: 'Email'},
    {prop: 'numTel', name: 'Numéro de téléphone'},
    {prop: 'userStatusName', name: 'Status'}
  ];

  page = {
    size: 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0,
    cle: '',
    startDate: null,
    endDate: null,
    status: []
  };


  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    this.store.select(getSelectedPackage).pipe(take(1)).subscribe(data => {
      console.log('selected package :', data);
      this.selectedPackage = data;
      if (!this.selectedPackage) {
        this.router.navigate(['/application/packages']);
        return;
      }
    });
    this.selectedClientsSubscription = this.store.select(getPackageSelectedClients).pipe(filter(resp => resp !== undefined))
    .subscribe((data: any[]) => {
      console.log('selected clients : ', data);
      if (data.length > 0) {
        this.selectedClients = data;
        this.selected = data;
        this.assignDisabled = true;
        this.clientsLoading = false;
        // this.columns[0].checkboxable = false;
        // this.columns[0].headerCheckboxable = false;
      }
    });

    this.clientsSubscription = this.store.select(getUsers).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('clients :', data);
      this.clients = data.content;
      this.page.totalElements = data.totalElements;
      this.page.totalPages = data.totalPages;
      this.page.size = data.size;
      this.store.dispatch(new GetPackageSelectedClientsAttemptedAction([
        {id: '5d03c5e221dcc56aead8cdc4'}, {id: '5db080db21dcc50e790efcea'}
      ]));
    });
    this.setPage({offset: 0});
    this.loading = false;
  }

  ngOnDestroy(): void {
    if (this.clientsSubscription) {
      this.clientsSubscription.unsubscribe();
    }
    if (this.selectedClientsSubscription) {
      this.selectedClientsSubscription.unsubscribe();
    }
  }

  setPage(pageInfo: any): void {
    this.clientsLoading = true;
    this.page.pageNumber = pageInfo.offset;
    this.page.cle = this.key;
    this.page.status = this.statusChecked;
    this.store.dispatch(new GetUsersAttemptedAction(this.page));
  }

  filter(): void {
    console.log('searching');
    console.log('status chekced', this.statusChecked);
    this.setPage({offset: 0});
  }

  getId(row: any): any {
    return row.id;
  }

  onAssignClients(): void {
    console.log('SELECTED CLIENTS ARE :', this.selected);
    const clientIds = [];
    this.selected.forEach((client: any) => clientIds.push(client.id));
    const payload = {
      idPackage: this.selectedPackage.id,
      clientIds
    };
    console.log('ASSIGNMENT PAYLOAD IS : ', payload);
    let dialogRef = this.dialog.open(DialogComponent, {
      width: '60%',
      data: {
        title: 'Confirmation des clients sélectionné',
        message: 'Vous avez sélectionner ' + this.selected.length + 'clients attribuer au paquet (' + this.selectedPackage.label + ').<br/>Voulez vous continuer ?',
        buttons: 'oui-non',
        type: 'PACKAGE_CLIENT_ASSIGNMENT',
        value: payload
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      console.log('* SUBSCRIPTION FROM DIALOG REF : ', res);
      this.loading = true;
      // this.store.dispatch(new AffectPackageToClientsAttemptedAction(payload));
      setTimeout(() => {
        this.loading = false;
        this.assignDisabled = true;
        this.assignCompleted = true;
      }, 1000);
      this.showPopupMessage = true;
      setTimeout(() => {
        this.showPopupMessage = false;
      }, 3000);
    });
  }

  enableUpdate(): void {
    this.assignDisabled = false;
    this.assignCompleted = false;
    this.enableUpdateClicked = true;
  }

  onSelect(e: any): void {
    console.log(e);
  }

  disableUpdate(): void {
    this.assignDisabled = true;
    this.assignCompleted = false;
    this.enableUpdateClicked = false;
  }

  goBack(): void {
    this.router.navigate(['/application/packages']);
  }

}
