import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromOppositions from './oppositions.actions';
import { OppositionService } from '../../services/oppositions/opposition.service';


@Injectable()
export class OppositionEffects {

    constructor(private actions$: Actions, private oppositionService: OppositionService) {}

    @Effect()
    loadOppositionsAttempted$ = this.actions$.pipe(
        ofType(fromOppositions.LOAD_OPPOSITIONS_ATTEMPTED),
        switchMap((data: fromOppositions.LoadOppositionsAttemptedAction) => {
            return this.oppositionService.getOppositions(data.payload).pipe(
                map(resp => new fromOppositions.LoadOppositionsSuccessfulAction(resp)),
                catchError(err => of(new fromOppositions.LoadOppositionsFailedAction(err)))
            );
        })
    );
}
