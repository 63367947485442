import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadChildsAttemptedAction, SelectChildAction } from '../../../core/store/cards/cards.actions';
import { getChilds } from '../../../core/store/cards/cards.reducer';
import { Router } from '@angular/router';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';

@Component({
  selector: 'app-child-documents',
  templateUrl: './child-documents.component.html',
  styleUrls: ['./child-documents.component.scss']
})
export class ChildDocumentsComponent implements OnInit, OnDestroy {

  loading: boolean;

  private childSubscription$;

  childs: any;

  childsLength: number;

  childsDisplayedColumns: string[] = ['firstname_child', 'lastname_child', 'email_child', 'birthday_child', 'citoyen_child', 'userStatusName_child'];

  private childDocumentsSubscription$;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnDestroy(): void {
    if (this.childDocumentsSubscription$) {
      this.childDocumentsSubscription$.unsubscribe();
    }
    if (this.childSubscription$) {
      this.childSubscription$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    this.store.dispatch(new LoadChildsAttemptedAction({idClient: client.id}));
    this.childSubscription$ = this.store.select(getChilds).subscribe(data => {
      console.log('CHILDS ARE: ', data);
      if (data) {
        this.childs = data;
        this.childsLength = data.length;
        this.loading = false;
      }
    });
  }

  onChildClicked(child: any): void {
    console.log('CHILDD ', child);
    localStorage.setItem('selectedChild', JSON.stringify(child));
    this.store.dispatch(new SelectChildAction(child));
    this.router.navigate(['/clients/details/documents/child-documents/viewer']);
  }

}
