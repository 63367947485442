import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from 'src/app/core/store';
import { Router, ActivatedRoute } from '@angular/router';
import { getDepositsHistory, getSelectedDeposit } from '../../../core/store/deposit/deposit.reducer';
import { filter, take } from 'rxjs/operators';
import { LoadDepositHistory } from '../../../core/store/deposit/deposit.actions';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';


@Component({
  selector: 'app-deposit-history',
  templateUrl: './deposit-history.component.html',
  styleUrls: ['./deposit-history.component.scss']
})
export class DepositHistoryComponent implements OnInit, OnDestroy {

  transactionsLength: number;

  transactions: any;

  historyDisplayedColumns: string[] = ['title-history', 'date-history', 'amount-history'];

  deposit: any;

  private historyTransactions$;

  loading = false;

  constructor(private router: Router, private store: Store<AppState>, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    const idSafeDeposit = this.route.snapshot.paramMap.get('idSafeDeposit');
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    this.store.dispatch(new LoadDepositHistory(client.id, idSafeDeposit));

    this.store.select(getSelectedDeposit).pipe(take(1)).subscribe(selected => {
      console.log('SELECTED DEPOSITS: ', selected);
      if (selected === undefined || selected === null) {
        this.router.navigate(['/clients/details/deposits']);
        return;
      }
      this.deposit = selected;
    });

    this.historyTransactions$ = this.store.select(getDepositsHistory).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('HISTORY : ', data);
      this.transactions = data;
      this.transactionsLength = data.length;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.historyTransactions$) {
      this.historyTransactions$.unsubscribe();
    }
  }

  onBack(): void {
    this.router.navigate(['/clients/details/deposits']);
  }

}
