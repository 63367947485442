<ng-container *ngIf="haveReadPermission; else NO_READ_PERMISSION">
    <div class="content" *ngIf="depositScreen == 1">
        <div class="full-height bg-white">
            <div class="title-div">
                <h2 class="text title-text">
                    {{'DEPOSIT.SUB_TITLE' | translate}}
                </h2>
            </div>

            <div class="deposits-content">
                <div *ngIf="this.loading" class="spinner">
                    <mat-spinner [diameter]="45"></mat-spinner>
                </div>
        
                <div class="deposits" *ngIf="!this.loading">
                    <div class="none-div" *ngIf="depositsLength == 0">
                        <img src="../../../assets/images/vide/vide.png"
                            srcset="../../../assets/images/vide/vide@2x.png 2x, ../../../assets/images/vide/vide@3x.png 3x"
                            class="none"/>
                        <p class="text none-text">{{'DEPOSIT.NONE' | translate}}</p>
                    </div>

                    <div class="deposit-content" *ngIf="depositsLength > 0">
                        <div class="deposit-tab">
                            <table mat-table [dataSource]="deposits" class="mat-elevation-z8 tab-deposit">
                                <ng-container matColumnDef="category_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <img src="../../../assets/images/home/safe-deposit/category/{{element.safeDepositType}}@2x.png"
                                            srcset="../../../assets/images/home/safe-deposit/category/{{element.safeDepositType}}@2x.png 2x,
                                                    ../../../assets/images/home/safe-deposit/category/{{element.safeDepositType}}@3x.png 3x"
                                            class="ic-category" alt="Category Icon" />
                                    </td>
                                </ng-container>
        
                                <ng-container matColumnDef="title_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text deposit-text "> {{element.title}}</span>
                                    </td>
                                </ng-container>
        
                                <ng-container matColumnDef="amount_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text deposit-text">{{element.totalAmount}} {{element.currency | currencySymbol}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="feedType_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text deposit-text">
                                            {{ element.periodic ? ('DEPOSIT.FEED_PER' | translate) : ('DEPOSIT.FEED_PON' | translate)}}
                                            {{ element.frequencyType ? '- ' + ('DEPOSIT.FREQUENCY_TYPE.' + element.frequencyType | translate) : ''}}
                                        </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="date_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text deposit-text"  [matTooltip]="'DEPOSIT.TOOLTIP_DATE' | translate">{{element.startDate}}</span>
                                    </td>
                                </ng-container>
        
                                <ng-container matColumnDef="history_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text deposit-text colored pointer" (click)="depositHistory(element)">{{'DEPOSIT.TITLE_HISTORY' | translate}}</span>
                                    </td>
                                </ng-container>
        
                                <ng-container matColumnDef="details_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text deposit-text colored pointer" (click)="depositDetails(element)">{{'DEPOSIT.DETAIL' | translate}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="wallet_deposit">
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text deposit-text colored pointer" (click)="depositWalletDetails(element)">{{'DEPOSIT.WALLET' | translate}}</span>
                                    </td>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: depositsDisplayedColumns;" class="table-row"></tr>
                            </table>
                        </div>

                        <mat-paginator class="deposit-tab mt-4" *ngIf="depositsLength > 6"  #paginator [showFirstLastButtons]="true" [length]="depositsLength" 
                            [pageIndex]="page.pageIndex" [pageSize]="page.pageSize"  [hidePageSize]="true" (page)="pageEvent = handlePage($event)">
                        </mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #NO_READ_PERMISSION>
    <div class="none-block-content bg-white">
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../assets/images/vide/vide@2x.png"
                    srcset="../../../assets/images/vide/vide@2x.png 2x,
                    ../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p>
            </div>     
        </div>
    </div>
</ng-template>