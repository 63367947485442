<div class="content">
    <div class="full-height bg-white">
        <div class="beneficiaries-container card">
            <div class="bold card-header">
                {{'BENEFICIARIES.HEADER' | translate}}
            </div>

            <div class="beneficiaries-content" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                <div class="spinner" *ngIf="loading">
                    <mat-spinner [diameter]="40"></mat-spinner>
                </div>

                <div class="beneficiaries" *ngIf="!loading" >
                    <div class="none-block" *ngIf="beneficiariesLength <= 0" >
                        <img src="../../../assets/images/vide/vide.png"
                            srcset="../../../assets/images/vide/vide@2x.png 2x, 
                            ../../../assets/images/vide/vide@3x.png 3x"
                            class="none" alt="none">
                        <p class="text none-text">{{'BENEFICIARIES.NONE' | translate}}</p>
                    </div>

                    <ngx-simplebar class="benifs-content" *ngIf="beneficiariesLength > 0" >
                        <div class="benifs-tab">
                            <table mat-table [dataSource]="this.beneficiaries" class="mat-elevation-z8 tab-beneficiaries">
                                <!-- Name Column -->
                                <ng-container matColumnDef="name" >
                                    <td mat-cell *matCellDef="let element"><span class="{{ element.active ? 'text tab' : 'text tab inactive'}}"> {{element.lastName}} {{element.firstName}}</span> </td>
                                </ng-container>
                        
                                <!-- Iban Column -->
                                <ng-container matColumnDef="iban">
                                    <td mat-cell *matCellDef="let element"><span class="{{ element.active ? 'text tab' : 'text tab inactive'}}"> <span style="font-weight: 600;">{{'BENEFICIARIES.IBAN' | translate}}</span>       {{element.iban}}</span></td>
                                </ng-container>
                        
                                <!-- BIC Column -->
                                <ng-container matColumnDef="bic">
                                    <td mat-cell *matCellDef="let element"><span class="{{ element.active ? 'text tab' : 'text tab inactive'}}"> <span style="font-weight: 600;">{{'BENEFICIARIES.BIC' |translate}}</span>       {{element.bic}}</span></td>
                                </ng-container>
                                <tr mat-row *matRowDef="let row; columns: beneficiariesDisplayedColumns;"  class="table-row"></tr>
                            </table>
                        </div>
                    </ngx-simplebar>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
                srcset="../../../assets/images/vide/vide@2x.png 2x,
                ../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
    </div>
</ng-template>