import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private baseUrl =  environment.baseUrl;

  constructor(private http: HttpClient) { }

  getCurrency(): any {
    return this.http.get(this.baseUrl + `/application/appconfig/currency`);
  }

  updateCurrency(payload: any): any {
    // const params = new HttpParams().set('currnency', payload);
    const body = { currency: payload };
    return this.http.post(this.baseUrl + `/application/appconfig/currency`, body);
  }

  getLanguage(): any {
    return this.http.get(this.baseUrl + `/application/appconfig/language`);
  }

  updateLanguage(payload: any): any {
    // const params = new HttpParams().set('language', payload);
    const body = { language: payload };
    return this.http.post(this.baseUrl + `/application/appconfig/language`, body);
  }

  getTimezone(): any {
    return this.http.get(this.baseUrl + `/application/appconfig/timeZone`);
  }

  updateTimezone(payload: any): any {
    // const params = new HttpParams().set('timeZone', payload);
    const body = { timeZone: payload };
    return this.http.put(this.baseUrl + `/application/appconfig/timeZone`, body);
  }
}
