import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsComponent } from './news/news.component';
import { MccComponent } from './mcc/mcc.component';
import { ApplicationComponent } from './application.component';
import { RouterModule } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { EffectsModule } from '@ngrx/effects';
import { NewsEffects } from '../core/store/news/news.effects';
import { OffersComponent } from './offers/offers.component';
import { OffersEffects } from '../core/store/offers/offers.effects';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NewOfferComponent } from './offers/new-offer/new-offer.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { AngularFileUploaderModule } from 'angular-file-uploader';
import { UpdateOfferComponent } from './offers/update-offer/update-offer.component';
import { AddNewsComponent } from './news/add-news/add-news.component';
import { UpdateNewsComponent } from './news/update-news/update-news.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SimplebarAngularModule } from 'simplebar-angular';
import { MatTableModule } from '@angular/material/table';
import { MccEffects } from '../core/store/mcc/mcc.effects';
import { PackageEffects } from '../core/store/package/package.effects';
import { OperationEffects } from '../core/store/operation/operation.effects';
import { CommissionEffects } from '../core/store/commission/commission.effects';
import { PackagesComponent } from './packages/packages.component';
import { CommissionComponent } from './packages/commission/commission.component';
import { OperationComponent } from './packages/operation/operation.component';
import { PackageComponent } from './packages/package/package.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { TimeLanguageCurrencyComponent } from './time-language-currency/time-language-currency.component';
import { DateTimeComponent } from './time-language-currency/date-time/date-time.component';
import { LanguageComponent } from './time-language-currency/language/language.component';
import { CurrencyComponent } from './time-language-currency/currency/currency.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AppConfigEffects } from '../core/store/app-config/app-config.effects';
import { PackageClientAssignmentComponent } from './packages/package-client-assignment/package-client-assignment.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {MatIconModule} from '@angular/material/icon';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { CreateOperationComponent } from './packages/operation/create-operation/create-operation.component';
import { NewPackageComponent } from './packages/package/new-package/new-package.component';
import { TranslateModule } from '@ngx-translate/core';
import { UpdateMccDialogComponent } from './mcc/update-mcc-dialog/update-mcc-dialog.component';


@NgModule({
  declarations: [
    ApplicationComponent,
    NewsComponent,
    MccComponent,
    OffersComponent,
    NewOfferComponent,
    UpdateOfferComponent,
    AddNewsComponent,
    UpdateNewsComponent,
    PackagesComponent,
    CommissionComponent,
    OperationComponent,
    PackageComponent,
    TimeLanguageCurrencyComponent,
    DateTimeComponent,
    LanguageComponent,
    CurrencyComponent,
    PackageClientAssignmentComponent,
    CreateOperationComponent,
    NewPackageComponent,
    UpdateMccDialogComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MatTabsModule,
    EffectsModule.forFeature([
      NewsEffects,
      OffersEffects,
      MccEffects,
      PackageEffects,
      OperationEffects,
      CommissionEffects,
      AppConfigEffects
    ]),
    CodemirrorModule,
    AngularFileUploaderModule,
    MatProgressSpinnerModule,
    SimplebarAngularModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatDatepickerModule,
    MatPaginatorModule,
    NgxDatatableModule,
    MatIconModule,
    MatCheckboxModule,
    TranslateModule
  ]
})
export class ApplicationModule { }
