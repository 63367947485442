import { Action } from '@ngrx/store';

export const LOAD_BENEFICIARIES_ATTEMPTED = '[Beneficiaries] Load beneficiaries attempted';
export const LOAD_BENEFICIARIES_SUCCESSFUL = '[beneficiaries] Load beneficiaries successful';
export const LOAD_BENEFICIARIES_FAILED = '[Beneficiaries] Load beneficiaries failed';

export class LoadBeneficiariesAttemptedAction implements Action {
    readonly type = LOAD_BENEFICIARIES_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadBeneficiariesSuccesfulAction implements Action {
    readonly type = LOAD_BENEFICIARIES_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadBeneficiariesFailedAction implements Action {
    readonly type = LOAD_BENEFICIARIES_FAILED;
    constructor(public payload: any) {}
}

export type BeneficiariesActions = 
    |LoadBeneficiariesAttemptedAction
    |LoadBeneficiariesSuccesfulAction
    |LoadBeneficiariesFailedAction
;
