import { ContactService } from './../../services/contact/contact.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromContact from './contact.actions';

@Injectable()
export class ContactEffects {

    constructor(private actions$: Actions, private contactService: ContactService) {}

    @Effect()
    loadChatHistoryAttempted$ = this.actions$.pipe(
        ofType<fromContact.LoadChatHistoryAttemptedAction>(fromContact.LOAD_CHAT_HISTORY_ATTEMPTED),
        switchMap((data: fromContact.LoadChatHistoryAttemptedAction) => this.contactService.getChatHistory(data.payload)
            .pipe(
                map(resp => new fromContact.LoadChatHistorySuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromContact.LoadChatHistoryFailedAction(err)); })
            )
        )
    );

    @Effect()
    sendMessageAttempted$ = this.actions$.pipe(
        ofType<fromContact.SendMessageAttemptedAction>(fromContact.SEND_MESSAGE_ATTEMPTED),
        switchMap((data: fromContact.SendMessageAttemptedAction) => this.contactService.send(data.payload)
            .pipe(
                map(resp => new fromContact.SendMessageSuccessfulAction(resp)),
                catchError(err => {console.log(err); return of (new fromContact.SendMessageFailedAction(data.payload)); })
            )
        )
    );

    @Effect()
    loadClientTicketsAttempted$ = this.actions$.pipe(
        ofType<fromContact.LoadClientTicketsAttemptedAction>(fromContact.LOAD_CLIENT_TICKETS_ATTEMPTED),
        switchMap((data: fromContact.LoadClientTicketsAttemptedAction) => this.contactService.loadClientTickets(data.idClient)
            .pipe(
                map(resp => new fromContact.LoadClientTicketsSuccessfulAction(resp)),
                catchError(err =>  of (new fromContact.LoadClientTicketsFailedAction(err)))
            ))
    );

    @Effect()
    createClientTicketAttempted$ = this.actions$.pipe(
        ofType<fromContact.CreateClientTicketAttemptedAction>(fromContact.CREATE_CLIENT_TICKET_ATTEMPTED),
        switchMap((data: fromContact.CreateClientTicketAttemptedAction) => this.contactService.createTicket(data.idClient, data.payload)
            .pipe(
                map(resp => new fromContact.CreateClientTicketSuccessfulAction(resp)),
                catchError(err => of (new fromContact.CreateClientTicketsFailedAction(err)))
            ))
    );

    @Effect()
    deleteClientTicketAttempted$ = this.actions$.pipe(
        ofType<fromContact.DeleteClientTicketAttemptedAction>(fromContact.DELETE_CLIENT_TICKET_ATTEMPTED),
        switchMap((data: fromContact.DeleteClientTicketAttemptedAction) => this.contactService.deleteTicket(data.idClient, data.idTicket)
            .pipe(
                map(resp => new fromContact.DeleteClientTicketSuccessfulAction(resp)),
                catchError(err => of (new fromContact.DeleteClientTicketFailedAction(err)))
            ))
    );

    @Effect()
    loadAllClientTicketsAttempted$ = this.actions$.pipe(
        ofType<fromContact.LoadAllClientTicketsAttemtpedAction>(fromContact.LOAD_ALL_CLIENT_TICKETS_ATTEMPTED),
        switchMap((data: fromContact.LoadAllClientTicketsAttemtpedAction) => this.contactService.loadAllTickets()
            .pipe(
                map(resp => new fromContact.LoadAllClientTicketsSuccessfulAction(resp)),
                catchError(err => of (new fromContact.LoadAllClientTicketsFailedAction(err)))
            ))
    );

}
