import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../core/store';
import { LoadAllClientTicketsAttempted, SelectTicketAction } from '../core/store/issues/issues.actions';
import { getAllTickets } from '../core/store/issues/issues.reducer';
import { filter, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { LoadBackofficeUsersAttempted } from '../core/store/user-managements/user-management.action';
import { GetUsersAttemptedAction } from '../core/store/users/users.action';
import { getBackofficeUsers } from '../core/store/user-managements/user-management-reducer';
import { getUsers } from '../core/store/users/users.reducer';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TicketDetailsDialogComponent } from './ticket-details-dialog/ticket-details-dialog.component';

@Component({
  selector: 'app-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {

  haveReadPermission = true;

  haveWritePermission = true;

  loading: boolean;

  filterBySubject = '';

  filterByStatus = '';

  fitlerByCreatedByName = '';

  filterByClientName = '';

  selectedSubject = 'aucune';

  selectedStatus = 'aucun';

  selectedCreatedByName = 'aucun';

  selectedClientName = 'aucun';

  private ticketsSubscription;

  tickets: any[];

  ticketsLength: any;

  ticketsDisplayedColumns: string[] = ['subject_ticket', 'numero_ticket', 'title_ticket', 'content_ticket', 'clientName_ticket', 'createdByName_ticket', 'status_ticket', 'creation_ticket'];

  searchKey = '';

  pageEvent: PageEvent;

  private boUsersSubscription;

  private clientsSubscription;

  boUsersLoading: boolean;

  boUsers: any;

  boUsersLength: number;

  clientsLoading: boolean;

  clients: any;

  clientsLength: number;

  clientName = '';

  boUserName = '';

  private clientNameTerms$ = new Subject<string>();

  private boUserNameTerms$ = new Subject<string>();

  private ticketTerms$ = new Subject<string>();

  page = {
    size: 1500,
    pageNumber: 0,
    totalElements: 0,
    totalPages: 0,
    searchKey: '',
    clientFirstName: '',
    createdByName: '',
    status: '',
    subject: ''
  };

  constructor(private store: Store<AppState>, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    this.store.dispatch(new LoadAllClientTicketsAttempted(this.page));
    this.ticketsSubscription = this.store.select(getAllTickets).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('ALL TICKETS: ', data);
      this.tickets = data.content;
      this.ticketsLength = data.length;
      this.page.totalElements = data.totalElements;
      this.page.totalPages = data.totalPages;
      this.page.size = data.size;
      console.log('CURRENT PAGE SIZE: ', this.page);
      this.loading = false;
    });

    this.boUsersSubscription = this.store.select(getBackofficeUsers).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      this.boUsers = data;
      this.boUsersLength = data.length;
      this.boUsersLoading = false;
    });

    this.clientsSubscription = this.store.select(getUsers).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      this.clients = data.content;
      this.clientsLength = data.content.length;
      this.clientsLoading = false;
    });

    this.ticketTerms$.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      this.page.searchKey = term;
      this.store.dispatch(new LoadAllClientTicketsAttempted(this.page));
    });

    this.store.dispatch(new GetUsersAttemptedAction({page: 0, size: 10}));
    this.clientNameTerms$.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      if (term !== '') {
        this.store.dispatch(new GetUsersAttemptedAction({cle: this.clientName, size: 1500}));
      } else {
        this.store.dispatch(new GetUsersAttemptedAction({page: 0, size: 10}));
        /*
        this.clients = [];
        this.clientsLoading = false;
        */
      }
    });

    this.store.dispatch(new LoadBackofficeUsersAttempted());
    this.boUserNameTerms$.pipe(debounceTime(400), distinctUntilChanged()).subscribe(term => {
      if (term !== '') {
        this.store.dispatch(new LoadBackofficeUsersAttempted());
      } else {
        this.store.dispatch(new LoadBackofficeUsersAttempted());
      }
    });
  }

  filterSubject(subject: string): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.filterBySubject = subject;
    if (subject === 'SAFEDEPOSIT') {
      this.selectedSubject = 'SAFE_DEPOSIT';
      this.page.subject = 'SAFEDEPOSIT';
    } else if (subject === 'VIRTUALCARD') {
      this.selectedSubject = 'VIRTUAL_CARD';
      this.page.subject = 'VIRTUALCARD';
    } else if (subject === 'CHILDCARD') {
      this.selectedSubject = 'CHILD_CARD';
      this.page.subject = 'CHILDCARD';
    } else if (subject === 'PHYSICALCARD') {
      this.selectedSubject = 'PHYSICAL_CARD';
      this.page.subject = 'PHYSICALCARD';
    } else if (subject === 'POT') {
      this.selectedSubject = 'POT';
      this.page.subject = 'POT';
    } else if (subject === 'PERMANENTTRANSFER') {
      this.selectedSubject = 'PERMANENT_TRANSFER';
      this.page.subject = 'PERMANENTTRANSFER';
    } else if (subject === 'OTHER') {
      this.selectedSubject = 'OTHER';
      this.page.subject = 'OTHER';
    } else if (subject === 'OPPOSITION') {
      this.selectedSubject = 'OPPOSITION';
      this.page.subject = 'OPPOSITION';
    } else {
      this.selectedSubject = 'aucune';
      this.page.subject = '';
    }
    console.log('SEARCHING WITH PAGE : ', this.page);
    this.store.dispatch(new LoadAllClientTicketsAttempted(this.page));
  }

  filterStatus(status: string): void {
    this.loading = true;
    if (status === 'OPEN') {
      this.selectedStatus = 'open';
      this.page.status = 'OPEN';
    } else if (status === 'CLOSED') {
      this.selectedStatus = 'closed';
      this.page.status = 'CLOSED';
    } else if (status === 'BLOCKED') {
      this.selectedStatus = 'blocked';
      this.page.status = 'BLOCKED';
    } else {
      this.selectedStatus = 'aucun';
      this.page.status = '';
    }
    console.log('SEARCHING WITH PAGE : ', this.page);
    this.store.dispatch(new LoadAllClientTicketsAttempted(this.page));
  }

  filterCreatedByName(responsable: string): void {
    this.loading = true;
    if (responsable === 'aucun') {
      this.selectedCreatedByName = 'aucun';
      this.page.createdByName = '';
    } else {
      this.selectedCreatedByName = responsable;
      this.page.createdByName = responsable;
    }
    console.log('SEARCHING WITH PAGE : ', this.page);
    this.store.dispatch(new LoadAllClientTicketsAttempted(this.page));
  }

  filterClientName(value: string, id?: string): void {
    this.loading = true;
    if (value === 'aucun') {
      this.selectedClientName = 'aucun';
      this.page.clientFirstName = '';
    } else {
      this.selectedClientName = value;
      this.page.clientFirstName = value;
    }
    console.log('SEARCHING WITH PAGE : ', this.page);
    this.store.dispatch(new LoadAllClientTicketsAttempted(this.page));
  }

  onTicketKeyup(event: any): void {
    this.loading = true;
    this.ticketTerms$.next(event.target.value);
  }

  onClientNameKeyup(event: any): void {
    this.clientsLoading = true;
    this.clientNameTerms$.next(event.target.value);
  }

  onBOUserNameKeyup(event: any): void {
    this.boUsersLoading = true;
    this.boUserNameTerms$.next(event.target.value);
  }

  onTicketDetails(ticket: any): void {
    console.log('DISPATCHING SELECTED TICKET: ', ticket);
    this.store.dispatch(new SelectTicketAction(ticket));
    this.dialog.open(TicketDetailsDialogComponent, {
      width: '60%',
      maxHeight: '90vh',
      data: {
        value: ticket
      }
    });
  }

  setPage(pageInfo: any): void {
    this.loading = true;
    console.log('loading is: ', this.loading);
    console.log('setPage called with pageInfo: ', pageInfo);
    this.page.pageNumber = pageInfo.offset;
    this.page.searchKey = this.searchKey;
    this.page.clientFirstName = this.selectedClientName;
    this.page.createdByName = this.selectedCreatedByName;
    this.page.subject = this.selectedSubject;
    this.page.status = this.selectedStatus;
    console.log('ticket page is ', this.page);
  }

  handlePage(event: any): void {
    this.loading = true;
    console.log(event);
    this.store.dispatch(new LoadAllClientTicketsAttempted(event));
  }

  resetFilters(): void {
    this.selectedSubject = 'aucune';
    this.selectedStatus = 'aucun';
    this.selectedCreatedByName = 'aucun';
    this.selectedClientName = 'aucun';
  }

}
