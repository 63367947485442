import { Action } from '@ngrx/store';

export const LOAD_TRANSACTIONS_ATTEMTPED = '[Transactions] Load Transactions Attempted';
export const LOAD_TRANSACTIONS_FAIL = '[Transactions] Load Transactions Fail';
export const LOAD_TRANSACTIONS_SUCCESS = '[Transactions] Load Transactions Success';

export const LOAD_TRANSACTIONS_FILTERED_ATTEMTPED = '[Transactions] Load Transactions Filtered Attempted';
export const LOAD_TRANSACTIONS_FILTERED_SUCCESSFUL = '[Transactions] Load Transactions Filtered Succcessful';
export const LOAD_TRANSACTIONS_FILTERED_FAILED = '[Transactions] Load Transactions Filtered Failed';

export const LOAD_TRANSACTIONS_BY_CATEGORY_ATTEMPTED = '[Transactions] Load transactions by category attempted';
export const LOAD_TRANSACTIONS_BY_CATEGORY_SUCCESSFUL = '[Transactions] Load transactions by category successful';
export const LOAD_TRANSACTIONS_BY_CATEGORY_FAILED = '[Transactions] Load transactions by category failed';

export const SELECT_TRANSACTION = '[Transactions] Select Transactions';
export const UNSELECT_TRANSACTION = '[Transactions] Unselect Transactions';


export class LoadTransactionsAttemptedAction implements Action {
    readonly type = LOAD_TRANSACTIONS_ATTEMTPED;
    constructor(public payload: any) {}
}

export class LoadTransactionsSuccessfulAction implements Action {
    readonly type = LOAD_TRANSACTIONS_SUCCESS;
    constructor(public payload: any) {}
}

export class LoadTransactionsFailedAction implements Action {
    readonly type = LOAD_TRANSACTIONS_FAIL;
    constructor(public payload: any) {}
}

export class LoadTransactionsFilteredAttemptedAction implements Action {
    readonly type = LOAD_TRANSACTIONS_FILTERED_ATTEMTPED;
    constructor(public idClient: string, public payload: any) {}
}

export class LoadTransactionsFilteredSuccessfulAction implements Action {
    readonly type = LOAD_TRANSACTIONS_FILTERED_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadTransactionsFilteredFailedAction implements Action {
    readonly type = LOAD_TRANSACTIONS_FILTERED_FAILED;
    constructor(public payload: any) {}
}

export class SelectTransactionAction implements Action {
    readonly type = SELECT_TRANSACTION;
    constructor(public payload: any) {}
}

export class UnselectTransactionAction implements Action {
    readonly type = UNSELECT_TRANSACTION;
    constructor(public payload: any) {}
}

export class LoadTransactionsByCategoryAttemptedAction implements Action {
    readonly type = LOAD_TRANSACTIONS_BY_CATEGORY_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadTransactionsByCategorySuccessfulAction implements Action {
    readonly type = LOAD_TRANSACTIONS_BY_CATEGORY_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadTransactionsByCategoryFailedAction implements Action {
    readonly type = LOAD_TRANSACTIONS_BY_CATEGORY_FAILED;
    constructor(public payload: any) {}
}



export type TransactionActions =
    | LoadTransactionsAttemptedAction
    | LoadTransactionsSuccessfulAction
    | LoadTransactionsFailedAction
    | LoadTransactionsFilteredAttemptedAction
    | LoadTransactionsFilteredSuccessfulAction
    | LoadTransactionsFilteredFailedAction
    | SelectTransactionAction
    | UnselectTransactionAction
    |LoadTransactionsByCategoryAttemptedAction
    |LoadTransactionsByCategoryFailedAction
    |LoadTransactionsByCategorySuccessfulAction
    ;
