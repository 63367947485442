import { AppConfigService } from './../../services/app-config/app-config.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromAppConfig from './app-config.actions';

@Injectable()
export class AppConfigEffects {

    constructor(private actions$: Actions, private appConfigService: AppConfigService) {}

    @Effect()
    getAppCurrencyAttempted$ = this.actions$.pipe(
        ofType<fromAppConfig.GetAppCurrencyAttemptedAction>(fromAppConfig.GET_APP_CURRENCY_ATTEMPTED),
        switchMap((data: fromAppConfig.GetAppCurrencyAttemptedAction) => this.appConfigService.getCurrency()
            .pipe(
                map(resp => new fromAppConfig.GetAppCurrencySuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromAppConfig.GetAppCurrencyFailedAction(err)); })
            )
        )
    );

    @Effect()
    updateAppCurrencyAttempted$ = this.actions$.pipe(
        ofType<fromAppConfig.UpdateAppCurrencyAttemptedAction>(fromAppConfig.UPDATE_APP_CURRENCY_ATTEMPTED),
        switchMap((data: fromAppConfig.UpdateAppCurrencyAttemptedAction) => this.appConfigService.updateCurrency(data.payload)
            .pipe(
                map(resp => new fromAppConfig.UpdateAppCurrencySuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromAppConfig.UpdateAppCurrencyFailedAction(err)); })
            )
        )
    );

    @Effect()
    getAppLanguageAttempted$ = this.actions$.pipe(
        ofType<fromAppConfig.GetAppLanguageAttemptedAction>(fromAppConfig.GET_APP_LANGUAGE_ATTEMPTED),
        switchMap((data: fromAppConfig.GetAppLanguageAttemptedAction) => this.appConfigService.getLanguage()
            .pipe(
                map(resp => new fromAppConfig.GetAppLanguageSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromAppConfig.GetAppLanguageFailedAction(err)); })
            )
        )
    );

    @Effect()
    updateAppLanguageAttempted$ = this.actions$.pipe(
        ofType<fromAppConfig.UpdateAppLanguageAttemptedAction>(fromAppConfig.UPDATE_APP_LANGUAGE_ATTEMPTED),
        switchMap((data: fromAppConfig.UpdateAppLanguageAttemptedAction) => this.appConfigService.updateLanguage(data.payload)
            .pipe(
                map(resp => new fromAppConfig.UpdateAppLanguageSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromAppConfig.UpdateAppLanguageFailedAction(err)); })
            )
        )
    );

    @Effect()
    getAppTimezoneAttempted$ = this.actions$.pipe(
        ofType<fromAppConfig.GetAppTimezoneAttemptedAction>(fromAppConfig.GET_APP_TIMEZONE_ATTEMPTED),
        switchMap((data: fromAppConfig.GetAppTimezoneAttemptedAction) => this.appConfigService.getTimezone()
            .pipe(
                map(resp => new fromAppConfig.GetAppTimezoneSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromAppConfig.GetAppTimezoneFailedAction(err)); })
            )
        )
    );

    @Effect()
    updateAppTimezoneAttempted$ = this.actions$.pipe(
        ofType<fromAppConfig.UpdateAppTimezoneAttemptedAction>(fromAppConfig.UPDATE_APP_TIMEZONE_ATTEMPTED),
        switchMap((data: fromAppConfig.UpdateAppTimezoneAttemptedAction) => this.appConfigService.updateTimezone(data.payload)
            .pipe(
                map(resp => new fromAppConfig.UpdateAppTimezoneSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromAppConfig.UpdateAppTimezoneFailedAction(err)); })
            )
        )
    );
}