<div class="Currency-container" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
    <div class="Currency-wrapper">
        <h1 class="Currency-header">{{'APPLICATION.SETTINGS.CURRENCY.NAV' | translate}}</h1>
        <p class="text-gray">{{'APPLICATION.SETTINGS.CURRENCY.HEADER' | translate}}</p>
        <hr/>

        <div>
            <form [formGroup]="currencyForm">
                <dl class="form-group">
                    <dt class="input-label">
                        <label aria-autocomplete="off" for="currency">{{'APPLICATION.SETTINGS.CURRENCY.NAV' | translate}}</label>
                    </dt>
                    <dd class="d-flex-row">
                        <select class="form-control long" [attr.disabled]="!haveWritePermission ? true : null" formControlName="currency" id="currency" (change)="submitCurrency($event)">
                            <option *ngFor="let currency of currencies" value="{{currency}}">{{currency}}</option>
                        </select>
                        <span class="flex-centered ml-3">
                            <mat-spinner *ngIf="isCurrencySubmitting" [diameter]="16"></mat-spinner>
                            <span class="success" *ngIf="submittedSuccess">{{'APPLICATION.SETTINGS.CURRENCY.UPDATED' | translate}}</span>
                        </span>
                        <span class="flex-centered ml-3" *ngIf="error">{{'ALERT.ERROR_RETRY' | translate}}</span>
                    </dd>
                </dl>
            </form>
            <ng-container *ngIf="haveReadPermission">
                <div *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center float-left">
                    <p class="error">{{'ACCEESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                    <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #NO_READ_PERMISSION>
    <div class="Currency-container">
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../assets/images/vide/vide@2x.png"
                    srcset="../../../assets/images/vide/vide@2x.png 2x,
                    ../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
            </div>     
        </div>
    </div>
</ng-template>