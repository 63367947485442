import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as formAppConfig from './app-config.actions';

export interface AppConfigState {
    currency: any;
    currencyUpdateResponse: any;
    language: any;
    languageUpdateResponse: any;
    timezone: any;
    timezoneUpdateResponse: any;
    loading: boolean;
    error: string;
}

export const initialState: AppConfigState = {
    currency: undefined,
    currencyUpdateResponse: undefined,
    language: undefined,
    languageUpdateResponse: undefined,
    timezone: undefined,
    timezoneUpdateResponse: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: AppConfigState = initialState, action: formAppConfig.AppConfigTypes): any {
    switch (action.type) {
        case formAppConfig.GET_APP_CURRENCY_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case formAppConfig.GET_APP_CURRENCY_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                currency: action.payload
            };
        case formAppConfig.GET_APP_CURRENCY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case formAppConfig.UPDATE_APP_CURRENCY_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case formAppConfig.UPDATE_APP_CURRENCY_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                currencyUpdateResponse: action.payload
            };
        case formAppConfig.UPDATE_APP_CURRENCY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case formAppConfig.GET_APP_LANGUAGE_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case formAppConfig.GET_APP_LANGUAGE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                language: action.payload
            };
        case formAppConfig.GET_APP_LANGUAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case formAppConfig.UPDATE_APP_LANGUAGE_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case formAppConfig.UPDATE_APP_LANGUAGE_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                languageUpdateResponse: action.payload
            };
        case formAppConfig.UPDATE_APP_LANGUAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


            case formAppConfig.GET_APP_TIMEZONE_ATTEMPTED:
                return {
                    ...state,
                    loading: true,
                };
            case formAppConfig.GET_APP_TIMEZONE_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    timezone: action.payload
                };
            case formAppConfig.GET_APP_TIMEZONE_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
            case formAppConfig.UPDATE_APP_TIMEZONE_ATTEMPTED:
                return {
                    ...state,
                    loading: true,
                };
            case formAppConfig.UPDATE_APP_TIMEZONE_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    timezoneUpdateResponse: action.payload
                };
            case formAppConfig.UPDATE_APP_TIMEZONE_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };

            default:
                return state;
    }
}

export const getAppConfigState = createFeatureSelector<AppConfigState>('appConfig');

export const getCurrency = createSelector(getAppConfigState, (state: AppConfigState) => state.currency);
export const getCurrencyUpdateResponse = createSelector(getAppConfigState, (state: AppConfigState) => state.currencyUpdateResponse);

export const getLanguage = createSelector(getAppConfigState, (state: AppConfigState) => state.language);
export const getLanguageUpdateResponse = createSelector(getAppConfigState, (state: AppConfigState) => state.languageUpdateResponse);

export const getTimezone = createSelector(getAppConfigState, (state: AppConfigState) => state.timezone);
export const getTimezoneUpdateResponse = createSelector(getAppConfigState, (state: AppConfigState) => state.timezoneUpdateResponse);
