import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { GetAppLanguageAttemptedAction, UpdateAppLanguageAttemptedAction } from '../../../core/store/app-config/app-config.actions';
import { getLanguage, getLanguageUpdateResponse } from '../../../core/store/app-config/app-config.reducer';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';

const LANGUAGES = ['FR', 'EN (united states)', 'EN (united kingdom)', 'AR'];
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  languages = LANGUAGES;

  languageForm: FormGroup = new FormGroup({
    language: new FormControl(null)
  });

  isLanguageSubmitting: boolean;

  submittedSuccess = false;

  private error = false;

  private languageUpdateSubscription$;

  private selectedLanguageSubscription$;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_SETTINGS_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_SETTINGS_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.store.dispatch(new GetAppLanguageAttemptedAction());
    this.selectedLanguageSubscription$ = this.store.select(getLanguage).pipe(filter(resp => resp !== undefined)).subscribe(resp => {
      console.log('SELECTED LANGUAGE: ', resp);
      this.languageForm.patchValue({language: resp.message});
    });
    this.languageUpdateSubscription$ = this.store.select(getLanguageUpdateResponse).pipe(filter(resp => resp !== undefined))
    .subscribe(resp => {
      console.log(resp);
      this.isLanguageSubmitting = false;
      if (resp.code !== 200) {
        this.error = true;
      } else {
        this.submittedSuccess = true;
        setTimeout(() => {
          this.submittedSuccess = false;
        }, 2000);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.languageUpdateSubscription$) {
      this.languageUpdateSubscription$.unsubscribe();
    }
    if (this.selectedLanguageSubscription$) {
      this.selectedLanguageSubscription$.unsubscribe();
    }
  }

  submitLanguage(event: any): void {
    this.isLanguageSubmitting = true;
    this.submittedSuccess = false;
    console.log(event.target.value);
    this.store.dispatch(new UpdateAppLanguageAttemptedAction(event.target.value));
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }

}
