import { Router } from '@angular/router';
import { mergeMap, map, catchError, tap, switchMap } from 'rxjs/operators';
import { LoginAttemptedAction, AuthActionTypes, LoginSuccessAction, LoginFailedAction, LogoutAction, PasswordResetRequestAttemptedAction,
    PasswordResetRequestSuccessfulAction, PasswordResetRequestFailedAction, ResetPasswordAttemptedAction, ResetPasswordSuccessfulAction, ResetPasswordFailedAction } from './auth.action';
import { AuthService } from './../../services/auth/auth.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';



@Injectable()
export class AuthEffects {

    constructor(private actions$: Actions, private authService: AuthService, private router: Router) {}

    @Effect()
    loginAttempted$ = this.actions$.pipe(
        ofType<LoginAttemptedAction>(AuthActionTypes.LOGIN_ATTEMPTED),
        switchMap(data => this.authService.login(data.payload)
        .pipe(
            map( (response: any) => new LoginSuccessAction(response)),
            catchError( error => of(new LoginFailedAction(error)))
        ))
    );

    @Effect({dispatch: false})
    loginSuccessful$: Observable<any> = this.actions$.pipe(
        ofType<LoginSuccessAction>(AuthActionTypes.LOGIN_SUCCESS),
        tap(data => {
            localStorage.setItem('token', data.payload.value.token);
            localStorage.setItem('user', JSON.stringify(data.payload.value));
        })
    );

    @Effect({dispatch: false})
    onLogout = this.actions$.pipe(
        ofType<LogoutAction>(AuthActionTypes.LOGOUT),
        tap(data => {
            localStorage.clear();
            this.router.navigate(['signin']);
        })
    );

    @Effect()
    passwordResetRequestAttempted$ = this.actions$.pipe(
        ofType<PasswordResetRequestAttemptedAction>(AuthActionTypes.PASSWORD_RESET_REQUEST_ATTEMPTED),
        switchMap(data => this.authService.sendPasswordResetMail(data.payload)
            .pipe(
                map((resp: any) => new PasswordResetRequestSuccessfulAction(resp)),
                catchError(error => of (new PasswordResetRequestFailedAction(error)))
            )
        )
    );

    @Effect()
    resetPasswordAttempted$ = this.actions$.pipe(
        ofType<ResetPasswordAttemptedAction>(AuthActionTypes.RESET_PASSWORD_ATTEMPTED),
        switchMap(data => this.authService.resetPassword(data.key, data.payload)
            .pipe(
                map(resp => new ResetPasswordSuccessfulAction(resp)),
                catchError(error => of (new ResetPasswordFailedAction(error)))
            )
        )
    );
}
