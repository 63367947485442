import { Component, OnInit, OnDestroy, AfterViewChecked, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadClientTicketsAttemptedAction } from '../../../core/store/contact/contact.actions';
import { getClientTickets, getContactResponse } from '../../../core/store/contact/contact.reducer';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { NewTicketDialogComponent } from '../new-ticket-dialog/new-ticket-dialog.component';
import { SimplebarAngularComponent } from 'simplebar-angular';
import { TicketDialogComponent } from './ticket-dialog/ticket-dialog.component';
import { SelectTicketAction } from '../../../core/store/issues/issues.actions';

@Component({
  selector: 'app-client-tickets',
  templateUrl: './client-tickets.component.html',
  styleUrls: ['./client-tickets.component.scss']
})
export class ClientTicketsComponent implements OnInit, OnDestroy {

  loading: boolean;

  private clientTicketsSubscription;

  private dialogSubscription;

  private ticketSubmittedSubscription;

  tickets: any;

  ticketsLength: number;

  isTickedCreatedSuccess: boolean;

  @ViewChild('scroll')
  private scroll: ElementRef;

  constructor(private store: Store<AppState>, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new LoadClientTicketsAttemptedAction(client.id));
    this.clientTicketsSubscription = this.store.select(getClientTickets).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('TICKETS: ', data);
      this.tickets = data;
      this.ticketsLength = data.length;
      this.loading = false;
    });
    this.ticketSubmittedSubscription = this.store.select(getContactResponse).pipe(filter(resp => resp !== undefined)).subscribe(resp => {
      console.log('TICKET SUBMITTED AND SERVER RESPONSE IS: ', resp);
      if (resp.code === 200) {
        this.isTickedCreatedSuccess = true;
        this.store.dispatch(new LoadClientTicketsAttemptedAction(client.id));
      } else {
        this.isTickedCreatedSuccess = false;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.clientTicketsSubscription) {
      this.clientTicketsSubscription.unsubscribe();
    }
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  /*
  ngAfterViewChecked(): void {
    console.log(this.scroll.nativeElement.scrollHeight);
    this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
  }
  */

  onNewTicket(): void {
    const dialogRef = this.dialog.open(NewTicketDialogComponent, {
      width: '45%',
      maxHeight: '90vh',
      data: {
        type: 'NEW_TICKET_CREATION'
      }
    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        console.log('CLOSING WITH FALSE RESULT');
      } else {
        console.log('** FROM TICKET COMPONENT, RESULT IS: ', result);
        this.loading = true;
      }
    });
  }

  openTicketDialog(ticket: any): void {
    this.store.dispatch(new SelectTicketAction(ticket));
    let dialogRef = this.dialog.open(TicketDialogComponent, {
      width: '45%',
      maxHeight: '90vh',
      data: {
        value: ticket
      }
    });
  }

}
