import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-time-language-currency',
  templateUrl: './time-language-currency.component.html',
  styleUrls: ['./time-language-currency.component.scss']
})
export class TimeLanguageCurrencyComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  constructor() { }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_SETTINGS_READ') {
        this.haveReadPermission = true;
      }
    }
  }

}
