<div class="content">
    <mat-card>
        <mat-card-title>{{'LOGIN.PWD_CONFIRM_TITLE' | translate}}</mat-card-title>
        <mat-card-content class="my-0">
            <div class="d-flex direction-column align-center justify-center" *ngIf="isResetSuccess || isResetFailed">
                <div class="alert alert-success alert-dismissible w-70" role="alert" *ngIf="isResetSuccess">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.isResetSuccess = false"><span aria-hidden="true">&times;</span></button>
                    <strong>{{'LOGIN.SUCCESS' | translate}}</strong> {{'LOGIN.SUCCESS_CONFIRM_TEXT' | translate}}
                </div>
    
                <div class="alert alert-danger alert-dismissible w-70" role="alert" *ngIf="isResetFailed">
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.isResetFailed = false"><span aria-hidden="true">&times;</span></button>
                    <strong>{{'LOGIN.FAIL' | translate}}</strong> {{'LOGIN.FAIL_CONFIRM_TEXT' | translate}} <br/> {{'LOGIN.FAIL_CONFIRM_TEXT_PERSIST' | translate}}
                    <span class="link">{{'LOGIN.CONTACT_SUPPORT' | translate}}</span>
                </div>
            </div>

            <form [formGroup]="pwdResetForm" (submit)="onSubmit(); form.resetForm();" #form="ngForm" class="w-100">
                <p>{{'LOGIN.PWD_CONFIRM_TEXT' | translate}}</p>
                <mat-form-field>
                    <input matInput fullWidth [type]="hide ? 'password' : 'text'" formControlName="password" #password placeholder="{{'LOGIN.PWD' | translate}}" autocomplete="off"/>
                    <mat-icon matSuffix class="pointer" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-hint>{{'LOGIN.NEW_PWD_HINT' | translate}}</mat-hint>
                    <mat-error *ngIf="(pwdResetForm.controls['password'].hasError('required'))">{{'LOGIN.PASSWORD_REQUIRED' | translate}}</mat-error>
                    <mat-error *ngIf="pwdResetForm.controls['password'].hasError('minLength')">{{'LOGIN.PASSWORD_MIN_LENGTH' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <input matInput fullWidth [type]="hide ? 'password' : 'text'" formControlName="confirmPassword" #confirmPassword placeholder="{{'LOGIN.PWD_CONFIRM' | translate}}" autocomplete="off"/>
                    <mat-icon matSuffix class="pointer" (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    <mat-hint>{{'LOGIN.CONFIRM_PASSWORD_HINT' | translate}}</mat-hint>
                    <mat-error *ngIf="(pwdResetForm.controls['confirmPassword'].hasError('required'))">{{'LOGIN.CONFIRM_PASSWORD_REQUIRED' | translate}}</mat-error>
                    <mat-error *ngIf="(pwdResetForm.controls['confirmPassword'].hasError('NoPasswordMatch'))">{{'LOGIN.NO_PASSWORD_MATCH' | translate}}</mat-error>
                </mat-form-field>
                <br />

                <button mat-raised-button [disabled]="pwdResetForm.invalid" type="submit" color="primary" fullWidth status="success" [class.btn-pulse]="submitted" >
                    {{'LOGIN.CONFIRM' | translate}}
                </button>

                <div class="spinner" *ngIf="sending" >
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
            </form>
            <div class="d-flex direction-column align-center justify-center f3 my-1">
                <p class="m-0 link" (click)="onConnect()">{{'LOGIN.LOGIN' | translate}}</p>
            </div>
        </mat-card-content>
    </mat-card>
</div>