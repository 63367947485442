import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getChatHistory(payload: {idClient: string, page: number}): any {
    console.log('web service called with payload: ', payload);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/chat/history`);
  }

  send(payload: {idClient: string, content: string}): any {
    console.log('message sent with payload: ', {content: payload.content});
    return this.http.post(this.baseUrl + `/clients/${payload.idClient}/chat/send`, {content: payload.content});
  }

  loadClientTickets(idClient: string): any {
    console.log('LOAD CLIENT TICKETS LOADING WITH PAYLOAD: ', idClient);
    return this.http.get(this.baseUrl + `/clients/${idClient}/ticket/`);
  }

  createTicket(idClient: string, payload: any): any {
    return this.http.post(this.baseUrl + `/clients/${idClient}/ticket/`, payload);
  }

  deleteTicket(idClient: string, idTicket: string): any {
    return this.http.delete(this.baseUrl + `/clients/${idClient}/ticket/${idTicket}`);
  }

  loadAllTickets(): any {
    return this.http.get(this.baseUrl + `/tickets`);
  }

}
