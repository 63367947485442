import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { UpdateAppTimezoneAttemptedAction, GetAppTimezoneAttemptedAction } from '../../../core/store/app-config/app-config.actions';
import { getTimezone, getTimezoneUpdateResponse } from '../../../core/store/app-config/app-config.reducer';
import { filter } from 'rxjs/operators';

const TIME_ZONES = [
  {offset: 'UTC+01:00', name: 'West Africa Time', location: 'Africa', abbreviation: 'WAT', value: '(UTC+01:00) West Africa Time'},
  {offset: 'UTC+12:00', name: 'Wake Time', location: 'Pacific', abbreviation: 'WAKT', value: '(UTC+12:00) Wake Time'},
  {offset: 'UTC-03:00', name: 'Western Argentine Summer Time', location: 'South America', abbreviation: 'WARST', value: '(UTC-03:00) Western Argentine Summer Time'},
  {offset: 'UTC+02:00', name: 'West Africa Summer Time', location: 'Africa', abbreviation: 'WAST', value: '(UTC+02:00) West Africa Summer Time'},
];

@Component({
  selector: 'app-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss']
})
export class DateTimeComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  now: Date = new Date();

  offset: any = new Date().getTimezoneOffset();

  timeZones: any[] = TIME_ZONES;

  dateTimeForm: FormGroup = new FormGroup({
    timeZone: new FormControl(null)
  });

  isTimeZoneSubmitting: boolean;

  submittedSuccess = false;

  error = false;

  private selectedTimezoneSubscription$;

  private timezoneUpdateSubscription$;


  constructor(private store: Store<AppState>) {
    setInterval(() => {
      this.now = new Date();
      this.offset = new Date().getTimezoneOffset();
    }, 1);
  }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_SETTINGS_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_SETTINGS_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.store.dispatch(new GetAppTimezoneAttemptedAction());
    this.selectedTimezoneSubscription$ = this.store.select(getTimezone).pipe(filter(resp => resp !== undefined)).subscribe(resp => {
      console.log('SELECTED TIMEZONE:', resp);
      this.dateTimeForm.patchValue({timeZone: resp.message});
    });
    this.timezoneUpdateSubscription$ = this.store.select(getTimezoneUpdateResponse).pipe(filter(resp => resp !== undefined))
    .subscribe(resp => {
      console.log(resp);
      this.isTimeZoneSubmitting = false;
      if (resp.code !== 200) {
        this.error = true;
      } else {
        this.submittedSuccess = true;
        setTimeout(() => {
          this.submittedSuccess = false;
        }, 2000);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.selectedTimezoneSubscription$) {
      this.selectedTimezoneSubscription$.unsubscribe();
    }
    if (this.timezoneUpdateSubscription$) {
      this.timezoneUpdateSubscription$.unsubscribe();
    }
  }

  submitTimezone(event: any): void {
    this.isTimeZoneSubmitting = true;
    this.submittedSuccess = false;
    console.log(event.target.value);
    this.store.dispatch(new UpdateAppTimezoneAttemptedAction(event.target.value));
  }

  onSeePermissions(): void {}

}
