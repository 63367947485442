import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromAccount from './account.action';
import { AccountService } from '../../services/account/account.service';

@Injectable()
export class AccountEffects {

    constructor(private actions$: Actions, private accountService: AccountService) {}

    @Effect()
    loadAccountAttempted$ = this.actions$.pipe(
        ofType(fromAccount.LOAD_ACCOUNT_ATTEMPTED),
        switchMap((data: fromAccount.LoadAccountAttemptedAction) => {
            return this.accountService.getAccount(data.payload)
                .pipe(
                    map(resp => new fromAccount.LoadAccountSuccessfulAction(resp)),
                    catchError(error => of(new fromAccount.LoadAccountFailedAction(error)))
                );
        })
    );

}
