<div class="assignments-container">
    <div class="assignment-content">
        <div class="spinner" *ngIf="loading">
            <mat-spinner [diameter]="40"></mat-spinner>
        </div>

        <div class="assignments" *ngIf="!loading">
            <div class="Subhead mb-5">
                <h1 class="Subhead-heading"><mat-icon class="ic-nav mr-3" (click)="goBack()">arrow_back</mat-icon> Affectation de clients à un package</h1>
                <div class="Subhead-description">Veuillez sélectionner les clients à attribuer au package 
                    <span *ngIf="selectedPackage" >({{selectedPackage.label}})</span>
                    <span type="button" class="muted-link ml-3" *ngIf="this.selected.length >= 1 && assignDisabled && !clientsLoading" (click)="enableUpdate()">
                        <i class="fa fa-edit" width="16" height="16"></i> Modifier
                    </span>
                    <button type="button" class="btn btn-sm btn-success float-right" [disabled]="this.selected.length < 1 || (this.selected.length >= 1 && assignDisabled) || assignCompleted" (click)="onAssignClients()"> ({{selected.length}}) Continuer <i class="fas fa-arrow-right"></i> </button>
                </div>
            </div>
            
            <div *ngIf="showPopupMessage"  class="full-width d-flex align-center justify-center bg-white primary">
                L'affectation des client à été éffectuer avec succés !
            </div>

            <div class="Top-bar bg-white">
                <mat-form-field appearance="legacy" class="search-form-field">
                    <mat-label>Rechercher des utilisateurs backoffice</mat-label>
                    <input matInput type="text" [(ngModel)]="key" (ngModelChange)="filter()" autocomplete="off">
                    <button mat-button *ngIf="key" matSuffix mat-icon-button (click)="key=''; filter()">
                      <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>

            <ngx-datatable *ngIf="!loading"  class="bootstrap" style="display: flex; align-items: center; justify-content: center; width: 90%;"
                            [rows]="clients"
                            [columnMode]="ColumnMode.force" 
                            [messages]="messages"
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            rowHeight="auto" 
                            [selected]="selected" 
                            [selectionType]="SelectionType.checkbox"
                            [loadingIndicator]="this.clientsLoading"
                            [selectAllRowsOnPage]="false"
                            (select)="onSelect($event)"
                            [externalPaging]="true"
                            [count]="page.totalElements"
                            [offset]="page.pageNumber"
                            [limit]="page.size"
                            (page)="setPage($event)"
                            [rowIdentity]="getId"
                            [limit]="6">
                <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false" [resizeable]="false">
                    <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected" let-selectFn="selectFn">
                        <mat-checkbox type="checkbox" [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)" [disabled]="this.assignDisabled"> </mat-checkbox>
                    </ng-template>

                    <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected" let-onCheckboxChangeFn="onCheckboxChangeFn">
                        <mat-checkbox type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)" [disabled]="this.assignDisabled"> </mat-checkbox>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column prop="id" name="ID"></ngx-datatable-column>
                <ngx-datatable-column prop="firstname" name="Prénom"></ngx-datatable-column>
                <ngx-datatable-column prop="lastname" name="Nom"></ngx-datatable-column>
                <ngx-datatable-column prop="email" name="Email"></ngx-datatable-column>
                <ngx-datatable-column prop="numTel" name="Numéro de téléphone"></ngx-datatable-column>
                <ngx-datatable-column prop="userStatusName" name="Status"></ngx-datatable-column>
            </ngx-datatable>
        </div>
    </div>
</div>
