import { CardsService } from './../../services/cards/cards.service';
import { MatDialog } from '@angular/material/dialog';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromCards from './cards.actions';

import {  } from './cards.actions';

@Injectable()
export class CardsEffects {

    constructor(private actions$: Actions, private cardsService: CardsService, public dialog: MatDialog) {}

    @Effect()
    loadPhysicalCardsAttempted$ = this.actions$.pipe(
        ofType<fromCards.LoadPhysicalCardsAttemptedAction>(fromCards.LOAD_PHYSICAL_CARDS_ATTEMPTED),
        switchMap(data => this.cardsService.getPhysicalCards(data.payload)
            .pipe(
                map(resp => new fromCards.LoadPhysicalCardsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCards.LoadPhysicalCardsFailedAction(err)); })
            )
        )
    );

    @Effect()
    loadVirtualCardsAttempted$ = this.actions$.pipe(
        ofType<fromCards.LoadVirtualCardsAttemptedAction>(fromCards.LOAD_VIRTUAL_CARDS_ATTEMPTED),
        switchMap(data => this.cardsService.getVirtualCards(data.payload)
            .pipe(
                map (resp => new fromCards.LoadVirtualCardsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCards.LoadVirtualCardsFailedAction(err)); })
            )
        )
    );

    @Effect()
    loadChildsAttempted$ = this.actions$.pipe(
        ofType<fromCards.LoadChildsAttemptedAction>(fromCards.LOAD_CHILDS_ATTEMPTED),
        switchMap(data => this.cardsService.getChilds(data.payload)
            .pipe(
                map (resp => new fromCards.LoadChildsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCards.LoadChildsFailedAction(err)); })
            )
        )
    );

    @Effect()
    loadChildCardsAttempted$ = this.actions$.pipe(
        ofType<fromCards.LoadChildCardsAttemptedAction>(fromCards.LOAD_CHILD_CARDS_ATTEMPTED),
        switchMap(data => this.cardsService.getChildCards(data.payload)
            .pipe(
                map (resp => new fromCards.LoadChildCardsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCards.LoadChildCardsFailedAction(err)); })
            )
        )
    );

    @Effect()
    getWalletAttempted$ = this.actions$.pipe(
        ofType<fromCards.GetWalletAttemptedAction>(fromCards.GET_WALLET_ATTEMPTED),
        switchMap(data => this.cardsService.getWallet(data.payload)
            .pipe(
                map (resp => new fromCards.GetWalletSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromCards.GetWalletFailedAction(err)); })
            )
        )
    );
}
