<div class="wrapper">
    <!--
    <div class="panel panel-default border">
        <div class="panel-heading">
            <div class="panel-title">{{'HOME.REGISTRATION_DOCUMENTS.CLIENT' | translate}}: <span *ngIf="this.client" class="font name link" (click)="onNavClientInformations()">{{client.firstname}} {{client.lastname}}/{{client.id}}</span></div>
            <div class="document-info-divider"></div>
            <div class="panel-title">{{'HOME.REGISTRATION_DOCUMENTS.FOLDER_STATUS' | translate}}: <span class="bold">{{this.userStatusName}}</span></div>
            // this.userStatusName !== 'RECTIFICATION' &&  
            <span class="document-info-divider" *ngIf="this.verifiedDocuments.length > 0"></span>
            <div class="panel-title" *ngIf="this.verifiedDocuments.length > 0" >{{'HOME.REGISTRATION_DOCUMENTS.RECTIFIED_DOCS' | translate}}: 
                <div class="panel-link" *ngFor="let name of verifiedDocuments; let lastFile = last" (click)="onFileLinkClicked(name)">
                    <span [ngClass]="{'panel-link-text': true}">{{name}}</span>
                    <span *ngIf="!lastFile">&nbsp;,&nbsp;</span>
                </div>
            </div>
        </div>
    </div>
-->
    

    <div  *ngIf="loading" class="spinner">
        <mat-spinner [diameter]="45"></mat-spinner>
    </div>
                
    <div *ngIf="!loading">
        <div  class="none-block" *ngIf="folderEmpty">
            <div class="none-inner-block">
                <img src="../../../../assets/images/vide/vide@2x.png"
                srcset="../../../../assets/images/vide/vide@2x.png 2x,
                ../../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text">{{ 'HOME.REGISTRATION_DOCUMENTS.CHILDS_NONE' | translate }} </p> 
            </div>     
        </div>
    
        <div *ngIf="!loading" style="width: 100%; margin-top: 2%;">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-container">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <li class="mat-tree-node">
                        <button mat-icon-button disabled></button>
                        <div class="container-fluid">
                            <div class="row file-item"  (click)="visualiseDocument(node)">
                                <i class="far fa-file-alt octicon"></i>
                                <div class="col">{{node.name}}</div>
                                <div class="col">{{node.imageState}}</div>
                            </div>
                        </div>
                    </li>
                </mat-tree-node>
    
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <li class="folder-item">
                        <div>
                            <button mat-button matTreeNodeToggle [attr.aria-label]="'toggle' + node.name" class="f-0">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                                {{ 'HOME.REGISTRATION_DOCUMENTS.' + node.identity | translate}}
                            </button>
                                        
                        </div>
                         <!--           
                        <div class="divergence-graph hidden-xs" *ngIf="this.userStatusName === 'IDENTITY_PENDING'"  title={{node.title}}>
                            <div class="graph-side">
                                <div class="bar bar-behind" [ngStyle]="{'width' : node.behindBarWidth}"></div>
                                <span class="count count-behind">{{node.behindFilesCount}}</span>
                            </div>
                            <div class="graph-separator"></div>
                            <div class="graph-side">
                                <div class="bar bar-ahead" [ngStyle]="{'width' : node.aheadBarWidth}"></div>
                                <span class="count count-ahead">{{node.aheadFilesCount}}</span>
                            </div>
                        </div>
                        -->
                    </li>
                    <ul class="folder" [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
    </div>

    <div class="documents-actions" fxLayout="row" fxLayoutAlign="center center" *ngIf="!folderEmpty && this.userStatusName === 'IDENTITY_PENDING' && !loading">
        <button mat-raised-button class="text documents-action" (click)="envoyerRectification()"
            [disabled]="!haveDocumentsWritePermission || (haveDocumentsWritePermission && (userStatusName === 'RECTIFICATION' || this.folderEmpty ||
            this.verifiedRectifications.length < 1))" >
            ({{this.verifiedRectifications.length}}) {{'HOME.REGISTRATION_DOCUMENTS.SEND_RECTIFICATION' | translate}}
        </button>
        <button mat-raised-button class="text documents-action" *ngIf="userStatusName === 'IDENTITY_PENDING'" (click)="sendToKYC()">{{'HOME.REGISTRATION_DOCUMENTS.SEND_KYC' | translate}}</button>
    </div>
</div>