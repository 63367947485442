<div class="content">
    <div class="full-height bg-white" *ngIf="haveDocumentsReadPermission; else NO_READ_PERMISSION" >
        <div class="documents-wrapper">
            <div class="Nav">
                <div class="Nav-item pointer" [routerLink]="['/clients/details/documents/registration-documents']" [routerLinkActive]="['border-bottom']">
                    <span class="Nav-label">{{'HOME.REGISTRATION_DOCUMENTS.REGISTRATION_DOCS' | translate}}</span>
                </div>

                <div class="Nav-item pointer" [routerLink]="['/clients/details/documents/child-documents']" [routerLinkActive]="['border-bottom']">
                    <span class="Nav-label">{{'HOME.REGISTRATION_DOCUMENTS.CHILD_DOCS' | translate}}</span>
                </div>

                <div class="Nav-item pointer" [routerLink]="['/clients/details/documents/address-documents']" [routerLinkActive]="['border-bottom']">
                    <span class="Nav-label">{{'HOME.REGISTRATION_DOCUMENTS.ADDRESS_DOCS' | translate}}</span>
                </div>
            </div>

            <div class="documents-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
        
        <div *ngIf="!this.haveDocumentsWritePermission" class="d-flex justify-center mt-1">
            <p class="error">{{'HOME.REGISTRATION_DOCUMENTS.NO_WRITE_PERMISSION' | translate}}.</p> &nbsp;
            <span class="link f-1"> ({{'HOME.REGISTRATION_DOCUMENTS.MY_PERMISSIONS' | translate}}) </span>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div class="full-height bg-white">
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../../assets/images/vide/vide@2x.png"
                srcset="../../../../assets/images/vide/vide@2x.png 2x,
                ../../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
            </div>     
        </div>
    </div>
</ng-template>