import { ClearStateAction } from './../../../core/store/app/app.action';
import { ProgressbarService } from './../../../core/services/shared/progressbar.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/core/store';
import { NgProgress } from '@ngx-progressbar/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private store: Store<AppState>, private router: Router, private progress: NgProgress,
              public progressBarService: ProgressbarService) { }

  ngOnInit(): void {
    this.progressBarService.progressRef = this.progress.ref('progressBar');
  }

  logout(): void {
    this.store.dispatch(new ClearStateAction());
    localStorage.clear();
    this.router.navigate(['/signin']);
    console.log('logout dispatched');
  }

}
