import { Component, Inject, OnInit, HostListener, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LogoutAction } from '../../../core/store/auth/auth.action';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CreateClientTicketAttemptedAction } from '../../../core/store/contact/contact.actions';

export interface NewTicketDialogData {
  message: string;
  value: any;
  type: string;
  align: string;
  id: string;
}

@Component({
  selector: 'app-new-ticket-dialog',
  templateUrl: './new-ticket-dialog.component.html',
  styleUrls: ['./new-ticket-dialog.component.scss']
})
export class NewTicketDialogComponent implements OnInit {

  ticketForm: FormGroup;

  submitting: boolean;

  constructor(@Inject (MAT_DIALOG_DATA) public data: NewTicketDialogData, private dialogRef: MatDialogRef<NewTicketDialogComponent>,
              private store: Store<AppState>, private fb: FormBuilder) {
    this.ticketForm = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      content: [null],
      subject: [null, Validators.compose([Validators.required])],
      createdByName: [null, Validators.compose([Validators.required])],
      clientFirstName: [null, Validators.compose([Validators.required])],
      clientLastName: [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    const client = JSON.parse(localStorage.getItem('client'));
    const user = JSON.parse(localStorage.getItem('account'));
    this.ticketForm.patchValue({
      createdByName: user.fullname,
      clientFirstName: client.firstname,
      clientLastName: client.lastname
    });
    this.ticketForm.get('createdByName').disable();
    this.ticketForm.get('clientFirstName').disable();
    this.ticketForm.get('clientLastName').disable();
  }

  onSubmit(): void {
    this.submitting = true;
    const client = JSON.parse(localStorage.getItem('client'));
    const user = JSON.parse(localStorage.getItem('account'));
    const payload = {
      title: this.ticketForm.get('title').value,
      subject: this.ticketForm.get('subject').value,
      content: this.ticketForm.get('content').value,
      clientID: client.id,
      clientEmail: client.email,
      clientFirstName: client.firstname,
      clientclientLastName: client.lastname
    };
    console.log('SUBMITTING TICKET PAYLOAD: ', payload);
    this.store.dispatch(new CreateClientTicketAttemptedAction(client.id, payload));
    this.dialogRef.close(payload);
  }

  onCancel(): void {
    this.close(false);
  }

  close(value: any): void {
    this.dialogRef.close(value);
  }

  onError(): void {
    this.close(false);
    this.store.dispatch(new LogoutAction());
  }

  @HostListener('keydown.esc')
  onEsc(): void {
    this.close(false);
  }
}
