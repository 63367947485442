<div class="content">
    <div class="full-height bg-white">
        <div class="container" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
            <form [formGroup]="offerForm" id="new-offer" aria-label="Créer un nouveau offre" style="padding: 18px 0;">
                <div class="Subhead mb-5">
                    <h1 class="Subhead-heading">{{'APPLICATION.OFFERS.SUBHEADER' | translate}}
                        <span class="muted-link ml-3 f1" [ngClass]="{'muted-link': haveWritePermission, 'disabled': !haveWritePermission}" *ngIf="action === 'show'"  (click)="enableUpdate()">
                            <i class="fa fa-edit" width="16" height="16"></i>
                            {{'BUTTONS.UPDATE' | translate}}
                        </span>
                    </h1>
                    <!-- <div class="Subhead-description">{{'APPLICATION.OFFERS.SUBHEADER_DESCRIPTION' | translate}}</div> -->
                </div>

                <div class="clearfix">
                    <dl class="form-group float-left mt-1">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="offer-title">{{'APPLICATION.OFFERS.TITLE' | translate}} <span class="error">*</span></label>
                        </dt>
                        <dd>
                            <input [ngClass]="{'border-error' : this.isFieldValid('title')}" class="form-control long" formControlName="title" autocomplete="off" type="text" id="offer-title">
                        </dd>
                    </dl>

                    <span class="float-left pt-4 mx-2 f2 mt-1">/</span>

                    <dl class="form-group float-left mt-1">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="offer-expiration">{{'APPLICATION.OFFERS.EXPIRATION_DATE' | translate}} <span class="error">*</span></label>
                        </dt>
                        <dd>
                            <input [ngClass]="{'border-error' : this.isFieldValid('expiration')}" class="form-control short" formControlName="expiration" autocomplete="off" type="datetime-local" id="offer-expiration">
                        </dd>
                    </dl>
                </div>

                <div class="clearfix"></div>

                <div>
                    <hr aria-hidden="true">
                    <h3 class="h5">{{'APPLICATION.OFFERS.TYPE_CHOICE' | translate}} <span class="error">*</span></h3>
                    <!-- <p class="text-gray">{{'APPLICATION.OFFERS.ONE_TYPE' | translate}}</p> -->
                    <div class="form-checkbox">
                        <label (click)="onContentTypeChecked('Link')">
                            <input [ngClass]="{'border-error' : this.isFieldValid('contentType')}" class="mt-2" type="radio" value="LINK" formControlName="contentType">
                            {{'APPLICATION.OFFERS.LINK' | translate}}
                        </label>
                        <span class="note">{{'APPLICATION.OFFERS.LINK_NOTE' | translate}}</span>
                        <span class="tabpanel" *ngIf="linkIsChecked">
                            <div class="input-group" style="display: table;">
                                <input [disabled]="action === 'show'" [(ngModel)]="link" [ngModelOptions]="{standalone: true}" type="text" class="form-control input-monospace input-sm bg-gray-light">
                            </div>
                            <p class="mt-2 mb-0 f6 text-gray">{{'APPLICATION.OFFERS.ENTER_LINK' | translate}}</p>
                        </span>
                    </div>
                    <div class="form-checkbox">
                        <label (click)="onContentTypeChecked('HTML', $event)">
                            <input [ngClass]="{'border-error' : this.isFieldValid('contentType')}"  class="mt-2" type="radio" value="HTML" formControlName="contentType">
                            {{'APPLICATION.OFFERS.HTML' | translate}}
                        </label>
                        <span class="note">{{'APPLICATION.OFFERS.HTML_NOTE' | translate}}</span>
                        <span class="form-checkbox-details" *ngIf="htmlIsChecked" >
                            <div class="card">
                                <div class="card-header">{{'APPLICATION.OFFERS.HTML_CODE_EDITOR' | translate}}</div>
                                <div class="card-body">
                                    <ngx-codemirror [disabled]="action === 'show'" [(ngModel)]="code" [ngModelOptions]="{standalone: true}" [options]="options"></ngx-codemirror>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="form-checkbox">
                        <label (click)="onContentTypeChecked('PLAIN_TEXT')">
                            <input  (click)="stopPropagation($event)" [ngClass]="{'border-error' : this.isFieldValid('contentType')}" class="mt-2" type="radio" value="PLAIN_TEXT" formControlName="contentType">
                            {{'APPLICATION.OFFERS.PLAIN_TEXT' | translate}}
                        </label>
                        <span class="note">{{'APPLICATION.OFFERS.PLAIN_TEXT_NOTE' | translate}}</span>
                        <span class="form-checkbox-details" *ngIf="textIsChecked">
                            <div class="card">
                                <div class="card-header">{{'APPLICATION.OFFERS.EDITOR' | translate}}</div>
                                <div class="card-body">
                                    <textarea [disabled]="action === 'show'" #textarea [(ngModel)]="text" [ngModelOptions]="{standalone: true}" style="min-width: 100%; border: none;" rows="10"></textarea>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div class="form-checkbox">
                        <label (click)="onContentTypeChecked('Image')">
                            <input (click)="stopPropagation($event)" [ngClass]="{'border-error' : this.isFieldValid('contentType')}" class="mt-2" type="radio" value="BASE64"formControlName="contentType">
                            {{'APPLICATION.OFFERS.IMAGE' | translate}}
                        </label>
                        <br />
                        <span class="form-checkbox-details" *ngIf="imageIsSelected && action === 'update'">
                            <!--
                            <angular-file-uploader #fileUpload [config]="afuConfig" [resetUpload]="resetVar" (ApiResponse)="docUpload($event)"></angular-file-uploader>
                            -->
                            <input [disabled]="action === 'show'" type="file" id="upload-btn" (change)="fileChangeEvent($event)" hidden/>
                            <label for="upload-btn" class="upload-btn">{{'BUTTONS.SELECT' | translate}}</label>
                            <span id="file-chosen">{{this.fileName}}</span>
                        </span>
                    </div>
                </div>

                <hr aria-hidden="true">

                <button *ngIf="action === 'update' && isUpdateClicked"  type="button" class="btn btn-sm btn-success first-in-line" [disabled]="!offerForm.valid" (click)="onSubmit()">Modifier l'offre</button>
                <button *ngIf="isUpdateClicked"  type="button" class="btn btn-sm btn-white first-in-line ml-6" (click)="cancelUpdate()">Annuler la modification</button>
            </form>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div class="container">
        <div class="Subhead mb-5">
            <h1 class="Subhead-heading">{{'APPLICATION.OFFERS.CREATE_OFFER_HEADER' | translate}}</h1>
        </div>
        <div  class="none-block">
            <div class="none-inner-block">
                <img src="../../../assets/images/vide/vide@2x.png"
                    srcset="../../../assets/images/vide/vide@2x.png 2x,
                    ../../../assets/images/vide/vide@3x.png 3x"
                    class="none" alt="None">
                <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
            </div>     
        </div>
    </div>
</ng-template>
