import { GetUserAttempted } from './../../core/store/auth/auth.action';
import { filter, take } from 'rxjs/operators';
import { getUser, getUserStatusName } from './../../core/store/user-details/user-details.reducer';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/core/store';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  userStatusName: string;

  client: any;

  private clientSubscription$;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.clientSubscription$ = this.store.select(getUser).pipe(filter(resp => resp !== undefined)).subscribe(user => {
      this.client = user;
      this.userStatusName = user.userStatusName;
    });
  }

  onNavClientInformations(): void {
    this.router.navigate(['/clients/details/info']);
  }

  goBack(): void {
    window.history.back();
  }
}
