import { NgProgressRef } from '@ngx-progressbar/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {

  progressRef: NgProgressRef;

  constructor() { }

  startLoading(): void {
    this.progressRef.start();
  }

  completeLoading(): void {
    this.progressRef.complete();
  }


}
