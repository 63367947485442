import {createFeatureSelector, createSelector} from '@ngrx/store';
import { from } from 'rxjs';
import * as fromContact from './contact.actions';

export interface ContactState {
    data: any;
    conversationId: any;
    clientTickets: any;
    tickets: any;
    loaded: boolean;
    loading: boolean;
    sent: boolean;
    sending: boolean;
    response: any;
    error: string;
}

export const initialState: ContactState = {
    data: undefined,
    conversationId: undefined,
    clientTickets: undefined,
    tickets:  undefined,
    loaded: false,
    loading: false,
    sent: false,
    sending: false,
    response: undefined,
    error: undefined,
};

export function reducer(state: ContactState = initialState, action: fromContact.ContactActions): any {
    switch (action.type) {
        case fromContact.LOAD_CHAT_HISTORY_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined,
                conversationId: undefined
            };
        case fromContact.LOAD_CHAT_HISTORY_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload.value,
                conversationId: action.payload.value[0] ? action.payload.value[0].conversationID : undefined
            };
        case fromContact.LOAD_CHAT_HISTORY_FAILED:
            return {
                ...state,
                loaded: false,
                error: action.payload
            };

        case fromContact.SEND_MESSAGE_ATTEMPTED:
            return {
                ...state,
                sending: true,
            };
        case fromContact.SEND_MESSAGE_SUCCESSFUL:
            return {
                ...state,
                sent: true,
                response: action.payload
            };
        case fromContact.SEND_MESSAGE_FAILED:
            return {
                ...state,
                sent: false,
                error: action.payload
            };
        case fromContact.LOAD_CLIENT_TICKETS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                clientTickets: undefined
            };
        case fromContact.LOAD_CLIENT_TICKETS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                clientTickets: action.payload.value
            };
        case fromContact.LOAD_CLIENT_TICKETS_FAILED:
            return {
                ...state,
                loading: false,
            };
        case fromContact.CREATE_CLIENT_TICKET_ATTEMPTED:
            return {
                ...state,
                loading: true,
                response: undefined,
            };
        case fromContact.CREATE_CLIENT_TICKET_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.payload
            };
        case fromContact.CREATE_CLIENT_TICKET_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromContact.DELETE_CLIENT_TICKET_ATTEMPTED:
            return {
                ...state,
                loading: true,
                response: undefined
            };
        case fromContact.DELETE_CLIENT_TICKET_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                response: action.payload
            };
        case fromContact.DELETE_CLIENT_TICKET_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromContact.LOAD_ALL_CLIENT_TICKETS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                tickets: undefined
            };
        case fromContact.LOAD_ALL_CLIENT_TICKETS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                tickets: action.payload.value
            };
        case fromContact.LOAD_ALL_CLIENT_TICKETS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getContactState = createFeatureSelector<ContactState>('contact');

export const getChathistory = createSelector(getContactState, (state: ContactState) => state.data);
export const getConversationId = createSelector(getContactState, (state: ContactState) => state.data);
export const getSendingResponse = createSelector(getContactState, (state: ContactState) => state.response);
export const getClientTickets = createSelector(getContactState, (state: ContactState) => state.clientTickets);
export const getContactResponse = createSelector(getContactState, (state: ContactState) => state.response);
export const getAllTickets = createSelector(getContactState, (state: ContactState) => state.tickets);
