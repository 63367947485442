<div class="sub-navbar sticky-top bg-white">
    <div class="nav-list">

        <div class="nav-item" [routerLink]="['/application/news']" [routerLinkActive]="['border-bottom']">
            <div class="item">
                <img src="../../assets/images/application/ic-news/ic-news.png" class="ic-nav"/>
                <h3 class="text nav-text">{{'APPLICATION.NEWS.NEWS' | translate}}</h3>
            </div>
        </div>

        <div class="nav-item" [routerLink]="['/application/offers']" [routerLinkActive]="['border-bottom']">
            <div class="item">
                <img src="../../assets/images/application/ic-offer/ic-offer.png" class="ic-nav" />
                <h3 class="text nav-text">{{'APPLICATION.OFFERS.OFFERS' | translate}}</h3>
            </div>
        </div>

        <div class="nav-item" [routerLink]="['/application/packages']" [routerLinkActive]="['border-bottom']">
            <div class="item">
                <img src="../../assets/images/application/ic-package/ic-package.png" class="ic-nav"/>
                <h3 class="text nav-text">{{'APPLICATION.PACKAGES' | translate}}</h3>
            </div>
        </div>

        <div class="nav-item" [routerLink]="['/application/mcc']" [routerLinkActive]="['border-bottom']">
            <div class="item">
                <img src="../../assets/images/application/ic-mcc/ic-spreadsheet.png" class="ic-nav"/>
                <h3 class="text nav-text">{{'APPLICATION.MCC.TITLE' | translate}}</h3>
            </div>
        </div>

        <div class="nav-item" [routerLink]="['/application/settings']" [routerLinkActive]="['border-bottom']">
            <div class="item">
                <img src="../../assets/images/application/ic-language/ic-language.png" class="ic-nav"/>
                <h3 class="text nav-text">{{'APPLICATION.CONFIG' | translate}}</h3>
            </div>
        </div>

    </div>
</div>

<div class="container-fluid">
    <router-outlet></router-outlet>
</div>