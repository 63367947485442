import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { AddNewsAttemptedAction, LoadNewsAttemptedAction } from '../../../core/store/news/news.actions';
import { getAddResponse, getNews } from '../../../core/store/news/news.reducer';
import { take, filter } from 'rxjs/operators';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.scss']
})
export class AddNewsComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  isLinkedChecked = false;

  isnotLinkedChecked = false;

  link: string;

  news: any[];

  newsLength: number;

  firstRankIsPresent = false;

  rankList: number[] = [-1, 0, 1, 2, 3];

  newsForm: FormGroup;

  constructor(private store: Store<AppState>, private router: Router, private fb: FormBuilder) {
    this.newsForm = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      rang: [null, Validators.compose([Validators.required])],
      description: [null, Validators.compose([Validators.required])],
      tag: [null, Validators.compose([Validators.required])],
      linkedObject: [null]
    });
  }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_NEWS_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_NEWS_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }
    this.store.dispatch(new LoadNewsAttemptedAction());
    this.store.select(getNews).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      this.news = data;
      this.newsLength = data.length;
      console.log('NEWS: ', data);
      if (this.newsLength > 0) {
        for (let i = 0; i < this.newsLength; i++) {
          for (let j = 0; j < this.rankList.length; j++) {
            console.log(this.news[i].rang, this.rankList[j]);
            if (this.news[i].rang === -1 && this.news[i].rang === this.rankList[j]) {
              console.log('spliced ', this.rankList[j]);
              this.rankList.splice(j, 1);
            }
          }
        }
      }
      console.log('RANKS AFTER :', this.rankList);
    });
  }

  onSubmit(): void {
    const payload = {
      title: this.newsForm.get('title').value,
      rang: this.newsForm.get('rang').value,
      description: this.newsForm.get('description').value,
      tag: this.newsForm.get('tag').value,
      linkedObject: this.newsForm.get('linkedObject').value
    };
    console.log(payload);
    this.store.dispatch(new AddNewsAttemptedAction(payload));
    this.store.select(getAddResponse).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
      console.log('data inside is', data);
      if (data !== null) {
        console.log('new added !');
        this.router.navigate(['/application/news']);
      } else {
        console.log('erreur while adding news');
      }
    });
  }

  isFieldValid(field: string): any {
  }

  onTagCheck(tag: string): void {
    if (tag === 'LINKED') {
      this.isLinkedChecked = true;
      this.isnotLinkedChecked = false;
    } else {
      this.newsForm.get('linkedObject').reset();
      this.isLinkedChecked = false;
      this.isnotLinkedChecked = true;
    }
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }
}
