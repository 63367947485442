import { Observable } from 'rxjs';
import { environment } from './../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Pot } from '../../models/pot';

@Injectable({
  providedIn: 'root'
})
export class PotsService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getPots(payload: {idClient: string}): Observable<Pot> {
    console.log('pots web service called with id:', payload.idClient);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/pots`);
  }
}
