import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { LoadChatHistoryAttemptedAction, SendMessageAttemptedAction } from '../../core/store/contact/contact.actions';
import { getChathistory, getSendingResponse } from '../../core/store/contact/contact.reducer';
import { filter, take } from 'rxjs/operators';
import { ContactService } from '../../core/services/contact/contact.service';
import { ScrollDirective } from '../../core/directives/scroll/scroll.directive';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [ScrollDirective]
})
export class ContactComponent implements OnInit, AfterViewChecked {

  haveReadPermission = false;

  loading: boolean;

  messagesLoading: boolean;

  client: any;

  messages$: any;

  messagesLength: number;

  page = 1;

  msg = '';

  response: any;

  @ViewChild('scroll') private scroll: ElementRef;

  constructor(private store: Store<AppState>, private contactService: ContactService) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.client = client;
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    console.log(account.permissions);
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_CONTACT') {
        this.haveReadPermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadChatHistoryAttemptedAction({idClient: client.id, page: this.page}));
    this.store.select(getChathistory).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
      console.log(data);
      this.messages$ = data;
      this.messagesLength = data.length;
    },
    (err) => console.log(err),
    () => this.loading = false);
  }

  ngAfterViewChecked(): void {
    try {
      this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
    } catch (err) {}

  }


  send(): void {
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new SendMessageAttemptedAction({idClient: client.id, content: this.msg}));
    this.store.select(getSendingResponse).pipe(filter(resp => resp !== undefined), take(1)).subscribe(resp => {
      console.log(resp);
      this.response = resp;
    },
    (err) => console.log(err),
    () => {
      if (this.response.message === 'SUCCESS_SEND') {
        this.messages$ = Object.assign([], this.messages$);
        this.messages$.push({content: this.msg});
        this.msg = '';
      } else {
        console.log('MESSAGE COULD NOT BE SENT !');
      }
    });
  }


}
