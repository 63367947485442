import { Action } from '@ngrx/store';

export enum DocumentActionTypes {
    SAVE_USER_DOCUMENT = '[USER VISUAL DOCUMENT] Save selected document of user',

    GET_USER_DOCUMENTS_ATTEMPTED = '[User Documents Info] Get client documents - attempted',
    GET_USER_DOCUMENTS_SUCCESSFUL = '[User Documents Info] get client Documents - Successful',
    GET_USER_DOCUMENTS_FAILED = '[User Documents Info] get Client Documents - Failed',

    LOAD_DOCUMENT_IMAGE_ATTEMPTED = '[UserVisualDocuments] Load image of a document - Attempted',
    LOAD_DOCUMENT_IMAGE_SUCCESSFUL = '[UserVisualDocuments] Load image of a document - successful',
    LOAD_DOCUMENT_IMAGE_FAILED = '[UserVisualDocuments] Load image of document - failed',

    RESET_STORE_STATE = '[UserVisualDocuments] Reset store State to initial state',

    LOAD_CHILD_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] Load selected user child documents attempted',
    LOAD_CHILD_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] Load selected user child documents successful',
    LOAD_CHILD_DOCUMENTS_FAILED = '[UserVisualDocuments] Load selected user child documents failed',

    RECTIFY_CHILD_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] rectify child documents attempted',
    RECTIFY_CHILD_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] rectify child documents successful',
    RECTIFY_CHILD_DOCUMENTS_FAILED = '[UserVisualDocuments] rectify child documents failed',

    VALIDATE_CHILD_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] validate child documents attemtped',
    VALIDATE_CHILD_DOCUMENTS_SUCCEESFUL = '[UserVisualDocuments] validate child documents successful',
    VALIDATE_CHILD_DOCUMENTS_FAILED = '[UserVisualDocuments] validate child documents failed',

    LOAD_CHILD_DOCUMENTS_PHOTO_ATTEMTPED = '[UserVisualDocuments] Load child documents photo attempted',
    LOAD_CHILD_DOCUMENTS_PHOTO_SUCCESSFUL = '[UserVisualDocuments] Load child documents photo successful',
    LOAD_CHILD_DOCUMENTS_PHOTO_FAILED = '[UserVisualDocuments] Load child documents photo failed',

    LOAD_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] Load user address modification documents attempted',
    LOAD_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] Load user address modification documents successful',
    LOAD_ADDRESS_MODIFICATION_DOCUMENTS_FAILED = '[UserVisualDocuments] Load user address modification documents failed',

    INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] Invalidate clients address modification documents attempted',
    INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] Invalidate clients address modification documents successful',
    INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_FAILED = '[UserVisualDocuments] Invalidate clients address modification documents failed',

    VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED = '[UserVisualDocuments] Validate clients address modification documents attempted',
    VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL = '[UserVisualDocuments] Validate clients address modification documents successful',
    VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_FAILED = '[UserVisualDocuments] Validate clients address modification documents failed',

    LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_ATTEMPTED = '[UserVisualDocuments] Load client modification address docs by attempt and tag , attempted',
    LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_SUCCESSFUL = '[UserVisualDocuments] Load client modification address docs by attempt and tag , successful',
    LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_FAILED = '[UserVisualDocuments] Load client modification address docs by attempt and tag, failed',

    LOAD_ADDRESS_MODIFICATION_PHOTO_ATTEMTPED = '[UserVisualDocuments] Load address modification photo attempted',
    LOAD_ADDRESS_MODIFICATION_PHOTO_SUCCESSFUL = '[UserVisualDocuments] Load address modification photo successful',
    LOAD_ADDRESS_MODIFICATION_PHOTO_FAILED = '[UserVisualDocuments] Load address modification photo failed'

}

export class SaveUserSelectedDocumentAction implements Action {
    readonly type = DocumentActionTypes.SAVE_USER_DOCUMENT;
    constructor(public payload: any) {}
}

export class GetUserDocumentsAttemptedAction implements Action {
    readonly type = DocumentActionTypes.GET_USER_DOCUMENTS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class GetUserDocumentsSuccessAction implements Action {
    readonly type = DocumentActionTypes.GET_USER_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetUserDocumentsFailedAction implements Action {
    readonly type = DocumentActionTypes.GET_USER_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class LoadDocumentImageAttemptedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_DOCUMENT_IMAGE_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadDocumentImageSuccessful implements Action {
    readonly type = DocumentActionTypes.LOAD_DOCUMENT_IMAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadDocumentImageFailed implements Action {
    readonly type = DocumentActionTypes.LOAD_DOCUMENT_IMAGE_FAILED;
    constructor(public error: any) {}
}

export class ResetStoretateAction implements Action {
    readonly type = DocumentActionTypes.RESET_STORE_STATE;
}

export class LoadChildDocumentsAttemptedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_CHILD_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string, public idChild: string) {}
}

export class LoadChildDocumentsSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.LOAD_CHILD_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadChildDocumentsFailedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_CHILD_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class RectifyChildDocumentsAttemptedAction implements Action {
    readonly type = DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string, public idChild) {}
}

export class RectifyChildDocumentsSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class RectifyChildDocumentsFailedAction implements Action {
    readonly type = DocumentActionTypes.RECTIFY_CHILD_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class ValidateChildDocumentsAttemptedAction implements Action {
    readonly type = DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string, public idChild: string) {}
}

export class ValidateChildDocumentsSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_SUCCEESFUL;
    constructor(public payload: any) {}
}

export class ValidateChildDocumentsFailedAction implements Action {
    readonly type = DocumentActionTypes.VALIDATE_CHILD_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class LoadChildDocumentsPhotoAttemptedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_ATTEMTPED;
    constructor(public idClient: string, public idChild: string, public tag: string) {}
}

export class LoadChildDocumentsPhotoSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadChildDocumentsPhotoFailedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_CHILD_DOCUMENTS_PHOTO_FAILED;
    constructor(public payload: any) {}
}

export class LoadAddressModificationDocumentsAttemptedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class LoadAddressModificationDocumentsSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadAddressModificationDocumentsFailedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class InvalidateAddressModificationDocumentsAttemptedAction implements Action {
    readonly type = DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class InvalidateAddressModificationDocumentsSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class InvalidateAddressModificationDocumentsFailedAction implements Action {
    readonly type = DocumentActionTypes.INVALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class ValidateAddressModificationDocumentsAttemptedAction implements Action {
    readonly type = DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class ValidateAddressModificationDocumentsSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class ValidateAddressModificationDocumentsFailedAction implements Action {
    readonly type = DocumentActionTypes.VALIDATE_ADDRESS_MODIFICATION_DOCUMENTS_FAILED;
    constructor(public payload: any) {}
}

export class LoadAddressModificationDocumentsByAttemptAttemptedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_ATTEMPTED;
    constructor(public idClient: string, public attempt: number, public tag: string) {}
}

export class LoadAddressModificationDocumentsByAttemptSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadAddressModificationDocumentsByAttemptFailedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_DOCUMENTS_BY_ATTEMPT_FAILED;
    constructor(public payload: any) {}
}

export class LoadAddressModificationPhotoAttemptedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_PHOTO_ATTEMTPED;
    constructor(public idClient: string, public attempt: number, public tag: string) {}
}

export class LoadAddressModificationPhotoSuccessfulAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_PHOTO_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadAddressModificationPhotoFailedAction implements Action {
    readonly type = DocumentActionTypes.LOAD_ADDRESS_MODIFICATION_PHOTO_FAILED;
    constructor(public payload: any) {}
}

export type DocumentActions =
    |SaveUserSelectedDocumentAction
    |GetUserDocumentsAttemptedAction
    |GetUserDocumentsSuccessAction
    |GetUserDocumentsFailedAction
    |LoadDocumentImageAttemptedAction
    |LoadDocumentImageSuccessful
    |LoadDocumentImageFailed
    |ResetStoretateAction
    |LoadChildDocumentsAttemptedAction
    |LoadChildDocumentsFailedAction
    |LoadChildDocumentsSuccessfulAction
    |RectifyChildDocumentsAttemptedAction
    |RectifyChildDocumentsFailedAction
    |RectifyChildDocumentsSuccessfulAction
    |ValidateChildDocumentsAttemptedAction
    |ValidateChildDocumentsFailedAction
    |ValidateChildDocumentsSuccessfulAction
    |LoadChildDocumentsPhotoAttemptedAction
    |LoadChildDocumentsPhotoSuccessfulAction
    |LoadChildDocumentsPhotoFailedAction
    |LoadAddressModificationDocumentsAttemptedAction
    |LoadAddressModificationDocumentsFailedAction
    |LoadAddressModificationDocumentsSuccessfulAction
    |InvalidateAddressModificationDocumentsAttemptedAction
    |InvalidateAddressModificationDocumentsFailedAction
    |InvalidateAddressModificationDocumentsSuccessfulAction
    |ValidateAddressModificationDocumentsAttemptedAction
    |ValidateAddressModificationDocumentsFailedAction
    |ValidateAddressModificationDocumentsSuccessfulAction
    |LoadAddressModificationDocumentsByAttemptAttemptedAction
    |LoadAddressModificationDocumentsByAttemptFailedAction
    |LoadAddressModificationDocumentsByAttemptSuccessfulAction
    |LoadAddressModificationPhotoAttemptedAction
    |LoadAddressModificationPhotoFailedAction
    |LoadAddressModificationPhotoSuccessfulAction
    ;
