import { UserDetailsAction, UserDetailsActionTypes } from './user-details.action';
import { Action, createFeatureSelector, createSelector } from '@ngrx/store';


export interface UserDetailsState {
  id: string;
  user: any;
  userStatusName: string;
  loading: boolean;
  error: Error;
}


export const initialState: UserDetailsState = {
  id: undefined,
  user: undefined,
  userStatusName: undefined,
  loading: false,
  error: undefined,
}


export function reducer(state: UserDetailsState = initialState, action: UserDetailsAction): any {
    switch (action.type) {
      case UserDetailsActionTypes.GET_USER_DETAILS_ATTEMPTED:
        return {
          ...state,
          id: action.payload.idClient,
          loading: true,
      };
      case UserDetailsActionTypes.GET_USER_DETAILS_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload.value,
          userStatusName: action.payload.value.userStatusName
        };
        case UserDetailsActionTypes.GET_USER_DETAILS_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.payload.error
          };
        case UserDetailsActionTypes.GET_USER_ATTEMPTED:
          return {
            ...state,
            id: action.payload.idClient,
            loading: true
          };
        case UserDetailsActionTypes.GET_USER_SUCCESS:
          return {
            ...state,
            loading: false,
            user: action.payload.value,
            userStatusName: action.payload.value.userStatusName
          };
        case UserDetailsActionTypes.GET_USER_FAILED:
          return {
            ...state,
            loading: false,
            error: action.payload.error
          };
      case UserDetailsActionTypes.ADD_ID_USER:
        return {
          ...state,
          id: action.payload.id
        };
      default:
        return state;
    }
  }


export const getUserDetailsState = createFeatureSelector<UserDetailsState>('userDetails');

export const getUser = createSelector(getUserDetailsState, (state: UserDetailsState ) => state.user);
export const getUserId = createSelector(getUserDetailsState, (state: UserDetailsState ) => state.id);
export const getUserDetailsError = createSelector(getUserDetailsState, (state: UserDetailsState ) => state.error);
export const getUserDetailsLoading = createSelector(getUserDetailsState, (state: UserDetailsState ) => state.loading);
export const getUserStatusName = createSelector(getUserDetailsState, (state: UserDetailsState) => state.userStatusName);

