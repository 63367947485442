<div class="content">
    <div class="full-height">
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <div class="d-inline-block">
                        <div class="bold d-inline-block">{{'ISSUES.HEADER' | translate}}</div>
                        <div class="d-inline-block mx-0">
                            <mat-form-field class="search-form-field" appearance="legacy">
                                <mat-label>{{'ISSUES.RESEARCH' | translate}}</mat-label>
                                <input matInput type="text" [(ngModel)]="searchKey"  (input)="onTicketKeyup($event)" autocomplete="off"/>
                                <button  matSuffix mat-icon-button *ngIf="searchKey" attr.aria-label="{{'HOME.CLEAR' | translate}}" (click)="searchKey=''; onTicketKeyup($event)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                        <div class="d-inline-block mx-0">
                            <div class="d-flex flex-rows">
                                <div class="filter-summary">
                                    {{'ISSUES.CATEGORY' | translate}}: <span class="text-bold dropdown-toggle" [matMenuTriggerFor]="subjectMenu">{{selectedSubject}}</span>
                                </div>

                                <div class="filter-summary">
                                    {{'ISSUES.STATE' | translate}}: <span class="text-bold dropdown-toggle" [matMenuTriggerFor]="statusMenu">{{selectedStatus}}</span>
                                </div>

                                <div class="filter-summary">
                                    {{'ISSUES.RESPONSIBLE' | translate}}: <span class="text-bold dropdown-toggle" [matMenuTriggerFor]="createdByNameMenu">{{selectedCreatedByName}}</span>
                                </div>

                                <div class="filter-summary">
                                    {{'ISSUES.CLIENT' | translate}}: <span class="text-bold dropdown-toggle" [matMenuTriggerFor]="clientNameMenu">{{selectedClientName}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <ng-container *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                        <div class="spinner" *ngIf="this.loading">
                            <mat-spinner [diameter]="40"></mat-spinner>
                        </div>

                        <div class="tickets auto-overflow" *ngIf="!this.loading">
                            <div class="none-div" *ngIf="ticketsLength == 0">
                                <img src="../../assets/images/vide/vide.png"
                                    srcset="../../assets/images/vide/vide@2x.png 2x, ../../assets/images/vide/vide@3x.png 3x"
                                    class="none"/>
                                <p class="text none-text">{{'ISSUES.NONE' | translate}}</p>
                            </div>

                            <div class="ticket-tab">
                                <table mat-table [dataSource]="tickets" class="mat-elevation-z8 tab-ticket">
                                    <ng-container matColumnDef="subject_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.SUBJECT' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="text tickets-title">{{element.subject}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="numero_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.KEY' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="text-gray tickets-title bold muted-link" (click)="onTicketDetails(element)">{{element.numero}}</span>
                                        </td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="title_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.TITLE' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <div class="titles">
                                                <span class="text tickets-title muted-link" title="{{element.title}}" (click)="onTicketDetails(element)">{{element.title}}</span>
                                              </div>
                                        </td>
                                    </ng-container>
            
                                    <ng-container matColumnDef="content_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.SUMMARY' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="text tickets-title muted-link" title="{{element.content}}" (click)="onTicketDetails(element)">{{element.content}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="clientName_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.CLIENT' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="text no-wrap tickets-title muted-link" title="{{element.clientFirstName}} {{element.clientLastName}}">{{element.clientFirstName}} {{element.clientLastName}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="createdByName_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.RESPONSIBLE' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="text no-wrap tickets-title muted-link" title="{{element.createdByName}}">{{element.createdByName}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="status_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.STATE' | translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="status-small" [ngClass]="{'bg-blue': element.status === 'OPEN', 'bg-green': element.status === 'CLOSED', 'bg-crimson': element.status === 'BLOCKED'}">{{'ISSUES.STATUS_' + element.status | translate}}</span>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="creation_ticket">
                                        <th mat-header-cell *matHeaderCellDef class="bold">{{'ISSUES.START_DATE'| translate}}</th>
                                        <td mat-cell *matCellDef="let element">
                                            <span class="text tickets-title" title="{{element.openDate | date: 'medium'}}">{{element.openDate | date: 'short'}}</span>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="ticketsDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: ticketsDisplayedColumns;" class="table-row"></tr>
                                </table>
                            </div>

                            <mat-paginator #paginator [showFirstLastButtons]="true" [length]="ticketsLength"
                                       [pageIndex]="page.pageNumber" [pageSize]="page.size" color="primary" [hidePageSize]="true" 
                                       (page)="pageEvent = handlePage($event)">
                            </mat-paginator>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../assets/images/vide/vide@2x.png"
                srcset="../../assets/images/vide/vide@2x.png 2x,
                ../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
    </div>
</ng-template>

<mat-menu #subjectMenu="matMenu" xposition="after">
    <div class="filter-menu Subject-filter-menu">
        <header class="filter-header">
            <span class="filter-title">{{'ISSUES.FILTER_BY_TYPE' | translate}}</span>
        </header>
        <ul>
            <li *ngIf="selectedSubject !== 'aucune'" (click)="filterSubject('aucune')">
                <span class="text filter-by"><i class="far fa-times-circle filter-none"></i>{{'ISSUES.NONEE' | translate}}</span>
            </li>

            <li (click)="filterSubject('SAFEDEPOSIT')">
                <span class="text filter-by">SAFE_DEPOSIT</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="check" *ngIf="filterBySubject === 'SAFEDEPOSIT'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'SAFEDEPOSIT'">
            </li>

            <li (click)="filterSubject('VIRTUALCARD')">
                <span class="text filter-by">VIRTUAL_CARD</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="Check Icon" *ngIf="filterBySubject === 'VIRTUALCARD'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'VIRTUALCARD'">
            </li>

            <li (click)="filterSubject('CHILDCARD')">
                <span class="text filter-by">CHILD_CARD</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="Check Icon" *ngIf="filterBySubject === 'CHILDCARD'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'CHILDCARD'">
            </li>

            <li (click)="filterSubject('PHYSICALCARD')">
                <span class="text filter-by">PHYSICAL_CARD</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="Check Icon" *ngIf="filterBySubject === 'PHYSICALCARD'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'PHYSICALCARD'">
            </li>

            <li (click)="filterSubject('POT')">
                <span class="text filter-by">POT</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="Check Icon" *ngIf="filterBySubject === 'POT'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'POT'">
            </li>

            <li (click)="filterSubject('PERMANENTTRANSFER')">
                <span class="text filter-by">PERMANENT_TRANSFER</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="Check Icon" *ngIf="filterBySubject === 'PERMANENTTRANSFER'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'PERMANENTTRANSFER'">
            </li>

            <li (click)="filterSubject('OTHER')">
                <span class="text filter-by">OTHER</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="Check Icon" *ngIf="filterBySubject === 'OTHER'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'OTHER'">
            </li>

            <li (click)="filterSubject('OPPOSITION')">
                <span class="text filter-by">OPPOSITION</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="Check Icon" *ngIf="filterBySubject === 'OPPOSITION'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterBySubject === 'OPPOSITION'">
            </li>
        </ul>
    </div>
</mat-menu>

<mat-menu #statusMenu="matMenu" xposition="after">
    <div class="filter-menu Subject-filter-menu">
        <header class="filter-header">
            <span class="filter-title">{{'ISSUES.FILTER_BY_STATUS' | translate}}</span>
        </header>
        <ul>
            <li *ngIf="selectedStatus !== 'aucun'" (click)="filterSubject('aucun')">
                <span class="text filter-by"><i class="far fa-times-circle filter-none"></i>{{'ISSUES.NONE' | translate}}</span>
            </li>

            <li (click)="filterStatus('OPEN')">
                <span class="text filter-by bg-blue">{{'ISSUES.STATUS_OPEN' | translate}}</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="check" *ngIf="filterByStatus === 'OPEN'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterByStatus === 'OPEN'">
            </li>

            <li (click)="filterStatus('BLOCKED')">
                <span class="text filter-by bg-crimson">{{'ISSUES.STATUS_BLOCKED' | translate}}</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="check" *ngIf="filterByStatus === 'BLOCKED'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterByStatus === 'BLOCKED'">
            </li>

            <li (click)="filterStatus('CLOSED')">
                <span class="text filter-by bg-green">{{'ISSUES.STATUS_CLOSED' | translate}}</span>
                <img src="../../assets/images/ic-check/ic-check@2x.png"
                    class="ic ic-check" alt="check" *ngIf="filterByStatus === 'CLOSED'">
                <img src="../../assets/images/home/empty.png" class="ic ic-check" alt="Empty Icon" *ngIf="filterByStatus === 'CLOSED'">
            </li>

        </ul>
    </div>
</mat-menu>

<mat-menu #createdByNameMenu="matMenu" xposition="after">
    <div class="filter-menu Subject-filter-menu">
        <header class="filter-header">
            <span class="filter-title">{{'ISSUES.FILTER_BY_RESPONSIBLE' | translate}}</span>
        </header>
        <div class="createdByName-filter-menu container-fluid p-8" (click)="$event.stopPropagation()">
            <input type="text" (input)="onBOUserNameKeyup($event)" [(ngModel)]="this.boUserName" class="form-control block" placeholder="{{'ISSUES.FILTER_RESPONSIBLE' | translate}}" autocomplete="off" />
        </div>
        <ul>
            <li *ngIf="selectedCreatedByName !== 'aucun'" (click)="filterSubject('aucun')">
                <span class="text filter-by"><i class="far fa-times-circle filter-none"></i>{{'ISSUES.NONE' | translate}}</span>
            </li>

            <li (click)="$event.stopPropagation()" *ngIf="!boUsers && !boUsersLoading"  class="filter-note cursor-default">{{'ISSUES.SEARCH_RESPONSIBLE' | translate}}</li>
            
            <ng-container *ngIf="boUsersLoading">
                <li class="spinner">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </li>
            </ng-container>

            <ng-container *ngIf="!boUsersLoading">
                <li *ngFor="let user of boUsers" (click)="filterCreatedByName(user.fullname, user.id)">
                    <span class="text filter-by">{{user.fullname}}</span>
                </li>
            </ng-container>
        </ul>
    </div>
</mat-menu>

<mat-menu #clientNameMenu="matMenu" xposition="after">
    <div class="filter-menu Subject-filter-menu">
        <header class="filter-header">
            <span class="filter-title">{{'ISSUES.FILTER_BY_CLIENT' | translate}}</span>
        </header>
        <div class="createdByName-filter-menu container-fluid p-8" (click)="$event.stopPropagation()">
            <input type="text" (input)="onClientNameKeyup($event)" [(ngModel)]="this.clientName" class="form-control block" placeholder="{{'ISSUES.FILTER_CLIENTS' | translate}}" autocomplete="off" />
        </div>
        <ul>
            <li *ngIf="selectedClientName !== 'aucun'" (click)="filterClientName('aucun')">
                <span class="text filter-by"><i class="far fa-times-circle filter-none"></i>{{'ISSUES.NONE' | translate}}</span>
            </li>

            <li (click)="$event.stopPropagation()" *ngIf="!clients && !clientsLoading"  class="filter-note cursor-default">{{'ISSUES.SEARCH_CLIENTS' | translate}}</li>
            
            <ng-container *ngIf="clientsLoading">
                <li class="spinner">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </li>
            </ng-container>

            <ng-container *ngIf="!clientsLoading">
                <li *ngFor="let client of clients" (click)="filterClientName(client.firstname + ' ' + client.lastname, client.id)">
                    <span class="text filter-by">{{client.firstname}} {{client.lastname}}</span>
                </li>
            </ng-container>
        </ul>
    </div>
</mat-menu>