import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadAddressModificationDocumentsAttemptedAction,
  SaveUserSelectedDocumentAction,
  ValidateAddressModificationDocumentsAttemptedAction,
  InvalidateAddressModificationDocumentsAttemptedAction } from '../../../core/store/document/document.action';
import { getAddressModificationDocuments, getValidationAddressModificationResponse, getInvalidationAddressModificationResponse } from '../../../core/store/document/document.reducer';
import { filter } from 'rxjs/operators';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { getUser } from '../../../core/store/user-details/user-details.reducer';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

interface ChildElement {
  indexChild?: number;
  name?: string;
  imageState?: string;
  found?: boolean;
  fileUrl?: string;
  image?: any;
  children?: ChildElement[];
}

interface ParentElement {
  indexParent?: number;
  identity?: string;
  name?: string;
  title?: string;
  files?: [{
    name: string;
    imageState: string;
    found: boolean;
    fileUrl: string;
  }];
  children?: ChildElement[];
}

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>;
  expanded?: boolean;
}

@Component({
  selector: 'app-address-documents',
  templateUrl: './address-documents.component.html',
  styleUrls: ['./address-documents.component.scss']
})
export class AddressDocumentsComponent implements OnInit, OnDestroy {

  haveDocumentsReadPermission = true;

  haveDocumentsWritePermission = true;

  loading = false;

  private addressModificationDocumentsSubscription$;

  addressModificationDocs: any[];

  addressModificationDocsLength: number;

  client: any;

  folderEmpty: boolean;

  dataSource = new MatTreeNestedDataSource<ParentElement>();

  treeData: ParentElement[] = [];

  dbdata: any;

  treeControl = new NestedTreeControl<ChildElement>(node => node.children);

  private clientSubscription$;

  userStatusName: string;

  private validationSubscription$;

  private invalidationSubscription$;

  private dialogValidationSubscription$;

  private dialogInvalidationSUbscription$;


  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) {
    this.dataSource.data = [];
  }

  hasChild = (_: number, node: ChildElement) => !!node.children && node.children.length >= 0;

  ngOnDestroy(): void {
    if (this.addressModificationDocumentsSubscription$) {
      this.addressModificationDocumentsSubscription$.unsubscribe();
    }
    if (this.clientSubscription$) {
      this.clientSubscription$.unsubscribe();
    }
    if (this.validationSubscription$) {
      this.validationSubscription$.unsubscribe();
    }
    if (this.invalidationSubscription$) {
      this.invalidationSubscription$.unsubscribe();
    }
    if (this.dialogValidationSubscription$) {
      this.dialogValidationSubscription$.unsubscribe();
    }
    if (this.dialogInvalidationSUbscription$) {
      this.dialogInvalidationSUbscription$.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.loading = true;
    this.dataSource.data = [];
    const client = JSON.parse(localStorage.getItem('client'));
    this.client = client;

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    this.clientSubscription$ = this.store.select(getUser).pipe(filter(user => user !== undefined)).subscribe(user => {
      this.client = user;
      this.userStatusName = user.userStatusName;
    });

    this.store.dispatch(new LoadAddressModificationDocumentsAttemptedAction(client.id));
    this.addressModificationDocumentsSubscription$ = this.store.select(getAddressModificationDocuments)
      .pipe(filter(resp => resp !== undefined))
      .subscribe(data => {
        console.log('ADDRESS MODIFICATION DOCUMENTS: ', data.updatedDocument);
        this.addressModificationDocs = data.updatedDocument;
        this.addressModificationDocsLength = data.updatedDocument.length;
        this.populateFolderTree();
      });
    this.dataSource.data = this.treeData;

    this.validationSubscription$ = this.store.select(getValidationAddressModificationResponse).pipe(filter(resp => resp !== undefined))
    .subscribe(res => {
      console.log('VALIDATION RESPONSE: ', res);
      if (res.code === 200) {
        this.loading = false;
        this.store.dispatch(new GetUserAttemptedAction({idClient: this.client.id}));
      }
    });

    this.invalidationSubscription$ = this.store.select(getInvalidationAddressModificationResponse).pipe(filter(resp => resp !== undefined))
      .subscribe(res => {
        console.log('INVALIDATION RESPONSE: ', res);
        if (res.code === 200) {
          this.loading = false;
          this.store.dispatch(new GetUserAttemptedAction({idClient: this.client.id}));
        }
      });
  }

  populateFolderTree(): void {
    let fileIndex = 0;
    let folderIndex = 0;
    if (this.addressModificationDocsLength > 0) {
      const children: ChildElement [] = [];
      this.addressModificationDocs.forEach((file: any) => {
        children.push({indexChild: fileIndex++, fileUrl: file.fileUrl, imageState: file.imageState, found: file.found,
          name: file.name, image: file.image});
      });
      this.treeData.push({indexParent: folderIndex++, identity: 'DOCUMENTS DE MODIFICATION D\'ADDRESSE', children});
    }
    const normalizedDocuments = this.concatenateFolders();
    localStorage.setItem('documents', JSON.stringify(normalizedDocuments));
    this.loading = false;
  }

  visualiseDocument(document: any): void {
    console.log('SELECTED DOCUMENT IS: ', document);
    if (this.haveDocumentsReadPermission) {
      this.store.dispatch(new SaveUserSelectedDocumentAction(document));
      localStorage.setItem('document', JSON.stringify(document));
    }
    this.router.navigate(['/clients/details/documents/address-documents/viewer']);
  }

  /*
  onNavClientInformations(): void {
    this.router.navigate(['/clients/details/info']);
  }
  */

  validate(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translate.instant('CONFIRMATION'),
        message: this.translate.instant('ADDRESS_UPDATE_VALIDATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'ADDRESS_UPDATE_VALIDATION'
      }
    });
    this.dialogValidationSubscription$ = dialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.loading = true;
        this.store.dispatch(new ValidateAddressModificationDocumentsAttemptedAction(this.client.id));
      }
    });
  }

  invalidate(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '50%',
      data: {
        title: this.translate.instant('CONFIRMATION'), // 'Confirmation',
        message: this.translate.instant('ADDRESS_UPDATE_INVALIDATION_MESSAGE') ,
        buttons: 'oui-non',
        type: 'ADDRESS_UPDATE_INVALIDATION'
      }
    });
    this.dialogInvalidationSUbscription$ = dialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.loading = true;
        this.store.dispatch(new InvalidateAddressModificationDocumentsAttemptedAction(this.client.id));
      }
    });
  }

  private concatenateFolders(): any[] {
    let result = [];
    for (let i = 0; i < this.treeData.length; i++) {
      result.push(...this.treeData[i].children);
    }
    return result;
  }

}
