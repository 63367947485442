import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { getSelectedStatement } from '../../../core/store/opposition/oppositions.reducer';
import { filter, take } from 'rxjs/operators';
import { GetWalletAttemptedAction } from '../../../core/store/cards/cards.actions';
import { getWallet } from '../../../core/store/cards/cards.reducer';

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.scss']
})
export class StatementComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  loading: boolean;

  statement: any;

  wallet: any;

  client: any;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    this.client = client;

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_SAFEDEPOSIT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_SAFEDEPOSIT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.select(getSelectedStatement).pipe(filter(resp => resp !== undefined), take(1)).subscribe(statement => {
      console.log('statement is : ', statement);
      this.statement = statement;
      this.loading = false;
      /*
      this.store.dispatch(new GetWalletAttemptedAction({idClient: client.id, idWallet: statement.walletID}));
      this.store.select(getWallet).pipe(filter(resp => resp !== undefined), take(1)).subscribe(obj => {
        console.log('wallet is :', obj);
        this.wallet = obj.balances[0];
        this.loading = false;
      });
      */
    });
  }

}
