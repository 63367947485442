import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  constructor() { }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    console.log(account.permissions);
    for (const permission of account.permissions) {
      if (permission.trim() === 'READ_USER') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'WRITE_USER') {
        this.haveWritePermission = true;
      }
    }
  }

}
