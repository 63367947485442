import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LogoutAction } from '../../../../core/store/auth/auth.action';

export interface TicketDialogData {
  message: string;
  value: any;
  type: string;
  align: string;
  id: string;
}

@Component({
  selector: 'app-ticket-dialog',
  templateUrl: './ticket-dialog.component.html',
  styleUrls: ['./ticket-dialog.component.scss']
})
export class TicketDialogComponent implements OnInit {

  selectedTicket: any;

  status = ['OPEN', 'BLOCKED', 'CLOSED'];

  constructor(@Inject (MAT_DIALOG_DATA) public data: TicketDialogData, private dialogRef: MatDialogRef<TicketDialogComponent>,
              private store: Store<AppState>) { }

  ngOnInit(): void {
    console.log('FROM TICKET DIALOG COMPONENT : ', this.data.value);
    this.selectedTicket = this.data.value;
  }

  onCancel(): void {
    this.close(false);
  }

  close(value: any): void {
    this.dialogRef.close(value);
  }

  onError(): void {
    this.close(false);
    this.store.dispatch(new LogoutAction());
  }

  @HostListener('keydown.esc')
  onEsc(): void {
    this.close(false);
  }

}
