import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/store/index';
import { Router } from '@angular/router';
import { getPackages } from '../../core/store/package/package.reducer';

@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent implements OnInit, OnDestroy {

  private packageSubscription;

  packagesLoading: boolean;

  commissionsLoading: boolean;

  operationsLoading: boolean;

  packages: any;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.operationsLoading = true;
    this.packageSubscription = this.store.select(getPackages).subscribe(data => {
    });
  }

  ngOnDestroy(): void {
    if (this.packageSubscription) {
      this.packageSubscription.unsubscribe();
    }
  }

  onOperationNav(): void {
    console.log('ops');
  }

  onCommissionsNav(): void {
    console.log('commissions');
  }

  onPackagesNav(): void {
    console.log('packages');
  }

  show(event: any): void {
    switch (event.index) {
      case 0:
        this.onOperationNav();
        break;
      case 1:
        this.onCommissionsNav();
        break;
      case 2:
        this.onPackagesNav();
        break;
      default:
        this.onOperationNav();
    }
  }
}
