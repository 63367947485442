import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatterService {

  constructor() { }

  formatWithSlash(d: Date): string {
    if (d !== null) {
      const dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/') 
      + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
      return dformat;
    }
    return null;
  }

  formatToIsoString(d: Date): string {
    if (d !== null) {
      console.log('--------------------- d is :', d.toISOString());
      return d.toISOString();
    }
    return null;
  }

  formatWithHash(d: Date): string {
    if (d !== null) {
      const dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('-') 
      + 'T' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
      return dformat;
    }
    return null;
  }
}
