<div class="wrapper">
    <!--
    <div class="panel panel-default border">
        <div class="panel-heading">
            <div class="panel-title">{{'HOME.REGISTRATION_DOCUMENTS.CLIENT' | translate}}: <span *ngIf="this.client" class="font name link" (click)="onNavClientInformations()">{{client.firstname}} {{client.lastname}}/{{client.id}}</span></div>
            <div class="document-info-divider"></div>
            <div class="panel-title">{{'HOME.REGISTRATION_DOCUMENTS.FOLDER_STATUS' | translate}}: <span class="bold">{{this.userStatusName}}</span></div>
            <div class="document-info-divider" *ngIf="this.client.userStatusNameModificationAddress" ></div>
            <div class="panel-title" *ngIf="this.client.userStatusNameModificationAddress">
                {{'HOME.REGISTRATION_DOCUMENTS.ADDRESS_FOLDER_STATUS' | translate}}: 
                <span class="bold">{{this.client.userStatusNameModificationAddress}}</span>
            </div>
        </div>
    </div>
    -->

    <div  *ngIf="loading" class="spinner">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>
                
    <div style="min-height: 32.6vh;" *ngIf="!loading">
        <div  class="none-block" *ngIf="addressModificationDocsLength === 0">
            <div class="none-inner-block">
                <img src="../../../../assets/images/vide/vide@2x.png"
                srcset="../../../../assets/images/vide/vide@2x.png 2x,
                ../../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text">{{'HOME.REGISTRATION_DOCUMENTS.ADDRESS_MODIF_NONE' | translate }} </p> 
            </div>     
        </div>
    
        <div *ngIf="!loading" style="width: 100%; margin-top: 2%;">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-container">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <li class="mat-tree-node">
                        <button mat-icon-button disabled></button>
                        <div class="container-fluid">
                            <div class="row file-item"  (click)="visualiseDocument(node)">
                                <i class="far fa-file-alt octicon"></i>
                                <div class="col">{{node.name}}</div>
                                <div class="col">{{node.imageState}}</div>
                            </div>
                        </div>
                    </li>
                </mat-tree-node>
    
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <li class="folder-item">
                        <div>
                            <button mat-button matTreeNodeToggle [attr.aria-label]="'toggle' + node.name" class="f-0">
                                <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                </mat-icon>
                                {{ 'HOME.REGISTRATION_DOCUMENTS.ADDRESS_MODIFICATION_DOCUMENT' | translate}}
                            </button>
                                        
                        </div>
                    </li>
                    <ul class="folder" [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                        <ng-container matTreeNodeOutlet></ng-container>
                    </ul>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
    </div>

    <div class="documents-actions" fxLayout="row" fxLayoutAlign="center center" 
    *ngIf="client.modificationsAdressAttempt > 0 && addressModificationDocsLength > 0 && client.userStatusNameModificationAddress === 'KYC_NEW_ADDRESS_PENDING' && !loading">
        <button mat-raised-button class="text documents-action" (click)="validate()">
            {{'BUTTONS.VALIDATE' | translate}}
        </button>
        <button mat-raised-button class="text documents-action" (click)="invalidate()">
            {{'BUTTONS.DECLINE' | translate}}
        </button>
    </div>
</div>