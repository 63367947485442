import { RectifyService } from './../../services/rectification/rectify.service';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { AddVerifiedRectificationFailedAction,
         AddVerifiedRectificationAttemptedAction,
         GetVerifiedRectificationsAttemptedAction,
         GetVerifiedRectificationsFailedAction,
         RectificationActionTypes,
         RectifyAttemptedAction,
         RectifyFailedAction,
         RemoveRectificationAttemptedAction,
         RemoveRectificationFailedAction,
         RemoveRectificationSuccessfulAction,
         /*
         ClearCheckedDocumentsAttemptedAction,
         ClearCheckedDocumentsSuccessfulAction,
         ClearCheckedDocumentFailedAction,
         GetCheckedDocumentsAttemptedAction,
         GetCheckedDocumentsSuccessfulAction,
         GetCheckedDocumentsFailedAction,
         AddCheckedDocumentsAttemptedAction,
         AddCheckedDocumentsSuccessfulAction,
         AddCheckedDocumentsFailedAction,
         RemoveCheckedDocumentsAttemptedAction,
         RemoveCheckedDocumentsSuccessfulAction,
         RemoveCheckedDocumentsFailedAction,
         UpdateCheckedDocumentsAttemptedAction,
         UpdateCheckedDocumentsFailedAction,
         UpdateCheckedDocumentsSucessfulAction,
         */
         SendToKYCAttemptedAction,
         SendToKYCSuccessfulAction,
         SendToKYCFailedAction,
         ValidateClientDocumentsAttemptedAction,
         ValidateClientDocumentsSuccessfulAction
} from './rectification.action';
import { of } from 'rxjs';
import { ValidateClientDocumentsFailedAction } from './rectification.action';


@Injectable()
export class RectificationEffects {

    constructor(private actions$: Actions, private rectifyService: RectifyService) {}

    @Effect()
    RectifyAttempted$ = this.actions$.pipe(
        ofType<RectifyAttemptedAction>(RectificationActionTypes.RectifyAttempted),
        switchMap(data => this.rectifyService.rectify(data.payload)
            .pipe(
                map(resp => {
                    return {
                        type: RectificationActionTypes.RectifySuccessful,
                        payload: {resp}
                    };
                }),
                catchError((error: any) => of(new RectifyFailedAction(error)))
            )
        )
    );


    @Effect()
    GetVerfiedRectificationsAttempted$ = this.actions$.pipe(
        ofType<GetVerifiedRectificationsAttemptedAction>(RectificationActionTypes.GET_VERIFIED_RECTIFICATIONS_ATTEMPTED),
        switchMap(data => this.rectifyService.getVerifiedRectifications(data.payload)
            .pipe(
                map(resp => {
                    return {
                        type: RectificationActionTypes.GET_VERIFIED_RECTIFICATIONS_SUCCESSFUL,
                        payload: {resp}
                    };
                }),
                catchError((error: any) => of(new GetVerifiedRectificationsFailedAction(error)))
            )
        )
    );

    @Effect()
    AddVerifiedRectification$ = this.actions$.pipe(
        ofType<AddVerifiedRectificationAttemptedAction>(RectificationActionTypes.ADD_VERIFIED_RECTIFICATION_ATTEMPTED),
        switchMap(data => this.rectifyService.addVerifiedRectification(data.payload)
            .pipe(
                map(resp => {
                    return {type: RectificationActionTypes.ADD_VERIFIED_RECTIFICATION_SUCCESSFUL, payload: {resp}};
                }),
                catchError((error: any) => of(new AddVerifiedRectificationFailedAction(error)))
            )
        )
    );

    @Effect()
    removeVerifiedRectificationAttempted$ = this.actions$.pipe(
        ofType<RemoveRectificationAttemptedAction>(RectificationActionTypes.REMOVE_RECTIFICATION_ATTEMPTED),
        switchMap(data => this.rectifyService.removeTemporaryRectification(data.payload)
            .pipe(
                map(resp => new RemoveRectificationSuccessfulAction(resp)),
                catchError((error: any) => of (new RemoveRectificationFailedAction(error)))
            )
        )
    );
/*
    @Effect()
    getCheckedDocumentsAttempted$ = this.actions$.pipe(
        ofType<GetCheckedDocumentsAttemptedAction>(RectificationActionTypes.GET_CHECKED_DOCUMENTS_ATTEMPTED),
        switchMap((data) => this.rectifyService.getCheckedDocuments(data.idClient)
            .pipe(
                map(resp => new GetCheckedDocumentsSuccessfulAction(resp)),
                catchError((error: any) => of (new GetCheckedDocumentsFailedAction(error)))
            ))
    );

    @Effect()
    addCheckedDocumentsAttempted$ = this.actions$.pipe(
        ofType<AddCheckedDocumentsAttemptedAction>(RectificationActionTypes.ADD_CHECKED_DOCUMENTS_ATTEMPTED),
        switchMap(data => this.rectifyService.addCheckedDocuments(data.payload)
            .pipe(
                map(resp => new AddCheckedDocumentsSuccessfulAction(resp)),
                catchError((error: any) => of (new AddCheckedDocumentsFailedAction(error)))
            ))
    );

    @Effect()
    updateCheckedDocumentsAttempted$ = this.actions$.pipe(
        ofType<UpdateCheckedDocumentsAttemptedAction>(RectificationActionTypes.UPDATE_CHECKED_DOCUMENTS_ATTEMPTED),
        switchMap(data => this.rectifyService.updateCheckedDocuments(data.payload)
            .pipe(
                map(resp => new UpdateCheckedDocumentsSucessfulAction(resp)),
                catchError((error: any) => of (new UpdateCheckedDocumentsFailedAction(error)))
            )
        )
    );


    @Effect()
    removeCheckedDocumentsAttempted$ = this.actions$.pipe(
        ofType<RemoveCheckedDocumentsAttemptedAction>(RectificationActionTypes.REMOVE_RECTIFICATION_ATTEMPTED),
        switchMap(data => this.rectifyService.removeCheckedDocuments(data.payload)
            .pipe(
                map(resp => new RemoveCheckedDocumentsSuccessfulAction(resp)),
                catchError((error: any) => of (new RemoveCheckedDocumentsFailedAction(error)))
            ))
    );

    @Effect()
    clearCheckedDocumentsAttempted$ = this.actions$.pipe(
        ofType<ClearCheckedDocumentsAttemptedAction>(RectificationActionTypes.CLEAR_CHECKED_DOCUMENTS_ATTEMPTED),
        switchMap(data => this.rectifyService.clearCheckedDocuments(data.idClient)
            .pipe(
                map(resp => new ClearCheckedDocumentsSuccessfulAction(resp)),
                catchError((error: any) => of (new ClearCheckedDocumentFailedAction(error)))
            ))
    );
    */

    @Effect()
    sendToKYCAttemtped$ = this.actions$.pipe(
        ofType<SendToKYCAttemptedAction>(RectificationActionTypes.SEND_TO_KYC_ATTEMPTED),
        switchMap(data => this.rectifyService.sendToKYC(data.idClient)
            .pipe(
                map(resp => new SendToKYCSuccessfulAction(resp)),
                catchError(err => of (new SendToKYCFailedAction(err)))
            ))
    );

    @Effect()
    validateClientDocuments$ = this.actions$.pipe(
        ofType<ValidateClientDocumentsAttemptedAction>(RectificationActionTypes.VALIDATE_CLIENT_DOCUMENTS_ATTEMPTED),
        switchMap(data => this.rectifyService.validateClientDocuments(data.idClient)
            .pipe(
                map(resp => new ValidateClientDocumentsSuccessfulAction(resp)),
                catchError(err => of (new ValidateClientDocumentsFailedAction(err)))
            ))
    );
}

