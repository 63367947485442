import { GetUserAttemptedAction } from './../../core/store/user-details/user-details.action';
import { filter } from 'rxjs/operators';
import { getDepositScreen, getDeposits } from './../../core/store/deposit/deposit.reducer';
import { LoadDeposits, LoadDepositHistory, SelectDeposit, ScreenDeposit } from './../../core/store/deposit/deposit.actions';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Deposit } from '../../core/models/deposit.model';
import { AppState } from 'src/app/core/store';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { LoadAllClientTicketsAttempted } from '../../core/store/issues/issues.actions';
import { TranslateService } from '@ngx-translate/core';


export const tooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 0,
  touchendHideDelay: 0
};

@Component({
  selector: 'app-safe-deposit',
  templateUrl: './safe-deposit.component.html',
  styleUrls: ['./safe-deposit.component.scss'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: tooltipDefaults}
  ]
})
export class SafeDepositComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  depositScreen: number;

  deposits: any;

  depositsLength: number;

  loading: boolean;

  depositsDisplayedColumns: string[] = [
    'category_deposit',
    'title_deposit',
    'amount_deposit',
    'feedType_deposit',
    'date_deposit',
    'history_deposit',
    'details_deposit',
    'wallet_deposit'
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  page = {
    pageSize: 6,
    pageIndex: 0,
    totalElements: 0,
    totalPages: 0
  };

  pageEvent: PageEvent;

  private depositsSubscription$;


  constructor(private store: Store<AppState>, private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    // this.paginator._intl.itemsPerPageLabel = this.translate.instant('ITEMS_PER_PAGE');

    this.loading = true;

    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new ScreenDeposit());
    this.store.select(getDepositScreen).subscribe(screen => {
      return this.depositScreen = screen;
    });

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_SAFEDEPOSIT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_SAFEDEPOSIT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadDeposits({idClient: client.id}, this.page));
    this.depositsSubscription$ = this.store.select(getDeposits).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('deposits are :', data);
      this.deposits = data.content;
      this.depositsLength = data.totalElements;
      this.page.totalElements = data.totalElements;
      this.page.totalPages = data.totalPages;
      this.page.pageIndex = data.number;
      this.page.pageSize = data.size;
      this.loading = false;
    });
  }

  ngOnDestroy(): void {
    if (this.depositsSubscription$) {
      this.depositsSubscription$.unsubscribe();
    }
  }

  depositHistory(deposit: any): void {
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new SelectDeposit(deposit));
    this.router.navigate(['/clients/details/deposits/deposit-history/' + deposit.id]);
  }

  depositDetails(deposit: Deposit): void {
    this.store.dispatch(new SelectDeposit(deposit));
    this.router.navigate(['/clients/details/deposits/deposit-details']);
  }

  depositWalletDetails(deposit: any): void {
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new SelectDeposit(deposit));
    this.router.navigate(['/clients/details/deposits/wallet-details/' + deposit.walletId]);
  }

  handlePage(event: any): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    console.log('HANDLING PAGE : ', {idClient: client.id}, event);
    this.store.dispatch(new LoadDeposits({idClient: client.id}, event));
  }

}
