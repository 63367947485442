import { Action } from '@ngrx/store';

export enum AppActionTypes {
    CLEAR_STATE = '[App] Clear app state',
}


export class ClearStateAction implements Action {
    readonly type = AppActionTypes.CLEAR_STATE;
}


export type AuthAction =
    |ClearStateAction
    ;
