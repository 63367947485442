<div class="Operation-container">
    <div class="d-flex direction-column align-center justify-center" *ngIf="showError">
        <div class="alert alert-danger alert-dismissible w-70" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.isResetFailed = false"><span aria-hidden="true">&times;</span></button>
            <strong>{{'ALERTS.ERROR' | translate}} !</strong> {{'ALERTS.TRY_AGAIN' | translate}}.
        </div>
    </div>
    <form [formGroup]="operationForm" id="new-commission" aria-label="Créer une opération">
        <div class="Subhead mb-5">
            <h1 class="Subhead-heading">{{'PACKAGES.OPERATION_CREATION' | translate}}</h1>
            <!--<div class="Subhead-description">{{'PACKAGES.OPERATION_CREATION_NOTE' | translate}}</div>-->
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="operation-name">{{'PACKAGES.NAME' | translate}}<span class="error"> * </span></label>
                </dt>
                <dd>
                    <input [ngClass]="{'border-error' : this.operationForm.controls['name'].hasError('required')}" class="form-control long" formControlName="name" autocomplete="off" type="text" id="operation-name">
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="operation-description">{{'PACKAGES.DESCRIPTION' | translate}}<span class="error"> * </span></label>
                </dt>
                <dd>
                    <textarea [ngClass]="{'border-error' : this.operationForm.controls['description'].hasError('required')}" formControlName="description" class="form-control long" rows="3"></textarea>
                    <!--<span class="note d-block"></span>-->
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="operation-type">{{'PACKAGES.OPERATION' | translate}}<span class="error"> * </span></label>
                </dt>
                <dd>
                    <select [ngClass]="{'border-error' : this.operationForm.controls['operation'].hasError('required')}" class="form-control short" formControlName="operation" id="operation-type">
                        <option>FINISH_SAFE_DEPOSITE</option>
                    </select>
                    <!--<span class="note d-block">{{'PACKAGES.SELECT_OP' | translate}}</span>-->
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="operation-paidType">{{'PACKAGES.PAIDTYPE' | translate}}<span class="error"> * </span></label>
                </dt>
                <dd>
                    <select [ngClass]="{'border-error' : this.operationForm.controls['paidType'].hasError('required')}" class="form-control short" formControlName="paidType" id="operation-paidType">
                        <option>INSTANTLY</option>
                    </select>
                    <!--<span class="note d-block">{{'PACKAGES.SELECT_PAYMENT' | translate}}</span>-->
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="operation-comissionType">{{'PACKAGES.COMMISSION_RATE' | translate}}<span class="error"> * </span></label>
                </dt>
                <dd>
                    <select [ngClass]="{'border-error' : this.operationForm.controls['comissionType'].hasError('required')}" class="form-control short" formControlName="comissionType" id="operation-comissionType">
                        <option *ngFor="let comission of commissionTypes; let index = index" value="{{index}}">
                            {{comission.value}} {{comission.type === 'PERCENT' ? '%' : 'EUR'}}
                        </option>
                    </select>
                    <!--<span class="note d-block">{{'PACKAGES.COMMISSION_RATE_REMOVED' | translate}}</span>-->
                </dd>
            </dl>
        </div>
        <hr />
        <button type="button" class="btn btn-sm btn-success" [disabled]="!operationForm.valid" (click)="onSubmit()">{{'BUTTONS.ADD' | translate}}</button>
    </form>
</div>
