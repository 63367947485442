import { Component, OnInit } from '@angular/core';
import { ImageState } from '../../../../core/enums/imageState.enum';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { GetUserAttemptedAction } from '../../../../core/store/user-details/user-details.action';
import { getUserStatusName } from '../../../../core/store/document/document.reducer';
import { filter, take } from 'rxjs/operators';
import { UsersService } from '../../../../core/services/users/users.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-address-document-viewer',
  templateUrl: './address-document-viewer.component.html',
  styleUrls: ['./address-document-viewer.component.scss']
})
export class AddressDocumentViewerComponent implements OnInit {

  loading: boolean;

  haveReadPermission = true;

  haveWritePermission = true;

  photo: any;

  documentName: string;

  isPhotoLoading = false;

  toggleArrows: boolean;

  selectedDocument: any;

  userStatusName: string;

  client: any;

  currentDocumentStatus: ImageState;

  documentIdentity: string;

  documents: any[];

  private photoSubscription$;

  RESIDENCE_TEXT_FIELDS = [
    { key: 'rue', value: 'RUE'},
    { key: 'ville', value: 'VILLE'},
    { key: 'pays', value: 'PAYS'},
    { key: 'numRue', value: 'NUM_RUE'},
    { key: 'codePostal', value: 'CODE_POSTAl'},
    { key: 'numApp', value: 'NUM_APP'},
  ];

  constructor(private store: Store<AppState>, private usersService: UsersService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.loading = true;
    this.isPhotoLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    const document = JSON.parse(localStorage.getItem('document'));
    this.documents = JSON.parse(localStorage.getItem('documents'));
    this.client = client;
    this.selectedDocument = document;
    this.documentIdentity = document.identity;
    this.documentName = document.name;
    this.currentDocumentStatus = document.imageState;
    console.log('CLIENT IS :', client);
    console.log('DOCUMENT IS: ', document);
    console.log('DOCUMENTS ARE: ', this.documents);

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    this.store.select(getUserStatusName).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
      this.userStatusName = data;
    });

    this.photoSubscription$ = this.usersService
      .getAddressModificationPhoto(client.id, client.modificationsAdressAttempt, this.selectedDocument.name)
      .subscribe(data => {
        this.createImageFromBlob(data);
        console.log(data);
      },
      (error) => {
        console.log('ERROR OCCURED WHILE READING IMAGE: ', error);
        this.isPhotoLoading = false;
      },
      () => { this.isPhotoLoading = false; console.log('image downloaded successfully'); });
  }

  onNextDocument(): void {
    const currentDocument = JSON.parse(localStorage.getItem('document'));
    let currentIndex = currentDocument.indexChild;
    if (currentIndex === this.documents.length - 1) {
      currentIndex = -1;
    }
    localStorage.setItem('document', JSON.stringify(this.documents[currentIndex + 1]));
    this.ngOnInit();
  }

  onPreviousDocument(): void {
    const currentDocument = JSON.parse(localStorage.getItem('document'));
    let currentIndex = currentDocument.indexChild;
    if (currentIndex === 0) {
      currentIndex = this.documents.length;
    }
    localStorage.setItem('document', JSON.stringify(this.documents[currentIndex - 1]));
    this.ngOnInit();
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.photo = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  getSantizeUrl(url: string): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getUserFieldName(field: string): any {
    switch (field) {
      case 'firstname': return this.client.firstname;
      case 'lastname': return this.client.lastname;
      case 'enabled': return this.client.enabled;
      case 'status': return this.client.status;
      case 'email': return this.client.email;
      case 'numTel': return this.client.numTel;
      case 'birthday': return this.client.birthday;
      case 'creationDate': return this.client.creationDate;
      case 'lastUserUpdate': return this.client.lastUserUpdate;
      case 'cin': return this.client.cin;
      case 'numPassport': return this.client.numPassport;
      case 'numCarteSejour': return this.client.numCarteSejour;
      case 'datePassport': return this.client.datePassport;
      case 'dateSejour': return this.client.dateSejour;
      case 'dateCin': return this.client.dateCin;
      case 'userStatusName': return this.client.userStatusName;
      case 'citizenship': return this.client.citizenship;
      case 'hostEnum': return this.client.hostEnum;
      case 'nature': return this.client.nature;
      case 'rue': return this.client.rue;
      case 'numRue': return this.client.numRue;
      case 'ville': return this.client.ville;
      case 'codePostal': return this.client.codePostal;
      case 'numApp': return this.client.numApp;
      case 'pays': return this.client.pays;
      case 'rueNew': return this.client.rueNew;
      case 'numRueNew': return this.client.numRueNew;
      case 'villeNew': return this.client.villeNew;
      case 'codePostalNew': return this.client.codePostalNew;
      case 'numAppNew': return this.client.numAppNew;
      case 'paysNew': return this.client.paysNew;
      case 'codeParrainage': return this.client.codeParrainage;
      case 'numFis': return this.client.numFis;
      case 'paysFisc': return this.client.paysFisc;
      case 'gender': return this.client.gender;
      case 'citoyen': return this.client.citoyen;
      case 'numTelNew': return this.client.numTelNew;
      case 'lastLogin': return this.client.lastLogin;
      case 'attempt': return this.client.attempt;
      case 'newsLetterValid': return this.client.newsLetterValid;
      case 'mailConfirmed': return this.client.mailConfirmed;
      case 'telConfirmed': return this.client.telConfirmed;
    }
}

}
