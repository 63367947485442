import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromUserManagement from './user-management.action';

export interface UserManagementState {
    data: any;
    account: any;
    backofficeAddUserResponse: any;
    backofficePriviligeResponse: any;
    backofficeDeleteResponse: any;
    backofficeUpdateResponse: any;
    selectedUser: any;
    usersByName: any;
    loading: boolean;
    error: string;
}

export const initialState: UserManagementState = {
    data: undefined,
    account: undefined,
    backofficeAddUserResponse: undefined,
    backofficePriviligeResponse: undefined,
    backofficeDeleteResponse: undefined,
    backofficeUpdateResponse: undefined,
    selectedUser: undefined,
    usersByName: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: UserManagementState = initialState, action: fromUserManagement.UserManagementTypes): any {
    switch (action.type) {
        case fromUserManagement.LOAD_BACKOFFICE_USERS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined
            };
        case fromUserManagement.LOAD_BACKOFFICE_USERS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value
            };
        case fromUserManagement.LOAD_BACKOFFICE_USERS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

            case fromUserManagement.ADD_BACKOFFICE_USER_ATTEMPTED:
                return {
                    ...state,
                    loading: true,
                };
            case fromUserManagement.ADD_BACKOFFICE_USER_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    backofficeAddUserResponse: action.payload
                };
            case fromUserManagement.ADD_BACKOFFICE_USER_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };

                case fromUserManagement.SET_BACKOFFICE_USER_PRIVILIGE_ATTEMPTED:
                    return {
                        ...state,
                        loading: true,
                    };
                case fromUserManagement.SET_BACKOFFICE_USER_PRIVILIGE_SUCCESSFUL:
                    return {
                        ...state,
                        loading: false,
                        backofficePriviligeResponse: action.payload
                    };
                case fromUserManagement.SET_BACKOFFICE_USER_PRIVILIGE_FAILED:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                    };
                    case fromUserManagement.DELETE_BACKOFFICE_USER_ATTEMPTED:
                        return {
                            ...state,
                            loading: true,
                        };
                    case fromUserManagement.DELETE_BACKOFFICE_USER_SUCCESSFUL:
                        return {
                            ...state,
                            loading: false,
                            deleteResponse: action.payload
                        };
                    case fromUserManagement.DELETE_BACKOFFICE_USER_FAILED:
                        return {
                            ...state,
                            loading: false,
                            error: action.payload,
                        };

                        case fromUserManagement.UPDATE_BACKOFFICE_USER_ACCOUNT_ATTEMPTED:
                            return {
                                ...state,
                                loading: true,
                            };
                        case fromUserManagement.UPDATE_BACKOFFICE_USER_ACCOUNT_SUCCESSFUL:
                            return {
                                ...state,
                                loading: false,
                                backofficeUpdateResponse: action.payload
                            };
                        case fromUserManagement.UPDATE_BACKOFFICE_USER_ACCOUNT_FAILED:
                            return {
                                ...state,
                                loading: false,
                                error: action.payload
                            };
                        case fromUserManagement.SELECT_BACKOFFICE_USER:
                            return {
                                ...state,
                                selectedUser: action.payload,
                            };
                        case fromUserManagement.LOAD_BACKOFFICE_USER_PROFILE_ATTEMPTED:
                            return {
                                ...state,
                                loading: true,
                                account: undefined,
                            };
                        case fromUserManagement.LOAD_BACKOFFICE_USER_PROFILE_SUCCESSFUL:
                            return {
                                ...state,
                                loading: false,
                                account: action.payload.value
                            };
                        case fromUserManagement.LOAD_BACKOFFICE_USER_PROFILE_FAILED:
                            return {
                                ...state,
                                loading: false,
                                error: action.payload
                            };
                        case fromUserManagement.UPDATE_BACKOFFICE_USER_CREDENTIALS_ATTEMPTED:
                            return {
                                ...state,
                                loading: true,
                            };
                        case fromUserManagement.UPDATE_BACKOFFICE_USER_CREDENTIALS_FAILED:
                            return {
                                ...state,
                                loading: false,
                                backofficeUpdateResponse: action.payload,
                            };
                        case fromUserManagement.UPDATE_BACKOFFICE_USER_CREDENTIALS_FAILED:
                            return {
                                ...state,
                                loading: false,
                                error: action.payload
                            };
                        case fromUserManagement.SEARCH_BACKOFFICE_USERS_BY_NAME_ATTEMPTED:
                            return {
                                ...state,
                                loading: true,
                                usersByName: undefined
                            };
                        case fromUserManagement.SEARCH_BACKOFFICE_USERS_BY_NAME_SUCCESSFUL:
                            return {
                                ...state,
                                loading: false,
                                usersByName: action.payload.value
                            };
                        case fromUserManagement.SEARCH_BACKOFFICE_USERS_BY_NAME_FAILED:
                            return {
                                ...state,
                                loading: false,
                                error: action.payload
                            };
                    default:
                        return state;
    }
}

export const getUserManagementState = createFeatureSelector<UserManagementState>('backofficeUsers');

export const getBackofficeUsers = createSelector(getUserManagementState, (state: UserManagementState) => state.data);
export const getBackofficeAddUserResponse =
    createSelector(getUserManagementState, (state: UserManagementState) => state.backofficeAddUserResponse);
export const getBackofficePriviligeResponse =
    createSelector(getUserManagementState, (state: UserManagementState) => state.backofficePriviligeResponse);
export const getBackofficeDeleteResponse =
    createSelector(getUserManagementState, (state: UserManagementState) => state.backofficeDeleteResponse);
export const getBackofficeUpdateResponse =
    createSelector(getUserManagementState, (state: UserManagementState) => state.backofficeUpdateResponse);
export const getBackofficeSelectedUser = createSelector(getUserManagementState, (state: UserManagementState) => state.selectedUser);
export const getUserProfile = createSelector(getUserManagementState, (state: UserManagementState) => state.account);
export const getBackofficeUsersByName = createSelector(getUserManagementState, (state: UserManagementState) => state.usersByName);

