import { User } from './../../models/user.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthAction, AuthActionTypes } from './auth.action';



export interface AuthState {
    email: string;
    isAuthenticated: boolean;
    isBadAuthentication: boolean;
    token: string;
    user: User | null;
    passwordResetInitResponse: any;
    passwordResetResponse: any;
    loading: boolean;
    error: Error;
}

export const initialState: AuthState = {
    email: undefined,
    isAuthenticated: false,
    isBadAuthentication: false,
    token: undefined,
    user: null,
    passwordResetInitResponse: undefined,
    passwordResetResponse: undefined,
    loading: false,
    error: undefined
}

export function AuthReducer(state: AuthState = initialState, action: AuthAction): any {
    switch (action.type) {
        case AuthActionTypes.LOGIN_ATTEMPTED:
            return {
                ...state,
                email: action.payload.email,
                loading: true
            };
        case AuthActionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                token: action.payload.value.token,
                loading: false,
                isAuthenticated: true
            };
        case AuthActionTypes.LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                isBadAuthentication: true,
                error: action.payload.error
            };
        case AuthActionTypes.LOGOUT:
            return initialState;
        case AuthActionTypes.GET_USER_ATTEMPTED:
            return {
                ...state,
                token: action.payload.token
            };
        case AuthActionTypes.GET_USER_SUCCESSFUL:
            return {
                ...state,
                user: action.payload.user
            };
        case AuthActionTypes.PASSWORD_RESET_REQUEST_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case AuthActionTypes.PASSWORD_RESET_REQUEST_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                passwordResetInitResponse: action.payload
            };
        case AuthActionTypes.PASSWORD_RESET_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
            };
        case AuthActionTypes.RESET_PASSWORD_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case AuthActionTypes.RESET_PASSWORD_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                passwordResetResponse: action.payload,
            };
        case AuthActionTypes.RESET_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                passwordResetResponse: {code: 500, value: 'error'}
            };
        default:
            return state;
    }
}

export const getAuthState = createFeatureSelector<AuthState>('auth');

export const getLoginEmail = createSelector(getAuthState, (state: AuthState) => state.email);

export const getLoginToken = createSelector(getAuthState, (state: AuthState) => state.token);

export const getLoginIsAuthenticated = createSelector(getAuthState, (state: AuthState) => state.isAuthenticated);

export const getLoginIsBadCredentials = createSelector(getAuthState, (state: AuthState) => state.isBadAuthentication);

export const getLoginLoading = createSelector(getAuthState, (state: AuthState) => state.loading);

export const getLoginError = createSelector(getAuthState, (state: AuthState) => state.error);

export const getPasswordResetInitResponse = createSelector(getAuthState, (state: AuthState) => state.passwordResetInitResponse);

export const getPasswordResetResponse = createSelector(getAuthState, (state: AuthState) => state.passwordResetResponse);
