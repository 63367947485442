<div class="content container-fluid">
    <div class="full-height bg-white">
        <h1 class="h1">{{'SETTINGS.USER_MANAGEMENT' | translate}}</h1>
        <div class="settings-container">
            <div class="nav-wrapper">
                <nav class="SideNav" aria-label="settings">
                    <span class="SideNav-item muted-link" [routerLink]="['/settings/profile']" [routerLinkActive]="['border-left']" [routerLinkActiveOptions]="{exact: false}">
                        {{'SETTINGS.MY_PROFILE' | translate}}
                    </span>
                    <span class="SideNav-item muted-link" [routerLink]="['/settings/users']" [routerLinkActive]="['border-left']" [routerLinkActiveOptions]="{exact: false}"
                            [class.disabled]="haveReadPermission ? null : true">
                        {{'SETTINGS.USER_MANAGEMENT' | translate}}
                    </span>
                    <!--
                    <span class="SideNav-item muted-link" [routerLink]="['/settings/roles-and-privileges']" [routerLinkActive]="['border-left']" [routerLinkActiveOptions]="{exact: false}"
                            [class.disabled]="haveReadPermission ? null : true">
                        Gestion des roles et privilèges
                    </span>
                    -->
                </nav>
            </div>

            <div class="settings-wrapper">
                <div class="Box">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>