import { MatDialog } from '@angular/material/dialog';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-user-documents-info',
  templateUrl: './user-documents-info.component.html',
  styleUrls: ['./user-documents-info.component.scss']
})
export class UserDocumentsInfoComponent implements OnInit {

  user: any;

  haveDocumentsReadPermission = false;

  haveDocumentsWritePermission = false;

  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    for (const permission of this.user.permissions) {
      if (permission.trim() === 'CLIENT_DOCUMENT_READ') {
        this.haveDocumentsReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_DOCUMENT_WRITE') {
        this.haveDocumentsWritePermission = true;
      }
    }
  }

}
