<div class="content">
    <div class="full-height bg-white">
      <div class="contact-container">
        <div class="tickets-container my-0">
          <app-client-tickets></app-client-tickets>
        </div>
        <div class="chat-container my-0" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
          <div class="messaging">
                <div class="inbox_msg">
                  <div class="mesgs">
                    <div class="d-flex-center" *ngIf="this.messagesLoading">
                      <mat-spinner [diameter]="45"></mat-spinner>
                    </div>

                    <div class="msg_history" *ngIf="!this.messagesLoading" >
                      <div class="scroll" #scroll>
                          <ng-container *ngFor="let msg of messages$">
                            <div class="incoming_msg" *ngIf="msg.sender !== client.id && msg.content.trim() !== ''" >
                              <div class="incoming_msg_img">
                                <img src="../../../assets/images/ic-support/support@2x.png" srcset="../../../assets/images/ic-support/support@2x.png,
                                ../../../assets/images/ic-support/support@3x.png" alt="Support">
                              </div>
                              <div class="received_msg">
                                <div class="received_withd_msg">
                                  <p>{{msg.content}}</p>
                                <span class="time_date"> {{msg.dateEnvoie | date: 'short'}}</span></div>
                              </div>
                            </div>

                            <div class="outgoing_msg" *ngIf="msg.sender === client.id && msg.content.trim() !== ''" >
                              <div class="sent_msg">
                                <p>{{msg.content}}</p>
                              <span class="time_date"> {{msg.dateEnvoie | date: 'short'}}</span> </div>
                            </div>
                        </ng-container>
                      </div>
                      
                      <div class="type_msg">
                        <div class="input_msg_write">
                          <input (keydown.enter)="send()" type="text" class="write_msg" placeholder="Ecrire un message" [(ngModel)]="msg" />
                          <span class="msg_send_btn" (click)="send()">
                            <img src="../../../assets/images/ic-send/send@2x.png" srcset="../../../assets/images/ic-send/send@2x.png, ../../../assets/images/ic-send/send@3x.png" />
                            <!-- <i class="fa fa-paper-plane-o" aria-hidden="true"></i>-->
                          </span>
                          
                        </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
  </div>
      </div>
        
</div>

<ng-template #NO_READ_PERMISSION>
  <div  class="none-block">
      <div class="none-inner-block">
          <img src="../../../assets/images/vide/vide@2x.png"
              srcset="../../../assets/images/vide/vide@2x.png 2x,
              ../../../assets/images/vide/vide@3x.png 3x"
              class="none" alt="None">
          <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
      </div>     
  </div>
</ng-template>
