import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { DocumentStatePipe } from './document-state.pipe';
import { DateTimePipe } from './date-time.pipe';

@NgModule({
    declarations: [
        CurrencySymbolPipe,
        DocumentStatePipe,
        DateTimePipe
    ],
    imports: [
        CommonModule
    ],
    exports: [
        CurrencySymbolPipe,
        DocumentStatePipe,
        DateTimePipe
    ]

})
export class ApplicationPipesModule {}

