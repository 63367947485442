import { GetUserDetailsAttemptedAction } from './../../../core/store/user-details/user-details.action';
import { filter, take } from 'rxjs/operators';
import { User } from './../../../core/models/user.model';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { UserDetailsState, getUser } from './../../../core/store/user-details/user-details.reducer';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-user-infos',
  templateUrl: './user-infos.component.html',
  styleUrls: ['./user-infos.component.scss']
})
export class UserInfosComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  userId: string;

  userInfo: User;

  loaded = false;

  updating = false;

  infoForm = new FormGroup({
    status: new FormControl(''),
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    email: new FormControl(''),
    birthday: new FormControl(''),
    phoneNumber: new FormControl(''),
    cin: new FormControl(''),
    numPassport: new FormControl(''),
    gender: new FormControl(''),
    citoy: new FormControl(''),
    rue: new FormControl(''),
    numRue: new FormControl(''),
    ville: new FormControl(''),
    codePostal: new FormControl('')
  });

  constructor(private store: Store<UserDetailsState>) { }

  ngOnInit(): void {
    const clientId = JSON.parse(localStorage.getItem('client')).id;
    this.store.dispatch(new GetUserDetailsAttemptedAction({idClient: clientId}));

    const account = JSON.parse(localStorage.getItem('user'));
    console.log(account.permissions);
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.disableAll();
    this.store.select(getUser).pipe(filter(resp => resp !== undefined), take(1)).subscribe(user => {
      this.userInfo = user;
      console.log('user info is: ', this.userInfo);
      this.fillAllFields();
    });
  }

  disableAll(): void{
    this.infoForm.get('status').disable();
    this.infoForm.get('firstname').disable();
    this.infoForm.get('lastname').disable();
    this.infoForm.get('email').disable();
    this.infoForm.get('birthday').disable();
    this.infoForm.get('phoneNumber').disable();
    this.infoForm.get('cin').disable();
    this.infoForm.get('numPassport').disable();
    this.infoForm.get('gender').disable();
    this.infoForm.get('citoy').disable();
    this.infoForm.get('rue').disable();
    this.infoForm.get('numRue').disable();
    this.infoForm.get('ville').disable();
    this.infoForm.get('codePostal').disable();
    this.updating = false;
  }
  enableAll(): void{
    this.infoForm.get('status').enable();
    this.infoForm.get('firstname').enable();
    this.infoForm.get('lastname').enable();
    this.infoForm.get('email').enable();
    this.infoForm.get('birthday').enable();
    this.infoForm.get('phoneNumber').enable();
    this.infoForm.get('cin').enable();
    this.infoForm.get('numPassport').enable();
    this.infoForm.get('gender').enable();
    this.infoForm.get('citoy').enable();
    this.infoForm.get('rue').enable();
    this.infoForm.get('numRue').enable();
    this.infoForm.get('ville').enable();
    this.infoForm.get('codePostal').enable();
    this.updating = true;

  }
  fillAllFields(): void{
    if (this.userInfo !== undefined) {
      this.infoForm.patchValue({
        status: this.userInfo.userStatusName,
        firstname: this.userInfo.firstname,
        lastname: this.userInfo.lastname,
        email: this.userInfo.email,
        birthday: this.userInfo.birthday,
        phoneNumber: this.userInfo.numTel,
        cin: this.userInfo.cin,
        numPassport: this.userInfo.numPassport,
        gender: this.userInfo.gender,
        citoy: this.userInfo.citoyen,
        rue: this.userInfo.rue,
        numRue: this.userInfo.numRue,
        ville: this.userInfo.ville,
        codePostal: this.userInfo.codePostal
      });
    }
  }

}
