import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { AddOfferAttemptedAction } from '../../../core/store/offers/offers.actions';
import { getAddResponse } from 'src/app/core/store/offers/offers.reducer';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import * as _ from 'lodash';

@Component({
  selector: 'app-new-offer',
  templateUrl: './new-offer.component.html',
  styleUrls: ['./new-offer.component.scss']
})
export class NewOfferComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  linkIsChecked = false;

  htmlIsChecked = false;

  textIsChecked = false;

  imageIsChecked = false;

  offerForm = new FormGroup({
    title: new FormControl(undefined, Validators.required),
    expiration: new FormControl(undefined, [Validators.required]),
    contentType: new FormControl(undefined, [Validators.required])
  });

  readOnly = false;

  options: any = {
    lineNumbers: true,
    mode: 'htmlmixed',
    theme: 'material',
    value: '<p>Hello</p>',
    htmlMode: true,
    autoFocus: true
  };

  link: string;

  code: string;

  text: string;

  @ViewChild('textarea')
  private textArea: ElementRef;

  token: string;

  imageError: string;

  isImageSaved: boolean;

  cardImageBase64: string;

  previewImagePath: any;

  fileName: string;



  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_OFFERT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_OFFERT_WRITE') {
        this.haveWritePermission = true;
      }
    }
  }

  onSubmit(): void {
    let payload: any;
    switch (this.offerForm.get('contentType').value) {
      case 'LINK':
        payload = {
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.link
        };
        break;
      case 'HTML':
        payload = {
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.code
        };
        break;
      case 'PLAIN_TEXT':
        payload = {
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.text
        };
        break;
      case 'BASE64':
        payload = {
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.cardImageBase64
        };
        break;
    }
    this.store.dispatch(new AddOfferAttemptedAction(payload));
    this.store.select(getAddResponse).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
      this.onSuccess(data);
      },
      (err) => console.log(err),
      () => this.onComplete());
  }

  onSuccess(data: any): void {
    console.log('new: ', data);
    console.log('offer added success !');
  }

  onComplete(): void {
    this.router.navigate(['/application/offers']);
  }

  onContentTypeChecked(value: any): void {
    console.log(value);
    console.log(this.offerForm.get('contentType').value);
    switch (value) {
      case 'LINK':
        this.linkIsChecked = true;
        this.htmlIsChecked = false;
        this.textIsChecked = false;
        this.imageIsChecked = false;
        break;
      case 'HTML':
        this.htmlIsChecked = true;
        this.linkIsChecked = false;
        this.textIsChecked = false;
        this.imageIsChecked = false;
        break;
      case 'PLAIN_TEXT':
      this.textIsChecked = true;
      this.linkIsChecked = false;
      this.htmlIsChecked = false;
      this.imageIsChecked = false;
      setTimeout(() => {
        this.textArea.nativeElement.focus();
      }, 0);
      break;
      case 'BASE64':
        this.imageIsChecked = true;
        this.linkIsChecked = false;
        this.htmlIsChecked = false;
        this.textIsChecked = false;
        break;
    }
  }

  docUpload(res: any): void {
    console.log('upload called');
  }

  isFieldValid(field: string): boolean {
    return !this.offerForm.get(field).valid && this.offerForm.get(field).touched;
  }

  fileChangeEvent(fileInput: any): any {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
        'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = rs => {
        const img_height = rs.currentTarget['height'];
        const img_width = rs.currentTarget['width'];

        console.log(img_height, img_width);


        if (img_height > max_height && img_width > max_width) {
          this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
          return false;
        } else {
          const imgBase64Path = e.target.result;
          this.cardImageBase64 = imgBase64Path;
          this.isImageSaved = true;
          this.previewImagePath = imgBase64Path;
        }
      };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
      this.fileName = fileInput.target.files[0].name;
    }
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }

}
