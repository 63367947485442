<mat-sidenav-container class="main-container" autosize>
    <mat-sidenav #sidenav class="main-sidenav" mode="side" opened="true">
      <mat-nav-list>
        <mat-list-item (click)="isExpanded = !isExpanded">
          <mat-icon mat-list-icon *ngIf="!isExpanded">menu</mat-icon>
          <mat-icon mat-list-icon *ngIf="isExpanded">arrow_back</mat-icon>
        </mat-list-item>

        <mat-list-item routerLinkActive="mat-list-item-active" [routerLink]="['/clients']">
          <mat-icon mat-list-icon>
            dashboard
          </mat-icon>
          <p matLine class="text menu-item" *ngIf="isExpanded">
            {{'HOME.CLIENTS' | translate}}
          </p>
        </mat-list-item>

        <mat-list-item routerLinkActive="mat-list-item-active" [routerLink]="['/application']">
          <mat-icon mat-list-icon>
            desktop_windows
          </mat-icon>
          <p matLine class="text menu-item" *ngIf="isExpanded">
            {{'HOME.APPLICATIONS' | translate}}
          </p>
        </mat-list-item>

        <mat-list-item routerLinkActive="mat-list-item-active" [routerLink]="['/issues']">
          <mat-icon mat-list-icon >
            confirmation_number
          </mat-icon>
          <p matLine class="text menu-item" *ngIf="isExpanded">
            {{'HOME.TICKETS' | translate}}
          </p>
        </mat-list-item>

        <mat-list-item routerLinkActive="mat-list-item-active" [routerLink]="['/settings']">
          <mat-icon mat-list-icon >
            settings
          </mat-icon>
          <p matLine class="text menu-item" *ngIf="isExpanded">
            {{'HOME.SETTINGS' | translate}}
          </p>
        </mat-list-item>

        <mat-list-item (click)="onLogout()">
          <mat-icon mat-list-icon>exit_to_app</mat-icon>
          <p matLine class="text menu-item" *ngIf="isExpanded">{{'HOME.DISCONNECT' | translate}}</p>
        </mat-list-item>
        
      </mat-nav-list>
    </mat-sidenav>
  
    <div>
      <div class="home-container">
          <app-header></app-header>
          <router-outlet style="height: 100%"></router-outlet>
          <app-footer></app-footer>
      </div>
    </div>
  </mat-sidenav-container>
