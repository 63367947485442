import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadOffersAttemptedAction, SelectOfferAction, DeleteOfferAttemptedAction } from '../../core/store/offers/offers.actions';
import { getOffers, getDeleteOfferResponse } from '../../core/store/offers/offers.reducer';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  private offersSubscription;

  loading: boolean;

  offers$: any;

  offersLength: number;

  private dialogSubscription$;

  private deleteSubscription$;

  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_OFFERT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_OFFERT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadOffersAttemptedAction());
    this.offersSubscription = this.store.select(getOffers).pipe(filter(data => data !== undefined)).subscribe(data => {
      console.log(data);
      this.offers$ = data;
      this.offersLength = data.length;
      this.loading = false;
    });
    this.deleteSubscription$ = this.store.select(getDeleteOfferResponse).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      if (data.message !== 'DELETED') {
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            buttons: 'serverError'
          }
        });
      } else {
        this.store.dispatch(new LoadOffersAttemptedAction());
      }
    });
  }

  ngOnDestroy(): void {
    if (this.offersSubscription) {
      this.offersSubscription.unsubscribe();
    }
  }

  onNewOffer(): void {
    this.router.navigate(['/application/offers/new']);
  }

  deleteOffer(offer: any): void {
    console.log('offer id:', offer.id);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30%',
      data: {
        title: this.translate.instant('CONFIRMATION_CHOICE'),
        message: this.translate.instant('DELETE_OFFER_CONFIRMATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'DELETE_OFFER_CONFIRMATION',
        value: offer
      }
    });
    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.store.dispatch(new DeleteOfferAttemptedAction(offer.id));
      }
    });
  }

  modifyOffer(offer: any): void {
    this.store.dispatch(new SelectOfferAction(offer, 'update'));
    this.router.navigate(['/application/offers/update']);
  }

  offerDetails(offer: any): void {
    this.store.dispatch(new SelectOfferAction(offer, 'show'));
    this.router.navigate(['/application/offers/update']);
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }

}
