import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgProgress } from '@ngx-progressbar/core';
import { ProgressbarService } from './../core/services/shared/progressbar.service';
import { Actions, ofType } from '@ngrx/effects';
import { LoginAttemptedAction, AuthActionTypes } from './../core/store/auth/auth.action';
import { getLoginLoading, getLoginIsBadCredentials } from './../core/store/auth/auth.reducer';
import { Router } from '@angular/router';
import { AuthService } from './../core/services/auth/auth.service';
import { Store } from '@ngrx/store';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppState } from '../core/store';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/dialog/dialog.component';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  @ViewChild('email') email: ElementRef;

  @ViewChild('password') password: ElementRef;

  loginForm = new FormGroup({
  });

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  passwordFormControl = new FormControl('', [
    Validators.required
  ]);

  submitted = false;

  isSubmittedClicked = false;

  loginError = false;

  loading = false;

  isUnauthorized = false;


  constructor(private store: Store<AppState>, private authService: AuthService, private router: Router, private actions$: Actions,
              public progressBarService: ProgressbarService, private progress: NgProgress,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.progressBarService.progressRef = this.progress.ref('progressBar');
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/clients']);
    }
    this.store.select(getLoginLoading).subscribe(data => {
      if (data) {

      } else {

      }
    });
    this.store.select(getLoginIsBadCredentials).subscribe(data => this.loginError = data);
  }

  login(): void {
    console.log('progress bar starting ...');
    this.progressBarService.startLoading();
    this.store.dispatch(new LoginAttemptedAction({email: this.emailFormControl.value, password: this.passwordFormControl.value}));
    this.actions$.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS)).subscribe(() => {
      if (localStorage.getItem('token')) {
        this.router.navigate(['/clients']);
        this.progressBarService.completeLoading();
      }
    });
    this.actions$.pipe(ofType(AuthActionTypes.LOGIN_FAILED)).subscribe((data: any) => {
      this.progressBarService.completeLoading();
      this.isUnauthorized = true;
    });
  }

  onEnter(event: any): void {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }

  onSubmit(): void {
    if ( (this.emailFormControl.hasError('required') || !this.email.nativeElement.checkValidity()) ) {
      this.emailFormControl.markAllAsTouched();
      this.isSubmittedClicked = true;
    } else if (this.passwordFormControl.hasError('required') || !this.password.nativeElement.checkValidity()) {
      this.passwordFormControl.markAllAsTouched();
      this.isSubmittedClicked = true;
    } else {
      this.login();
    }
  }

  onForgotPassword(): void {
    this.router.navigate(['/forgot-password']);
  }
}
