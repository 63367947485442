import { ErrorInterceptor, TokenInterceptor } from './core/interceptors/token.interceptor';
import { SigninModule } from './signin/signin.module';
import { environment } from './../environments/environment.prod';
import { HomeModule } from './home/home.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { clearState, reducers } from './core/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule} from '@ngrx/router-store';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { ScrollDirective } from './core/directives/scroll/scroll.directive';
import { ApplicationModule } from './application/application.module';
import { SettingsComponent } from './settings/settings.component';
import { SettingsModule } from './settings/settings.module';
import { IssuesModule } from './issues/issues.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from './core/utils/custom-mat-paginator-intl.service';
import { DatePipe } from '@angular/common';

// tslint:disable-next-line: typedef
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    ScrollDirective,
    SettingsComponent
  ],
  imports: [
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers: [clearState],
      runtimeChecks: {strictActionImmutability: false, strictStateImmutability: false}
    }),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HomeModule,
    ApplicationModule,
    SettingsModule,
    SigninModule,
    IssuesModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS
    },
    JwtHelperService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomMatPaginatorIntlService
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
