import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromOppostions from './oppositions.actions';

export interface OppositionState {
    data: any[];
    statement: any;
    loading: boolean;
    error: string;
}

export const initialState: OppositionState = {
    data: undefined,
    statement: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state = initialState, action: fromOppostions.OppositionTypes): any {
    switch (action.type) {
        case fromOppostions.LOAD_OPPOSITIONS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined
            };
        case fromOppostions.LOAD_OPPOSITIONS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value
            };
        case fromOppostions.LOAD_OPPOSITIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromOppostions.SET_STATEMENT:
            return {
                ...state,
                statement: action.payload
            };
        default:
            return state;
    }
}

export const getOppositionState = createFeatureSelector<OppositionState>('oppositions');

export const getOppositions = createSelector(getOppositionState, (state: OppositionState) => state.data);
export const getSelectedStatement = createSelector(getOppositionState, (state: OppositionState) => state.statement);
