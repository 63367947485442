import { Action } from '@ngrx/store';

export const LOAD_OPERATIONS_ATTEMPTED = '[Operations] Load operations attempted';
export const LOAD_OPERATIONS_SUCCESSFUL = '[Operations] Load operations successful';
export const LOAD_OPERATIONS_FAILED = '[Operations] Load operations failed';

export const ADD_OPERATION_ATTEMPTED = '[Operations] Add operations attempted';
export const ADD_OPERATION_SUCCESSFUL = '[Operations] Add operations successful';
export const ADD_OPERATION_FAILED = '[Operations] Add operations failed';

export const UPDATE_OPERATION_ATTEMPTED = '[Operations] Update operations attempted';
export const UPDATE_OPERATION_SUCCESSFUL = '[Operations] Update operations successful';
export const UPDATE_OPERATION_FAILED = '[Operations] Update operations failed';

export const DELETE_OPERATION_ATTEMPTED = '[Operations] Delete operations attempted';
export const DELETE_OPERATION_SUCCESSFUL = '[Operations] Delete operations successful';
export const DELETE_OPERATION_FAILED = '[Operations] Delete operations failed';

export class LoadOperationsAttemptedAction implements Action {
    readonly type = LOAD_OPERATIONS_ATTEMPTED;
    constructor() {}
}

export class LoadOperationsSuccessfulAction implements Action {
    readonly type = LOAD_OPERATIONS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadOperationsFailedAction implements Action {
    readonly type = LOAD_OPERATIONS_FAILED;
    constructor(public payload: any) {}
}

export class AddOperationAttemptedAction implements Action {
    readonly type = ADD_OPERATION_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddOperationSuccessfulAction implements Action {
    readonly type = ADD_OPERATION_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddOperationFailedAction implements Action {
    readonly type = ADD_OPERATION_FAILED;
    constructor(public payload: any) {}
}

export class UpdateOperationAttemptedAction implements Action {
    readonly type = UPDATE_OPERATION_ATTEMPTED;
    constructor(public idOperation: string, public payload: any) {}
}

export class UpdateOperationSuccessfulAction implements Action {
    readonly type = UPDATE_OPERATION_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateOperationFailedAction implements Action {
    readonly type = UPDATE_OPERATION_FAILED;
    constructor(public payload: any) {}
}

export class DeleteOperationAttemtpedAction implements Action {
    readonly type = DELETE_OPERATION_ATTEMPTED;
    constructor(public idOperation: string) {}
}

export class DeleteOperationSuccessfulAction implements Action {
    readonly type = DELETE_OPERATION_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeleteOperationFailedAction implements Action {
    readonly type = DELETE_OPERATION_FAILED;
    constructor(public payload: any) {}
}

export type OperationActions =
    |LoadOperationsAttemptedAction
    |LoadOperationsFailedAction
    |LoadOperationsSuccessfulAction
    |AddOperationAttemptedAction
    |AddOperationFailedAction
    |AddOperationSuccessfulAction
    |UpdateOperationAttemptedAction
    |UpdateOperationFailedAction
    |UpdateOperationSuccessfulAction
    |DeleteOperationAttemtpedAction
    |DeleteOperationFailedAction
    |DeleteOperationSuccessfulAction
    ;
