<div class="close-button" xmlns="http://www/w3.org/1999/html">
    <img src="../../../assets/images/ic-close/close-btn.png"
        srcset="../../../assets/images/ic-close/close-btn@2x.png 2x,
                ../../../assets/images/ic-close/close-btn@3x.png 3x"
        class="close-btn"
        alt="close error"
        (click)="close(false)" />
</div>

<h1 mat-dialog-title>{{'APPLICATION.MCC.MCC_UPDATE' | translate}}</h1>
<div mat-dialog-content>
    <div class="dialog-wrapper">
        <form [formGroup]="mccForm">
            <div class="flex-row">
                <div class="w-80">
                    <dl class="form-group">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="code">{{'APPLICATION.MCC.CODE' | translate}}</label>
                        </dt>
                        <dd>
                            <input class="form-control long" formControlName="code" autocomplete="off" type="text" id="code" />
                        </dd>
                    </dl>

                    <dl class="form-group ">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="category">{{'APPLICATION.MCC.CATEGORY' | translate}}</label>
                        </dt>
                        <dd>
                            <input class="form-control long" formControlName="category" autocomplete="off" type="text" id="category" />
                        </dd>
                    </dl>

                    <dl class="form-group ">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="sousCategory">{{'APPLICATION.MCC.SUB_CATEGORY' | translate}}</label>
                        </dt>
                        <dd>
                            <input class="form-control long" formControlName="sousCategory" autocomplete="off" type="text" id="sousCategory" />
                        </dd>
                    </dl>

                    <dl class="form-group">
                        <dt class="input-label">
                            <label aria-autocomplete="off" for="description">{{'APPLICATION.MCC.DESCRIPTION' | translate}}</label>
                        </dt>
                        <dd>
                            <textarea class="form-control long" formControlName="description" autocomplete="off" type="text" id="description" rows="3">
                            </textarea>
                        </dd>
                    </dl>
                </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <button  class="btn btn-sm btn-success mx-0" (click)="updateMcc()">{{'BUTTONS.SAVE' | translate}}</button>
                <button class="btn btn-sm border mx-0" (click)="this.close(false)">{{'BUTTONS.CANCEL' | translate}}</button>
            </div>
        </form>
    </div>
</div>
