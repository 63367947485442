import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommissionService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getCommissionTypes(): any {
    return this.http.get(this.baseUrl + `/application/commissiontype`);
  }

  add(payload: any): any {
    return this.http.post(this.baseUrl + `/application/commissiontype`, payload);
  }

  update(idCommissionType: string, payload: any): any {
    return this.http.put(this.baseUrl + `/application/commissiontype/${idCommissionType}`, payload);
  }

  delete(idCommissionType: string): any {
    return this.http.delete(this.baseUrl + `/application/commissiontype/${idCommissionType}`);
  }
}
