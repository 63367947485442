import { Action } from '@ngrx/store';

export const LOAD_COMMISSION_TYPES_ATTEMPTED = '[Commissions] Load commissions attempted';
export const LOAD_COMMISSION_TYPES_SUCCESSFUL = '[Commissions] Load commissions successful';
export const LOAD_COMMISSIONS_TYPES_FAILED = '[Commissions] Load commissions failed';

export const ADD_COMMISSION_TYPE_ATTEMPTED = '[Commissions] Add commission attempted';
export const ADD_COMMISSION_TYPE_SUCCESSFUL = '[Commissions] Add commission successful';
export const ADD_COMMISSION_TYPE_FAILED = '[Commissions] Add commission failed';

export const UPDATE_COMMISSION_TYPE_ATTEMPTED = '[Commissions] Update commission attempted';
export const UPDATE_COMMISSION_TYPE_SUCCESSFUL = '[Commissions] Update commission successful';
export const UPDATE_COMMISSION_TYPE_FAILED = '[Commissions] Update commission failed';

export const DELETE_COMMISSION_TYPE_ATTEMPTED = '[Commissions] Delete commission attempted';
export const DELETE_COMMISSION_TYPE_SUCCESSFUL = '[Commissions] Delete commission successful';
export const DELETE_COMMISSION_TYPE_FAILED = '[Commissions] Delete commission failed';


export class LoadCommissionTypesAttemptedAction implements Action {
    readonly type = LOAD_COMMISSION_TYPES_ATTEMPTED;
    constructor() {}
}

export class LoadCommissionTypesSuccessfulAction implements Action {
    readonly type = LOAD_COMMISSION_TYPES_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadCommissionTypesFailedAction implements Action {
    readonly type = LOAD_COMMISSIONS_TYPES_FAILED;
    constructor(public payload: any) {}
}

export class AddCommissionTypeAttemptedAction implements Action {
    readonly type = ADD_COMMISSION_TYPE_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddCommissionTypeSuccessfulAction implements Action {
    readonly type = ADD_COMMISSION_TYPE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddCommissionTypeFailedAction implements Action {
    readonly type = ADD_COMMISSION_TYPE_FAILED;
    constructor(public payload: any) {}
}

export class UpdateCommissionTypeAttemptedAction implements Action {
    readonly type = UPDATE_COMMISSION_TYPE_ATTEMPTED;
    constructor(public idCommissionType: string, public payload: any) {}
}

export class UpdateCommissionTypeSuccessfulAction implements Action {
    readonly type = UPDATE_COMMISSION_TYPE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateCommissionTypeFailedAction implements Action {
    readonly type = UPDATE_COMMISSION_TYPE_FAILED;
    constructor(public payload: any) {}
}

export class DeleteCommissionTypeAttemptedAction implements Action {
    readonly type = DELETE_COMMISSION_TYPE_ATTEMPTED;
    constructor(public idCommissionType: string) {}
}

export class DeleteCommissionTypeSuccessfulAction implements Action {
    readonly type = DELETE_COMMISSION_TYPE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeleteCommissionTypeFailedAction implements Action {
    readonly type = DELETE_COMMISSION_TYPE_FAILED;
    constructor(public payload: any) {}
}

export type CommissionTypes =
    |LoadCommissionTypesAttemptedAction
    |LoadCommissionTypesFailedAction
    |LoadCommissionTypesSuccessfulAction
    |AddCommissionTypeAttemptedAction
    |AddCommissionTypeFailedAction
    |AddCommissionTypeSuccessfulAction
    |UpdateCommissionTypeAttemptedAction
    |UpdateCommissionTypeFailedAction
    |UpdateCommissionTypeSuccessfulAction
    |DeleteCommissionTypeAttemptedAction
    |DeleteCommissionTypeFailedAction
    |DeleteCommissionTypeSuccessfulAction
    ;

