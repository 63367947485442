import { Action } from '@ngrx/store';

export const LOAD_CHAT_HISTORY_ATTEMPTED = '[Contact] Load chat Messages History Attempted';
export const LOAD_CHAT_HISTORY_SUCCESSFUL = '[Contact] Load Chat Messages History Successful';
export const LOAD_CHAT_HISTORY_FAILED = '[Contact] Load Chat Messages History Failed';

export const SEND_MESSAGE_ATTEMPTED = '[Contact] Send Chat Message Attempted';
export const SEND_MESSAGE_SUCCESSFUL = '[Contact] Send chat Message Successful';
export const SEND_MESSAGE_FAILED = '[Contact] Send Chat Message Failed';

export const LOAD_CLIENT_TICKETS_ATTEMPTED = '[Contact] Load client created tickets - attempted';
export const LOAD_CLIENT_TICKETS_SUCCESSFUL = '[Contact] Load client created tickets - successful';
export const LOAD_CLIENT_TICKETS_FAILED = '[Contact] Load client created tickets - failed';

export const CREATE_CLIENT_TICKET_ATTEMPTED = '[Contact] Create client ticket - attemtped';
export const CREATE_CLIENT_TICKET_SUCCESSFUL = '[Contact] Create client ticket - successful';
export const CREATE_CLIENT_TICKET_FAILED = '[Contact] Create client ticket - failed';

export const DELETE_CLIENT_TICKET_ATTEMPTED = '[Contact] Delete client ticket - attempted';
export const DELETE_CLIENT_TICKET_SUCCESSFUL = '[Contact] Delete client ticket - successful';
export const DELETE_CLIENT_TICKET_FAILED = '[Contact] Delete client ticket - failed';

export const LOAD_ALL_CLIENT_TICKETS_ATTEMPTED = '[Contact] Load all client tickets - attemtped';
export const LOAD_ALL_CLIENT_TICKETS_SUCCESSFUL = '[Contact] Load all client tickets - successful';
export const LOAD_ALL_CLIENT_TICKETS_FAILED = '[Contact] Load all client tickets - failed';

export class LoadChatHistoryAttemptedAction implements Action {
    readonly type = LOAD_CHAT_HISTORY_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadChatHistorySuccessfulAction implements Action {
    readonly type = LOAD_CHAT_HISTORY_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadChatHistoryFailedAction implements Action {
    readonly type = LOAD_CHAT_HISTORY_FAILED;
    constructor(public payload: any) {}
}

export class SendMessageAttemptedAction implements Action {
    readonly type = SEND_MESSAGE_ATTEMPTED;
    constructor(public payload: any) {}
}

export class SendMessageSuccessfulAction implements Action {
    readonly type = SEND_MESSAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class SendMessageFailedAction implements Action {
    readonly type = SEND_MESSAGE_FAILED;
    constructor(public payload: any) {}
}

export class LoadClientTicketsAttemptedAction implements Action {
    readonly type = LOAD_CLIENT_TICKETS_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class LoadClientTicketsSuccessfulAction implements Action {
    readonly type = LOAD_CLIENT_TICKETS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadClientTicketsFailedAction implements Action {
    readonly type = LOAD_CLIENT_TICKETS_FAILED;
    constructor(public payload: any) {}
}

export class CreateClientTicketAttemptedAction implements Action {
    readonly type = CREATE_CLIENT_TICKET_ATTEMPTED;
    constructor(public idClient: string, public payload: any) {}
}

export class CreateClientTicketSuccessfulAction implements Action {
    readonly type = CREATE_CLIENT_TICKET_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class CreateClientTicketsFailedAction implements Action {
    readonly type = CREATE_CLIENT_TICKET_FAILED;
    constructor(public payload: any) {}
}

export class DeleteClientTicketAttemptedAction implements Action {
    readonly type = DELETE_CLIENT_TICKET_ATTEMPTED;
    constructor(public idClient: string, public idTicket: string) {}
}

export class DeleteClientTicketSuccessfulAction implements Action {
    readonly type = DELETE_CLIENT_TICKET_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeleteClientTicketFailedAction implements Action {
    readonly type = DELETE_CLIENT_TICKET_FAILED;
    constructor(public payload: any) {}
}

export class LoadAllClientTicketsAttemtpedAction implements Action {
    readonly type = LOAD_ALL_CLIENT_TICKETS_ATTEMPTED;
    constructor() {}
}

export class LoadAllClientTicketsSuccessfulAction implements Action {
    readonly type = LOAD_ALL_CLIENT_TICKETS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadAllClientTicketsFailedAction implements Action {
    readonly type = LOAD_ALL_CLIENT_TICKETS_FAILED;
    constructor(public payload: any) {}
}



export type ContactActions =
    |LoadChatHistoryAttemptedAction
    |LoadChatHistorySuccessfulAction
    |LoadChatHistoryFailedAction
    |SendMessageAttemptedAction
    |SendMessageSuccessfulAction
    |SendMessageFailedAction
    |LoadClientTicketsAttemptedAction
    |LoadClientTicketsFailedAction
    |LoadClientTicketsSuccessfulAction
    |CreateClientTicketAttemptedAction
    |CreateClientTicketSuccessfulAction
    |CreateClientTicketsFailedAction
    |DeleteClientTicketAttemptedAction
    |DeleteClientTicketFailedAction
    |DeleteClientTicketSuccessfulAction
    |LoadAllClientTicketsAttemtpedAction
    |LoadAllClientTicketsFailedAction
    |LoadAllClientTicketsSuccessfulAction
    ;
