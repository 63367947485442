<div class="close-button" xmlns="http://www/w3.org/1999/html">
    <img src="../../../assets/images/ic-close/close-btn.png"
        srcset="../../../assets/images/ic-close/close-btn@2x.png 2x,
                ../../../assets/images/ic-close/close-btn@3x.png 3x"
        class="close-btn"
        alt="close error"
        (click)="close(false)" />
</div>

<h1 mat-dialog-title>{{'TICKETS.NEW' | translate}}</h1>
<div mat-dialog-content>
    <form [formGroup]="ticketForm" id="new-ticket" aria-label="Ouverture d'un nouveau ticket">
        <div class="Subhead mb-5">
            <div class="Subhead-description">{{'TICKETS.STEPS' | translate}}</div>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="ticket-title">{{'TICKETS.TITLEE' | translate}} <span class="error">*</span></label>
                </dt>
                <dd>
                    <input class="form-control long" formControlName="title" autocomplete="off" type="text" id="ticket-title">
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="ticket-title">{{'TICKETS.RESUME' | translate}} <span class="note">({{'TICKETS.OPTIONAL' | translate}})</span></label>
                </dt>
                <dd>
                    <textarea formControlName="content" class="form-control long" rows="3"></textarea>
                    <span class="note d-block">{{'TICKETS.PROBLEME' | translate}}</span>
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="ticket-subject">Subject <span class="error">*</span></label>
                </dt>
                <dd>
                    <select class="form-control long" formControlName="subject" id="ticket-subject">
                        <option>SAFEDEPOSIT</option>
                        <option>VIRTUALCARD</option>
                        <option>CHILDCARD</option>
                        <option>PHYSICALCARD</option>
                        <option>POT</option>
                        <option>PERMANENTTRANSFER</option>
                        <option>OTHER</option>
                        <option>OPPOSITION</option>
                    </select>
                    <span class="note d-block">{{'TICKETS.SELECT_CATEGORY' | translate}}</span>
                </dd>
            </dl>
        </div>

        <hr />

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="ticket-createdByName">{{'TICKETS.CREATED_BY' | translate}}</label>
                </dt>
                <dd>
                    <input class="form-control long" formControlName="createdByName" autocomplete="off" type="text" id="ticket-createdbyName">
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="ticket-clientFirstName">{{'TICKETS.ASSIGNED_TO' | translate}} <span class="note">({{'TICKETS.NAME' | translate}})</span></label>
                </dt>
                <dd>
                    <input class="form-control long" formControlName="clientFirstName" autocomplete="off" type="text" id="ticket-clientFirstName">
                </dd>
            </dl>

            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="rang">{{'TICKETS.ASSIGNED_TO' | translate}} <span class="note">({{'TICKETS.LASTNAME' | translate}})</span></label>
                </dt>
                <dd>
                    <input class="form-control long" formControlName="clientLastName" autocomplete="off" type="text" id="ticket-clientLastName">
                </dd>
            </dl>
        </div>

    </form>
</div>
<div mat-dialog-actions class="mat-dialog-actions">
    <button mat-raised-button color="primary" class="border" (click)="onSubmit()" [disabled]="this.ticketForm.invalid">{{'BUTTONS.CREATE' | translate}}</button>
    <button mat-raised-button class="border" (click)="this.close(false)">{{'BUTTONS.CANCEL' | translate}}</button>
</div>
