import { Action } from '@ngrx/store';

export const LOAD_PACKAGES_ATTEMPTED = '[Package] Load packages attempted';
export const LOAD_PACKAGES_SUCCESSFUL = '[Package] Load packages successful';
export const LOAD_PACKAGES_FAILED = '[Package] Load packages failed';

export const ADD_PACKAGE_ATTEMPTED = '[Package] Add package attempted';
export const ADD_PACKAGE_SUCCESSFUL = '[Package] Add package successful';
export const ADD_PACKAGE_FAILED = '[Package] Add package failed';

export const UPDATE_PACKAGE_ATTEMPTED = '[Package] Update packages attempted';
export const UPDATE_PACKAGE_SUCCESSFUL = '[Package] Update packages successful';
export const UPDATE_PACKAGE_FAILED = '[Package] Update packages failed';

export const DELETE_PACKAGE_ATTEMPTED = '[Package] Delete package attempted';
export const DELETE_PACKAGE_SUCCESSFUL = '[Package] Delete package successful';
export const DELETE_PACKAGE_FAILED = '[Package] Delete package failed';

export const SELECT_PACKAGE = '[Package] Sets selected package';

export const AFFECT_PACKAGE_CLIENTS_ATTEMPTED = '[Package] Affect selected clients to selected package - attempted';
export const AFFECT_PACKAGE_CLIENTS_SUCCESSFUL = '[Package] Affect selected clients to selected package - successful';
export const AFFECT_PACKAGE_CLIENTS_FAILED = '[Package] Affect selected clients to selected package - failed';

export const GET_PACKAGE_SELECTED_CLIENTS_ATTEMPTED = '[Package] Get package selected clients - attempted';
export const GET_PACKAGE_SELECTED_CLIENTS_SUCCESSFUL = '[Package] Get package selected clients - successful';
export const GET_PACKAGE_SELECTED_CLIENTS_FAILED = '[Package] Get package selected clients - failed';

export const AFFECT_CLIENT_PACKAGE_ATTEMPTED = '[Package] Affect one client to a package - attempted';
export const AFFECT_CLIENT_PACKAGE_SUCCESSFUL = '[Package] Affect one client to package - successful';
export const AFFECT_CLIENT_PACKAGE_FAILED = '[Package] Affect one client to package - failed';

export const LOAD_CLIENT_PACKAGE_ATTEMPTED = '[Package] Load client package - attempted';
export const LOAD_CLIENT_PACKAGE_SUCCESSFUL = '[Package] Load client package - successful';
export const LOAD_CLIENT_PACKAGE_FAILED = '[Package] Load client package - failed';


export class LoadPackagesAttemptedAction implements Action {
    readonly type = LOAD_PACKAGES_ATTEMPTED;
    constructor() {}
}

export class LoadPackagesSuccessfulAction implements Action {
    readonly type = LOAD_PACKAGES_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadPackagesFailedAction implements Action {
    readonly type = LOAD_PACKAGES_FAILED;
    constructor(public payload: any) {}
}

export class AddPackageAttemptedAction implements Action {
    readonly type = ADD_PACKAGE_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddPackageSuccessfulAction implements Action {
    readonly type = ADD_PACKAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddPackageFailedAction implements Action {
    readonly type = ADD_PACKAGE_FAILED;
    constructor(public payload: any) {}
}

export class UpdatePackageAttemptedAction implements Action {
    readonly type = UPDATE_PACKAGE_ATTEMPTED;
    constructor(public idPackage: string, public payload: any) {}
}

export class UpdatePackageSuccessfulAction implements Action {
    readonly type = UPDATE_PACKAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdatePackageFailedAction implements Action {
    readonly type = UPDATE_PACKAGE_FAILED;
    constructor(public payload: any) {}
}

export class DeletePackageAttemptedAction implements Action {
    readonly type = DELETE_PACKAGE_ATTEMPTED;
    constructor(public idPackage: string) {}
}

export class DeletePackageSuccessfulAction implements Action {
    readonly type = DELETE_PACKAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeletePackageFailedAction implements Action {
    readonly type = DELETE_PACKAGE_FAILED;
    constructor(public payload: any) {}
}

export class SelectPackageAction implements Action {
    readonly type = SELECT_PACKAGE;
    constructor(public payload: any) {}
}

export class AffectPackageToClientsAttemptedAction implements Action {
    readonly type = AFFECT_PACKAGE_CLIENTS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AffectPackageToClientsSuccessfulAction implements Action {
    readonly type = AFFECT_PACKAGE_CLIENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AffectPackageToClientsFailedAction implements Action {
    readonly type = AFFECT_PACKAGE_CLIENTS_FAILED;
    constructor(public payload: any) {}
}

export class GetPackageSelectedClientsAttemptedAction implements Action {
    readonly type = GET_PACKAGE_SELECTED_CLIENTS_ATTEMPTED;
    constructor(public idPackage: any) {}
}

export class GetPackageSelectedClientsSuccessfulAction implements Action {
    readonly type = GET_PACKAGE_SELECTED_CLIENTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class GetPackageSelectedClientsFailedAction implements Action {
    readonly type = GET_PACKAGE_SELECTED_CLIENTS_FAILED;
    constructor(public payload: any) {}
}

export class AffectClientPackageAttemptedAction implements Action {
    readonly type = AFFECT_CLIENT_PACKAGE_ATTEMPTED;
    constructor(public idClient: string, public idPackageClient: string) {}
}

export class AffectClientPackageSuccessfulAction implements Action {
    readonly type = AFFECT_CLIENT_PACKAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AffectClientPackageFailedAction implements Action {
    readonly type = AFFECT_CLIENT_PACKAGE_FAILED;
    constructor(public payload: any) {}
}

export class LoadClientPackageAttemtedAction implements Action {
    readonly type = LOAD_CLIENT_PACKAGE_ATTEMPTED;
    constructor(public idClient: string) {}
}

export class LoadClientPackageSuccessfulAction implements Action {
    readonly type = LOAD_CLIENT_PACKAGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadClientPackageFailedAction implements Action {
    readonly type = LOAD_CLIENT_PACKAGE_FAILED;
    constructor(public payload: any) {}
}


export type PackageActions =
    |LoadPackagesAttemptedAction
    |LoadPackagesFailedAction
    |LoadPackagesSuccessfulAction
    |AddPackageAttemptedAction
    |AddPackageSuccessfulAction
    |AddPackageFailedAction
    |UpdatePackageAttemptedAction
    |UpdatePackageFailedAction
    |UpdatePackageSuccessfulAction
    |DeletePackageAttemptedAction
    |DeletePackageFailedAction
    |DeletePackageSuccessfulAction
    |SelectPackageAction
    |AffectPackageToClientsAttemptedAction
    |AffectPackageToClientsFailedAction
    |AffectPackageToClientsSuccessfulAction
    |GetPackageSelectedClientsAttemptedAction
    |GetPackageSelectedClientsFailedAction
    |GetPackageSelectedClientsSuccessfulAction
    |AffectClientPackageAttemptedAction
    |AffectClientPackageFailedAction
    |AffectClientPackageSuccessfulAction
    |LoadClientPackageAttemtedAction
    |LoadClientPackageFailedAction
    |LoadClientPackageSuccessfulAction
    ;
