import { TranslateService } from '@ngx-translate/core';
import { Pipe, PipeTransform } from '@angular/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Pipe({
  name: 'documentState'
})
export class DocumentStatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: any, ...args: any[]): any {
    switch (value) {
      case 'CONFIRMED':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.CONFIRMED');
      case 'KYC_CONFIRMED':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.KYC_CONFIRMED');
      case 'KYC_REFUSED':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.KYC_REFUSED');
      case 'REFUSED':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.REFUSED');
      case 'PENDING_KYC':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.PENDING_KYC');
      case 'PENDING':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.PENDING');
      case 'RECTIFICATION':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.RECTIFICATION');
      case 'RECTIFICATION_KYC':
        return this.translate.instant('HOME.USER_VISUAL_DOCUMENTS.DOC_STATE.RECTIFICATION_KYC');
    }
  }

}
