import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as fromOperation from './operation.action';
import { OperationService } from '../../services/operations/operation.service';

@Injectable()
export class OperationEffects {

    constructor(private actions$: Actions, private operationService: OperationService) {}

    @Effect()
    loadMccAttempted$ = this.actions$.pipe(
        ofType<fromOperation.LoadOperationsAttemptedAction>(fromOperation.LOAD_OPERATIONS_ATTEMPTED),
        switchMap((data: fromOperation.LoadOperationsAttemptedAction) => this.operationService.getOperations()
            .pipe(
                map(resp => new fromOperation.LoadOperationsSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromOperation.LoadOperationsFailedAction(err)); })
            )
        )
    );

    @Effect()
    addPackageAttempted$ = this.actions$.pipe(
        ofType<fromOperation.AddOperationAttemptedAction>(fromOperation.ADD_OPERATION_ATTEMPTED),
        switchMap((data: fromOperation.AddOperationAttemptedAction) => this.operationService.add(data.payload)
            .pipe(
                map(resp => new fromOperation.AddOperationSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromOperation.AddOperationFailedAction(err)); })
            )
        )
    );

    @Effect()
    updatePackageAttempted$ = this.actions$.pipe(
        ofType<fromOperation.UpdateOperationAttemptedAction>(fromOperation.UPDATE_OPERATION_ATTEMPTED),
        switchMap((data: fromOperation.UpdateOperationAttemptedAction) => this.operationService.update(data.idOperation, data.payload)
            .pipe(
                map(resp => new fromOperation.UpdateOperationSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromOperation.UpdateOperationFailedAction(err)); })
            )
        )
    );

    @Effect()
    deletePackageAttempted$ = this.actions$.pipe(
        ofType<fromOperation.DeleteOperationAttemtpedAction>(fromOperation.DELETE_OPERATION_ATTEMPTED),
        switchMap((data: fromOperation.DeleteOperationAttemtpedAction) => this.operationService.delete(data.idOperation)
            .pipe(
                map(resp => new fromOperation.DeleteOperationSuccessfulAction(resp)),
                catchError(err => { console.log(err); return of (new fromOperation.DeleteOperationFailedAction(err)); })
            )
        )
    );
}