import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

  transform(value: any, ...args: any): any {
    switch (value) {
      case 'EUR': {
        return '€';
      }
      case 'USD': {
        return '$';
      }
      case 'GBP': {
        return '£';
      }
      default: {
        return '€';
      }
    }
  }

}
