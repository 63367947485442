import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { UpdateOfferAttemtpedAction, SelectOfferAction, LoadOffersAttemptedAction } from '../../../core/store/offers/offers.actions';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getSelectedOffer, getUpdateResponse } from '../../../core/store/offers/offers.reducer';

@Component({
  selector: 'app-update-offer',
  templateUrl: './update-offer.component.html',
  styleUrls: ['./update-offer.component.scss']
})
export class UpdateOfferComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  linkIsChecked = false;

  htmlIsChecked = false;

  textIsChecked = false;

  imageIsSelected = false;

  offerForm: FormGroup;

  readOnly = false;

  options: any = {
    lineNumbers: true,
    mode: 'htmlmixed',
    theme: 'material',
    value: '<p>Hello</p>',
    htmlMode: true,
    autoFocus: true
  };

  link: string;

  code: string;

  text: string;

  @ViewChild('textarea')
  private textArea: ElementRef;

  token: string;

  imageError: string;

  isImageSaved: boolean;

  cardImageBase64: string;

  previewImagePath: any;

  fileName: string;

  selectedOffer: any;

  action: string;

  isUpdateClicked: boolean;

  private updateSubscription$;


  constructor(private store: Store<AppState>, private router: Router, private fb: FormBuilder) {
    this.offerForm = this.fb.group({
      title: [null, Validators.compose([Validators.required])],
      expiration: [null, Validators.compose([Validators.required])],
      contentType: [null, Validators.compose([Validators.required])]
    });
  }

  ngOnDestroy(): void {
    if (this.updateSubscription$) {
      this.updateSubscription$.unsubscribe();
    }
  }

  ngOnInit(): void {
    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_OFFERT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_OFFERT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.updateSubscription$ = this.store.select(getUpdateResponse).pipe(filter(resp => resp !== undefined)).subscribe(resp => {
      this.router.navigate(['/application/offers']);
    });

    this.store.select(getSelectedOffer).pipe(take(1)).subscribe(data => {
      console.log(data);
      this.selectedOffer = data.offer;
      this.action = data.action;
      if (this.action === 'show') {
        this.offerForm.get('title').disable();
        this.offerForm.get('expiration').disable();
        this.offerForm.get('contentType').disable();
        this.isUpdateClicked = false;
      } else {
        this.isUpdateClicked = true;
      }
    },
    (err) => console.log(err),
    () => {
      if (this.selectedOffer === undefined || this.selectedOffer === null) {
        this.router.navigate(['/application/offers']);
        return;
      }
      if (this.selectedOffer.contentType === 'LINK') {
        this.offerForm.patchValue({
          title: this.selectedOffer.title,
          expiration: new Date(this.selectedOffer.expiryDate).toISOString().slice(0, 19),
          contentType: this.selectedOffer.contentType
        });
        this.linkIsChecked = true;
        this.link = this.selectedOffer.content;
      }
      if (this.selectedOffer.contentType === 'HTML') {
        this.offerForm.patchValue({
          title: this.selectedOffer.title,
          expiration: new Date(this.selectedOffer.expiryDate).toISOString().slice(0, 19),
          contentType: this.selectedOffer.contentType
        });
        this.htmlIsChecked = true;
        this.code = this.selectedOffer.content;
      }
      if (this.selectedOffer.contentType === 'PLAIN_TEXT') {
        this.offerForm.patchValue({
          title: this.selectedOffer.title,
          expiration: new Date(this.selectedOffer.expiryDate).toISOString().slice(0, 19),
          contentType: this.selectedOffer.contentType
        });
        this.textIsChecked = true;
        this.text = this.selectedOffer.content;
      }
      if (this.selectedOffer.contentType === 'BASE64') {
        this.offerForm.patchValue({
          title: this.selectedOffer.title,
          expiration: new Date(this.selectedOffer.expiryDate).toISOString().slice(0, 19),
          contentType: this.selectedOffer.contentType
        });
        this.imageIsSelected = true;
      }
      console.log('*****', this.offerForm.value);
    });
  }

  enableUpdate(): void {
    this.store.dispatch(new SelectOfferAction(this.selectedOffer, 'update'));
    this.action = 'update';
    this.offerForm.controls['title'].enable();
    this.offerForm.controls['expiration'].enable();
    this.offerForm.controls['contentType'].enable();
    this.isUpdateClicked = true;
  }

  cancelUpdate(): void {
    this.store.dispatch(new SelectOfferAction(this.selectedOffer, 'show'));
    this.action = 'show';
    this.offerForm.controls['title'].disable();
    this.offerForm.controls['expiration'].disable();
    this.offerForm.controls['contentType'].disable();
    this.isUpdateClicked = false;
  }


  onSubmit(): void {
    let payload: any;
    switch (this.offerForm.get('contentType').value) {
      case 'LINK':
        payload = {
          id: this.selectedOffer.id,
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.link
        };
        break;
      case 'HTML':
        payload = {
          id: this.selectedOffer.id,
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.code
        };
        break;
      case 'PLAIN_TEXT':
        payload = {
          id: this.selectedOffer.id,
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.text
        };
        break;
      case 'Image':
        payload = {
          id: this.selectedOffer.id,
          title: this.offerForm.get('title').value,
          expiryDate: this.offerForm.get('expiration').value,
          contentType: this.offerForm.get('contentType').value,
          content: this.cardImageBase64
        };
        break;
    }
    this.store.dispatch(new UpdateOfferAttemtpedAction(this.selectedOffer.id, payload));
  }

  onContentTypeChecked(value: any, event: any): void {
    if (this.action !== 'show') {
      switch (value) {
        case 'Link':
          this.linkIsChecked = true;
          this.htmlIsChecked = false;
          this.textIsChecked = false;
          this.imageIsSelected = false;
          break;
        case 'HTML':
          this.htmlIsChecked = true;
          this.linkIsChecked = false;
          this.textIsChecked = false;
          this.imageIsSelected = false;
          break;
        case 'PLAIN_TEXT':
        this.textIsChecked = true;
        this.linkIsChecked = false;
        this.htmlIsChecked = false;
        this.imageIsSelected = false;
        setTimeout(() => {
          this.textArea.nativeElement.focus();
        }, 0);
        break;
        case 'Image':
          this.imageIsSelected = true;
          this.linkIsChecked = false;
          this.htmlIsChecked = false;
          this.textIsChecked = false;
          break;
      }
    }
  }

  docUpload(res: any): void {
    console.log('upload called');
  }

  isFieldValid(field: string): boolean {
    return !this.offerForm.get(field).valid && this.offerForm.get(field).touched;
  }

  fileChangeEvent(fileInput: any): any {
    this.imageError = null;
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
        'Maximum size allowed is ' + max_size / 1000 + 'Mb';
        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = rs => {
        const img_height = rs.currentTarget['height'];
        const img_width = rs.currentTarget['width'];

        console.log(img_height, img_width);


        if (img_height > max_height && img_width > max_width) {
          this.imageError = 'Maximum dimentions allowed ' + max_height + '*' + max_width + 'px';
          return false;
        } else {
          const imgBase64Path = e.target.result;
          this.cardImageBase64 = imgBase64Path;
          this.isImageSaved = true;
          this.previewImagePath = imgBase64Path;
        }
      };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
      this.fileName = fileInput.target.files[0].name;
    }
  }

  stopPropagation(event: any): void {
    if (this.action === 'show') {
      event.stopPropagation();
    }
  }


}
