import { Action } from '@ngrx/store';

export const LOAD_NEWS_ATTEMPTED = '[News] Load News Attempted';
export const LOAD_NEWS_SUCCESSFUL = '[News] Load News Successful';
export const LOAD_NEWS_FAILED = '[News] Load News Failed';

export const ADD_NEWS_ATTEMPTED = '[News] Add new news attempted';
export const ADD_NEWS_SUCCESSFUL = '[News] Add new news successful';
export const ADD_NEWS_FAILED =  '[News] Add new news failed';

export const UPDATE_NEWS_ATTEMPTED = '[News] Update new news attempted';
export const UPDATE_NEWS_SUCCESSFUL = '[News] Update new news successful';
export const UPDATE_NEWS_FAILED =  '[News] Update new news failed';

export const DELETE_NEWS_ATTEMPTED = '[News] Delete new news attempted';
export const DELETE_NEWS_SUCCESSFUL = '[News] Delete new news successful';
export const DELETE_NEWS_FAILED =  '[News] Delete new news failed';

export const SELECT_NEWS = '[News] Select News';


export class LoadNewsAttemptedAction implements Action {
    readonly type = LOAD_NEWS_ATTEMPTED;
    constructor() {}
}

export class LoadNewsSuccessfulAction implements Action {
    readonly type = LOAD_NEWS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadNewsFailedAction implements Action {
    readonly type = LOAD_NEWS_FAILED;
    constructor(public payload: any) {}
}

export class AddNewsAttemptedAction implements Action {
    readonly type = ADD_NEWS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddNewsSuccessfulAction implements Action {
    readonly type = ADD_NEWS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddNewsFailedAction implements Action {
    readonly type = ADD_NEWS_FAILED;
    constructor(public payload: any) {}
}

export class UpdateNewsAttemptedAction implements Action {
    readonly type = UPDATE_NEWS_ATTEMPTED;
    constructor(public idNews: string, public payload: any) {}
}

export class UpdateNewsSuccessfulAction implements Action {
    readonly type = UPDATE_NEWS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateNewsFailedAction implements Action {
    readonly type = UPDATE_NEWS_FAILED;
    constructor(public payload: any) {}
}

export class DeleteNewsAttemptedAction implements Action {
    readonly type = DELETE_NEWS_ATTEMPTED;
    constructor(public idNews: string) {}
}

export class DeleteNewsSuccessfulAction implements Action {
    readonly type = DELETE_NEWS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeleteNewsFailedAction implements Action {
    readonly type = DELETE_NEWS_FAILED;
    constructor(public payload: any) {}
}

export class SelectNewsAction implements Action {
    readonly type = SELECT_NEWS;
    constructor(public payload: any, public action: string) {}
}


export type NewsActions =
    |LoadNewsAttemptedAction
    |LoadNewsSuccessfulAction
    |LoadNewsFailedAction
    |AddNewsAttemptedAction
    |AddNewsSuccessfulAction
    |AddNewsFailedAction
    |UpdateNewsAttemptedAction
    |UpdateNewsFailedAction
    |UpdateNewsSuccessfulAction
    |DeleteNewsAttemptedAction
    |DeleteNewsSuccessfulAction
    |DeleteNewsFailedAction
    |SelectNewsAction
    ;
