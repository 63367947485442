import { Deposit } from '../../models/deposit.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTransaction from './transaction.actions';

export interface TransactionState {
    data: any[];
    transactionToExport: any[];
    selected: any;
    loaded: boolean;
    loading: boolean;
    error: string;
}

export const initialState: TransactionState = {
    data: undefined,
    transactionToExport: undefined,
    selected: undefined,
    loaded: false,
    loading: false,
    error: undefined,
};



export function reducer(state = initialState, action: fromTransaction.TransactionActions): TransactionState {
    switch (action.type) {
        case fromTransaction.LOAD_TRANSACTIONS_ATTEMTPED:
            return {
                ...state,
                loading: true,
                loaded: false,
                data: undefined
            };
        case fromTransaction.LOAD_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                loading : false,
                loaded : true,
                data : action.payload.value
            };
        case fromTransaction.LOAD_TRANSACTIONS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            };

        case fromTransaction.LOAD_TRANSACTIONS_FILTERED_ATTEMTPED:
            return {
                ...state,
                loading: true,
                loaded: false
            };
        case fromTransaction.LOAD_TRANSACTIONS_FILTERED_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload.value
            };
        case fromTransaction.LOAD_TRANSACTIONS_FILTERED_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.payload
            };
        case fromTransaction.SELECT_TRANSACTION:
            return {
                ...state,
                selected: action.payload
            };
        case fromTransaction.UNSELECT_TRANSACTION:
            return {
                ...state,
                selected: undefined
            };
        case fromTransaction.LOAD_TRANSACTIONS_BY_CATEGORY_ATTEMPTED:
            return {
                ...state,
                loading: true,
                transactionToExport: undefined
            };
        case fromTransaction.LOAD_TRANSACTIONS_BY_CATEGORY_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                transactionToExport: action.payload.value,
            };
        case fromTransaction.LOAD_TRANSACTIONS_BY_CATEGORY_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }

}

export const getTransactionState = createFeatureSelector < TransactionState > ('transactions');

export const getTransactions = createSelector(getTransactionState, (state: TransactionState) => state.data);
export const getSelectedTransaction = createSelector(getTransactionState, (state: TransactionState) => state.selected);
export const getTransactionsToExport = createSelector(getTransactionState, (state: TransactionState) => state.transactionToExport);
