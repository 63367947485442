import { Action } from '@ngrx/store';

export const LOAD_POTS_ATTEMPTED = '[Pots] Load Client Pots';
export const LOAD_POTS_SUCCESSFUL = '[Pots] Load Client Pots Successful';
export const LOAD_POTS_FAILED = '[Pots] Load Client Pots Failed';

export class LoadPotsAttemptedAction implements Action {
    readonly type = LOAD_POTS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadPotsSuccessfulAction implements Action {
    readonly type = LOAD_POTS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadPotsFailedAction implements Action {
    readonly type = LOAD_POTS_FAILED;
    constructor(public payload: any) {}
}

export type PotsActions =
    |LoadPotsAttemptedAction
    |LoadPotsSuccessfulAction
    |LoadPotsFailedAction
    ;

