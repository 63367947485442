import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromPackage from './package.action';

export interface PackageState {
    data: any[];
    addResponse: any;
    deleteResponse: any;
    updateResponse: any;
    selectedPackage: any;
    packageResponse: any;
    packageSelectedClients: any[];
    clientPackage: any;
    affectClientPackageResponse: any;
    action: string;
    loading: boolean;
    error: string;
}

export const initialState: PackageState = {
    data: undefined,
    addResponse: undefined,
    deleteResponse: undefined,
    updateResponse: undefined,
    selectedPackage: undefined,
    packageResponse: undefined,
    packageSelectedClients: undefined,
    clientPackage: undefined,
    affectClientPackageResponse: undefined,
    action: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: PackageState = initialState, action: fromPackage.PackageActions): any {
    switch (action.type) {
        case fromPackage.LOAD_PACKAGES_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined,
                addResponse: undefined,
                deleteResponse: undefined,
                updateResponse: undefined,
                selectedPackage: undefined,
                packageResponse: undefined,
                packageSelectedClients: undefined,
                clientPackage: undefined,
                affectClientPackageResponse: undefined
            };
        case fromPackage.LOAD_PACKAGES_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value,
            };
        case fromPackage.LOAD_PACKAGES_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

            case fromPackage.ADD_PACKAGE_ATTEMPTED:
                return {
                    ...state,
                    loading: true,
                };
            case fromPackage.ADD_PACKAGE_SUCCESSFUL:
                return {
                    ...state,
                    loading: false,
                    addResponse: action.payload,
                };
            case fromPackage.ADD_PACKAGE_FAILED:
                return {
                    ...state,
                    loading: false,
                    error: action.payload,
                };
                case fromPackage.UPDATE_PACKAGE_ATTEMPTED:
                    return {
                        ...state,
                        loading: true,
                    };
                case fromPackage.UPDATE_PACKAGE_SUCCESSFUL:
                    return {
                        ...state,
                        loading: false,
                        updateResponse: action.payload,
                    };
                case fromPackage.UPDATE_PACKAGE_FAILED:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                    };

                    case fromPackage.DELETE_PACKAGE_ATTEMPTED:
                    return {
                        ...state,
                        loading: true,
                    };
                case fromPackage.DELETE_PACKAGE_SUCCESSFUL:
                    return {
                        ...state,
                        loading: false,
                        deleteResponse: action.payload,
                    };
                case fromPackage.DELETE_PACKAGE_FAILED:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload,
                    };
                case fromPackage.SELECT_PACKAGE:
                    return {
                        ...state,
                        selectedPackage: action.payload
                    };
                case fromPackage.AFFECT_PACKAGE_CLIENTS_ATTEMPTED:
                    return {
                        ...state,
                        loading: true,

                    };
                case fromPackage.GET_PACKAGE_SELECTED_CLIENTS_ATTEMPTED:
                    return {
                        ...state,
                        loading: true,
                        packageSelectedClients: action.idPackage
                    };
                case fromPackage.GET_PACKAGE_SELECTED_CLIENTS_SUCCESSFUL:
                    return {
                        ...state,
                        loading: false,
                        packageSelectedClients: action.payload.value
                    };
                case fromPackage.GET_PACKAGE_SELECTED_CLIENTS_FAILED:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload
                    };
                case fromPackage.LOAD_CLIENT_PACKAGE_ATTEMPTED:
                    return {
                        ...state,
                        loading: true,
                    };
                case fromPackage.LOAD_CLIENT_PACKAGE_SUCCESSFUL:
                    return {
                        ...state,
                        loading: false,
                        clientPackage: action.payload.value
                    };
                case fromPackage.LOAD_CLIENT_PACKAGE_FAILED:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload
                    };
                case fromPackage.AFFECT_CLIENT_PACKAGE_ATTEMPTED:
                    return {
                        ...state,
                        loading: true,
                        affectClientPackageResponse: undefined
                    };
                case fromPackage.AFFECT_CLIENT_PACKAGE_SUCCESSFUL:
                    return {
                        ...state,
                        loading: false,
                        affectClientPackageResponse: action.payload
                    };
                case fromPackage.AFFECT_CLIENT_PACKAGE_FAILED:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload
                    };
                default:
                    return state;
    }
}

export const getPackageState = createFeatureSelector<PackageState>('packages');

export const getPackages = createSelector(getPackageState, (state: PackageState) => state.data);
export const getPackageAddResponse = createSelector(getPackageState, (state: PackageState) => state.addResponse);
export const getPackageUpdateResponse = createSelector(getPackageState, (state: PackageState) => state.updateResponse);
export const getPackageDeleteResponse = createSelector(getPackageState, (state: PackageState) => state.deleteResponse);
export const getSelectedPackage = createSelector(getPackageState, (state: PackageState) => state.selectedPackage);
export const getPackageSelectedClients = createSelector(getPackageState, (state: PackageState) => state.packageSelectedClients);
export const getClientPackage = createSelector(getPackageState, (state: PackageState) => state.clientPackage);
export const getAffectClientPackageResponse = createSelector(getPackageState, (state: PackageState) => state.affectClientPackageResponse);
