<div class="content">
    <div class="full-height py-18 bg-white">
        <div class="container h-100">
            <div class="card">
                <div class="card-header">
                    <div class="bold float-left">{{'APPLICATION.OFFERS.HEADER' | translate}}</div>
                    <ng-container *ngIf="haveReadPermission">
                        <div *ngIf="!this.haveWritePermission" class="d-flex justify-center align-center float-left ml-18">
                            <p class="error">{{'ACCESS_CONTROL.NO_WRITE_PERMISSION' | translate}}</p> &nbsp;
                            <span class="link f-1" (click)="onSeePermissions()"> ({{'ACCESS_CONTROL.MY_PERMISSIONS' | translate}}) </span>
                        </div>
                    </ng-container>
                    <button type="button" class="btn btn-sm btn-success float-right" *ngIf="haveReadPermission" [disabled]="!haveWritePermission"  (click)="onNewOffer()">
                        <i class="fa fa-plus" aria-hidden="true" width="16" height="16"></i> {{'BUTTONS.NEW' | translate}}
                    </button>
                    <div class="clearfix"></div>
                </div>

                <div class="card-body" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                    <div class="spinner" *ngIf="loading" >
                        <mat-spinner [diameter]="40"></mat-spinner>
                    </div>
    
                    <div *ngIf="!loading">
                        <ng-container *ngIf="offersLength > 0; else OFFERS_EMPTY">
                            <article class="Box-row" *ngFor="let offer of offers$" >
                                <div class="float-right">
                                    <span *ngIf="haveWritePermission"  class="muted-link ml-3" (click)="modifyOffer(offer)" title="{{'BUTTONS.UPDATE' | translate}}">
                                        <i class="fa fa-edit" width="16" height="16"></i>
                                    </span>
                                
                                    <span *ngIf="haveWritePermission"  class="muted-link ml-3" (click)="deleteOffer(offer)" title="{{'BUTTONS.DELETE' | translate}}">
                                        <i class="fa fa-trash" width="16" height="16"></i>
                                    </span>
                                </div>
    
                                <h1 class="h3 lh-condensed">
                                    <span class="muted-link" (click)="offerDetails(offer)">
                                        <i class="fa fa-gift ic-gift" aria-hidden="true" width="16" height="16"></i>
                                        <span>{{offer.title}}</span>
                                    </span>
                                </h1>
                                <p class="col-9 text-gray my-1 pr-4 max-height" *ngIf="offer.contentType !=='BASE64'" >{{offer.content}}</p>
                                <div class="f6 text-gray mt-2">
                                    <span class="d-inline-block ml-0 mr-3">
                                        <i [ngClass]="{'fa': true, 'bc-link': true , 'fa-link': offer.contentType === 'LINK'}" aria-hidden="true" width="12" height="12"></i>
                                        <i [ngClass]="{'fa': true, 'bc-html': true, 'fa-file-code': offer.contentType === 'HTML'}" aria-hidden="true" width="12" height="12"></i>
                                        <i [ngClass]="{'fa': true, 'bc-text': true, 'fa-file-alt': offer.contentType === 'PLAIN_TEXT'}" aria-hidden="true" width="12" height="12"></i>
                                        <i [ngClass]="{'fa': true, 'bc-img': true, 'fa-file-image': offer.contentType === 'BASE64'}" aria-hidden="true" width="12" height="12"></i>
                                        <span class="ml-4">{{offer.contentType}}</span>
                                    </span>
                                    <span class="d-inline-block mr-3">{{'APPLICATION.OFFERS.EXPIRES_ON' | translate}}: {{offer.expiryDate | date: 'short'}}</span>
                                </div>
                            </article>
                        </ng-container>
                        <ng-template #OFFERS_EMPTY>
                            <div class="none-block" *ngIf="offersLength == 0">
                                <img src="../../..//assets/images/vide/vide.png"
                                    srcset="../../../assets/images/vide/vide@2x.png 2x, ../../../assets/images/vide/vide@3x.png 3x"
                                    class="none"/>
                                <p class="text none-text">{{'ACCESS_CONTROL.NO_ACCESS' | translate}}</p>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
                srcset="../../../assets/images/vide/vide@2x.png 2x,
                ../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> Pas d'accès </p> 
        </div>     
    </div>
</ng-template>