import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { AppState } from 'src/app/core/store';
import { getCardsScreen } from '../../core/store/cards/cards.reducer';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { CardsScreenAction } from '../../core/store/cards/cards.actions';
import { take, filter } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  haveWritePermission = false;

  haveReadPermission = false;

  cardsScreen: number;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_CARD_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_CARD_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.store.dispatch(new CardsScreenAction(1));
    this.store.select(getCardsScreen).pipe(filter(obj => obj !== undefined), take(2)).subscribe(screen => {
      this.cardsScreen = screen;
    });
  }

  onNavigateToPhysical(): void {
    this.store.dispatch(new CardsScreenAction(2));
    this.router.navigate(['/clients/details/cards/physical']);
  }

  onNavigateToVirtual(): void {
    this.store.dispatch(new CardsScreenAction(3));
    this.router.navigate(['clients/details/cards/virtual']);
  }

  onNavigateToChild(): void {
    this.store.dispatch(new CardsScreenAction(4));
    this.router.navigate(['/clients/details/cards/childs']);
  }

}
