import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import {LoadTransactionsAttemptedAction,
        LoadTransactionsFilteredAttemptedAction } from '../../core/store/transactions/transaction.actions';
import { getTransactions, getTransactionsToExport } from '../../core/store/transactions/transaction.reducer';
import { filter, take } from 'rxjs/operators';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ExcelExportService } from '../../core/services/shared/excel-export.service';
import { LoadTransactionsByCategoryAttemptedAction } from '../../core/store/transactions/transaction.actions';
import { Router } from '@angular/router';


class AdvancedSearchPayload {
  debit: boolean;
  credit: boolean;
  startDate: Date;
  endDate: Date;
  amount: number;
  currency: string;
}

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  private subscription: any;

  searchForm = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
    amount: new FormControl(),
    currency: new FormControl('EUR')
  });

  payload: AdvancedSearchPayload;

  loading: boolean;

  transactions: any;

  transactionsLength: number;

  transactionsDisplayedColumns: string[] = [
    'category_transaction',
    'label_transaction',
    'amount_transaction',
  ];

  transactionNameSearchBox = '';

  filterByCategory = '';

  selectedCategory = 'aucune';

  filterByType = '';

  selectedType = 'aucun';

  selectedDateAmount = 'aucune';

  @ViewChild('dateAmountMenuTrigger') dateAmountMenuTrigger: MatMenuTrigger;

  constructor(private store: Store<AppState>, private excelService: ExcelExportService, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      console.log('- ', permission);
      if (permission.trim() === 'CLIENT_TRANSACTIONS_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_TRANSACTIONS_WRITE') {
        this.haveWritePermission = true;
      }
    }
    console.log('************ ', this.haveReadPermission, this.haveWritePermission);
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.payload = new AdvancedSearchPayload();
    this.store.dispatch(new LoadTransactionsAttemptedAction({idClient: client.id, filterKey: 'DATE'}));
    this.subscription = this.store.select(getTransactions).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log('transactions ', data);
      this.transactions = data;
      this.transactionsLength = data.length;
      this.loading = false;
      console.log('loading is', this.loading);
    });

    this.selectedCategory = 'Par date';
    this.filterByCategory = 'DATE';
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  transactionHistory(id: string): void {
  }

  transactionDetails(element: any): void {
  }

  filterCategory(category: string): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.filterByCategory = category;
    if (category === 'OPERATION_TYPE') {
      this.selectedCategory = 'Par type d\'opération';
      this.store.dispatch(new LoadTransactionsAttemptedAction({idClient: client.id, filterKey: 'CATEGORY'}));
    } else if (category === 'DATE') {
      this.selectedCategory = 'Par date';
      this.store.dispatch(new LoadTransactionsAttemptedAction({idClient: client.id, filterKey: 'DATE'}));
    } else {
      this.selectedCategory = 'aucune';
      this.store.dispatch(new LoadTransactionsAttemptedAction({idClient: client.id, filterKey: 'DATE'}));
    }
    this.resetFilters();
  }

  resetFilters(): void {
    this.payload = new AdvancedSearchPayload();
    this.searchForm.reset();
    this.selectedDateAmount = 'aucune';
    this.selectedType = 'aucun';
  }

  filterDateAmount(value: string): void {
    this.dateAmountMenuTrigger.closeMenu();
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    if (value === 'date/montant') {
      this.selectedDateAmount = 'date/montant';
      const payload = {
        amount: this.searchForm.get('amount').value,
        startDate: this.searchForm.get('startDate').value,
        endDate: this.searchForm.get('endDate').value,
        currency: this.searchForm.get('currency').value
      };

      this.payload.amount = this.searchForm.get('amount').value;
      this.payload.startDate = this.searchForm.get('startDate').value;
      this.payload.endDate = this.searchForm.get('endDate').value;
      this.payload.currency = this.searchForm.get('currency').value;

      this.store.dispatch(new LoadTransactionsFilteredAttemptedAction(client.id, this.payload));
    } else {
      this.selectedDateAmount = 'aucune';
      this.payload.startDate = null;
      this.payload.endDate = null;
      this.payload.amount = null;
      this.payload.currency = 'EUR';

      this.searchForm.reset();
      this.store.dispatch(new LoadTransactionsFilteredAttemptedAction(client.id, this.payload));
    }
    console.log('current search payload is :', this.payload);
  }

  filterType(type: string): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.filterByType = type;
    if (type === 'DEBIT') {
      this.selectedType = 'Par débit';
      const payload = {
        debit: true,
        credit: false
      };
      this.payload.debit = true;
      this.payload.credit = false;
      this.selectedCategory = 'Par date';
      this.filterByCategory = 'DATE';
      this.store.dispatch(new LoadTransactionsFilteredAttemptedAction(client.id, this.payload));
    } else if (type === 'CREDIT') {
      this.selectedType = 'Par Crédit';
      const payload = {
        debit: false,
        credit: true,
      };
      this.payload.debit = false;
      this.payload.credit = true;
      this.selectedCategory = 'Par date';
      this.filterByCategory = 'DATE';
      this.store.dispatch(new LoadTransactionsFilteredAttemptedAction(client.id, this.payload));
    } else {
      this.selectedType = 'aucun';
      this.payload.credit = false;
      this.payload.debit = false;
      this.store.dispatch(new LoadTransactionsAttemptedAction({idClient: client.id, filterKey: 'DATE'}));
    }
    console.log('current search payload is: ', this.payload);
  }

  onEnter(): void {
  }

  exportAsXLSX(): void {
    console.log(this.transactions);
    const client = JSON.parse(localStorage.getItem('client'));
    const d = new Date();
    const dformat = [d.getMonth() + 1, d.getDate(), d.getFullYear()].join('/') + ' ' + [d.getHours(), d.getMinutes(), d.getSeconds()].join(':');
    console.log('transactions-' + dformat);
    let result = [];
    this.store.dispatch(new LoadTransactionsByCategoryAttemptedAction({idClient: client.id, filterKey: 'DATE'}));
    this.store.select(getTransactionsToExport).pipe(filter(resp => resp !== undefined), take(1)).subscribe(transactions => {
      transactions.forEach(value => {
        result = [...result, ...value.transactions];
      });
      result = result.map(({id, historyType,  ...rest}) => rest);
      console.log('Exported transactions : ', result);
      this.excelService.exportAsExcelFile(result, 'transactions-' + dformat);
    });
  }

}
