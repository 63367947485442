import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { GetUserAttemptedAction } from '../../core/store/user-details/user-details.action';
import { LoadAccountAttemptedAction } from '../../core/store/account/account.action';
import { getAccount } from '../../core/store/account/account.reducer';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  account: any;

  loading: boolean;

  client: any;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));
    this.client = client;

    const loggedInAccount = JSON.parse(localStorage.getItem('user'));
    console.log(loggedInAccount.permissions);
    for (const permission of loggedInAccount.permissions) {
      if (permission.trim() === 'CLIENT_ACCOUNT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_ACCOUNT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadAccountAttemptedAction({idClient: client.id}));
    this.store.select(getAccount).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
      console.log('USER ACCOUNT: ', data);
      this.account = data;
    },
    (err) => console.log(err),
    () => {this.loading = false; });
  }

  onNavClientInformations(): void {
    this.router.navigate(['/clients/details/info']);
  }

  onNavCoffreFort(): void {
    this.router.navigate(['/clients/details/deposits']);
  }

  onNavChildCards(): void {
    this.router.navigate(['/clients/details/cards/childs']);
  }

  onNavVirtualCards(): void {
    this.router.navigate(['/clients/details/cards/virtual']);
  }

  onNavCagnottes(): void {
    this.router.navigate(['/clients/details/pots']);
  }

}
