<div class="content">
    <div class="full-height bg-white" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
      <div class="title-div">
        <div class="text title-text">
            {{'JACKPOT.JACKPOTS' | translate}}
        </div>
      </div>

      <hr />
      
      <div class="pots-content">
          <div *ngIf="this.loading" class="spinner">
            <mat-spinner [diameter]="45"></mat-spinner>
          </div>

          <div class="jackpots" *ngIf="!this.loading">
              <div class="none-block" *ngIf="potsLength == 0">
                  <img src="../../../assets/images/vide/vide.png"
                      srcset="../../../assets/images/vide/vide@2x.png 2x,
                              ../../../assets/images/vide/vide@3x.png 3x"
                      class="none"/>
                  <p class="text none-text">{{'JACKPOT.NONE' | translate}}</p>
              </div>

              <ngx-simplebar class="jackpot-content" *ngIf="potsLength > 0">
                  <div class="jackpot-tab">
                      <table mat-table [dataSource]="pots$" class="mat-elevation-z8 tab-jackpot">
                          <ng-container matColumnDef="category_jackpot">
                            <td mat-cell *matCellDef="let element">
                              <img src="../../../../assets/images/home/safe-deposit/category/{{element.safeDepositType}}@2x.png"
                                   srcset="../../../../assets/images/home/safe-deposit/category/{{element.safeDepositType}}@2x.png 2x,
                                 ../../../../assets/images/home/safe-deposit/category/{{element.safeDepositType}}@3x.png 3x"
                                   class="ic-category" alt="Category Icon">
                            </td>
                          </ng-container>
                
                          <ng-container matColumnDef="title_jackpot">
                            <td mat-cell *matCellDef="let element">
                              <div class="title-cell">
                                <span class="text weighted jackpot-title">{{ element.name }}</span>
                                <div>
                                  <span class="text jackpot-text">{{ 'JACKPOT.OBJECTIF' | translate }}</span> <span class="text jackpot-text weighted">{{ element.objectif }} {{ element.currency | currencySymbol }}</span>
                                </div>
                              </div>
                            </td>
                          </ng-container>
                
                          <ng-container matColumnDef="days_jackpot">
                            <td mat-cell *matCellDef="let element">
                              <span class="text jackpot-text">{{ 'JACKPOT.REMAINED_DAYS' | translate }}</span> <span class="text jackpot-text weighted">{{ element.remainingDays }}</span>
                            </td>
                          </ng-container>
                
                          <ng-container matColumnDef="participants_jackpot">
                            <td mat-cell *matCellDef="let element">
                              <span class="text jackpot-text">{{ 'JACKPOT.PARTICIPANTS' | translate }}</span> <span class="text jackpot-text weighted">{{ element.nbParticipant ? element.nbParticipant : 0 }}</span>
                            </td>
                          </ng-container>
                
                          <ng-container matColumnDef="collected_jackpot">
                            <td mat-cell *matCellDef="let element">
                              <span class="text jackpot-text">{{ 'JACKPOT.COLLECTED' | translate }}</span>
                              <span class="text jackpot-text weighted">{{ element.collected }} {{ element.currency | currencySymbol }}</span>
                            </td>
                          </ng-container>

                          <ng-container matColumnDef="finished_jackpot">
                            <td mat-cell *matCellDef="let element">
                              <span class="text jackpot-text weighted" *ngIf="element.finished" >{{ 'JACKPOT.FINISHED' | translate}}</span>
                            </td>
                          </ng-container>
                          
                          <tr mat-row *matRowDef="let row; columns: jackpotsDisplayedColumns;"  class="table-row"></tr>
                        </table>
                  </div>
              </ngx-simplebar>
          </div>
      </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
  <div class="full-height bg-white">
      <div class="pots-content">
        <div class="title-div">
          <div class="text title-text">
              {{'JACKPOT.JACKPOTS' | translate}}
          </div>
        </div>

        <div  class="none-block">
          <div class="none-inner-block">
              <img src="../../../assets/images/vide/vide@2x.png"
                  srcset="../../../assets/images/vide/vide@2x.png 2x,
                  ../../../assets/images/vide/vide@3x.png 3x"
                  class="none" alt="None">
              <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
          </div>     
        </div>
      </div>
  </div>
</ng-template>