import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { LoadPhysicalCardsAttemptedAction, GetWalletAttemptedAction } from '../../../core/store/cards/cards.actions';
import { getPhysicalCards, getWallet } from '../../../core/store/cards/cards.reducer';
import { take, filter } from 'rxjs/operators';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-physical',
  templateUrl: './physical.component.html',
  styleUrls: ['./physical.component.scss']
})
export class PhysicalComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  loading: boolean;

  walletIsLoading: boolean;

  wallet: any;

  physicalCards$: any;

  physicalLength: number;

  physicalCardsDisplayedColumns: string[] = [
    'card_physical',
    'embossedName_physical',
    'status_physical',
    'expiration_physical',
    'more_physical'
  ];

  statements$: any;

  statementsLength = 0;

  statementsDisplayedColumns: string[] = ['title', 'date'];

  card: any;

  isCardSelected: boolean;

  cardDetailsLoading = false;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.walletIsLoading = true;
    this.isCardSelected = false;
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_CARD_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_CARD_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadPhysicalCardsAttemptedAction({idClient: client.id}));
    this.store.select(getPhysicalCards).pipe(filter(data => data !== undefined), take(1)).subscribe(cards => {
      console.log(cards);
      console.log(cards.length);
      this.physicalCards$ = cards;
      this.physicalLength = cards.length;
    },
    (err) => {console.log(err); },
    () => {
      this.loading = false;
      console.log('loading is ', this.loading);
    });
  }

  onSeeMore(element: any): void {
    this.cardDetailsLoading = true;
    this.card = element;
    setTimeout(() => {
      this.isCardSelected = true;
      this.cardDetailsLoading = false;
    }, 150);
  }

  onRefresh(): void {
    this.walletIsLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    console.log('wallet payload is: ', {idClient: client.id, idWallet: this.physicalCards$[0].walletId});
    this.store.dispatch(new GetWalletAttemptedAction({idClient: client.id, idWallet: this.physicalCards$[0].walletId}));
    this.store.select(getWallet).pipe(filter(data => data !== undefined), take(1)).subscribe(obj => {
      console.log('WALLET: ', obj);
      this.wallet = obj;
      setTimeout(() => {
        this.walletIsLoading = false;
      }, 400);
    });
  }

  onOpenWallet(): void {
    this.walletIsLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetWalletAttemptedAction({idClient: client.id, idWallet: this.physicalCards$[0].walletId}));
    this.store.select(getWallet).pipe(filter(resp => resp !== undefined), take(1)).subscribe(obj => {
      console.log('SELECTED WALLET :', obj);
      this.wallet = obj;
      this.walletIsLoading = false;
    });
  }

  onSeeWallet(): void {
    this.router.navigate(['/clients/details/cards']);
  }

  onSeeOperations(): void {
    this.router.navigate(['/clients/details/cards']);
  }

}
