<div class="Package-container">
    <div class="d-flex direction-column align-center justify-center" *ngIf="showError">
        <div class="alert alert-danger alert-dismissible w-70" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="this.isResetFailed = false"><span aria-hidden="true">&times;</span></button>
            <strong>{{'ALERTS.ERROR' | translate}} !</strong> {{'ALERTS.TRY_AGAIN' | translate}}.
        </div>
    </div>

    <form [formGroup]="packageForm" id="new-package" aria-label="Créer un paquet">
        <div class="Subhead mb-5">
            <h1 class="Subhead-heading">{{'PACKAGES.PACKAGE_CREATION' | translate}}</h1>
            <!--<div class="Subhead-description">{{'PACKAGES.PACKAGE_CREATION_NOTE' | translate}}</div> -->
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="package-label">{{'PACKAGES.LABEL' | translate}}<span class="error"> * </span></label>
                </dt>
                <dd>
                    <input [ngClass]="{'border-error' : this.packageForm.controls['label'].hasError('required')}" class="form-control long" formControlName="label" autocomplete="off" type="text" id="package-label">
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="form-group float-left mt-1">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="package-description">{{'PACKAGES.DESCRIPTION' | translate}} <span class="error"> * </span></label>
                </dt>
                <dd>
                    <textarea [ngClass]="{'border-error' : this.packageForm.controls['description'].hasError('required')}" formControlName="description" class="form-control long" rows="3"></textarea>
                    <span class="note d-block"></span>
                </dd>
            </dl>
        </div>

        <div class="clearfix">
            <dl class="float-left mt-1" style="width: 80%;">
                <dt class="input-label">
                    <label aria-autocomplete="off" for="operation-type">{{'PACKAGES.OPERATION' | translate}}<span class="error"> * </span></label>
                </dt>
                <dd>
                    <!-- span class="note d-block">{{'PACKAGES.SELECT_OPERATION' | translate}}</span> -->
                    <ngx-simplebar class="operation-content" *ngIf="operationsLength > 0" >
                        <div class="operation-tab">
                            <table mat-table [dataSource]="operations" class="mat-elevation-z8 tab-operation">
                                <!-- Checkbox Column -->
                                <ng-container matColumnDef="checkbox" >
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element; let i = index">
                                        <span class="tab">
                                            <mat-checkbox formControlName="operation" type="checkbox" color="primary" [checked]="selectedOperationIndex === i" (change)="selectOperation(element, i)"> </mat-checkbox>
                                        </span>
                                    </td>
                                </ng-container>

                                <!-- Name Column -->
                                <ng-container matColumnDef="name" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.NAME' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span  class="text tab">{{element.name}}</span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="operation" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.OPERATION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.operation}}</span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="paidType" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.PAIDTYPE' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.paidType}}</span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="commission" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.COMMISSION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.commissionType.value}} <span>{{element.commissionType.type === 'PERCENT' ? '%' : 'EUR'}}</span></span>
                                    </td>
                                </ng-container>

                                <!-- Description Column -->
                                <ng-container matColumnDef="description" >
                                    <th mat-header-cell *matHeaderCellDef>{{'PACKAGES.DESCRIPTION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span class="text tab">{{element.description}}</span>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="operationsDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: operationsDisplayedColumns;"  class="table-row"></tr>
                            </table>
                        </div>
                    </ngx-simplebar>
                </dd>
            </dl>
        </div>
        <hr />
        <button type="button" class="btn btn-sm btn-success" [disabled]="!packageForm.valid" (click)="onSubmit()">{{'BUTTONS.ADD' | translate}}</button>
    </form>
</div>
