import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MccService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getMcc(payload: any): any {
    console.log('get mcc web service called');
    const params = new HttpParams().set('page', payload.pageIndex).set('size', payload.pageSize);
    return this.http.get(this.baseUrl + `/application/mcc`, {params});
  }

  add(payload: any): any {
    return this.http.post(this.baseUrl + `/application/mcc`, payload);
  }

  addMultiple(formData: any): any {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': undefined})
    };
    return this.http.post(this.baseUrl + `/application/mcc/addMultiple`, formData, httpOptions);
  }

  delete(idMcc: string): any {
    return this.http.delete(this.baseUrl + `/application/mcc/${idMcc}`);
  }

  update(idMcc: string, mcc: any): any {
    const payload = {
      id: idMcc,
      ...mcc
    };
    console.log('CALIING UPDATE MMC ', payload);
    return this.http.put(this.baseUrl + `/application/mcc/${idMcc}`, payload);
  }

}
