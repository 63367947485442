import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { LoadPackagesAttemptedAction, SelectPackageAction, DeletePackageAttemptedAction } from '../../../core/store/package/package.action';
import { getPackages, getPackageDeleteResponse } from '../../../core/store/package/package.reducer';
import { filter } from 'rxjs/operators';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.scss']
})
export class PackageComponent implements OnInit, OnDestroy {

  haveReadPermission = true;

  haveWritePermission = true;

  private subscription;

  loading: boolean;

  packages: any[];

  packagesLength: number;

  packagesDisplayedColumns: string[] = ['label', 'description', 'operations', 'actions'];

  operationsDisplayedColumns: string[] = ['operationName', 'operationCommissionType', 'operationDescription'];

  private deleteSubscription$;

  private dialogSubscription$;


  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_PACKAGE_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_PACKAGE_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadPackagesAttemptedAction());
    this.subscription = this.store.select(getPackages).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      this.packages = data;
      this.packagesLength = data.length;
      console.log(this.packagesLength);
      this.loading = false;
    });
    this.deleteSubscription$ = this.store.select(getPackageDeleteResponse).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      if (data.message !== 'DELETED') {
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            button: 'serverError'
          }
        });
      } else {
        this.store.dispatch(new LoadPackagesAttemptedAction());
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.dialogSubscription$) {
      this.dialogSubscription$.unsubscribe();
    }
    if (this.deleteSubscription$) {
      this.deleteSubscription$.unsubscribe();
    }
  }

  seeOperationDetails(operation: any): void{
    console.log(operation);
  }

  /*
  onAffectClients(packet: any): void {
    console.log(packet);
    this.store.dispatch(new SelectPackageAction(packet));
    this.router.navigate(['/application/packages/client-assignment']);
  }
  */

  onNewPackage(): void {
    this.router.navigate(['/application/packages/packages/add-package']);
  }

  onDeletePackage(element: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30%',
      data: {
        title: this.translate.instant('CONFIRMATION_CHOICE'),
        message: this.translate.instant('DELETE_PACKAGE_CONFIRMATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'DELETE_PACKAGE_CONFIRMATION',
        value: element
      }
    });
    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.store.dispatch(new DeletePackageAttemptedAction(element.id));
      }
    });
  }
}
