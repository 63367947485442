import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.baseUrl;

  constructor( private http: HttpClient) { }

  isAuthenticated(): boolean {
    if (localStorage.getItem('token')) { return true; }
    else { return false; }
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  login(user: any): any {
    return this.http.post(this.baseUrl + '/auth/login', user);
  }

  sendPasswordResetMail(payload: any): any {
    return this.http.post(this.baseUrl + `/auth/forgotpasswordinit`, payload);
  }

  resetPassword(key: string, payload: any): any {
    const params = new HttpParams().set('key', key);
    return this.http.put(this.baseUrl + `/auth/changepasswordfinal`, payload, {params});
  }
}
