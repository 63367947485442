import { Action } from '@ngrx/store';

export const LOAD_CLIENT_ACTIVITIES_ATTEMPTED = '[Logs] Load Client Activities Attempted';
export const LOAD_CLIENT_ACTIVITIES_SUCCESSFUL = '[Logs] Load Client Activities Successful';
export const LOAD_CLIENT_ACTIVITIES_FAILED = '[Logs] Load Client Activities Failed';

export class LoadClientActivitiesAttemptedAction implements Action {
    readonly type = LOAD_CLIENT_ACTIVITIES_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadClientActivitiesSuccessfulAction implements Action {
    readonly type = LOAD_CLIENT_ACTIVITIES_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadClientActivitiesFailedAction implements Action {
    readonly type = LOAD_CLIENT_ACTIVITIES_FAILED;
    constructor(public payload: any) {}
}

export type LogActions =
    |LoadClientActivitiesAttemptedAction
    |LoadClientActivitiesFailedAction
    |LoadClientActivitiesSuccessfulAction
    ;
