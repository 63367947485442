<div class="content">
    <div class="full-height bg-white">
        <div class="Details-wrapper">
            <h1 class="Details-header">{{'HOME.USER_INFOS.HEADER' | translate}}</h1>
            <hr />
            <div *ngIf="haveReadPermission; else NO_READ_PERMISSION" >
                <form [formGroup]="infoForm" class="infoForm">
                    <div class="flex-row">
                        <div class="w-50">
                            <dl class="form-group">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-id">{{'HOME.USER_INFOS.STATUS' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="status" autocomplete="off" type="text" id="user-status">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-lastname">{{'HOME.USER_INFOS.LASTNAME' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="lastname" autocomplete="off" type="text" id="user-lastname">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-firstname">{{'HOME.USER_INFOS.FIRSTNAME' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="firstname" autocomplete="off" type="text" id="user-firstname">
                                </dd>
                            </dl>

                            <dl class="form-group">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-cin">{{'HOME.USER_INFOS.CIN' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="cin" autocomplete="off" type="text" id="user-cin">
                                </dd>
                            </dl>

                            <dl class="form-group">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-passport">{{'HOME.USER_INFOS.NUMPASSPORT' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="numPassport" autocomplete="off" type="text" id="user-passport">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-gender">{{'HOME.USER_INFOS.GENDER' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="gender" autocomplete="off" type="text" id="user-gender">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-email">{{'HOME.USER_INFOS.EMAIL' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="email" autocomplete="off" type="text" id="user-email">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-citoy">{{'HOME.USER_INFOS.CITIZENSHIP' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="citoy" autocomplete="off" type="text" id="user-citoy">
                                </dd>
                            </dl>          
                        </div>
        
        
                        <div class="w-50">
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-birthday">{{'HOME.USER_INFOS.BIRTHDAY' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="birthday" autocomplete="off" type="text" id="user-birthday">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-phoneNumber">{{'HOME.USER_INFOS.PHONE' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="phoneNumber" autocomplete="off" type="text" id="user-phoneNumber">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-rue">{{'HOME.USER_INFOS.RUE' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="rue" autocomplete="off" type="text" id="user-rue">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-numRue">{{'HOME.USER_INFOS.NUMRUE' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="numRue" autocomplete="off" type="text" id="user-numRue">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-ville">{{'HOME.USER_INFOS.VILLE' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="ville" autocomplete="off" type="text" id="user-ville">
                                </dd>
                            </dl>
    
                            <dl class="form-group ">
                                <dt class="input-label">
                                    <label aria-autocomplete="off" for="user-codePostal">{{'HOME.USER_INFOS.CODEPOSTAL' | translate}}</label>
                                </dt>
                                <dd>
                                    <input class="form-control long" formControlName="codePostal" autocomplete="off" type="text" id="user-codePostal">
                                </dd>
                            </dl>      
                        </div>
                    </div>
                    
                    <!--
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <button mat-raised-button class="form-button">{{'HOME.USER_INFOS.BUTTONS.SAVE' | translate}}</button>
                                <button mat-raised-button class="form-button">{{'HOME.USER_INFOS.BUTTONS.CANCEL' | translate}}</button>
                            </div>
                    -->
                </form>
            </div>
        </div>
    </div>
    
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../../assets/images/vide/vide@2x.png"
            srcset="../../../../assets/images/vide/vide@2x.png 2x,
            ../../../../assets/images/vide/vide@3x.png 3x"
            class="none" alt="None">
        <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS'}} </p> 
        </div>     
    </div>
</ng-template>