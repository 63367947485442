import {createFeatureSelector, createSelector} from '@ngrx/store';
import { from } from 'rxjs';
import * as fromIssues from './issues.actions';


export interface IssuesState {
    tickets: any;
    selectedTicket: any;
    loading: boolean;
    error: string;
}

export const initialState: IssuesState = {
    tickets:  undefined,
    selectedTicket: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: IssuesState = initialState, action: fromIssues.IssuesActions): any {
    switch (action.type) {
        case fromIssues.LOAD_ALL_TICKETS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                tickets: undefined
            };
        case fromIssues.LOAD_ALL_TICKETS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                tickets: action.payload.value
            };
        case fromIssues.LOAD_ALL_TICKETS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case fromIssues.SELECT_TICKET:
            return {
                ...state,
                selectedTicket: action.payload
            };
        default:
            return state;
    }
}

export const getIssuesState = createFeatureSelector<IssuesState>('issues');

export const getAllTickets = createSelector(getIssuesState, (state: IssuesState) => state.tickets);
export const getSelectedTicket = createSelector(getIssuesState, (state: IssuesState) => state.selectedTicket);
