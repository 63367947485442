import { GetUserSuccessful } from './../auth/auth.action';
import { Action } from '@ngrx/store';


export enum UserDetailsActionTypes {
    GET_USER_DETAILS_ATTEMPTED = '[User Details] Get User Details and redirect to User-Infos componenet - Attempted',
    GET_USER_DETAILS_SUCCESS = '[User Details] Get User Details and redirect to User-Infos componenet -  Success',
    GET_USER_DETAILS_FAILURE = '[User Details] Get User Details and redirect to User-Infos componenet -  Failure',

    GET_USER_ATTEMPTED = '[User Details] Get User attempted',
    GET_USER_SUCCESS = '[User Details] Get User Success',
    GET_USER_FAILED = '[User Details] Get User failed',
    ADD_ID_USER = '[User Details] Add IS User',
}

export class GetUserDetailsAttemptedAction implements Action {
    readonly type = UserDetailsActionTypes.GET_USER_DETAILS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class GetUserDetailsSuccessAction implements Action {
    readonly type = UserDetailsActionTypes.GET_USER_DETAILS_SUCCESS;
    constructor(public payload: any) {}
}

export class GetUserDetailsFailureAction implements Action {
    readonly type = UserDetailsActionTypes.GET_USER_DETAILS_FAILURE;
    constructor(public payload: any) {}
}

export class GetUserAttemptedAction implements Action {
    readonly type = UserDetailsActionTypes.GET_USER_ATTEMPTED;
    constructor(public payload: any) {}
}

export class GetUserSuccessfulAction implements Action {
    readonly type = UserDetailsActionTypes.GET_USER_SUCCESS;
    constructor(public payload: any) {}
}

export class GetUserFailedAction implements Action {
    readonly type = UserDetailsActionTypes.GET_USER_FAILED;
    constructor(public payload: any) {}
}

export class AddIdUserAction implements Action {
    readonly type = UserDetailsActionTypes.ADD_ID_USER;
    constructor(public payload: any) {}
}



export type UserDetailsAction =
    |GetUserDetailsAttemptedAction
    |GetUserDetailsSuccessAction
    |GetUserDetailsFailureAction
    |AddIdUserAction
    |GetUserAttemptedAction
    |GetUserSuccessfulAction
    |GetUserFailedAction
    ;
