import {ActionReducerMap, combineReducers} from '@ngrx/store';
import { AppActionTypes } from './app/app.action';
import * as fromReducer from '@ngrx/router-store';
import * as fromUsers from './users/users.reducer';
import * as fromUserDetails from './user-details/user-details.reducer';
import * as fromRectification from './rectification/rectification.reducer';
import * as fromDeposit from './deposit/deposit.reducer';
import * as fromDocument from './document/document.reducer';
import * as fromPots from './pot/pot.reducer';
import * as fromCards from './cards/cards.reducer';
import * as fromTransactions from './transactions/transaction.reducer';
import * as fromAccount from './account/account.reducer';
import * as fromLog from './log/log.reducer';
import * as fromContact from './contact/contact.reducer';
import * as fromNews from './news/news.reducer';
import * as fromOffers from './offers/offers.reducer';
import * as frombeneficiaries from './beneficiaries/beneficiaries.reducer';
import * as fromOppositions from './opposition/oppositions.reducer';
import * as fromMcc from './mcc/mcc.reducer';
import * as fromPackage from './package/package.reducer';
import * as fromOperation from './operation/operation.reducer';
import * as fromCommission from './commission/commission.reducer';
import * as fromAppConfig from './app-config/app-config.reducer';
import * as fromUserManagement from './user-managements/user-management-reducer';
import * as fromIssues from './issues/issues.reducer';

export interface AppState {
  router: fromReducer.RouterReducerState;
  users: fromUsers.UsersState;
  userDetails: fromUserDetails.UserDetailsState;
  document: fromDocument.DocumentState;
  rectification: fromRectification.RectificationState;
  deposit: fromDeposit.DepositState;
  pots: fromPots.PotsState;
  cards: fromCards.CardsState;
  transactions: fromTransactions.TransactionState;
  account: fromAccount.AccountState;
  logs: fromLog.LogState;
  contact: fromContact.ContactState;
  news: fromNews.NewsState;
  offers: fromOffers.OffersState;
  beneficiaries: frombeneficiaries.BeneficiariesState;
  oppositions: fromOppositions.OppositionState;
  mcc: fromMcc.MccState;
  packages: fromPackage.PackageState;
  operations: fromOperation.OperationState;
  commissions: fromCommission.CommissionState;
  appConfig: fromAppConfig.AppConfigState;
  backofficeUsers: fromUserManagement.UserManagementState;
  issues: fromIssues.IssuesState;
}

export const reducers: ActionReducerMap<AppState> = {
  router: fromReducer.routerReducer,
  users: fromUsers.reducer,
  userDetails: fromUserDetails.reducer,
  document: fromDocument.reducer,
  rectification: fromRectification.reducer,
  deposit: fromDeposit.reducer,
  pots: fromPots.reducer,
  cards: fromCards.reducer,
  transactions: fromTransactions.reducer,
  account: fromAccount.reducer,
  logs: fromLog.reducer,
  contact: fromContact.reducer,
  news: fromNews.reducer,
  offers: fromOffers.reducer,
  beneficiaries: frombeneficiaries.reducer,
  oppositions: fromOppositions.reducer,
  mcc: fromMcc.reducer,
  packages: fromPackage.reducer,
  operations: fromOperation.reducer,
  commissions: fromCommission.reducer,
  appConfig: fromAppConfig.reducer,
  backofficeUsers: fromUserManagement.reducer,
  issues: fromIssues.reducer
};

const rootReducer = combineReducers(reducers);

export function reducer(state: AppState, action: any): AppState {
  return rootReducer(state, action);
}

export function clearState(reducer): any {
  return function(state: AppState, action: any): any {
    if (action.type === AppActionTypes.CLEAR_STATE) {
      state = undefined;
    }
    return reducer(state, action);
  };
}
