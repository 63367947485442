<div class="container-fluid content">
    <div class="Top-bar">
      <mat-form-field fxFlex="30%" fxFlexOffset="6%" appearance="legacy" class="search-form-field">
          <mat-label>{{'HOME.SEARCH' | translate}}</mat-label>
          <input matInput type="text" [(ngModel)]="key" (input)="onClientNameKeyup($event)" autocomplete="off">
          <button mat-button *ngIf="key" matSuffix mat-icon-button attr.aria-label="{{'HOME.CLEAR' | translate}}" (click)="key=''; onClientNameKeyup($event)">
            <mat-icon>close</mat-icon>
          </button>
      </mat-form-field>

      <span  class="mx-1">{{'HOME.FILTER' | translate}} : </span>

      <img src="../../../assets/images/ic-filter/ic-filtre.png" [matMenuTriggerFor]="appMenu" class="ic-filter"
          srcset="../../../assets/images/ic-filter/ic-filtre@2x.png 2x,
          ../../../assets/images/ic-filter/ic-filtre@3x.png 3x" />

      <div class="Chips-wrapper">
        <mat-chip-list #chipList aria-label="Status checked selections">
          <mat-chip *ngFor="let statuChecked of statusChecked; let lastItem = last" [selectable]="statusSelectable"
            [removable]="statusRemovable" (removed)="removeStatus(statuChecked)">
            {{statuChecked}}
            <mat-icon matChipRemove *ngIf="statusRemovable">cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>

    <div class="bg-white min-height border" style="height: 70%;">
        <h2 class="Table-header">{{'DASHBOARD.TITLE' | translate}}</h2>
        <div>
            <p class="clients-table">
              <ngx-datatable class="bootstrap" style="display: flex; align-items: center; justify-content: center;"
                [rowClass]="getRowClass" 
                [rows]="users" 
                [columnMode]="ColumnMode.force" 
                [messages]="messages"
                [headerHeight]="50" 
                [footerHeight]="50" 
                rowHeight="auto" 
                [selected]="selected" 
                [selectionType]="SelectionType.single"
                [loadingIndicator]="this.loading"
                (select)="userDetails($event)"
                [externalPaging]="true"
                [count]="page.totalElements"
                [offset]="page.pageNumber"
                [limit]="page.size"
                (page)="setPage($event, true)">
                  <ngx-datatable-column name="{{'HOME.USER_INFOS.FIRSTNAME' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.firstname}}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column name="{{'HOME.USER_INFOS.LASTNAME' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.lastname}}
                    </ng-template>
                  </ngx-datatable-column>
          
                  <ngx-datatable-column name="{{'HOME.USER_INFOS.EMAIL' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.email}}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column name="{{'HOME.USER_INFOS.PHONE' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.numTel}}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column name="{{'HOME.USER_INFOS.ACCOUNT_CREATION_DATE' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      {{row.creationDate | dateTime}}
                    </ng-template>
                  </ngx-datatable-column>

                  <ngx-datatable-column name="{{'HOME.USER_INFOS.STATUS' | translate}}">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                      <app-label [value]="row.userStatusName"></app-label>
                    </ng-template>
                  </ngx-datatable-column>
              </ngx-datatable>
            </p>
          </div>
      </div>
</div>


<mat-menu #appMenu="matMenu" xPosition="before">
    <div fxFlex fxLayout="column" fxLayoutAlign="center start" class="Filter-mat-menu" (click)="$event.stopPropagation()">
        <div class="w-100">
          <form [formGroup]="dateForm">
            <label for="start-date" class="bold mr-1">{{'DASHBOARD.FROM' | translate}}:</label>
            <mat-form-field class="date-form-field" appearance="outline">
              <mat-label>{{'DASHBOARD.START_DATE' | translate}}</mat-label>
              <input matInput [matDatepicker]="startPicker" formControlName="startDate">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>
            
            <label for="date-end" class="bold mr-1">{{'DASHBOARD.TO' | translate}}:</label>
            <mat-form-field class="date-form-field" appearance="outline">
              <mat-label>{{'DASHBOARD.END_DATE' | translate}}</mat-label>
              <input matInput [matDatepicker]="endPicker" formControlName="endDate">
              <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
              <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
          </form>
        </div>

        <div class="mt-4">
          <label class="bold">{{'DASHBOARD.STATUS' | translate}}:</label>
          <div class="scoll-menu-cadre-style">
            <div class="scroll-bar" >
              <div *ngFor="let statu of status" style="height: 30px">
                <mat-checkbox style="margin-left: 10px" color="primary" [checked]="statu.isSelected" (change)="addStatus($event,statu)">
                  {{statu.value}}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="button-div">
          <button class="btn btn-sm btn-success" (click) = "search()" > {{'BUTTONS.FILTER' | translate}} </button>
          <button class="btn btn-sm btn-bordered" (click) = "annuler()"> {{'BUTTONS.CANCEL' | translate}} </button>
        </div>
    </div>
  </mat-menu>

