import { HttpClient } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getPhysicalCards(payload: {idClient: string}): any {
    console.log('physical cards web service called with payload ', payload);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/cards/physicals`);
  }

  getVirtualCards(payload: {idClient: string}): any {
    console.log('virtual cards web service called with payload ', payload);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/cards/virtuals`);
  }

  getChilds(payload: {idClient: string}): any {
    console.log('childs web service was called with payload ', payload);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/childs`);
  }

  getChildCards(payload: {idClient: string, idChild: string}): any {
    console.log('child cards web service was called with payload ', payload);
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/cards/childs/${payload.idChild}`);
  }

  getWallet(payload: {idClient: string, idWallet: string}): any {
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/wallet/${payload.idWallet}`);
  }
  
}
