import { Observable } from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import { MatEndDate } from '@angular/material/datepicker';

export interface User {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  numTel: string;
}

@Injectable()
export class UsersService {

  private baseUrl = environment.baseUrl;
  private param = new HttpParams();

  constructor(private http: HttpClient ) {}


  getUsers(payload: {pageNumber: number, size: number, cle: string, status: [], startDate: Date, endDate: Date}): any {
    const body = {
      page: payload.pageNumber,
      size: payload.size,
      dateDebut: payload.startDate,
      dateFin: payload.endDate,
      cle: payload.cle,
      status: payload.status
    };
    console.log('web service called with payload: ', body);
    return this.http.post(this.baseUrl + '/clients', body);
  }
  getUserDetails(payload: {idClient: string}): any {
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}`);
  }

  getUserDocuments(payload: {idClient: string}): any {
    console.log('WEB SERVICE CALLED');
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/documents`);
  }

  getDocumentImageByTag(payload: {idClient: string, tag: string}): Observable<any> {
    console.log('DOWNLOAD SERVICE IS CALLED');
    return this.http.get(this.baseUrl + `/clients/${payload.idClient}/documents/${payload.tag}`, {responseType: 'blob'});
  }

  getChildDocuments(idClient: string, idChild: string): any {
    console.log('CALLING WS GET CHILD DOCS WITH PAYLOAD: ', idClient, idChild);
    return this.http.get(this.baseUrl + `/clients/${idClient}/documents/child/${idChild}`);
  }

  rectifyChildDocuments(idClient: string, idChild: string): any {
    return this.http.post(this.baseUrl + `/clients/${idClient}/documents/child/${idChild}/postRectificationChildDocument`, {});
  }

  validateChildDocuments(idClient: string, idChild: string): any {
    return this.http.post(this.baseUrl + `/clients/${idClient}/documents/child/${idChild}/validateChildDocument`, {});
  }

  getChildDocumentsPhoto(idClient: string, idChild: string, tag: string): Observable<any> {
    return this.http.get(this.baseUrl + `/clients/${idClient}/documents/child/${idChild}/${tag}`, {responseType: 'blob'});
  }

  /* ADDRESS MODIFICATION SERVICES */
  getAddressDocuments(idClient: string): any {
    return this.http.get(this.baseUrl + `/clients/${idClient}/documents/updatedadressdocuments`);
  }

  invalidateAddressModificationDocuments(idClient: string): any {
    return this.http.put(this.baseUrl + `/clients/${idClient}/documents/updatedadressdocuments/invalidate`, {});
  }

  validateAddressModificationDocuments(idClient: string): any {
    return this.http.put(this.baseUrl + `/clients/${idClient}/documents/updatedadressdocuments/validate`, {});
  }

  getAddressModificationDocumentsByAttempt(idClient: string, attempt: number, tag: string): any {
    return this.http.get(this.baseUrl + `/clients/${idClient}/documents/updatedadressdocuments/${attempt}/${tag}`);
  }

  getAddressModificationPhoto(idClient: string, attempt: number, tag: string): any {
    console.log('calling GET photo with ', idClient, attempt, tag);
    return this.http.get(this.baseUrl + `/clients/${idClient}/documents/updatedadressdocuments/${attempt}/${tag}`, {responseType: 'blob'});
  }

}
