import { Action } from '@ngrx/store';

export const LOAD_OPPOSITIONS_ATTEMPTED = '[Oppositions] Load Opposition Attempted';
export const LOAD_OPPOSITIONS_SUCCESSFUL = '[Oppositions] Load oppositions successful';
export const LOAD_OPPOSITIONS_FAILED = '[Oppositions] Load oppositions failed';

export const SET_STATEMENT = '[Oppositions] Sets the selected statement in store';


export class LoadOppositionsAttemptedAction implements Action {
    readonly type = LOAD_OPPOSITIONS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadOppositionsSuccessfulAction implements Action {
    readonly type = LOAD_OPPOSITIONS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadOppositionsFailedAction implements Action {
    readonly type = LOAD_OPPOSITIONS_FAILED;
    constructor(public payload: any) {}
}

export class SetStatementAction implements Action {
    readonly type = SET_STATEMENT;
    constructor(public payload: any) {}
}

export type OppositionTypes =
    |LoadOppositionsAttemptedAction
    |LoadOppositionsFailedAction
    |LoadOppositionsSuccessfulAction
    |SetStatementAction
;
