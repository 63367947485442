import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAccount from './account.action';

export interface AccountState {
    account: any;
    loaded: boolean;
    loading: boolean;
    error: string;
}

export const initialState: AccountState = {
    account: undefined,
    loaded: false,
    loading: false,
    error: undefined,
};

export function reducer(state = initialState, action: fromAccount.AccountActions): AccountState {
    switch(action.type) {
        case fromAccount.LOAD_ACCOUNT_ATTEMPTED:
            return {
                ...state,
                loading: true,
                account: undefined
            };
        case fromAccount.LOAD_ACCOUNT_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                loaded: true,
                account: action.payload.value
            };
        case fromAccount.LOAD_ACCOUNT_FAILED:
            return {
                ...state,
                loaded: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const getAccountState = createFeatureSelector<AccountState>('account');

export const getAccount = createSelector(getAccountState, (state: AccountState) => state.account);