<div class="sub-navbar bg-white sticky-top">
    <div class="nav-list">
        <div class="nav-arrow-item">
            <div class="item">
                <mat-icon class="ic-back-arrow ic-nav" (click)="goBack()">arrow_back</mat-icon>
            </div>
        </div>

        <div class="nav-item" [routerLink]="['/clients/details/info']" [routerLinkActive]="['border-bottom']">
            <div class="item">
                <img src="../../../../assets/images/ic-account/ic-account.png"
                     srcset="../../../../assets/images/ic-account/ic-account@2x.png 2x, 
                     ../../../../assets/images/ic-account/ic-account@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'INFORMATIONS' | translate}}</h3>
            </div>
        </div>

        <div class="nav-item" [routerLink]="['/clients/details/documents']" [routerLinkActive]="['border-bottom']">
            <div class="item">
                
                <img src="../../../assets/images/ic-description/ic-description.png"
                    srcset="../../../assets/images/ic-description/ic-description@2x.png 2x" class="ic-nav"/>
                <h3 class="text nav-text">{{'DOCUMENTS' | translate}}</h3>
            </div>
        </div>


        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledComptes" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-compte/ic-compte.png"
                    srcset="../../../assets/images/ic-compte/ic-compte@2x.png 2x, 
                    ../../../assets/images/ic-compte/ic-compte@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'ACCOUNT.TITLE' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledComptes>
            <div class="nav-item" [routerLink]="['/clients/details/account']"  [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-compte/ic-compte.png"
                    srcset="../../../assets/images/ic-compte/ic-compte@2x.png 2x, 
                    ../../../assets/images/ic-compte/ic-compte@3x.png 3x" class="ic-nav"/>
                    <h3 class="text nav-text">{{'ACCOUNT.TITLE' | translate}}</h3>
                </div>
            </div>
        </ng-template>


        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledTransactions" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-transaction/ic-transaction.png"
                    srcset="../../../assets/images/ic-transaction/ic-transaction@2x.png 2x, 
                    ../../../assets/images/ic-transaction/ic-transaction@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'TRANSACTIONS.TITLE' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledTransactions>
            <div class="nav-item" [routerLink]="['/clients/details/transactions']"  [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-transaction/ic-transaction.png"
                    srcset="../../../assets/images/ic-transaction/ic-transaction@2x.png 2x, 
                    ../../../assets/images/ic-transaction/ic-transaction@3x.png 3x" class="ic-nav" />
                <h3 class="text nav-text">{{'TRANSACTIONS.TITLE' | translate}}</h3>
                </div>
            </div>
        </ng-template>



        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledCartes" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-carte/ic-carte.png"
                    srcset="../../../assets/images/ic-carte/ic-carte@2x.png 2x, 
                    ../../../assets/images/ic-carte/ic-carte@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'CARDS.TITLE' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledCartes>
            <div class="nav-item" [routerLink]="['/clients/details/cards']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-carte/ic-carte.png"
                    srcset="../../../assets/images/ic-carte/ic-carte@2x.png 2x, 
                    ../../../assets/images/ic-carte/ic-carte@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'CARDS.TITLE' | translate}}</h3>
                </div>
            </div>
        </ng-template>


        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledCoffresForts" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-cagnotte/ic-cagnotte.png"
                    srcset="../../../assets/images/ic-cagnotte/ic-cagnotte@2x.png 2x, 
                    ../../../assets/images/ic-cagnotte/ic-cagnotte@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'SAFE_DEPOSITS' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledCoffresForts>
            <div class="nav-item" [routerLink]="['/clients/details/deposits']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-cagnotte/ic-cagnotte.png"
                        srcset="../../../assets/images/ic-cagnotte/ic-cagnotte@2x.png 2x, 
                        ../../../assets/images/ic-cagnotte/ic-cagnotte@3x.png 3x" class="ic-nav"/>
                    <h3 class="text nav-text">{{'SAFE_DEPOSITS' | translate}}</h3>
                </div>
            </div>
        </ng-template>


        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledCagnottes" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-cagnotte/ic-cagnotte.png"
                    srcset="../../../assets/images/ic-cagnotte/ic-cagnotte@2x.png 2x, 
                    ../../../assets/images/ic-cagnotte/ic-cagnotte@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'POTS' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledCagnottes>
            <div class="nav-item" [routerLink]="['/clients/details/pots']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-cagnotte/ic-cagnotte.png"
                        srcset="../../../assets/images/ic-cagnotte/ic-cagnotte@2x.png 2x, 
                        ../../../assets/images/ic-cagnotte/ic-cagnotte@3x.png 3x"  class="ic-nav"/>
                    <h3 class="text nav-text">{{'POTS' | translate}}</h3>
                </div>
            </div>
        </ng-template>

        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledOppositions" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-opposition/ic-opposition.png"
                    srcset="../../../assets/images/ic-opposition/ic-opposition@2x.png 2x, 
                    ../../../assets/images/ic-opposition/ic-opposition@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'OPPOSITIONS.HEADER' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledOppositions>
            <div class="nav-item" [routerLink]="['/clients/details/oppositions']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-opposition/ic-opposition.png"
                        srcset="../../../assets/images/ic-opposition/ic-opposition@2x.png 2x, 
                        ../../../assets/images/ic-opposition/ic-opposition@3x.png 3x"  class="ic-nav"/>
                    <h3 class="text nav-text">{{'OPPOSITIONS.HEADER' | translate}}</h3>
                </div>
            </div>
        </ng-template>

        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledBeneficiaire" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-parrainage/ic-parrainage.png"
                    srcset="../../../assets/images/ic-parrainage/ic-parrainage.png@2x.png 2x, 
                    ../../../assets/images/ic-parrainage/ic-parrainage.png@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'BENEFICIARIES.HEADER' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledBeneficiaire>
            <div class="nav-item" [routerLink]="['/clients/details/beneficiaries']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-parrainage/ic-parrainage.png"
                        srcset="../../../assets/images/ic-parrainage/ic-parrainage.png@2x.png 2x, 
                        ../../../assets/images/ic-parrainage/ic-parrainage.png@3x.png 3x"  class="ic-nav"/>
                    <h3 class="text nav-text">{{'BENEFICIARIES.HEADER' | translate}}</h3>
                </div>
            </div>
        </ng-template>

        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledPackages" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/application/ic-package/ic-package.png" class="ic-nav"/>
                <h3 class="text nav-text">{{'PACKAGES.HEADER' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledPackages>
            <div class="nav-item" [routerLink]="['/clients/details/packages']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/application/ic-package/ic-package.png" class="ic-nav" />
                    <h3 class="text nav-text">{{'PACKAGES.HEADER' | translate}}</h3>
                </div>
            </div>
        </ng-template>

        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledLog" class="nav-item disable">
            <div class="item">
                <img src="../../../assets/images/ic-log/ic-log.png"
                    srcset="../../../assets/images/ic-log/ic-log@2x.png 2x, 
                    ../../../assets/images/ic-log/ic-log@3x.png 3x" class="ic-nav"/>
                <h3 class="text nav-text">{{'ACTIVITIES.HEADER' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledLog>
            <div class="nav-item" [routerLink]="['/clients/details/logs']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <img src="../../../assets/images/ic-log/ic-log.png"
                        srcset="../../../assets/images/ic-log/ic-log@2x.png 2x, 
                        ../../../assets/images/ic-log/ic-log@3x.png 3x"  class="ic-nav"/>
                    <h3 class="text nav-text">{{'ACTIVITIES.HEADER' | translate}}</h3>
                </div>
            </div>
        </ng-template>

        

        <div *ngIf="this.userStatusName !== 'ENABLED'; else enabledContact" class="nav-item disable">
            <div class="item">
                <mat-icon class="ic-nav">info_outline</mat-icon>
                <h3 class="text nav-text">{{'CONTACT.HEADER' | translate}}</h3>
            </div>
        </div>
        <ng-template #enabledContact>
            <div class="nav-item" [routerLink]="['/clients/details/contact']" [routerLinkActive]="['border-bottom']">
                <div class="item">
                    <mat-icon class="ic-nav">info_outline</mat-icon>
                    <h3 class="text nav-text">{{'CONTACT.HEADER' | translate}}</h3>
                </div>
            </div>
        </ng-template>
        
    </div>
</div>

<div class="container-fluid">
    <div class="panel">
        <div class="panel-heading">
            <div class="panel-title">{{'HOME.REGISTRATION_DOCUMENTS.CLIENT' | translate}}: <span *ngIf="client" class="font name link" (click)="onNavClientInformations()">{{client.firstname}} {{client.lastname}}</span></div>
            <div class="document-info-divider"></div>
            <div class="panel-title">{{'HOME.REGISTRATION_DOCUMENTS.FOLDER_STATUS' | translate}}: 
                <span class="bold">{{userStatusName}}</span> 
                <!-- <app-label [value]="userStatusName"></app-label> -->
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>