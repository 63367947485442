import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppState } from 'src/app/core/store';
import { LoadNewsAttemptedAction, SelectNewsAction, DeleteNewsAttemptedAction } from '../../core/store/news/news.actions';
import { getNews, getDeleteNewsResponse } from '../../core/store/news/news.reducer';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  private newsSubscription;

  news: any;

  newsLength: number;

  show = 6;

  isShowMore = true;

  showText = 'Afficher plus';

  private dialogSubscription;

  private deleteSubscription;

  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.store.dispatch(new LoadNewsAttemptedAction());

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_NEWS_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_NEWS_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      return;
    }

    this.newsSubscription = this.store.select(getNews).pipe(filter(data => data !== undefined)).subscribe(data => {
      console.log(data);
      this.news = data;
      this.newsLength = data.length;
      this.news.sort((a: any, b: any) => this.sortNews(a, b));
    });
    this.deleteSubscription = this.store.select(getDeleteNewsResponse).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      if (data.message !== 'DELETED') {
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            buttons: 'serverError'
          }
        });
      } else {
        this.store.dispatch(new LoadNewsAttemptedAction());
      }
    });
  }

  private sortNews(a: any, b: any): any {
    if (a.rang < b.rang) {return -1; }
    else if (a.rang > b.rang) { return 1; }
    else {return 0; }
  }

  ngOnDestroy(): void {
    if (this.newsSubscription) {
      this.newsSubscription.unsubscribe();
    }
  }

  onAddNews(): void {
    this.router.navigate(['/application/news/add']);
  }

  deleteNews(news: any): void {
    console.log(news);
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30%',
      data: {
        title: this.translate.instant('CONFIRMATION_CHOICE'),
        message: this.translate.instant('DELETE_NEWS_CONFIRMATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'DELETE_NEWS_CONFIRMATION',
        value: news
      }
    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.store.dispatch(new DeleteNewsAttemptedAction(news.id));
      }
    });
  }

  modifyNews(news: any): void {
    this.store.dispatch(new SelectNewsAction(news, 'update'));
    this.router.navigate(['/application/news/update']);
  }

  showMore(): void {
    if (this.isShowMore) {
      this.show = this.news.length;
      this.showText = 'Afficher moins';
    } else {
      this.show = 6;
      this.showText = 'Afficher plus';
    }
    this.isShowMore = !this.isShowMore;
  }

  onNewsDetails(news: any): void {
    this.store.dispatch(new SelectNewsAction(news, 'show'));
    this.router.navigate(['/application/news/update']);
  }

  onSeePermissions(): void {
    this.router.navigate(['/settings/profile']);
  }

}
