import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssuesComponent } from './issues.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { SimplebarAngularModule } from 'simplebar-angular';
import {MatTableModule} from '@angular/material/table';
import { EffectsModule } from '@ngrx/effects';
import { IssuesEffect } from '../core/store/issues/issues.effects';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import { TicketDetailsDialogComponent } from './ticket-details-dialog/ticket-details-dialog.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [IssuesComponent, TicketDetailsDialogComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SimplebarAngularModule,
    MatTableModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    TranslateModule,
    EffectsModule.forFeature([
      IssuesEffect
    ])
  ]
})
export class IssuesModule { }
