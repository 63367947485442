import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getNews(): any {
    console.log('news web service was called');
    return this.http.get(this.baseUrl + `/application/news`);
  }

  add(payload: any): any {
    console.log('add news service called with payload: ', payload);
    return this.http.post(this.baseUrl + `/application/news`, payload);
  }

  update(idNews: string, payload: any): any {
    console.log('******', idNews, payload);
    return this.http.put(this.baseUrl + `/application/news/${idNews}`, payload);
  }

  delete(idnews: string): any {
    return this.http.delete(this.baseUrl + `/application/news/${idnews}`);
  }

}
