

<div class="content" fxFlex>
    <div fxFlex="73.5%" class="bg-white full-height">
        <div class="header" *ngIf="deposit" >
            <img src="../../../../assets/images/back-btn/back-btn@2x.png"
               srcset="../../../../assets/images/back-btn/back-btn@2x.png 2x,
                 ../../../../assets/images/back-btn/back-btn@3x.png 3x"
               class="back" alt="Back Button" (click)="onBack()">
            <h3 class="text header-title">{{'CARDS.WALLET_INFO' | translate}} "{{deposit.title}}"</h3>
        </div>

        <div class="details" *ngIf="wallet" >
            <div class="details-title-column">
                <span class="text details-title">{{'CARDS.CURRENT_BALANCE' | translate}} : </span>
                <span class="text details-title">{{'CARDS.AUTHORIZATIONS' | translate}} : </span>
                <span class="text details-title">{{'CARDS.AUTHORIZED_BALANCE' | translate}} : </span>
                <span class="text details-title">{{'CARDS.CALCULATION_DATE' | translate}} : </span>
            </div>

            <div class="details-text-column">
                <span class="text details-text">{{wallet.currentBalance + ' ' + (wallet.currency | currencySymbol)}}</span>
                <span class="text details-text">{{wallet.authorizations + ' ' + (wallet.currency | currencySymbol)}}</span>
                <span class="text details-text">{{wallet.authorizedBalance  + ' ' + (wallet.currency | currencySymbol)}}</span>
                <span class="text details-text">{{wallet.calculationDate | dateTime}}</span>
            </div>
        </div> 
    </div>
</div>