import { Action } from '@ngrx/store';

export const LOAD_ALL_TICKETS_ATTEMPTED = '[Issues] Load all client tickets - attempted';
export const LOAD_ALL_TICKETS_SUCCESSFUL = '[Issues] Load all client tickets - successful';
export const LOAD_ALL_TICKETS_FAILED = '[Issues] Load all client tickets - failed';

export const SELECT_TICKET = '[Issues] sets the selected ticket in store - attempted';


export class LoadAllClientTicketsAttempted implements Action {
    readonly type = LOAD_ALL_TICKETS_ATTEMPTED;
    constructor(public payload: any) {}
}

export class LoadAllClientTicketsSuccessful implements Action {
    readonly type = LOAD_ALL_TICKETS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadAllClientTicketsFailed implements Action {
    readonly type = LOAD_ALL_TICKETS_FAILED;
    constructor(public payload: any) {}
}

export class SelectTicketAction implements Action {
    readonly type = SELECT_TICKET;
    constructor(public payload: any) {}
}

export type IssuesActions =
    |LoadAllClientTicketsAttempted
    |LoadAllClientTicketsFailed
    |LoadAllClientTicketsSuccessful
    |SelectTicketAction
    ;
