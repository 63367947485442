<div class="content">
    <div class="full-height bg-white">
        <div class="oppositions-container card">
            <div class="bold card-header">
                {{'OPPOSITIONS.HEADER' | translate}}
            </div>

            <div class="oppositions-content" *ngIf="haveReadPermission; else NO_READ_PERMISSION">
                <div class="spinner" *ngIf="loading">
                    <mat-spinner [diameter]="40"></mat-spinner>
                </div>

                <div class="oppositions" *ngIf="!loading">
                    <div class="none-block" *ngIf="oppositionslength <= 0" >
                        <img src="../../../assets/images/vide/vide.png"
                            srcset="../../../assets/images/vide/vide@2x.png 2x, 
                            ../../../assets/images/vide/vide@3x.png 3x"
                            class="none" alt="none">
                        <p class="text none-text">{{'OPPOSITIONS.NONE' | translate}}</p>
                    </div>

                    <ngx-simplebar class="opposition-content" *ngIf="oppositionslength > 0">
                        <div class="opposition-tab">
                            <table mat-table [dataSource]="oppositions" class="mat-elevation-z8 tab-oppositions">
                                <!-- Category Column -->
                                <ng-container matColumnDef="category" >
                                    <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.OPPOSITION' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span  class="text tab">{{'CARDS.OPPOSITION_' + element.objectionCategory | translate}}</span>
                                    </td>
                                </ng-container>
                        
                                <!-- Objection Type Column -->
                                <ng-container matColumnDef="type">
                                    <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.REASON' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngFor="let type of element.objectionTypes; let last = last"  class="text tab">{{'OPPOSITIONS.' + type | translate}} <span *ngIf="!last" class="bold"> / </span></span>
                                    </td>
                                </ng-container>
                        
                                <!-- opposition creation Date Column -->
                                <ng-container matColumnDef="creationDate">
                                    <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.DATE' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"><span class="text tab">{{element.dateObjection | dateTime}}</span></td>
                                </ng-container>

                                <!-- opposition Label Column -->
                                <ng-container matColumnDef="label">
                                    <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.LABEL' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"><span class="text tab">{{element.label ? element.label : '-'}}</span></td>
                                </ng-container>

                                <!-- opposition statue Column -->
                                <!--
                                <ng-container matColumnDef="status">
                                    <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.STATUS' | translate}}</th>
                                    <td mat-cell *matCellDef="let element"><span class="text tab">{{element.statue ? element.statue : '-'}}</span></td>
                                </ng-container>
                                -->

                                <ng-container matColumnDef="statements" >
                                    <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.OPERATIONS' | translate}}</th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.statements.length > 0">
                                            <span class="text tab link" *ngIf="element"  [matMenuTriggerFor]="statementsMenu" #menuTrigger="matMenuTrigger" (click)="openMenu(element)">
                                                <i class="fa fa-tasks" aria-hidden="true" width="16" height="16"></i>
                                                {{element.statements.length}}
                                            </span>
    
                                            <mat-menu #statementsMenu="matMenu" xPosition="before">
                                                <div class="statement-mat-menu" (click)="$event.stopPropagation()">
                                                  <div class="statement-header">
                                                    <span class="statement-title">
                                                    <i class="fa fa-tasks" aria-hidden="true" width="16" height="16"></i>
                                                    {{'OPPOSITIONS.OPERATIONS' | translate}}
                                                    </span>
                                                  </div>
                                                  
                                                  <div class="statement-container">
                                                    <ul>
                                                        <li>
                                                            <ngx-simplebar class="statement-content">
                                                                <div class="statement-tab">
                                                                  <table mat-table [dataSource]="element.statements" class="mat-elevation-z8 tab-statement">
                                                                    <ng-container matColumnDef="statementCategorieIcon" >
                                                                        <th mat-header-cell *matHeaderCellDef></th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            <img src="../../../assets/images/home/transactions-category/{{element.category}}@2x.png"
                                                                                srcset="../../../assets/images/home/transactions-category/{{element.category}}@3x.png"
                                                                                class="ic-category" alt="Catégorie">
                                                                        </td>
                                                                    </ng-container>
                                                                    
                                                                    <ng-container matColumnDef="statementCategory" >
                                                                        <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.CATEGORY' | translate}}</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            <span  class="text statement-text">{{element.category}}</span>
                                                                        </td>
                                                                    </ng-container>
                                                                    
                                                                    <ng-container matColumnDef="name" >
                                                                        <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.NAME' | translate}}</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            <span  class="text statement-text">{{element.name}}</span>
                                                                        </td>
                                                                    </ng-container>
    
                                                                    <ng-container matColumnDef="amount" >
                                                                        <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.AMOUNT' | translate}}</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            <span  class="text statement-text">{{element.amount ? element.amount + ' ' +     (element.currency | currencySymbol) : '-'}}</span>
                                                                        </td>
                                                                    </ng-container>
    
                                                                    <ng-container matColumnDef="status" >
                                                                        <th mat-header-cell *matHeaderCellDef>{{'OPPOSITIONS.STATUS' | translate}}</th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            <span  class="text statement-text status green">{{element.statue}}</span>
                                                                        </td>
                                                                    </ng-container>
                                                                    
                                                                    <ng-container matColumnDef="actions" >
                                                                        <th mat-header-cell *matHeaderCellDef></th>
                                                                        <td mat-cell *matCellDef="let element">
                                                                            <span class="statement-link-text statement-text float-right" (click)="seeStatementDetails(element)">{{'OPPOSITIONS.DETAILS' | translate}}</span>
                                                                        </td>
                                                                    </ng-container>
                                                                    
                                                                    <tr mat-header-row *matHeaderRowDef="statementDisplayedColumns"></tr>
                                                                    <tr mat-row *matRowDef="let row; columns: statementDisplayedColumns;"  class="statement-table-row"></tr>
                                                                  </table>
                                                                </div>
                                                            </ngx-simplebar>
                                                        </li>
                                                    </ul>
                                                  </div>
                                  
                                                </div>
                                              </mat-menu>
                                        </ng-container> 
                                    </td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="oppositionDisplayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: oppositionDisplayedColumns;"  class="table-row"></tr>
                            </table>
                        </div>
                    </ngx-simplebar>
                </div> 
            </div>
        </div>
    </div>
</div>

<ng-template #NO_READ_PERMISSION>
    <div  class="none-block">
        <div class="none-inner-block">
            <img src="../../../assets/images/vide/vide@2x.png"
                srcset="../../../assets/images/vide/vide@2x.png 2x,
                ../../../assets/images/vide/vide@3x.png 3x"
                class="none" alt="None">
            <p class="text none-text"> {{'ACCESS_CONTROL.NO_ACCESS' | translate}} </p> 
        </div>     
    </div>
</ng-template>
