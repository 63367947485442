import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { Router } from '@angular/router';
import { LoadOperationsAttemptedAction, DeleteOperationAttemtpedAction } from '../../../core/store/operation/operation.action';
import { getOperations, getOperationDeleteResponse } from '../../../core/store/operation/operation.reducer';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.scss']
})
export class OperationComponent implements OnInit, OnDestroy {

  haveReadPermission = true;

  haveWritePermission = true;

  private subscription;

  loading: boolean;

  operations: any[];

  operationsLength: number;

  operationsDisplayedColumns: string[] = ['name', 'operation', 'paidType', 'commission', 'description', 'actions'];

  private dialogSubscription$;

  private deleteSubscription$;

  constructor(private store: Store<AppState>, private router: Router, private dialog: MatDialog, private translate: TranslateService) { }

  ngOnInit(): void {
    this.loading = true;

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'APPLICATION_PACKAGE_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'APPLICATION_PACKAGE_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }
    this.store.dispatch(new LoadOperationsAttemptedAction());
    this.subscription = this.store.select(getOperations).pipe(filter(data => data !== undefined)).subscribe(data => {
      console.log(data);
      this.operations = data;
      this.operationsLength = data.length;
      this.loading = false;
      console.log('loading is : ', this.loading);
    });
    this.deleteSubscription$ = this.store.select(getOperationDeleteResponse).pipe(filter(resp => resp !== undefined)).subscribe(data => {
      console.log(data);
      if (data.message !== 'DELETED') {
        this.dialog.open(DialogComponent, {
          width: '30%',
          data: {
            button: 'serverError'
          }
        });
      } else {
        this.store.dispatch(new LoadOperationsAttemptedAction());
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.dialogSubscription$) {
      this.dialogSubscription$.unsubscribe();
    }
    if (this.deleteSubscription$) {
      this.deleteSubscription$.unsubscribe();
    }
  }

  deleteOperation(operation: any): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '30%',
      data: {
        title: this.translate.instant('CONFIRMATION_CHOICE'),
        message: this.translate.instant('DELETE_OPERATION_CONFIRMATION_MESSAGE'),
        buttons: 'oui-non',
        type: 'DELETE_OPERATION_CONFIRMATION',
        value: operation
      }
    });
    this.dialogSubscription$ = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.store.dispatch(new DeleteOperationAttemtpedAction(operation.id));
      }
    });
  }

  onNewOperation(): void {
    console.log('NAVIGATING TO /CREATE');
    this.router.navigate(['/application/packages/operations/create']);
  }

}
