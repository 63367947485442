import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { PasswordResetRequestAttemptedAction } from '../../core/store/auth/auth.action';
import { getPasswordResetInitResponse } from '../../core/store/auth/auth.reducer';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-password-forgot',
  templateUrl: './password-forgot.component.html',
  styleUrls: ['./password-forgot.component.scss']
})
export class PasswordForgotComponent implements OnInit {

  @ViewChild('emailRef') emailRef: ElementRef;

  private pwdResetRequestSubscription;

  isResetRequestSuccess = false;

  isResetRequestFailed = false;

  sending: boolean;

  isSubmitClicked = false;

  submitted = false;

  pwResetForm: FormGroup;

  constructor(private store: Store<AppState>, private router: Router, private fb: FormBuilder) {
    this.pwResetForm = this.fb.group({
      emailFormControl: [null, Validators.compose([Validators.required, Validators.email])]
    });
  }

  ngOnInit(): void {
    this.pwdResetRequestSubscription = this.store.select(getPasswordResetInitResponse).pipe(filter(data => data !== undefined))
    .subscribe(resp => {
      console.log('** PASSWORD RESET INIT RESPONSE : ', resp);
      if (resp.code === 200 && resp.value === 'SEND') {
        this.isResetRequestSuccess = true;
      } else {
        this.isResetRequestFailed = true;
      }
      this.sending = false;
    });
  }

  onEnter(event: any): void {
    if (event.key === 'Enter') {
      this.onSubmit();
    }
  }

  send(): void {
    this.sending = true;
    const payload = {email: this.pwResetForm.get('emailFormControl').value};
    this.store.dispatch(new PasswordResetRequestAttemptedAction(payload));
  }

  onSubmit(): void {
    if ( (this.pwResetForm.get('emailFormControl').hasError('required') || !this.emailRef.nativeElement.checkValidity()) ) {
      this.pwResetForm.markAllAsTouched();
      this.isSubmitClicked = true;
    } else {
      this.send();
    }
  }

  onConnect(): void {
    this.router.navigate(['/signin']);
  }

}
