import { filter, take } from 'rxjs/operators';
import { LoadPotsAttemptedAction } from './../../core/store/pot/pot.actions';
import { GetUserAttemptedAction } from './../../core/store/user-details/user-details.action';
import { Store } from '@ngrx/store';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppState } from 'src/app/core/store';
import { getPots } from 'src/app/core/store/pot/pot.reducer';


@Component({
  selector: 'app-pots',
  templateUrl: './pots.component.html',
  styleUrls: ['./pots.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PotsComponent implements OnInit {

  haveReadPermission = false;

  haveWritePermission = false;

  startDate = new Date();

  potNameSearchBox = '';

  jackpotsDisplayedColumns: string[] = [
    'category_jackpot',
    'title_jackpot',
    'days_jackpot',
    'participants_jackpot',
    'collected_jackpot',
    'finished_jackpot'
  ];

  options = { autoHide: false, scrollbarMinSize: 100 }

  pots$: any[] = [];

  potsLength: number;

  loading: boolean;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.loading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_SAFEDEPOSIT_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_SAFEDEPOSIT_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadPotsAttemptedAction({idClient: client.id}));
    this.store.select(getPots).pipe(filter(resp => resp !== undefined), take(1)).subscribe(data => {
      console.log('pots data is ', data);
      this.pots$ = data;
      this.potsLength = data.length;
      this.loading = false;
    });

  }

  openDialogShare(): void {

  }

  editPot(): void {

  }

  feedPot(): void {

  }

  finishPot(): void {

  }

  filtrer(): void {

  }

  onDateChange(): void {

  }

}
