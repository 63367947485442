<div class="content" fxFlex>
    <div fxFlex="73.5%" class="bg-white full-height">
        <div class="header" *ngIf="deposit">
            <img src="../../../../assets/images/back-btn/back-btn@2x.png"
               srcset="../../../../assets/images/back-btn/back-btn@2x.png 2x,
                 ../../../../assets/images/back-btn/back-btn@3x.png 3x"
               class="back" alt="Back Button" (click)="onBack()">
            <h3 class="text header-title">{{'DEPOSIT.DETAILS' | translate}}  "{{deposit.title}}"</h3>
        </div>

        <div class="details" *ngIf="deposit">
            <div class="details-title-column">
                <span class="text details-title">{{ 'DEPOSIT.CATEGORY' | translate}} :</span>
                <span class="text details-title">{{ 'DEPOSIT.BALANCE' | translate}} :</span>
                <span class="text details-title">{{ 'DEPOSIT.MODE' | translate}} :</span>
                <span class="text details-title">{{ 'DEPOSIT.START_DATE' | translate}}</span>
                <span class="text details-title">{{ 'DEPOSIT.DEADLINE' | translate}}</span>
            </div>

            <div class="details-text-column">
                <div class="deposit-category">
                    <span class="text details-text">{{ 'CATEGORIES.' + deposit.safeDepositType | translate}}</span>
                    <img src="../../../../assets/images/home/safe-deposit/category/{{deposit.safeDepositType}}@2x.png"
                        srcset="../../../../assets/images/home/safe-deposit/category/{{deposit.safeDepositType}}@2x.png 2x,
                                ../../../../assets/images/home/safe-deposit/category/{{deposit.safeDepositType}}@3x.png 3x"
                        class="category" alt="Category">
                </div>
                <span class="text details-text">{{deposit.totalAmount}} {{deposit.currency | currencySymbol}}</span>
                <span class="text details-text">
                    {{ deposit.periodic ? ('DEPOSIT.FEED_PER' | translate) : ('DEPOSIT.FEED_PON' | translate)}}
                    {{ deposit.frequencyType ? '-' + ('DEPOSIT.FREQUENCY_TYPE.' + deposit.frequencyType | translate) : ''}}
                </span>
                <span class="text details-text">{{deposit.startDate}}</span>
                <span class="text details-text">{{deposit.excutionDate}}</span>
            </div>
        </div> 
    </div>
</div>
