import { Action } from '@ngrx/store';

export const LOAD_BACKOFFICE_USERS_ATTEMPTED = '[User Management] Load users attempted';
export const LOAD_BACKOFFICE_USERS_SUCCESSFUL = '[User Management] Load users successful';
export const LOAD_BACKOFFICE_USERS_FAILED = '[User Management] Load users failed';

export const ADD_BACKOFFICE_USER_ATTEMPTED = '[User Management] Add user attempted';
export const ADD_BACKOFFICE_USER_SUCCESSFUL = '[User Management] Add user successful';
export const ADD_BACKOFFICE_USER_FAILED = '[User Management] Add user failed';

export const SET_BACKOFFICE_USER_PRIVILIGE_ATTEMPTED = '[User Management] Set privilige to user attempted';
export const SET_BACKOFFICE_USER_PRIVILIGE_SUCCESSFUL = '[User Management] Set privilige to user successful';
export const SET_BACKOFFICE_USER_PRIVILIGE_FAILED = '[User Management] Set privilige to user failed';

export const DELETE_BACKOFFICE_USER_ATTEMPTED = '[User Management] Delete user attempted';
export const DELETE_BACKOFFICE_USER_SUCCESSFUL = '[User Management] Delete user successful';
export const DELETE_BACKOFFICE_USER_FAILED = '[User Manegement] Delete user failed';

export const UPDATE_BACKOFFICE_USER_ACCOUNT_ATTEMPTED = '[User Management] Update user account attempted';
export const UPDATE_BACKOFFICE_USER_ACCOUNT_SUCCESSFUL = '[User Management] Update user account successful';
export const UPDATE_BACKOFFICE_USER_ACCOUNT_FAILED = '[User Management] Update user account failed';

export const SELECT_BACKOFFICE_USER = '[User Management] Select current user';

export const LOAD_BACKOFFICE_USER_PROFILE_ATTEMPTED = '[User Management] Load user profile attempted';
export const LOAD_BACKOFFICE_USER_PROFILE_SUCCESSFUL = '[User Management] Load user profile successful';
export const LOAD_BACKOFFICE_USER_PROFILE_FAILED = '[User Management] Load user profile failed';

export const UPDATE_BACKOFFICE_USER_PROFILE_ATTEMPTED = '[User Management] Update user profile infos attempted';
export const UPDATE_BACKOFFICE_USER_PROFILE_SUCCESSFUL = '[User Management] Update user profile infos successful';
export const UPDATE_BACKOFFICE_USER_PROFILE_FAILED = '[User Management] Update user profile infos failed';

export const CHANGE_PASSWORD_ATTEMPTED = '[User Management] Change user password attempted';
export const CHANGE_PASSWORD_SUCCESSFUL = '[User Management] Change user password successful';
export const CHANGE_PASSWORD_FAILED = '[User Management] Change user password failed';

export const UPDATE_BACKOFFICE_USER_CREDENTIALS_ATTEMPTED = '[User Management] Update user credentials attempted';
export const UPDATE_BACKOFFICE_USER_CREDENTIALS_SUCCESSFUL = '[User Management] Update user credentials successful';
export const UPDATE_BACKOFFICE_USER_CREDENTIALS_FAILED = '[User Management] Update user credentials failed';

export const SEARCH_BACKOFFICE_USERS_BY_NAME_ATTEMPTED = '[User Management] Search user by name - attempted';
export const SEARCH_BACKOFFICE_USERS_BY_NAME_SUCCESSFUL = '[User Management] Search user by name - successful';
export const SEARCH_BACKOFFICE_USERS_BY_NAME_FAILED = '[User Management] Search user by name - failed';


export class LoadBackofficeUsersAttempted implements Action {
    readonly type = LOAD_BACKOFFICE_USERS_ATTEMPTED;
    constructor() {}
};

export class LoadBackofficeUsersSuccessfulAction implements Action {
    readonly type = LOAD_BACKOFFICE_USERS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadBackofficeUsersFailedAction implements Action {
    readonly type = LOAD_BACKOFFICE_USERS_FAILED;
    constructor(public payload: any) {}
}

export class AddBackofficeUserAttemptedAction implements Action {
    readonly type = ADD_BACKOFFICE_USER_ATTEMPTED;
    constructor(public payload: any) {}
}

export class AddBackofficeUserSuccessfulAction implements Action {
    readonly type = ADD_BACKOFFICE_USER_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class AddBackofficeUserFailedAction implements Action {
    readonly type = ADD_BACKOFFICE_USER_FAILED;
    constructor(public payload: any) {}
}

export class SetBackofficeUserPriviligeAttemptedAction implements Action {
    readonly type = SET_BACKOFFICE_USER_PRIVILIGE_ATTEMPTED;
    constructor(public idUser: string, public payload: any) {}
}

export class SetBackofficeUserPriviligeSuccessfulAction implements Action {
    readonly type = SET_BACKOFFICE_USER_PRIVILIGE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class SetBackofficeUserPriviligeFailedAction implements Action {
    readonly type = SET_BACKOFFICE_USER_PRIVILIGE_FAILED;
    constructor(public payload: any) {}
}

export class DeleteBackofficeUserAttemptedAction implements Action {
    readonly type = DELETE_BACKOFFICE_USER_ATTEMPTED;
    constructor(public idUser: string) {}
}

export class DeleteBackofficeUserSuccessfulAction implements Action {
    readonly type = DELETE_BACKOFFICE_USER_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class DeleteBackofficeUserFailedAction implements Action {
    readonly type = DELETE_BACKOFFICE_USER_FAILED;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserAccountAttemptedAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_ACCOUNT_ATTEMPTED;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserAccountSuccessfulAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_ACCOUNT_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserAccountFailedAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_ACCOUNT_FAILED;
    constructor(public payload: any) {}
}

export class SelectBackofficeUserAction implements Action {
    readonly type = SELECT_BACKOFFICE_USER;
    constructor(public payload: any) {}
}

export class LoadBackofficeUserProfileAttemptedAction implements Action {
    readonly type = LOAD_BACKOFFICE_USER_PROFILE_ATTEMPTED;
    constructor() {}
}

export class LoadBackofficeUserProfileSuccessfulAction implements Action {
    readonly type = LOAD_BACKOFFICE_USER_PROFILE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class LoadBackofficeUserProfileFailedAction implements Action {
    readonly type = LOAD_BACKOFFICE_USER_PROFILE_FAILED;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserProfileAttemptedAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_PROFILE_ATTEMPTED;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserProfileSuccessfulAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_PROFILE_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserProfileFailedAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_PROFILE_FAILED;
    constructor(public payload: any) {}
}

export class ChangePasswordAttemptedAction implements Action {
    readonly type = CHANGE_PASSWORD_ATTEMPTED;
    constructor(public payload: any) {}
}

export class ChangePasswordSuccessfulAction implements Action {
    readonly type = CHANGE_PASSWORD_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class ChangePasswordFailedAction implements Action {
    readonly type = CHANGE_PASSWORD_FAILED;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserCredentialsAttemptedAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_CREDENTIALS_ATTEMPTED;
    constructor(public idUser: string, public payload: any) {}
}

export class UpdateBackofficeUserCredentialsSuccessfulAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_CREDENTIALS_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class UpdateBackofficeUserCredentialsFailedAction implements Action {
    readonly type = UPDATE_BACKOFFICE_USER_CREDENTIALS_FAILED;
    constructor(public payload: any) {}
}

export class SearchBackofficeUsersByNameAttemptedAction implements Action {
    readonly type = SEARCH_BACKOFFICE_USERS_BY_NAME_ATTEMPTED;
    constructor(public nameKey: string) {}
}

export class SearchBackofficeUsersByNameSuccessfulAction implements Action {
    readonly type = SEARCH_BACKOFFICE_USERS_BY_NAME_SUCCESSFUL;
    constructor(public payload: any) {}
}

export class SearchBackofficeUsersByNameFailedAction implements Action {
    readonly type = SEARCH_BACKOFFICE_USERS_BY_NAME_FAILED;
    constructor(public payload: any) {}
}


export type UserManagementTypes =
    |LoadBackofficeUsersAttempted
    |LoadBackofficeUsersFailedAction
    |LoadBackofficeUsersSuccessfulAction
    |AddBackofficeUserAttemptedAction
    |AddBackofficeUserFailedAction
    |AddBackofficeUserSuccessfulAction
    |SetBackofficeUserPriviligeAttemptedAction
    |SetBackofficeUserPriviligeFailedAction
    |SetBackofficeUserPriviligeSuccessfulAction
    |DeleteBackofficeUserAttemptedAction
    |DeleteBackofficeUserFailedAction
    |DeleteBackofficeUserSuccessfulAction
    |UpdateBackofficeUserAccountAttemptedAction
    |UpdateBackofficeUserAccountFailedAction
    |UpdateBackofficeUserAccountSuccessfulAction
    |SelectBackofficeUserAction
    |LoadBackofficeUserProfileAttemptedAction
    |LoadBackofficeUserProfileFailedAction
    |LoadBackofficeUserProfileSuccessfulAction
    |UpdateBackofficeUserProfileAttemptedAction
    |UpdateBackofficeUserProfileFailedAction
    |UpdateBackofficeUserProfileSuccessfulAction
    |ChangePasswordAttemptedAction
    |ChangePasswordFailedAction
    |ChangePasswordSuccessfulAction
    |UpdateBackofficeUserCredentialsAttemptedAction
    |UpdateBackofficeUserCredentialsFailedAction
    |UpdateBackofficeUserCredentialsSuccessfulAction
    |SearchBackofficeUsersByNameAttemptedAction
    |SearchBackofficeUsersByNameFailedAction
    |SearchBackofficeUsersByNameSuccessfulAction
    ;
