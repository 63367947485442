import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { ResetPasswordAttemptedAction } from '../../core/store/auth/auth.action';
import { getPasswordResetResponse } from '../../core/store/auth/auth.reducer';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  queryKey: string;

  private resetSubscription;

  isResetSuccess = false;

  isResetFailed = false;

  sending: boolean;

  isSubmitClicked = false;

  submitted = false;

  pwdResetForm: FormGroup;

  hide = true;

  constructor(private store: Store<AppState>, private router: Router, private route: ActivatedRoute, private fb: FormBuilder) {
    this.pwdResetForm = this.createForm();
  }

  private passwordMatchValidator(control: AbstractControl): void {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ NoPasswordMatch: true});
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.queryKey = params.key;
    });
    this.resetSubscription = this.store.select(getPasswordResetResponse).pipe(filter(data => data !== undefined)).subscribe(resp => {
      console.log('NEW PASSWORD RESET RESPONSE IS : ', resp);
      if (resp.code === 200 && resp.value === 'UPDATED') {
        this.isResetSuccess = true;
      } else {
        this.isResetFailed = true;
      }
      // this.resetForm();
      this.sending = false;
    });
  }

  createForm(): FormGroup {
    return this.fb.group(
    {
      password: [null, Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: [null, Validators.compose([Validators.required])]
    },
    {
      validator: this.passwordMatchValidator
    });
  }

  onSubmit(): void {
    this.sending = true;
    const payload = { key: this.queryKey, newPassword: this.pwdResetForm.get('password').value };
    console.log('ON SUBMIT PAYLOAD IS : ', payload);
    this.store.dispatch(new ResetPasswordAttemptedAction(this.queryKey, payload));
  }

  onConnect(): void {
    this.router.navigate(['/signin']);
  }

}
