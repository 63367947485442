import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store';
import { GetUserAttemptedAction } from '../../../core/store/user-details/user-details.action';
import { LoadChildsAttemptedAction, SelectChildAction, LoadChildCardsAttemptedAction, GetWalletAttemptedAction } from '../../../core/store/cards/cards.actions';
import { getChilds, getChildCards, getWallet } from '../../../core/store/cards/cards.reducer';
import { filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-child',
  templateUrl: './child.component.html',
  styleUrls: ['./child.component.scss']
})
export class ChildComponent implements OnInit, OnDestroy {

  haveReadPermission = false;

  haveWritePermission = false;

  loading: boolean;

  walletIsLoading: boolean;

  selectedChild: any;

  wallet: any;

  cardsLoading: boolean;

  childsLength: number;

  childs$: any;

  childsDisplayedColumns: string[] = ['firstname_child', 'lastname_child', 'email_child', 'birthday_child', 'citoyen_child', 'status_child'];

  childCardsLength = 0;

  childCards$: any;

  childCardsDisplayedColumns: string[] = ['card_child', 'embossedName_child', 'status_child', 'expiration_child', 'more_child'];

  screen = 1;

  card: any;

  cardDetailsLoading = false;

  isCardSelected: boolean;

  private walletSubscription$;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.isCardSelected = false;
    this.walletIsLoading = true;
    this.selectedChild = null;
    const client = JSON.parse(localStorage.getItem('client'));

    this.store.dispatch(new GetUserAttemptedAction({idClient: client.id}));

    const account = JSON.parse(localStorage.getItem('user'));
    for (const permission of account.permissions) {
      if (permission.trim() === 'CLIENT_CARD_READ') {
        this.haveReadPermission = true;
      }
      if (permission.trim() === 'CLIENT_CARD_WRITE') {
        this.haveWritePermission = true;
      }
    }
    if (!this.haveReadPermission) {
      this.loading = false;
      return;
    }

    this.store.dispatch(new LoadChildsAttemptedAction({idClient: client.id}));
    this.store.select(getChilds).pipe(filter(childs => childs !== undefined), take(1))
      .subscribe(data => {
        this.childs$ = data.filter(child => child.userStatusName === 'ENABLED');
        this.childsLength = this.childs$.length;
        this.loading = false;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.walletSubscription$) {
      this.walletSubscription$.unsubscribe();
    }
  }

  onSeeMoreClicked(row: any): void {
    this.screen = 1;
    this.cardsLoading = true;
    this.selectedChild = row;
    const client = JSON.parse(localStorage.getItem('client'));
    console.log('row is ', row);
    this.store.dispatch(new SelectChildAction(row));
    this.store.dispatch(new LoadChildCardsAttemptedAction({idClient: client.id, idChild: row.id}));
    this.store.select(getChildCards).pipe(filter(data => data !== undefined), take(1)).subscribe(cards => {
      console.log('cards are : ', cards);
      this.childCards$ = cards;
      this.childCardsLength = cards.length;
    },
    (err) => console.log(err),
    () => { this.cardsLoading = false; console.log('childs loading is ', this.cardsLoading); });
  }

  onRefresh(): void {
    this.walletIsLoading = true;
    const client = JSON.parse(localStorage.getItem('client'));
    console.log('wallet payload is: ', {idClient: client.id, idWallet: this.childCards$[0].walletId});
    this.store.dispatch(new GetWalletAttemptedAction({idClient: client.id, idWallet: this.childCards$[0].walletId}));
    this.store.select(getWallet).pipe(filter(data => data !== undefined), take(1)).subscribe(obj => {
      console.log('WALLET: ', obj);
      this.wallet = obj;
      setTimeout(() => {
        this.walletIsLoading = false;
      }, 400);
    });
  }

  onOpenWallet(): void {
    this.walletIsLoading = true;
    console.log('getting wallet with payload :', {idClient: this.selectedChild.id, idWallet: this.childCards$[0].walletId});
    this.store.dispatch(new GetWalletAttemptedAction({idClient: this.selectedChild.id, idWallet: this.childCards$[0].walletId}));
    this.store.select(getWallet).pipe(filter(resp => resp !== undefined), take(1)).subscribe(obj => {
      console.log('SELECTED WALLET :', obj);
      this.wallet = obj;
      this.walletIsLoading = false;
    });
  }

  onSeeWallet(): void {
    this.router.navigate(['/clients/details/cards']);
  }

  onSeeOperations(): void {
    this.router.navigate(['/clients/details/cards']);
  }

  onChildCardDetails(card: any): void {
    this.screen = 2;
    this.cardDetailsLoading = true;
    setTimeout(() => {
      this.card = card;
      this.isCardSelected = true;
      this.cardDetailsLoading = false;
    }, 150);
  }

}
