import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PackageService {

  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getPackages(): any {
    return this.http.get(this.baseUrl + `/application/packages`);
  }

  add(payload: any): any {
    return this.http.post(this.baseUrl + `/application/packages`, payload);
  }

  update(idPackage: string, payload: any): any {
    return this.http.put(this.baseUrl + `/application/packages/${idPackage}`, payload);
  }

  delete(idPackage: string): any {
    return this.http.delete(this.baseUrl + `/application/packages/${idPackage}`);
  }

  affectPackageToClient(idClient: string, idPackageClient: string): any {
    console.log('ASSINGING PACKAGE TO CLIENT ', idClient, idPackageClient);
    return this.http.post(this.baseUrl + `/clients/${idClient}/package/changeclientpackage/${idPackageClient}`, {});
  }

  getClientPackage(idClient: string): any {
    return this.http.get(this.baseUrl + `/clients/${idClient}/package`);
  }

  affectClientsToPackage(idPackage: string, clientIds: string[]): any {
    /* TODO call assigning web service */
    return;
  }

  getPackageSelectedClients(idPackage: string): any {
    /* TODO call get selected clients */
    return;
  }
}
