import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromOperation from './operation.action';

export interface OperationState {
    data: any[];
    addResponse: any;
    deleteResponse: any;
    updateResponse: any;
    selectedOperation: any;
    action: string;
    loading: boolean;
    error: string;
}

export const initialState: OperationState = {
    data: undefined,
    addResponse: undefined,
    deleteResponse: undefined,
    updateResponse: undefined,
    selectedOperation: undefined,
    action: undefined,
    loading: false,
    error: undefined,
};

export function reducer(state: OperationState = initialState, action: fromOperation.OperationActions): any {
    switch (action.type) {
        case fromOperation.LOAD_OPERATIONS_ATTEMPTED:
            return {
                ...state,
                loading: true,
                data: undefined,
                addResponse: undefined,
                deleteResponse: undefined,
                selectedOperation: undefined
            };
        case fromOperation.LOAD_OPERATIONS_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                data: action.payload.value,
            };
        case fromOperation.LOAD_OPERATIONS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fromOperation.ADD_OPERATION_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromOperation.ADD_OPERATION_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                addResponse: action.payload,
            };
        case fromOperation.ADD_OPERATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fromOperation.UPDATE_OPERATION_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromOperation.UPDATE_OPERATION_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                updateResponse: action.payload,
            };
        case fromOperation.UPDATE_OPERATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case fromOperation.DELETE_OPERATION_ATTEMPTED:
            return {
                ...state,
                loading: true,
            };
        case fromOperation.DELETE_OPERATION_SUCCESSFUL:
            return {
                ...state,
                loading: false,
                deleteResponse: action.payload,
            };
        case fromOperation.DELETE_OPERATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
}

export const getOperationState = createFeatureSelector<OperationState>('operations');

export const getOperations = createSelector(getOperationState, (state: OperationState) => state.data);
export const getOperationAddResponse = createSelector(getOperationState, (state: OperationState) => state.addResponse);
export const getOperationUpdateResponse = createSelector(getOperationState, (state: OperationState) => state.updateResponse);
export const getOperationDeleteResponse = createSelector(getOperationState, (state: OperationState) => state.deleteResponse);
